import '../styles/globals.css'
import '../../node_modules/react-grid-layout/css/styles.css'
import '../../node_modules/react-resizable/css/styles.css'
import '../styles/AddWidgetSelector.css'
import '../styles/CustomShake.scss'
import '../styles/EmojiPicker.css'
// import 'tailwindcss/tailwind.css'
import '../styles/tweets.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import '../styles/Carousel.css'

// import 'react-static-tweets/styles.css'

import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { ReactElement, ReactNode } from 'react'

import { ChakraProvider, extendTheme } from '@chakra-ui/react'

import AuthModal from './../components/forms/auth/AuthModal'
import SettingsModal from './../components/settings/SettingsModal'
import DashboardSettingsModal from '../components/dashboard/dashboardSettings/DashboardSettingsModal'
import OnboardModal from '../components/onboard/Modal'
import AuthModalContextProvider from '../context/auth-modal/context'
import DashboardNavigationContextProvider from '../context/dashboard-navigation/context'
import DashboardSettingsModalContextProvider from '../context/dashboard-settings-modal/context'
import MobileWarningBannerContextProvider from '../context/mobile-alert-banner/context'
import NotificationsContextProvider from '../context/notifications/context'
import SettingsModalContextProvider from '../context/settings-modal/context'
import UserContextProvider from '../context/user/context'
import initAuth from '../utils/auth/initAuth'

// import '../../node_modules/csshake/dist/csshake.min.css'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const theme = extendTheme({
  colors: {
    brand: {
      bg: '#EDF2F7', // chakra ui's gray.100
      border: '#A0AEC0', // chakra ui's gray.400
      username: '#718096', // chakra ui's gray.500
      pageLink: '#4299E1', // chakra ui's blue.400
      following: '#79bcec80',
      purple: '#6277D7',
      purpleMedium: '#6277D790',
      purpleLight: '#6277D720',
    },
  },
  shadows: { outline: '0 !important' }, // remove focus outline
})

initAuth()

function MyApp({ Component, pageProps }: AppPropsWithLayout): ReactElement {
  const getLayout = Component.getLayout || ((page: ReactElement) => page)
  // const [rendered, setRendered] = useState(false)

  // useEffect(() => {
  //   setRendered(true)
  // }, [])

  // const mobileWarningToast = useToast()
  // const toastId = 'mobile-warning-toast'

  return (
    <>
      <UserContextProvider>
        <AuthModalContextProvider>
          <NotificationsContextProvider>
            <MobileWarningBannerContextProvider>
              <DashboardSettingsModalContextProvider>
                <DashboardNavigationContextProvider>
                  <SettingsModalContextProvider>
                    <ChakraProvider theme={theme} portalZIndex={10000}>
                      {getLayout(<Component {...pageProps} />)}
                      <SettingsModal />
                      <OnboardModal />
                      <AuthModal />
                      <DashboardSettingsModal snapshotsEnabled={false} />
                    </ChakraProvider>
                  </SettingsModalContextProvider>
                </DashboardNavigationContextProvider>
              </DashboardSettingsModalContextProvider>
            </MobileWarningBannerContextProvider>
          </NotificationsContextProvider>
        </AuthModalContextProvider>
      </UserContextProvider>
    </>
  )
}
export default MyApp
