import { useCurrentUser } from '../../context/user/context'

const useIsFollowingDashboard = (dashboardId: string): boolean => {
  const { user } = useCurrentUser()

  const isFollowing =
    user != null &&
    user.dashboardIdsInOrder.find((id) => id === dashboardId) != undefined

  return isFollowing
}

export default useIsFollowingDashboard
