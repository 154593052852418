export const excludedRanges = (text: string): number[][] => {
  const matches = Array.from(
    text.matchAll(/<(a|img)[^>]*(href|src)="(https?)[^>]+(\/?)>/g),
    (match) => {
      return match.index != undefined
        ? [match.index, match.index + match[0].length]
        : [-1, -1]
    }
  )

  return matches
}
