/**
 * Hook that handles clicks outside of the passed ref
 */
import React, { useEffect } from 'react'
/* usage:
in component of interest (containing element where clicks are considered "inside"):
...
ref = useRef(null)
const handleOutsideClick = () => {...}
useOutsideListener(ref, handleOutsideClick)
...
return <MyInsideElement ref={ref} ... />
*/

export function useOutsideListener(
  ref: React.RefObject<HTMLInputElement | HTMLDivElement | HTMLTextAreaElement>,
  onClickOutside: () => void
): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}
