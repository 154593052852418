import React, { ReactElement } from 'react'

import {
  Box,
  Container,
  Divider,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  VStack,
} from '@chakra-ui/react'

const FakeTwitterPreview = (): ReactElement => {
  return (
    <Box
      bg="white"
      w="100%"
      h="calc(100% - 20px)"
      borderRadius="lg"
      zIndex={0}
      overflowY="hidden"
      px="0"
      top="25px"
      pos="absolute"
      boxShadow={'0 0.75px 2.5px rgb(0 0 0 / 0.25)'}
    >
      <Container centerContent={true} minW="100%" padding="0" w="100%">
        <VStack
          p="0"
          w="100%"
          divider={<Divider borderColor="gray.300" w="95%" />}
          spacing={0}
        >
          {Array(4)
            .fill(0)
            .map((_, i) => {
              return (
                <VStack
                  key={`fake-tweet-${i}`}
                  w="100%"
                  paddingY="4"
                  paddingX="5"
                  align="start"
                  spacing={4}
                >
                  <HStack w="100%">
                    <SkeletonCircle
                      size="8"
                      startColor="gray.200"
                      endColor="gray.300"
                    />
                    <Skeleton
                      height={'16px'}
                      w="35%"
                      startColor="gray.200"
                      endColor="gray.300"
                    />
                  </HStack>
                  <SkeletonText
                    noOfLines={3}
                    w="100%"
                    startColor="gray.200"
                    endColor="gray.300"
                  />
                </VStack>
              )
            })}
        </VStack>
      </Container>
    </Box>
  )
}

export default FakeTwitterPreview
