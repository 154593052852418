/**
 * Component for displaying video or images embedded in a widget/elsewhere.
 * If the media is an image, it can be expanded by clicking on it to show a larger modal view.
 * If it is a video, display native controls for playing the video (including
 * fullscreen mode).
 */

// TODO: generalize styles (height, width, etc) to be props
import React, { ReactElement } from 'react'
import ReactPlayer from 'react-player'

import {
  Box,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { FocusableElement } from '@chakra-ui/utils'

import { generateYoutubeSrc } from '../../utils/general/generateYoutubeSrc'

interface Props {
  src: string
  alt?: string
  finalRef?: React.RefObject<FocusableElement>
  isVideo: boolean
  w?: number
  h?: number
}

const ExpandableMedia = ({
  src,
  alt,
  finalRef,
  isVideo,
}: Props): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (isVideo) {
    return <ReactPlayer url={generateYoutubeSrc(src)} controls />
  }

  const mediaContent = (borderRadius: string) => (
    <Image
      alt={alt ?? 'image'}
      borderRadius={borderRadius}
      fit="cover"
      h="100%"
      onClick={onOpen}
      src={src}
      w="100%"
    />
  )

  return (
    <>
      <Box
        onClick={onOpen}
        _hover={{ cursor: 'pointer' }}
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
      >
        {mediaContent('')}
      </Box>
      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent background="none" borderRadius="0.5">
          <ModalCloseButton
          // as={IconButton}
          />
          {mediaContent('0.5rem')}
        </ModalContent>
      </Modal>
    </>
  )
}

export default ExpandableMedia
