import firebase from 'firebase/app'

import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/app-check'

if (firebase.apps.length == 0) {
  firebase.initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_URL,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  })

  // firebase.firestore().useEmulator('localhost', 8080)
  // firebase.functions().useEmulator('localhost', 5002)
  // firebase.auth().useEmulator('http://localhost:9099')
}

// if (typeof document !== 'undefined') {
//   const appCheck = firebase.appCheck()
//   appCheck.activate('6LdpQicgAAAAAHxmtESgqOrqvbu5IPvoc2fRyyOj', true)
// }

export default firebase
