import React, { ReactElement } from 'react'
import { VStack, Text } from '@chakra-ui/react'
import useAuthToken from '../../../utils/hooks/useAuthToken'
import TwitterAuthButton from './TwitterAuthButton'
import { useToast } from '@chakra-ui/react'

const TwitterAuthPrompt = (): ReactElement => {
  const [, updateToken] = useAuthToken('twitter')

  const toast = useToast()

  const handleAuthError = (error: string) => {
    toast({
      title: `Error authenticating`,
      status: 'error',
      description: error,
      isClosable: true,
      position: 'bottom',
      duration: 9000,
    })
  }

  return (
    <VStack spacing={3} p={5}>
      <Text w="100%" color="gray.500">
        <VStack>
          <TwitterAuthButton
            onUpdateToken={updateToken}
            onError={handleAuthError}
          />
          <Text ml={1}>to view Tweets</Text>
        </VStack>
      </Text>
    </VStack>
  )
}

export default TwitterAuthPrompt
