interface Topics {
  [key: string]: string[]
}

export const topicsRef: Topics = {
  Markets: [
    'Cryptocurrencies',
    'Ethereum',
    'Bitcoin',
    'Economics',
    'Inflation',
  ],
  Technology: [
    'Data Science',
    'Tech personalities',
    'Mobile',
    'Gadgets',
    'Internet',
    'Virtual Reality',
    'Computing',
    'Artificial Intelligence',
  ],
  Sports: [
    'NFL',
    'NBA',
    'MLB',
    'Soccer',
    'NCAA Football',
    'NCAA Basketball',
    'NHL',
    'Formula 1',
    'UFC',
    'Premier League',
    'Lacrosse',
    'Sports news',
    'Esports',
    'ESPN',
  ],
  Business: [
    'Marketing',
    'Business personalities',
    'Personal finance',
    'Jobs',
    'Entrepreneurship',
  ],
  Entertainment: [
    'Movies',
    'Anime',
    'Podcasts',
    'Marvel universe',
    'Music festivals',
    'Digital creators',
    'Music',
    'TV',
    'Books',
    'Art',
    'Celebrities',
  ],
  Science: [
    'Psychology',
    'Biotech',
    'Science news',
    'Environment',
    'Outer space',
    'Physics',
    'Genetics',
    'Wildlife',
    'Medicine',
  ],
}
