import React, { ReactElement } from 'react'
import { Box, Center } from '@chakra-ui/react'
import { SubredditFlair } from '../types'

interface Props {
  text: string
  template: SubredditFlair | null
  subredditName: string | null
  size: 'md' | 'lg'
}
const Flair = ({
  template,
  text,
  subredditName,
  size,
}: Props): ReactElement => {
  const sizeMap = {
    md: {
      height: '1.3rem',
      fontSize: '0.8rem',
    },
    lg: {
      height: '1.6rem',
      fontSize: '0.9rem',
    },
  }
  const flairSearchLink = `https://www.reddit.com/r/${subredditName}/search?q=flair_name:"${text}"&restrict_sr=1`
  return (
    <Box
      as="a"
      display="inline-block"
      minWidth="3rem"
      px={2}
      h={sizeMap[size]['height']}
      backgroundColor={
        template?.background_color != null
          ? template.background_color
          : 'gray.300'
      }
      color={template?.text_color == 'dark' ? 'black' : 'white'}
      borderColor={template?.background_color === '' ? 'gray.300' : 'none'}
      borderWidth={template?.background_color === '' ? 1 : 0}
      fontSize={sizeMap[size]['fontSize']}
      borderRadius="md"
      href={flairSearchLink}
      target="_blank"
      _hover={{
        opacity: 0.85,
      }}
    >
      <Center h="100%" w="100%">
        {text}
      </Center>
    </Box>
  )
}

export default Flair
