import React, { ReactElement } from 'react'
import { TwitterToken } from '../../widgets/twitter/types'
import { RedditToken } from '../../widgets/reddit/types'
import { Box, Divider, Text, VStack } from '@chakra-ui/react'
import ConnectedAccountRow from './ConnectedAccountRow'
import { useCurrentUser } from '../../../context/user/context'
import { BODY_MARGIN } from '../SettingsModal'

export type ConnectedAccount = TwitterToken | RedditToken

function ManageConnectedAccounts(): ReactElement {
  const { connectedAccounts } = useCurrentUser()

  return (
    <VStack align="stretch" py={BODY_MARGIN.t} px={BODY_MARGIN.x} w="100%">
      <Text as="b" fontSize="2xl">
        Connected Accounts
      </Text>
      <Box marginBottom="4">
        <Text>
          {`Your connected accounts are used to fetch the data for your widgets on
          your behalf. We'll never post on your behalf.`}
        </Text>
      </Box>
      {connectedAccounts.length === 0 ? (
        <Text as="i" textColor="gray">
          No connected accounts
        </Text>
      ) : (
        <VStack align="stretch" spacing="0" w="100%">
          {connectedAccounts.map((account, i) => (
            <div key={account.type}>
              <ConnectedAccountRow account={account} />
              {i !== connectedAccounts.length - 1 && <Divider marginY="4" />}
            </div>
          ))}
        </VStack>
      )}
    </VStack>
  )
}

export default ManageConnectedAccounts
