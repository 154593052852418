/*
Twitter widget
*/

import React, { ReactElement } from 'react'
import TweetV2 from './new-display/tweet'

import { Box, Divider, VStack } from '@chakra-ui/react'

import NullWidget from '../NullWidget'
import { TwitterWidgetData } from './types'

interface Props {
  data: TwitterWidgetData | undefined
}

const TwitterWidgetView = ({ data }: Props): ReactElement => {
  if (data == undefined || data.tweets.length === 0) {
    return <NullWidget emojis="🐦 😵‍💫" />
  }
  const dataVersion = data.version ?? 1
  if (dataVersion < 2) {
    return <NullWidget emojis="🐦 😵‍💫" text="Please refresh" />
  }
  return (
    <VStack
      divider={<Divider borderColor="gray.300" w="100%" />}
      spacing={0}
      p={0}
      pt={2}
      align="center"
      w="100%"
    >
      {data?.tweets.map((tweet, index) => {
        return (
          <Box w="100%" px={1} py={2} key={`${tweet.id}-${index}`}>
            {dataVersion >= 2 && <TweetV2 tweet={tweet} />}
          </Box>
        )
      })}
    </VStack>
  )
}

export default TwitterWidgetView
// import React, { ReactElement } from 'react'
// import { Tweet as TweetEmbed } from 'react-static-tweets'

// import { Container, StackDivider, VStack } from '@chakra-ui/react'

// import useAuthToken from '../../../utils/hooks/useAuthToken'
// import NullWidget from '../NullWidget'
// import TwitterAuthPrompt from './TwitterAuthPrompt'
// import { TwitterWidgetData } from './types'

// interface TwitterWidgetProps {
//   data: TwitterWidgetData | undefined
//   isFollowing: boolean
//   isSnapshot: boolean
// }

// const TwitterWidgetView = ({
//   data,
//   isFollowing,
//   isSnapshot,
// }: TwitterWidgetProps): ReactElement => {
//   const [token] = useAuthToken('twitter')
//   const hasTwitterToken = token != null

//   if (data == undefined || (data.tweets.length === 0 && hasTwitterToken)) {
//     return (
//       <NullWidget
//         emojis="🐦 😵‍💫"
//         isFollowing={isFollowing}
//         isSnapshot={isSnapshot}
//       />
//     )
//   }

//   if (!hasTwitterToken)
//     return (
//       <VStack
//         w="100%"
//         position="absolute"
//         top="50%"
//         transform="translateY(-50%);"
//       >
//         <TwitterAuthPrompt />
//       </VStack>
//     )

//   return (
//     <Container centerContent={true} w="100%" padding="0" paddingTop="1">
//       {hasTwitterToken && (
//         <VStack
//           w="100%"
//           align="stretch"
//           divider={<StackDivider borderColor="gray.200" />}
//           spacing={0}
//         >
//           {data?.tweets.map((tweet) => {
//             //return <TweetDisplay key={tweet.id} tweet={tweet} />
//             return (
//               // <Box
//               //   borderWidth="3px"
//               //   key={tweet.id}
//               //   // marginY="-2"
//               //   borderRadius="xl"
//               // >
//               <TweetEmbed
//                 key={tweet.id}
//                 id={tweet.id}
//                 // options={{ padding: '200px' }}
//               />
//               // </Box>
//             )
//           })}
//         </VStack>
//       )}
//     </Container>
//   )
// }

// export default TwitterWidgetView
