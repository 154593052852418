import React from 'react'
import { Button } from '@chakra-ui/react'
import { createRedditAuthUrl } from './redditClient'

const RedditAuthButton = (): React.ReactElement => {
  const authUrl = createRedditAuthUrl('_', '_')
  return (
    <Button
      as="a"
      borderRadius={20}
      bg="#ff4500"
      _hover={{ bg: '#FF5525', cursor: 'pointer' }}
      color="white"
      px={10}
      onClick={() => {
        window.open(authUrl, '_blank')
      }}
    >
      Log in with Reddit
    </Button>
  )
}

export default RedditAuthButton
