import React, { ReactElement } from 'react'
import {
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  Button,
  Alert,
  AlertIcon,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import DeleteButton from '../../general/DeleteButton'
import { useState } from 'react'

interface AlertProps {
  onConfirm: () => void
  onSuccess: () => void
}

const AlertContent = ({ onConfirm, onSuccess }: AlertProps): ReactElement => {
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    setLoading(true)
    try {
      onConfirm()
      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }
  return (
    <AlertDialogContent>
      <AlertDialogCloseButton />
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Are you sure?
      </AlertDialogHeader>
      <AlertDialogBody>
        <Alert status="warning">
          <AlertIcon />
          Deleting a widget cannot be undone.
        </Alert>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button
          colorScheme="red"
          onClick={handleConfirm}
          ml={3}
          isLoading={loading}
        >
          Delete widget
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}

interface Props {
  onDelete: () => void
  as?: ReactElement
}
const DeleteWidgetButton = ({ onDelete, as }: Props): ReactElement => {
  return (
    <DeleteButton
      as={as}
      renderAlertContent={({ onSuccess }: { onSuccess: () => void }) => (
        <AlertContent onSuccess={onSuccess} onConfirm={onDelete} />
      )}
    />
  )
}
export default DeleteWidgetButton
