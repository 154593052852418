import { ReactElement, ReactNode } from 'react'

import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'

import { CommentData } from '../types'

interface Props {
  comment: CommentData
  children: ReactNode
  type: 'like' | 'dislike'
}

const ActivityList = ({ comment, type, children }: Props): ReactElement => {
  const userActivity = {
    like: comment.activity.likes,
    dislike: comment.activity.dislikes,
  }

  const counts = {
    like: comment.activityCounts.likes,
    dislike: comment.activityCounts.dislikes,
  }

  const userList = userActivity[type]

  if (counts[type] == 0) {
    return <>{children}</>
  }

  return (
    <Popover trigger="hover" openDelay={1000}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent w="max-content">
        <PopoverArrow />
        <PopoverBody>
          <VStack>
            {Object.entries(userList).map(([key, value]) => {
              return (
                <Text fontSize="sm" key={key} isTruncated>
                  {value}
                </Text>
              )
            })}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default ActivityList
