/**
 * Drawer component for displaying an expanded Reddit post
 */

import React, { ReactElement, useState } from 'react'
import {
  Text,
  HStack,
  Divider,
  Center,
  Image,
  // Switch,
  Box,
  Spacer,
  IconButton,
  Tooltip,
  useMediaQuery,
  VStack,
  Icon,
} from '@chakra-ui/react'
import { RedditPost, SubredditStyles } from '../types'
import Highlighted from '../../../layout/Highlighted'
import PostHeader from './PostHeader'
import ExpandableMedia from '../../../layout/ExpandableMedia'
import VerticalDrawer from '../../../layout/VerticalDrawer'
import { parseRedditMardownToHTML } from '../utils/parseRedditMarkdownToHTML'
import { extendKeyWordsToSearchMatches } from '../utils/extendKeyWordsToSearchMatches'
import ShortenedLink from './ShortenedLink'
import { excludedRanges } from '../utils/excludedRanges'
import { GrFormNext, GrFormPrevious } from 'react-icons/gr'
import CommentsDisplay from './CommentsDisplay'
// import { AiTwotoneSound } from 'react-icons/ai'
import { AiOutlineInfoCircle } from 'react-icons/ai'

// import useAuthToken from '../../../../utils/hooks/useAuthToken'

interface Props {
  isOpen: boolean
  onClose: () => void
  postData: RedditPost | null
  keyWords: string[]
  subredditStyles: SubredditStyles | null | undefined
  subredditName: string | null
  onSetPostIndex: (index: number) => void
  currentPostIndex: number | null
  numPosts: number
}

const RedditPostDrawer = ({
  isOpen,
  onClose,
  postData,
  keyWords,
  subredditStyles,
  subredditName,
  onSetPostIndex,
  currentPostIndex,
  numPosts,
}: Props): ReactElement | null => {
  const [isHighlightingActive] = useState(false)

  const [isMobile] = useMediaQuery('(max-width: 840px)')

  // const [token, updateToken] = useAuthToken('reddit')

  // const getTopCommentsForCurrentPost = async () => {
  //   if (token != null) {
  //     const newToken = await getNewAccessToken(token)
  //     updateToken(newToken)
  //   }

  //   if (
  //     subredditName != null &&
  //     postData?.id != null &&
  //     token?.access_token != null
  //   ) {
  //     const comments = await getTopComments(
  //       'r/' + subredditName,
  //       postData.id,
  //       token.access_token
  //     )
  //     console.log(comments)
  //   }
  // }

  if (postData == null) {
    return <></>
  }

  // const toggleIsHighlightingActive = () => {
  //   setIsHighlightingActive((prev) => !prev)
  // }

  const parsedText = parseRedditMardownToHTML(postData.content)
  const rangesToExclude = excludedRanges(parsedText)

  const incrementPostIndex = () => {
    if (currentPostIndex != null) {
      onSetPostIndex(currentPostIndex + 1)
    }
  }

  const decrementPostIndex = () => {
    if (currentPostIndex != null) {
      onSetPostIndex(currentPostIndex - 1)
    }
  }

  const nextDisabled =
    currentPostIndex != null && currentPostIndex >= numPosts - 1
  const previousDisabled = currentPostIndex == 0

  const videoUrl =
    postData.postType === 'video'
      ? postData.media ?? postData.externalUrl
      : null

  const isRedditHostedVideo = videoUrl?.includes('redd.it')

  return (
    <VerticalDrawer
      width={'70vw'}
      height={'90vh'}
      isOpen={isOpen}
      onClose={onClose}
      paddingTop={6}
    >
      {!isMobile && (
        <>
          <Tooltip
            pos="absolute"
            label="Previous post"
            openDelay={1000}
            closeDelay={500}
          >
            <IconButton
              icon={<GrFormPrevious />}
              aria-label="Previous post"
              pos="absolute"
              right="87vw"
              bottom="45vh"
              isRound
              shadow="md"
              onClick={decrementPostIndex}
              isDisabled={previousDisabled}
            />
          </Tooltip>
          <Tooltip
            label="Next post"
            openDelay={1000}
            closeDelay={500}
            closeOnClick={true}
          >
            <IconButton
              icon={<GrFormNext />}
              aria-label="Next post"
              pos="absolute"
              left="87vw"
              bottom="45vh"
              isRound
              shadow="md"
              onClick={incrementPostIndex}
              isDisabled={nextDisabled}
            />
          </Tooltip>
        </>
      )}
      <HStack alignItems="top" w="100%" p={5} pt={0}>
        {/* <Button onClick={getTopCommentsForCurrentPost}> Comments</Button> */}
        <PostHeader
          postData={postData}
          keyWords={keyWords}
          subredditStyles={subredditStyles}
          subredditName={subredditName}
          titleIsLink={true}
        />
        <Spacer />
        {/* <Switch
          size="md"
          isChecked={isHighlightingActive}
          onChange={toggleIsHighlightingActive}
        /> */}
      </HStack>
      <Divider />
      <VStack w="100%" justifyContent={'center'}>
        <Box py={isMobile ? 5 : 7} px={isMobile ? 5 : 10} maxW="800px">
          <Text>
            {postData.postType === 'image' && (
              <Center>
                <Image alt={postData.media} src={postData.media} maxH="60vh" />
              </Center>
            )}
            {postData.postType == 'video' && (
              <VStack>
                <ExpandableMedia
                  alt={postData.media}
                  src={postData.media}
                  isVideo={true}
                />{' '}
                {isRedditHostedVideo && (
                  <Text
                    w="100%"
                    fontSize={14}
                    display="inline"
                    color="gray.600"
                  >
                    <Icon fontSize={20}>
                      <AiOutlineInfoCircle />
                    </Icon>
                    To play this video with sound,{' '}
                    <Text
                      as="a"
                      href={postData.url}
                      target="_blank"
                      color="brand.pageLink"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      view it on reddit.com.
                    </Text>
                  </Text>
                )}
              </VStack>
            )}
            {postData.postType === 'link' && (
              <Box w="max-content">
                <ShortenedLink url={postData.externalUrl} maxChars={60} />
              </Box>
            )}
          </Text>
          {postData.content != '' && (
            <Highlighted
              isHighlightingActive={isHighlightingActive}
              text={parsedText}
              keyWords={extendKeyWordsToSearchMatches(keyWords)}
              excludedRanges={rangesToExclude}
            />
          )}
        </Box>
        <Box pb={'2rem'} px={isMobile ? 5 : 10} w="100%" maxW="800px">
          <CommentsDisplay
            comments={postData.comments}
            postPermalink={postData.url}
          />
        </Box>
      </VStack>
    </VerticalDrawer>
  )
}

export default RedditPostDrawer
