import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { IoMdArrowBack } from 'react-icons/io'

import {
  Center,
  CloseButton,
  Divider,
  HStack,
  IconButton,
  Spacer,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../../context/dashboard-navigation/context'
import { useNotificationsContext } from '../../../context/notifications/context'
import firebase from '../../../utils/firebase/firebase'
import db from '../../../utils/firebase/firestore'
import Comment from './Comment'
import CommentTextBox from './CommentTextBox'
import { CommentData } from './types'

const CommentsDisplay = (): ReactElement => {
  const [areCommentsLoading, setAreCommentsLoading] = useState(true)
  const [areRepliesLoading, setAreRepliesLoading] = useState(false)
  const [comments, setComments] = useState<CommentData[]>([])
  const [reactionLoading, setReactionLoading] = useState<
    { id: string; type: 'like' | 'dislike' } | undefined
  >(undefined)

  const [isThread, setIsThread] = useState(false)
  const [replies, setReplies] = useState<CommentData[]>([])
  const [parentComment, setParentComment] = useState<CommentData | undefined>(
    undefined
  )

  const {
    selectedDashboard: dashboard,
    selectedThreadId,
    isViewingComments,
    toggleViewComments,
  } = useDashboardNavigationContext()
  const dashboardId = dashboard?.id

  const handleOpenThread = useCallback(
    (comment: CommentData) => {
      if (comment.id != parentComment?.id) {
        setAreRepliesLoading(true)
      }

      setIsThread(true)
      setParentComment(comment)
    },
    [parentComment?.id]
  )

  const [hasUsedThreadId, setHasUsedThreadId] = useState(false)

  useEffect(() => {
    if (hasUsedThreadId) return
    if (parentComment?.id != undefined) return
    if (selectedThreadId == undefined) return
    if (comments == undefined) return
    setHasUsedThreadId(true)
    const selectedParentComment = comments?.find(
      (comment) => comment.id === selectedThreadId
    )
    if (
      selectedParentComment != undefined &&
      parentComment?.id !== selectedParentComment?.id
    ) {
      handleOpenThread(selectedParentComment)
    }
  }, [
    selectedThreadId,
    isThread,
    comments,
    handleOpenThread,
    hasUsedThreadId,
    parentComment?.id,
  ])

  const { setCurrentlyViewedDashboardAndThread, threadBadgeNumberMap } =
    useNotificationsContext()

  const handleCloseThread = () => {
    setIsThread(false)
  }

  // Listen for comments
  useEffect(() => {
    setCurrentlyViewedDashboardAndThread(dashboardId, parentComment?.id)

    // Always be loading comments, even if in thread
    const unsubComments = db
      .collection(`dashboards/${dashboardId}/comments`)
      .where('isReply', '==', false)
      .orderBy('timestamp', 'desc')
      .limit(30)
      .onSnapshot((snapshot) => {
        const fetchedComments = snapshot.docs.map(
          (doc) => doc.data() as CommentData
        )
        setComments(fetchedComments ?? [])
        setAreCommentsLoading(false)
      })

    return () => {
      unsubComments()
      setReactionLoading(undefined)
      setCurrentlyViewedDashboardAndThread(undefined, undefined)
    }
  }, [
    dashboardId,
    isThread,
    parentComment?.id,
    setCurrentlyViewedDashboardAndThread,
  ])

  // Load replies to focusedComment and focusedComment
  useEffect(() => {
    if (parentComment?.id != undefined) {
      const unsubReplies = db
        .collection(`dashboards/${dashboardId}/comments`)
        .where('parentComment.id', '==', parentComment.id)
        .orderBy('timestamp', 'asc')
        .onSnapshot((snapshot) => {
          const fetchedReplies = snapshot.docs.map(
            (doc) => doc.data() as CommentData
          )
          setReplies(fetchedReplies ?? [])
          setAreRepliesLoading(false)
        })

      const unsubParent = db
        .doc(`dashboards/${dashboardId}/comments/${parentComment.id}`)
        .onSnapshot((doc) => {
          const fetchedComment = doc.data() as CommentData
          setParentComment(fetchedComment)
        })

      return () => {
        unsubReplies()
        unsubParent()
        setReactionLoading(undefined)
        setCurrentlyViewedDashboardAndThread(undefined, undefined)
      }
    }
  }, [
    dashboardId,
    isThread,
    parentComment?.id,
    setCurrentlyViewedDashboardAndThread,
  ])

  const handleDeleteComment = useCallback(
    async (comment: CommentData) => {
      const cloudFunctions = firebase.functions()
      const deleteCommentFunction =
        cloudFunctions.httpsCallable('comments-delete')

      try {
        await deleteCommentFunction({
          commentId: comment.id,
          dashboardId,
        })

        if (comment == parentComment) {
          handleCloseThread()
        }
      } catch (e) {
        console.error(e)
      }
    },
    [dashboardId, parentComment]
  )

  const handleReact = useCallback(
    async (
      type: 'like' | 'dislike',
      comment: CommentData,
      prevReaction: boolean
    ) => {
      const cloudFunctions = firebase.functions()
      const reactFunction = cloudFunctions.httpsCallable('comments-react')

      try {
        setReactionLoading({
          id: comment.id,
          type,
        })

        await reactFunction({
          commentAuthorId: comment.author.id,
          commentId: comment.id,
          dashboardId,
          reactions: {
            [type]: !prevReaction,
          },
        })
      } catch (e) {
        console.error(e)
      } finally {
        setReactionLoading(undefined)
      }
    },
    [dashboardId]
  )

  const threadTitle = (
    <VStack w="100%" spacing={2} py={4} borderBottomWidth="3px">
      <HStack w="100%" px={4} spacing={3}>
        <IconButton
          aria-label="back button"
          icon={<IoMdArrowBack size={18} />}
          variant="ghost"
          size="xs"
          onClick={handleCloseThread}
        />

        <Text fontSize="xl" fontWeight="medium">
          Thread
        </Text>
        <Spacer />
      </HStack>

      {isThread && parentComment && (
        <Comment
          comment={parentComment}
          isThread={true}
          isReactionLoading={reactionLoading}
          onReact={handleReact}
          onDeleteComment={handleDeleteComment}
        />
      )}
    </VStack>
  )

  const defaultTitle = (
    <HStack pt="8" pb="2" px="5" w="100%">
      <Text fontSize="2xl" fontWeight="medium">
        Discussion
      </Text>

      <Spacer />

      <CloseButton onClick={toggleViewComments} />
    </HStack>
  )

  const posts = isThread ? replies : comments

  return (
    <VStack
      h="100%"
      borderLeftWidth="3px"
      w="350px"
      bg="white"
      align="start"
      px={0}
      spacing={0}
      display={isViewingComments ? 'flex' : 'none'}
    >
      {isThread ? threadTitle : defaultTitle}

      {(isThread && areRepliesLoading) || (!isThread && areCommentsLoading) ? (
        <Center h="100%" w="100%">
          <Spinner />
        </Center>
      ) : (
        <VStack
          w="100%"
          overflowY="scroll"
          divider={<Divider />}
          pt={2}
          px={1}
          pb="2"
        >
          {posts.map((comment) => {
            return (
              <Comment
                comment={comment}
                key={comment.id}
                isThread={isThread}
                onDeleteComment={handleDeleteComment}
                onOpenThread={() => handleOpenThread(comment)}
                showUnreadBadge={
                  !isThread && (threadBadgeNumberMap.get(comment.id) ?? 0) > 0
                }
                isReactionLoading={reactionLoading}
                onReact={handleReact}
              />
            )
          })}
        </VStack>
      )}

      <Spacer />
      <CommentTextBox
        isThread={isThread}
        dashboardId={dashboardId}
        comment={parentComment}
      />
    </VStack>
  )
}

export default CommentsDisplay
