/*
Right drawer showing options for editing a widget's settings. Appears when 
the user clicks the "edit" button on a widget
 */
import React, { ReactElement, ReactNode, useContext, useState } from 'react'

import {
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  Input,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import { setDoc } from '../../../utils/firebase/firebaseCustoms'
import db from '../../../utils/firebase/firestore'
import { Widget } from '../../widgets/types'
import DeleteWidgetButton from './DeleteWidgetButton'
import { WidgetSettingsDrawerContext } from './EditWidgetDrawerV2'

interface Props {
  children: ReactNode
  preventSaveMessage?: string
}

function EditWidgetDrawerTemplate({
  children,
  preventSaveMessage: widgetSpecificPresentSaveMessage,
}: Props): ReactElement {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    dashboardId,
    widget,
    canEdit,
    onClose,
    onCreatedWidget,
    onSaveEdits,
    onDeleteWidget,
    onChangeTitle,
  } = useContext(WidgetSettingsDrawerContext)

  const isCreatingWidget = widget?.id === 'new'

  const handleSave = async () => {
    const isValid = preventSaveMessage == undefined
    if (!isValid || widget == undefined) return

    setIsSubmitting(true)

    if (isCreatingWidget) {
      // Create widget
      try {
        const newWidgetId = await setDoc(
          db.collection(`dashboards/${dashboardId}/widgets/`),
          widget
        )
        const docSnapshot = await db
          .doc(`dashboards/${dashboardId}/widgets/${newWidgetId}`)
          .get()
        const createdWidget = docSnapshot.data() as Widget
        setIsSubmitting(false)
        onClose()
        onCreatedWidget(createdWidget)
      } catch (error) {
        setIsSubmitting(false)
      }
    } else {
      // Update widget
      const widgetRef = db.doc(`dashboards/${dashboardId}/widgets/${widget.id}`)
      widgetRef
        .update(widget)
        .then(() => {
          setIsSubmitting(false)
          onClose()
          onSaveEdits(widget)
        })
        .catch(() => {
          setIsSubmitting(false)
        })
    }
  }

  const updateTitle = (e: React.ChangeEvent<HTMLInputElement | undefined>) => {
    const title = e.target.value
    onChangeTitle(title)
  }

  if (widget === undefined) {
    return <></>
  }

  let preventSaveMessage: string | undefined
  if (widgetSpecificPresentSaveMessage != undefined)
    preventSaveMessage = widgetSpecificPresentSaveMessage
  else if (widget.title === '')
    preventSaveMessage = 'You must enter a title for your widget'

  return (
    <DrawerContent>
      <DrawerCloseButton isDisabled={isSubmitting} />
      <DrawerHeader>{canEdit ? 'Widget Settings' : widget?.title}</DrawerHeader>
      <DrawerBody>
        <Stack h="100%" spacing={4}>
          <FormControl id="title">
            {canEdit && (
              <>
                <Text as="b">Title</Text>
                <Input
                  variant="outline"
                  placeholder="Title"
                  value={widget?.title}
                  onChange={updateTitle}
                />
              </>
            )}
          </FormControl>
          {children}
        </Stack>
      </DrawerBody>
      {canEdit && (
        <DrawerFooter>
          {!isCreatingWidget && (
            <DeleteWidgetButton onDelete={onDeleteWidget} />
          )}
          <Spacer />
          <Button
            isDisabled={isSubmitting}
            variant="outline"
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Tooltip
            hidden={preventSaveMessage == undefined}
            bg="gray.500"
            hasArrow
            label={preventSaveMessage}
            placement="top-end"
          >
            <span>
              <Button
                isDisabled={preventSaveMessage != undefined}
                isLoading={isSubmitting}
                colorScheme="blue"
                onClick={handleSave}
              >
                {isCreatingWidget ? 'Add Widget' : 'Save'}
              </Button>
            </span>
          </Tooltip>
        </DrawerFooter>
      )}
    </DrawerContent>
  )
}

export default EditWidgetDrawerTemplate
