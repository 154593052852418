// Button to link the user's Twitter account

import { ReactElement } from 'react'
import { Button } from '@chakra-ui/react'
import Image from 'next/image'
import TwitterIcon from './../../../../../public/twitterIconWhite.svg'

interface Props {
  onClick: () => void
  title: string
}

const TwitterActionButton = ({ onClick, title }: Props): ReactElement => {
  return (
    <Button
      borderRadius={20}
      colorScheme={'twitter'}
      onClick={onClick}
      leftIcon={
        <Image
          src={TwitterIcon}
          width="24px"
          height="24px"
          alt="Twitter Icon"
        />
      }
    >
      {title}
    </Button>
  )
}

export default TwitterActionButton
