import {
  // Button,
  // HStack,
  Text,
  VStack,
  // Alert,
  // AlertIcon,
  // useDisclosure,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { useCurrentUser } from '../../../../context/user/context'
// import ChangeEmailModal from './ChangeEmailModal'

const EmailField = (): ReactElement => {
  const { user } = useCurrentUser()
  const email = user?.email
  // const isEmailVerified = false && user?.emailVerified

  // const {
  //   isOpen: isEmailModalOpen,
  //   onOpen: onOpenEmailModal,
  //   onClose: onCloseEmailModal,
  // } = useDisclosure()

  return (
    <VStack align="start">
      <Text fontWeight="medium">Email</Text>
      <Text>{email}</Text>
      {/* <HStack spacing={3}>
        <Text>{email}</Text>
        <Button onClick={onOpenEmailModal} size="xs" variant="link">
          Change Email
        </Button>
        <ChangeEmailModal
          isOpen={isEmailModalOpen}
          onClose={onCloseEmailModal}
        />
      </HStack>
      {!isEmailVerified && (
        <Alert status="warning" variant="left-accent">
          <AlertIcon />
          <Text mr={4}>Please verify your email</Text>
          <Button size="sm" variant="link">
            Resend confirmation
          </Button>
        </Alert>
      )} */}
    </VStack>
  )
}

export default EmailField
