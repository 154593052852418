/*
Form within the dashboard settings modal that controls an author's settings
 */

import React, { ReactElement, useEffect, useState } from 'react'
import { ActionMeta, OnChangeValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Spacer,
  StackDivider,
  Textarea,
  VStack,
} from '@chakra-ui/react'

import EmojiPicker from '../../general/EmojiPicker'
import { topicsRef } from '../../onboard/Interests/topicsReference'
import DeleteDashboardButton from './DeleteDashboardButton'

interface TagOption {
  value: string
  label: string
}

const tagOptions = Object.keys(topicsRef).reduce(
  (res: TagOption[], val) => [
    ...res,
    { value: val, label: val },
    ...topicsRef[val].map((item) => {
      return { value: item, label: item }
    }),
  ],
  []
)

export interface AuthorSettings {
  isPublic: boolean
  title: string
  description: string
  tags: string[]
}

interface Props {
  settings: AuthorSettings | undefined
  onChange: (settings: AuthorSettings) => void
  onDeleteDashboard: () => void
}

const AuthorDashboardSettings = ({
  onChange,
  settings,
  onDeleteDashboard,
}: Props): ReactElement => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState<string[]>([])
  const [isPublic, setIsPublic] = useState(true)

  // TODO: check relevant fields are filled before submitting changes
  useEffect(() => {
    setTitle(settings?.title ?? '')
    setIsPublic(settings?.isPublic ?? true)
    setDescription(settings?.description ?? '')
    setTags(settings?.tags ?? [])
  }, [settings])

  const togglePrivacy = () => {
    const newIsPublic = !isPublic
    setIsPublic(newIsPublic)
    onChange({ title, description, tags, isPublic: newIsPublic })
  }

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription)
    onChange({ title, description: newDescription, tags, isPublic })
  }

  const handleTitleChange = (newTitle: string) => {
    setTitle(newTitle)
    onChange({ title: newTitle, description, tags, isPublic })
  }

  const handleSelectTag = (
    newValues: OnChangeValue<TagOption, true>,
    _actionMeta: ActionMeta<TagOption>
  ) => {
    const newTags = newValues.map((t) => t.value)
    setTags(newTags)
    onChange({ title, description, tags: newTags, isPublic })
  }

  const selectedTags: TagOption[] =
    tags != null
      ? tags.map((t) => {
          return { value: t, label: t }
        })
      : []

  const handleSelectEmoji = (field: 'title' | 'description', emoji: string) => {
    if (field == 'title') {
      handleTitleChange(title + emoji)
    } else if (field == 'description') {
      handleDescriptionChange(description + emoji)
    }
  }

  return (
    <FormControl>
      <VStack
        align="left"
        spacing={7}
        divider={<StackDivider borderColor="gray.200" />}
        pb={5}
      >
        <VStack align="left" spacing={4}>
          <FormHelperText mb={2}>
            A relevant title, description, and tags help users find your
            dashboard in search results.
          </FormHelperText>
          <div>
            <FormLabel>Title</FormLabel>
            <HStack
              w="100%"
              p={'5px'}
              borderWidth="1px"
              _focusWithin={{
                borderColor: 'blue.400',
                borderWidth: '2px',
                padding: '4px',
              }}
              borderRadius={5}
            >
              <Input
                value={title}
                onChange={(e) => {
                  handleTitleChange(e.target.value)
                }}
                variant="unstyled"
                ml={2}
              />
              <EmojiPicker
                onSelectEmoji={(emoji) => handleSelectEmoji('title', emoji)}
              />
            </HStack>
          </div>
          <div>
            <FormLabel>Description</FormLabel>
            <InputGroup>
              <VStack
                w="100%"
                align="middle"
                p={'5px'}
                borderWidth="1px"
                _focusWithin={{
                  borderColor: 'blue.400',
                  borderWidth: '2px',
                  padding: '4px',
                }}
                borderRadius={5}
                spacing={0}
              >
                <Textarea
                  id="bio"
                  name="bio"
                  spellCheck={false}
                  placeholder="Add a short description to help people find your dashboard."
                  onChange={(e) => handleDescriptionChange(e.target.value)}
                  value={description}
                  maxLength={240}
                  borderWidth="0px"
                  h="100%"
                  maxH={'12'}
                  borderRadius="md"
                  px={2}
                  variant="unstyled"
                  overflowY={'scroll'}
                  resize="none"
                  display={'block'}
                />
                <HStack>
                  <Spacer />
                  <EmojiPicker
                    onSelectEmoji={(emoji) =>
                      handleSelectEmoji('description', emoji)
                    }
                  />
                </HStack>
              </VStack>
            </InputGroup>
          </div>
          <Box zIndex="popover">
            <FormLabel>Filter by tags</FormLabel>
            <CreatableSelect
              classNamePrefix="creatable-select"
              name="tags"
              value={selectedTags}
              options={tagOptions}
              isMulti
              onChange={handleSelectTag}
              closeMenuOnSelect={false}
              isClearable={false}
              maxMenuHeight={200}
            />
          </Box>
          <FormHelperText>
            Tags represent the topics of your dashboard. Adding at least 5 is
            recommended for better search results.
          </FormHelperText>
        </VStack>
        <div>
          <FormLabel>Privacy</FormLabel>
          <Flex>
            <Checkbox isChecked={!isPublic} onChange={togglePrivacy}>
              Make dashboard private
            </Checkbox>
          </Flex>
        </div>
        <div>
          <HStack>
            <VStack align={'flex-start'} spacing={0}>
              <FormLabel>Delete Dashboard</FormLabel>
              <FormHelperText>
                Beware! Deleting a dashboard cannot be undone.
              </FormHelperText>
            </VStack>
            <Spacer />
            <DeleteDashboardButton
              onDelete={onDeleteDashboard}
              dashboardTitle={title}
            />
          </HStack>
        </div>
      </VStack>
    </FormControl>
  )
}
export default AuthorDashboardSettings
