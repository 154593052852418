import { ReactElement, ReactNode } from 'react'
import { compiler } from 'markdown-to-jsx'
import ReactDOMServer from 'react-dom/server'

type HTMLStyleProperty =
  | 'padding'
  | 'border'
  | 'border-collapse'
  | 'margin-bottom'
  | 'margin-top'
  | 'overflow-x'
  | 'word-break'
  | 'font-size'
  | 'margin'
  | 'max-width'
  | 'padding-bottom'
  | 'height'
  | 'color'
  | 'text-decoration'
  | 'background'
  | 'border-left'

type InlineStyles = Partial<Record<HTMLStyleProperty, string>>

type HTMLElement =
  | 'ol'
  | 'ul'
  | 'table'
  | 'thead'
  | 'tr'
  | 'td'
  | 'hr'
  | 'img'
  | 'th'
  | 'a'
  | 'blockquote'

interface MarkdownOptionsObject {
  props: {
    style: InlineStyles
    target?: string
  }
  component?: ({ children }: { children: ReactNode[] }) => ReactElement
}
const markdownStyles: Record<HTMLElement, MarkdownOptionsObject> = {
  ol: {
    props: {
      style: {
        padding: '1rem',
      },
    },
  },
  ul: {
    props: {
      style: {
        padding: '1rem',
      },
    },
  },
  table: {
    props: {
      style: {
        'border-collapse': 'collapse',
        'margin-bottom': '4px',
        'margin-top': '4px',
        'overflow-x': 'auto',
        'word-break': 'normal',
        'font-size': '0.8rem',
        'max-width': '90%',
        margin: '2rem',
      },
    },
  },
  thead: {
    props: {
      style: {
        border: '2px solid #eee',
        'border-collapse': 'collapse',
        'margin-bottom': '4px',
        'margin-top': '4px',
        'overflow-x': 'auto',
        'word-break': 'normal',
      },
    },
  },
  th: {
    props: {
      style: {
        border: '2px solid #eee',
      },
    },
  },
  tr: {
    props: {
      style: {
        border: '2px solid #eee',
        'border-collapse': 'collapse',
        'margin-bottom': '4px',
        'margin-top': '4px',
        'overflow-x': 'auto',
        'word-break': 'normal',
      },
    },
  },
  td: {
    props: {
      style: {
        border: '2px solid #eee',
        'border-collapse': 'collapse',
        padding: '4px',
        'margin-bottom': '4px',
        'margin-top': '4px',
        'overflow-x': 'auto',
        'word-break': 'normal',
      },
    },
  },
  hr: {
    props: {
      style: {
        height: '1rem',
      },
    },
  },
  img: {
    props: {
      style: {
        'margin-bottom': '1rem',
        'margin-top': '1rem',
      },
    },
  },
  a: {
    props: {
      style: { color: '#3182CE', 'text-decoration': 'underline' },
      target: '_blank',
    },
  },
  blockquote: {
    props: {
      style: {
        background: '#f9f9f9',
        'border-left': '6px solid #ccc',
        margin: '1.5em 0.5em',
        padding: '0.8em',
      },
    },
  },
}

// const stylesObjToInlineString = (styles: InlineStyles) => {
//   return (
//     `"` +
//     Object.keys(styles as (keyof InlineStyles)[]).reduce(
//       (stylesString, key) =>
//         stylesString + `${key}: ${styles[key as HTMLStyleProperty]}; `,
//       ''
//     ) +
//     `"`
//   )
// }

// function to replace Reddit's plain text formatting with the corresponding HTML
export const parseRedditMardownToHTML = (markdown: string): string => {
  // const tableRowRegex = new RegExp(/^((.+\|)+[^\n]*\n)+/gm)
  //   while (true) {

  // let table

  // const match = tablesRendered.match(tableRowRegex)
  // if (match != null) {
  //   console.log(match[0])
  //   table = compiler(match[0])
  //   console.log(ReactDOMServer.renderToStaticMarkup(table))
  // }

  // tablesRendered = tablesRendered.replaceAll(tableRowRegex, (match) => {
  //   const table = ReactDOMServer.renderToStaticMarkup(compiler(match))
  //   return `<div style="width:100%">${table}</div>`
  // })

  // // HRs rendered
  // const horizontalRulesRendered = boldedAndItalicized.replaceAll(
  //   new RegExp(/\n-{5,}/g),
  //   `<hr/>`
  // )

  // // `inline code`
  // const inlineCodeRendered = horizontalRulesRendered.replaceAll(
  //   new RegExp(/`(.*?)`/g),
  //   (_, p1) => `<code>${p1}</code>`
  // )
  // // :"quotes":
  // const quotesRendered = inlineCodeRendered.replaceAll(
  //   new RegExp(/\:\"(.*?)\"\:/g),
  //   (_, p1) => `<q>${p1}</q>`
  // )

  // const asterisksEscaped = quotesRendered.replaceAll(/\\\*/g, '*')

  const imagesRendered = markdown.replaceAll(
    /(https?):\/\/.+(\.jpg|\.png|\.jpeg).+(\w)/g,
    (match) => `<img src='${match}'/>`
  )

  //console.log(markdown)
  const imageURLsCorrected = imagesRendered.replaceAll(
    'preview.redd.it',
    'i.redd.it'
  )

  // const linksRendered = imagesRendered.replaceAll(
  //   /\[([^\[]+)\]\(([^\)]+)\)/g,
  //   (match, p1, p2) =>
  //     `<a href="${p2}" style="color:blue; text-decoration:underline" target="blank" >${p1}</a>`
  // )

  const headersRendered = imageURLsCorrected.replaceAll(
    /(#+) (.+)\n/g,
    (_, p1, p2) => {
      // h1 -> 1.5 rem
      // h2 -> 1.3 rem
      // h3 -> 1.1 rem
      // h4 -> 1 rem
      const fontSize = Math.min(1.5 - p1.length * 0.2, 1)
      return `<div style="font-size:${fontSize}rem">${p2}</div>`
    }
  )
  // const tildesRendered = newLined.replaceAll(/\\~/g, '~')
  // const dashesRendered = tildesRendered.replaceAll(/\\-/g, '-')

  const bulletsRendered = renderBullets(headersRendered)
  const boldedAndItalicized = boldAndItalicizeText(bulletsRendered)

  const imageLinksFixed = boldedAndItalicized.replaceAll(
    /(https?)(\:\/\/.+)(\.jpg|\.png|\.jpeg).*\w/gm,
    (_, p1, p2, p3) => `${p1 + p2 + p3}`
  )

  const blockQuotesRendered = imageLinksFixed.replaceAll(
    /(&gt;.*\n)+/gm,
    (match) => `<blockquote>${match.replaceAll('&gt;', '')}</blockquote>`
  )

  //const newLined = blockQuotesRendered.replaceAll('\n', '<br/>')
  const zeroWidthSpacesRemoved = blockQuotesRendered.replaceAll(
    '&amp;#x200B;',
    ''
  )

  const finalHTML = ReactDOMServer.renderToStaticMarkup(
    compiler(zeroWidthSpacesRemoved, {
      overrides: markdownStyles,
    })
  )

  // console.log(finalHTML)
  const tablesCentered = finalHTML.replaceAll(
    new RegExp(/<table>.*<\/table>/g),
    (match) => `<center>${match}</center>`
  )
  // let newLines = tablesCentered.replaceAll('<p>', '<br/>')
  // newLines = newLines.replaceAll('</p>', '<br/>')

  const renderApostrophes = tablesCentered.replaceAll(`&amp;#x27;`, "'")

  return renderApostrophes
}

const boldAndItalicizeText = (markdown: string) => {
  let boldedAndItalicized = markdown

  const boldAndItalicMarkers = ['\\*']

  boldAndItalicMarkers.forEach((char) => {
    // bolded and italicized
    boldedAndItalicized = boldedAndItalicized.replaceAll(
      new RegExp(`${char}{3}([^\w][^${char}\n]+)${char}{3}`, 'g'),
      (_, p1) => `<b><em>${p1}</em></b>`
    )
    // bolded
    boldedAndItalicized = boldedAndItalicized.replaceAll(
      new RegExp(`${char}{2}([^\w][^${char}\n]+)${char}{2}`, 'g'),
      (_, p1) => `<b>${p1}</b>`
    )
    // italicized
    boldedAndItalicized = boldedAndItalicized.replaceAll(
      new RegExp(`${char}{1}([^\w][^${char}\n]+)${char}{1}`, 'g'),
      (_, p1) => `<em>${p1}</em>`
    )
  })

  return boldedAndItalicized
}

const renderBullets = (markdown: string) => {
  /*************************************************/
  /* Bulleted text
  /*************************************************/

  // Unordered Lists

  const ulMarkers = ['- ', '\\* ']
  let unorderedListsRendered = markdown

  ulMarkers.forEach((marker) => {
    unorderedListsRendered = unorderedListsRendered.replaceAll(
      new RegExp(`^(${marker})(.+)\n`, 'gm'),
      (_, __, p2) => `<li>${p2}</li>`
    )
  })

  unorderedListsRendered = unorderedListsRendered.replaceAll(
    new RegExp(/(<li>(.+)<\/li>)+/gm),
    (match) => `<ul>${match}</ul>`
  )

  // // Ordered Lists
  // let orderedListsRendered = unorderedListsRendered.replaceAll(
  //   new RegExp(/^[0-9]+\.(.*)$/gm),
  //   (_, p1) => `<li>${p1}</li>`
  // )
  // orderedListsRendered = orderedListsRendered.replaceAll(
  //   new RegExp(/[^(<ul>)](<li>(.+)<\/li>)+[^(</ul>)]/gm),
  //   (_, p1) => `<ol>${p1}</ol>`
  // )

  return unorderedListsRendered
}
