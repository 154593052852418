import React, { ReactElement } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'

import { CloseIcon } from '@chakra-ui/icons'
import {
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'

import { IEXCloudSymbol } from '../types'

interface TickerItemProps {
  ticker: IEXCloudSymbol
  tickerList: IEXCloudSymbol[]
  index: number
  canEdit: boolean
  onChange: (newTickers: IEXCloudSymbol[]) => void
}

const TickerItem = ({
  ticker,
  index,
  tickerList,
  canEdit,
  onChange,
}: TickerItemProps): ReactElement => {
  const removeTickerForId = (symbol: string) => {
    onChange(tickerList.filter((ticker) => ticker.symbol != symbol))
  }

  let portal: HTMLElement
  if (document && document.body) {
    portal = document.createElement('div')
    portal.style.cssText = 'position:absolute;z-index:100000'
    document.body.appendChild(portal)
  }

  return (
    <Draggable
      isDragDisabled={false}
      key={`Draggable-stock-${index}`}
      draggableId={`${index}`}
      index={index}
    >
      {(provided, snapshot) => {
        const isDragging = snapshot.isDragging

        const child = (
          <HStack
            width="100%"
            spacing={4}
            p={2}
            shadow={isDragging ? 'md' : 'none'}
            opacity={isDragging ? 0.7 : 1.0}
            style={provided.draggableProps.style}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <VStack align="start" w="100%" spacing={0} maxW="80%">
              <Text textOverflow="ellipsis" w="max-content" maxW="80%" as="b">
                {ticker.name}
              </Text>
              <HStack key={`Draggable-stock-${index}`} spacing="1" maxW="100%">
                <Text textColor="gray.500" overflowX="hidden" noOfLines={1}>
                  {ticker.symbol} &bull; {ticker.exchangeName}
                </Text>
              </HStack>
            </VStack>
            <Spacer />
            {canEdit && (
              <Flex justifyContent="end">
                <IconButton
                  aria-label="Remove ticker"
                  icon={<CloseIcon />}
                  variant="unstyled"
                  onClick={() => removeTickerForId(ticker.symbol)}
                />
              </Flex>
            )}
          </HStack>
        )

        if (!isDragging) {
          return child
        }
        return createPortal(child, portal)
      }}
    </Draggable>
  )
}

export default TickerItem
