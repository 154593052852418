import React, { ReactElement, ReactNode, ReactNodeArray } from 'react'
import { ChakraProps, CSSObject, Text } from '@chakra-ui/react'

interface Props extends ChakraProps {
  children: ReactNode | ReactNodeArray
  href: string
  isDisabled: boolean
}
const DisablableExternalLink = ({
  children,
  href,
  isDisabled,
  ...rest
}: Props): ReactElement => {
  const linkHoverEffects: CSSObject = {
    cursor: isDisabled ? 'inherit' : 'pointer',
    textDecoration: isDisabled ? 'none' : 'underline',
  }

  const linkClickHandler = (e: React.MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation()
    if (isDisabled) {
      e.preventDefault()
    }
  }

  return (
    <Text
      as="a"
      href={href}
      onClick={linkClickHandler}
      target="_blank"
      _hover={linkHoverEffects}
      {...rest}
    >
      {children}
    </Text>
  )
}

export default DisablableExternalLink
