/**
 * Component for displaying a (preview of) a Reddit post from the widget view
 */

import React, { ReactElement } from 'react'
import ReactPlayer from 'react-player'

import { Box, Flex, HStack, Image } from '@chakra-ui/react'

import { generateYoutubeSrc } from '../../../../utils/general/generateYoutubeSrc'
import { RedditPost, SubredditStyles } from '../types'
import PostHeader from './PostHeader'
import ShortenedLink from './ShortenedLink'
import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'

interface Props {
  postData: RedditPost
  keyWords: string[]
  subredditName: string
  subredditStyles: SubredditStyles | null | undefined // TODO: figure out why undefined
  onExpandPost: () => void
}

const RedditPostPreview = ({
  postData,
  keyWords,
  onExpandPost,
  subredditName,
  subredditStyles,
}: Props): ReactElement => {
  const { isEditingDashboard } = useDashboardNavigationContext()

  const dummyEventHandler = () => {
    return
  }
  const youtubeSrc =
    postData.media != null ? generateYoutubeSrc(postData.media) : ''
  return (
    <Box
      w="100%"
      paddingX={4}
      paddingY={3}
      // padding="3"
      onClick={!isEditingDashboard ? onExpandPost : dummyEventHandler}
      _hover={{
        cursor: isEditingDashboard ? 'default' : 'pointer',
        bg: isEditingDashboard ? 'auto' : 'gray.50',
      }}
    >
      <HStack>
        <Flex>
          <PostHeader
            postData={postData}
            keyWords={keyWords}
            subredditName={subredditName}
            subredditStyles={subredditStyles}
            titleIsLink={false}
          />
        </Flex>
        <Flex>
          {postData.postType === 'image' && (
            <Image
              alt={postData.media}
              src={postData.media}
              maxW="5rem"
              objectFit="scale-down"
              h="5rem"
            />
          )}
        </Flex>
        {postData.postType === 'video' && (
          <ReactPlayer
            width="100%"
            url={youtubeSrc}
            controls
            style={{ maxWidth: '20%', maxHeight: '8rem' }}
          />
        )}
      </HStack>
      {postData.postType === 'link' && (
        <Box ml={'43px'} w="max-content">
          <ShortenedLink
            url={postData.externalUrl}
            disableClick={isEditingDashboard}
          />
        </Box>
      )}
    </Box>
  )
}

export default RedditPostPreview
