import { ReactElement } from 'react'

import {
  Container,
  StackDivider,
  Text,
  VStack,
  HStack,
  Spacer,
  Kbd,
} from '@chakra-ui/react'

import StockItem from './StockItem'
import { StockWidgetDisplay } from '../types'

interface Props {
  selectedSymbol: string | undefined
  stocks: StockWidgetDisplay[]
  onSelectStock: (stock: StockWidgetDisplay) => void
}

const StockSelectionSidebar = ({
  selectedSymbol,
  stocks,
  onSelectStock,
}: Props): ReactElement => {
  return (
    <VStack align="start" w="100%" spacing={8}>
      <VStack align="start" w="100%" spacing={2}>
        <HStack w="100%">
          <Text paddingLeft="2" fontSize="sm" fontWeight="500" color="gray.500">
            STOCKS
          </Text>
          <Spacer />
          <span style={{ color: 'gray' }}>
            <Kbd>▼</Kbd>
            <Kbd ml={3} mr={2}>
              ▲
            </Kbd>
            {'keys to navigate'}
          </span>
        </HStack>
        <Container
          borderRadius="md"
          padding={0}
          w="100%"
          bg="white"
          borderWidth="1px"
          borderColor="gray.300"
          centerContent={true}
        >
          <VStack
            divider={<StackDivider borderColor="gray.200" />}
            spacing={0}
            w="100%"
          >
            {stocks.map((stock) => {
              const isSelected = stock.info.symbol === selectedSymbol
              return (
                <StockItem
                  key={stock.info.symbol}
                  stock={stock}
                  bg={isSelected ? 'gray.50' : 'none'}
                  onClick={onSelectStock}
                />
              )
            })}
          </VStack>
        </Container>
      </VStack>
    </VStack>
  )
}

export default StockSelectionSidebar
