import React, { ReactElement } from 'react'

import { Text, VStack } from '@chakra-ui/react'

import { CommentData } from '../types'
import Footer from './Footer'
import Header from './Header'

interface Props {
  comment: CommentData
  isThread?: boolean
  onDeleteComment: (comment: CommentData) => void
  onOpenThread?: (comment: CommentData) => void
  showUnreadBadge?: boolean
  isReactionLoading:
    | {
        id: string
        type: 'like' | 'dislike'
      }
    | undefined
  onReact: (
    type: 'like' | 'dislike',
    comment: CommentData,
    prevReaction: boolean
  ) => Promise<void>
}
const Comment = ({
  comment,
  onOpenThread,
  onDeleteComment,
  isThread = false,
  showUnreadBadge = false,
  isReactionLoading,
  onReact,
}: Props): ReactElement => {
  const { text } = comment
  return (
    <VStack w="100%" px={4} py={2} align="start" pb="0" spacing={2}>
      <Header
        comment={comment}
        isThread={isThread}
        onDeleteComment={onDeleteComment}
      />
      <Text fontSize="sm">{text}</Text>
      <Footer
        comment={comment}
        onOpenThread={onOpenThread}
        isThread={isThread}
        showUnreadBadge={showUnreadBadge}
        isReactionLoading={isReactionLoading}
        onReact={onReact}
      />
    </VStack>
  )
}

export default Comment
