import firebase from 'firebase'
import { useCallback, useEffect, useState } from 'react'

import { MostRecentView } from '../../components/dashboard/dashboardDisplay/DashboardDisplayV3'
import { uploadDashboardPreviewImage } from '../../components/dashboard/dashboardDisplay/DashboardDisplayV3'
import db from '../../utils/firebase/firestore'

export type DashboardDataStatus = 'refreshing' | 'done' | 'error'

interface DashboardDataReturn {
  refreshSelectedDashboard: () => Promise<void>
  selectedDashboardData: MostRecentView | undefined
  selectedDashboardDataStatus: DashboardDataStatus | undefined
}

export default function useDashboardData(
  dashboardId: string | undefined
): DashboardDataReturn {
  const [dashboardDataMap, setDashboardDataMap] = useState<
    Map<string, MostRecentView | undefined>
  >(new Map())
  const [dashboardDataStatusMap, setDashboardDataStatusMap] = useState<
    Map<string, DashboardDataStatus | undefined>
  >(new Map())

  const setStatus = useCallback(
    (status: DashboardDataStatus) => {
      if (dashboardId == undefined) return
      setDashboardDataStatusMap(
        (prev) => new Map(prev.set(dashboardId, status))
      )
    },
    [dashboardId]
  )

  const refreshSelectedDashboard = useCallback(async () => {
    const currentUser = firebase.auth().currentUser
    if (!currentUser || dashboardId == undefined) {
      setStatus('error')
      return
    }

    setStatus('refreshing')

    const refreshCF = firebase.functions().httpsCallable('dashboards-refresh')
    return refreshCF({
      dashboardId,
    })
      .then((result) => {
        setDashboardDataMap(
          (prev) => new Map(prev.set(dashboardId, result.data))
        )
        setStatus('done')
      })
      .then(() => {
        setTimeout(() => uploadDashboardPreviewImage(dashboardId), 5000)
      })
      .catch((e) => {
        console.error('error refreshing', e)
        setStatus('error')
      })
  }, [dashboardId, setStatus])

  useEffect(() => {
    if (dashboardId == undefined) {
      return
    }

    // If cache hit, nothing to fetch
    if (dashboardDataMap.has(dashboardId)) {
      return
    }

    // If cache miss, fetch and record data in cache
    const setStatus = (status: DashboardDataStatus) =>
      setDashboardDataStatusMap(
        (prev) => new Map(prev.set(dashboardId, status))
      )

    setStatus('refreshing')

    const fiveMinsAgo = Date.now() - 5 * 60 * 1000
    db.collection(`dashboards/${dashboardId}/data`)
      .orderBy('refreshed', 'desc')
      .limit(1)
      .get()
      .then((snapshot) => {
        // If data is recent enough, use it; if not, refresh
        if (!snapshot.empty) {
          const data = snapshot.docs[0].data() as MostRecentView

          if (data.refreshed >= fiveMinsAgo) {
            setStatus('done')
          } else {
            refreshSelectedDashboard()
          }
          setDashboardDataMap((prev) => new Map(prev.set(dashboardId, data)))
        } else {
          return refreshSelectedDashboard()
        }
      })
      .catch(() => setStatus('error'))
  }, [dashboardDataMap, dashboardId, refreshSelectedDashboard])

  const selectedDashboardData = dashboardDataMap.get(dashboardId ?? '')
  const selectedDashboardDataStatus = dashboardDataStatusMap.get(
    dashboardId ?? ''
  )

  return {
    refreshSelectedDashboard,
    selectedDashboardData,
    selectedDashboardDataStatus,
  }
}
