/*
Custom firebase functions to be used instead of the built-in functions
*/

import firebase from 'firebase/app'
import short from 'short-uuid'

type documentId = string

// setDoc returns a Promise that resolves to the new documentID
export const setDoc = async (
  collectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
  data: Partial<firebase.firestore.DocumentData>,
  options: firebase.firestore.SetOptions = {}
): Promise<documentId> => {
  const id = short.generate().toLowerCase()
  data.id = id
  data.created = firebase.firestore.Timestamp.fromDate(new Date())
  await collectionRef.doc(id).set(data, options)
  return id
}

// addDoc returns a Promise that resolves to the newly created document's DocumentData.
// The downside of this is we don't get to choose the id, so avoid using this unless absolutely necesary (use setDoc instead)
export const addDoc = async (
  collectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
  data: Partial<firebase.firestore.DocumentData>
): Promise<
  firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
> => {
  if (data.hasOwnProperty('id')) {
    console.warn(
      'The object being created in firebase already had an id property that will be overwritten'
    )
  }
  data.created = firebase.firestore.Timestamp.fromDate(new Date())
  const ref = await collectionRef.add(data)
  return ref
}
