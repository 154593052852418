import db from '../firebase/firestore'

const checkUsernameAvailability = async (
  username: string
): Promise<boolean> => {
  const usernamePromise = db
    .collection('users')
    .where('username', '==', username.toLowerCase())
    .limit(1)
    .get()
    .then((snapshot) => {
      return snapshot.docs.map((doc) => {
        const data = doc.data()
        return data
      })
    })

  const foundUsername = await usernamePromise
  return foundUsername.length > 0 ? false : true
}

export default checkUsernameAvailability
