/**
 * Modal for editing a dashboard's settings
 */
import React, { ReactElement } from 'react'
import {
  HStack,
  VStack,
  Box,
  Text,
  useMediaQuery,
  Flex,
} from '@chakra-ui/react'
import { IoShareSocial } from 'react-icons/io5'
import { GoLock } from 'react-icons/go'
import { IoPersonSharp } from 'react-icons/io5'
import ChangePassword from './ChangePassword'
import ManageConnectedAccounts from './ManageConnectedAccounts'
import EditProfile from './EditProfile'
import Modal, { SHORT_DEVICE_MAX_HEIGHT, MODAL_HEADER_HEIGHT } from './Modal'
import {
  useSettingsModalActions,
  useSettingsModalState,
} from '../../context/settings-modal/context'
import { useMemo } from 'react'

export const BODY_MARGIN = { x: 9, t: 4 }

export type SettingsTab = 'connectedAccounts' | 'profile' | 'resetPassword'
interface SettingsTabOption {
  key: SettingsTab
  label: string
  icon: ReactElement
}

const SettingsModal = (): ReactElement => {
  const { closeSettings, openSettings } = useSettingsModalActions()
  const { activeSettingsTab, isOpen } = useSettingsModalState()

  const tabOptions: SettingsTabOption[] = [
    {
      key: 'profile',
      label: 'Profile',
      icon: <IoPersonSharp />,
    },
    {
      key: 'connectedAccounts',
      label: 'Connected accounts',
      icon: <IoShareSocial />,
    },
    {
      key: 'resetPassword',
      label: 'Reset password',
      icon: <GoLock />,
    },
  ]

  const activeSettingsView = useMemo((): ReactElement => {
    switch (activeSettingsTab) {
      case 'connectedAccounts':
        return <ManageConnectedAccounts />
      case 'resetPassword':
        return <ChangePassword />
      case 'profile':
        return <EditProfile />
      default:
        return <EditProfile />
    }
  }, [activeSettingsTab])

  const [, isMobile] = useMediaQuery([
    '(max-height: 720px)',
    '(max-width: 840px)',
  ])

  const [isShortDevice] = useMediaQuery(
    `(max-height: ${SHORT_DEVICE_MAX_HEIGHT})`
  )

  const modalVerticalMargin = isShortDevice ? 0 : 128

  return (
    <Modal isOpen={isOpen} onClose={closeSettings} size="4xl" title="Settings">
      <HStack
        align="stretch"
        h={`calc(100vh - ${MODAL_HEADER_HEIGHT + modalVerticalMargin}px)`}
        spacing={0}
      >
        <Flex
          align="stretch"
          bg="gray.100"
          pt={4}
          px={4}
          borderBottomLeftRadius={'4px'}
        >
          <VStack width="max-content" align="flex-start" spacing="1.5" h="100%">
            {tabOptions.map((tab) => {
              const isSelected = activeSettingsTab === tab.key
              return (
                <HStack
                  spacing={2}
                  paddingY="1.5"
                  paddingX="3"
                  borderRadius="4px"
                  bg={isSelected ? 'blue.500' : 'transparent'}
                  color={isSelected ? 'white' : 'gray.800'}
                  _hover={{
                    bg: isSelected ? 'blue.500' : 'gray.200',
                    cursor: 'pointer',
                  }}
                  key={tab.key}
                  onClick={() => openSettings(tab.key)}
                  w="100%"
                >
                  {tab.icon}
                  {!isMobile && (
                    <Text w="100%" noOfLines={1}>
                      {tab.label}
                    </Text>
                  )}
                </HStack>
              )
            })}
          </VStack>
        </Flex>
        <Box h="100%" flexGrow={1}>
          {activeSettingsView}
        </Box>
      </HStack>
    </Modal>
  )
}
export default SettingsModal
