import React, { ReactElement, useMemo } from 'react'
import { FaTwitter } from 'react-icons/fa'
// import { FaRegClone } from 'react-icons/fa'
import { FiMessageSquare, FiMoreHorizontal } from 'react-icons/fi'
import { GrRefresh } from 'react-icons/gr'
import { IoMdClose, IoMdCopy } from 'react-icons/io'

import { DeleteIcon, SettingsIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useClipboard,
  useToast,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../../../context/auth-modal/context'
import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'
import { useDashboardSettingsModalContext } from '../../../../context/dashboard-settings-modal/context'
import { useCurrentUser } from '../../../../context/user/context'
import { routes } from '../../../../utils/constants'
import shareToTwitter from '../../../../utils/general/shareToTwitter'
import { Widget } from '../../../widgets/types'
import DeleteDashboardButton from '../../dashboardSettings/DeleteDashboardButton'
import UnfollowDashboardButton from '../UnfollowDashboardButton'

type EventHandler = () => void

export interface Props {
  onRefresh: EventHandler
  onFollow: () => Promise<void>
  onUnfollow: () => Promise<void>
  isAuthor: boolean
  isFollowing: boolean
  isLoadingRefresh: boolean
  onDeleteDashboard: () => void
  widgets: Widget[]
}

const ActionButtons = ({
  onRefresh,
  onUnfollow,
  onFollow,
  isAuthor,
  isFollowing,
  isLoadingRefresh,
  onDeleteDashboard,
  widgets,
}: Props): ReactElement => {
  const { toggleViewComments, isViewingComments } =
    useDashboardNavigationContext()

  const { openAuthModal } = useAuthModalContext()

  const { user, connectedAccounts } = useCurrentUser()

  const missingTokens = useMemo(() => {
    const hasTwitterWidget = widgets.some((widget) => widget.type === 'twitter')
    const hasTwitterToken = connectedAccounts.some(
      (acct) => acct.type === 'twitter'
    )

    const hasRedditWidget = widgets.some((widget) => widget.type === 'reddit')
    const hasRedditToken = connectedAccounts.some(
      (acct) => acct.type === 'reddit'
    )

    const missingTokens: string[] = []

    if (hasTwitterWidget && !hasTwitterToken) missingTokens.push('Twitter')
    if (hasRedditWidget && !hasRedditToken) missingTokens.push('Reddit')
    return missingTokens
  }, [connectedAccounts, widgets])

  const isAuthed = user != undefined

  let refreshTooltipLabel = 'Refresh dashboard'
  if (isAuthed && !isFollowing && !isAuthor) {
    refreshTooltipLabel = 'Follow to refresh'
  } else if (missingTokens.length > 0) {
    refreshTooltipLabel = `Sign in with ${missingTokens.join(
      ' and '
    )} to refresh`
  }

  const { selectedDashboardId, selectedDashboard } =
    useDashboardNavigationContext()

  const { openSettings: handleOpenSettingsModal } =
    useDashboardSettingsModalContext()

  const handleOpenSettings = () => {
    if (selectedDashboardId != undefined) {
      handleOpenSettingsModal(selectedDashboardId)
    }
  }

  const sharableLink =
    selectedDashboardId != undefined
      ? `${routes.baseUrl()}${routes.dashboard(selectedDashboardId)}`
      : ''

  const { onCopy } = useClipboard(sharableLink)
  const toast = useToast()

  const handleCopyShareLink = () => {
    onCopy()
    //setEverCopied(true) // persist copied state
    toast({
      title: 'Link copied!',
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  const handleShareToTwitter = () => {
    if (selectedDashboard != undefined) {
      shareToTwitter(selectedDashboard, user)
    }
  }

  const promptSignUp = () => {
    openAuthModal('signUp')
  }

  const followingOrAuthor = isFollowing || isAuthor

  return (
    <HStack align="center" spacing={0}>
      {!followingOrAuthor && (
        <Button
          mr={1}
          size="sm"
          onClick={isAuthed ? onFollow : promptSignUp}
          colorScheme="blue"
        >
          Follow
        </Button>
      )}
      <Tooltip
        label={refreshTooltipLabel}
        hasArrow
        arrowSize={5}
        openDelay={isAuthed ? 1000 : 0}
        closeOnClick={isAuthed ? true : false}
        placement="bottom"
      >
        {isAuthed && (missingTokens.length > 0 || !followingOrAuthor) ? (
          <span>
            <IconButton
              variant="ghost"
              aria-label="Refresh dashboard data"
              isDisabled={true}
              _hover={{ cursor: 'default' }}
              icon={<GrRefresh />}
            />
          </span>
        ) : (
          <IconButton
            variant="ghost"
            aria-label="Refresh dashboard data"
            onClick={isAuthed ? onRefresh : promptSignUp}
            icon={<GrRefresh />}
            isLoading={isLoadingRefresh}
          />
        )}
      </Tooltip>

      {isAuthed && (isFollowing || isAuthor) && (
        <Menu>
          <MenuButton
            variant="ghost"
            as={IconButton}
            icon={<FiMoreHorizontal size={24} />}
            transition="all 0.2s"
          />
          <MenuList w="100%">
            {isAuthor && (
              <MenuItem onClick={handleOpenSettings}>
                <Text mr={2} mt={'-4px'} fontSize={16}>
                  <SettingsIcon />
                </Text>
                Settings
              </MenuItem>
            )}

            {isAuthor && (
              <MenuItem>
                <DeleteDashboardButton
                  as={
                    <HStack w="100%">
                      <DeleteIcon />
                      <Text mr={2} mt={'-4px'} fontSize={16}>
                        Delete dashboard
                      </Text>
                    </HStack>
                  }
                  onDelete={onDeleteDashboard}
                  dashboardTitle={selectedDashboard?.title ?? ''}
                  shouldStopClickPropagation={true}
                />
              </MenuItem>
            )}

            {isAuthor && <MenuDivider />}

            {!isAuthor && (
              <UnfollowDashboardButton
                buttonComponent={
                  <MenuItem>
                    <Text mr={2} fontSize={14}>
                      <IoMdClose />
                    </Text>{' '}
                    Unfollow
                  </MenuItem>
                }
                onUnfollow={onUnfollow}
                dashboardTitle={selectedDashboard?.title ?? ''}
              />
            )}

            <MenuItem
              onClick={handleShareToTwitter}
              _hover={{ bg: 'gray.100' }}
            >
              <Text mt="2px" mr={2} fontSize={16}>
                <FaTwitter />
              </Text>{' '}
              Share to Twitter
            </MenuItem>
            <MenuItem onClick={handleCopyShareLink} _hover={{ bg: 'gray.100' }}>
              <Text mr={2} mt="1px" fontSize={19}>
                <IoMdCopy />
              </Text>{' '}
              Copy link
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      <Tooltip
        label={isViewingComments ? 'Hide comments' : 'View comments'}
        hasArrow
        arrowSize={5}
        placement="bottom"
      >
        <span>
          <IconButton
            variant="ghost"
            aria-label={isViewingComments ? 'Hide comments' : 'View comments'}
            onClick={toggleViewComments}
            icon={<FiMessageSquare />}
          />
        </span>
      </Tooltip>
    </HStack>
  )
}

export default ActionButtons
