/*
Widget wrapper
*/

// import Image from 'next/image'
import React, { ReactElement } from 'react'
import { useHover } from 'react-use'

import { SettingsIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  SlideFade,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react'

import NewsIcon from './../../../../public/news-emoji.png'
import RedditIcon from './../../../../public/roundedSocials/reddit.svg'
import TwitterIcon from './../../../../public/roundedSocials/twitter.svg'
import StocksIcon from './../../../../public/stocks-emoji.png'
import { useDashboardNavigationContext } from '../../../context/dashboard-navigation/context'
import { useCurrentUser } from '../../../context/user/context'
import useAuthToken from '../../../utils/hooks/useAuthToken'
// import CryptoWidget from '../../widgets/crypto/CryptoWidget'
// import { CryptoWidgetData } from '../../widgets/crypto/types'
import NewsWidget from '../../widgets/news/NewsWidget'
import { NewsWidgetData } from '../../widgets/news/types'
import RedditAuthPrompt from '../../widgets/reddit/RedditAuthPrompt'
import RedditWidget from '../../widgets/reddit/RedditWidget'
import { RedditWidgetSnapshotData } from '../../widgets/reddit/types'
import StockWidget from '../../widgets/stocks/StockWidget'
import { StockWidgetData } from '../../widgets/stocks/types'
import TwitterAuthPrompt from '../../widgets/twitter/TwitterAuthPrompt'
import TwitterWidget from '../../widgets/twitter/TwitterWidget'
import { TwitterWidgetData } from '../../widgets/twitter/types'
import { WidgetType } from '../../widgets/types'
import { Widget } from '../../widgets/types'
import EditingWidgetBlocker from './EditingWidgetBlocker'
import FakeTwitterPreview from './FakeTwitterPreview'

interface WidgetProps {
  widget: Widget
  data: unknown
  onEditWidget: (widget: Widget) => void
  onDeleteWidget: (widgetId: string) => void
  isAuthor: boolean
  disablePopover?: boolean
  isNewWidget: boolean
  isLoadingRefresh: boolean
  isBeingDragged: boolean
}

const widgetIcons: Record<WidgetType, StaticImageData> = {
  news: NewsIcon,
  reddit: RedditIcon,
  stocks: StocksIcon,
  twitter: TwitterIcon,
}

const WidgetView = ({
  isNewWidget,
  widget,
  data,
  onEditWidget,
  isLoadingRefresh,
}: WidgetProps): ReactElement => {
  const user = useCurrentUser().user
  const [isMobile] = useMediaQuery('(max-width: 840px)')

  const { isEditingDashboard } = useDashboardNavigationContext()

  const getViewForWidget = (widget: Widget, data: unknown): ReactElement => {
    const handleEditWidget = () => onEditWidget(widget)
    switch (widget.type) {
      case 'news':
        return (
          <NewsWidget
            onEditWidget={handleEditWidget}
            widget={widget}
            data={data as NewsWidgetData}
          />
        )
      case 'stocks':
        return (
          <StockWidget
            widget={widget}
            onEditWidget={handleEditWidget}
            settings={widget.settings}
            data={data as StockWidgetData}
          />
        )
      case 'reddit':
        return (
          <RedditWidget data={data as RedditWidgetSnapshotData} />
          // <> {JSON.stringify(data as RedditWidgetSnapshotData)} </>
        )
      case 'twitter':
        return <TwitterWidget data={data as TwitterWidgetData} />
    }
  }

  const [redditToken] = useAuthToken('reddit')
  const [twitterToken] = useAuthToken('twitter')

  // if not authed for a widget that requires auth,
  // add auth prompt component to popover above the usual widget menu
  const getAuthPopoverForWidgetType = (widgetType: WidgetType) => {
    if (user == null) {
      return null
    }
    switch (widgetType) {
      case 'news':
        return null
      case 'stocks':
        return null
      case 'reddit':
        if (redditToken == null) {
          return <RedditAuthPrompt />
        }
        return null
      case 'twitter':
        if (twitterToken == null) {
          return <TwitterAuthPrompt />
        }
        return null
    }
  }

  const getAuthTooltipMessageForWidgetType = (widgetType: WidgetType) => {
    switch (widgetType) {
      case 'reddit':
        return 'Log in with Reddit to refresh posts'
      case 'twitter':
        return 'Log in with Twitter to refresh tweets'
      default:
        return ''
    }
  }

  const authPopoverContent = getAuthPopoverForWidgetType(widget.type)
  const authTooltipMessage = getAuthTooltipMessageForWidgetType(widget.type)

  // const shouldShake = isEditingDashboard && !isBeingDragged

  const handleWidgetClick = (e: React.MouseEvent) => {
    if (isEditingDashboard) {
      e.stopPropagation()
    }
  }

  const handleWidgetHover = (e: React.MouseEvent) => {
    if (isEditingDashboard) {
      e.stopPropagation()
    }
  }

  const handleEditingWidgetClick = (e: React.MouseEvent) => {
    if (isEditingDashboard) {
      e.stopPropagation()
    }
  }

  const widgetElement = (isHovering: boolean): ReactElement => (
    <Box
      as="div"
      overflowY={'scroll'}
      w="100%"
      h="100%"
      borderRadius={'xl'}
      id={widget.id}
      // prevent
      // clicking
      // on
      // widget
      // from
      // bubbling
      // up
      // to
      // dashboard
      // and
      // stopping
      // editing
      // mode
      onClick={(e) => e.stopPropagation()}
      onClickCapture={handleWidgetClick}
      onMouseOverCapture={handleWidgetHover}
    >
      <VStack spacing={4} w="100%" align="start" h="100%">
        {/* <Flex
          w="100%"
          dir="row"
          zIndex={10}
          paddingY={0}
          paddingX={5}
          h="100%"
          // pr={2}
        > */}
        <HStack
          justify="center"
          zIndex={10}
          paddingY={0}
          pl={5}
          pr={3}
          w={'100%'}
          spacing={2}
          maxW="100%"
        >
          <Image
            alt={`${widget.type} icon`}
            width={5}
            height={5}
            src={widgetIcons[widget.type].src}
          />
          <HStack justify="center" spacing={0} w="100%" maxW="100%">
            {widget.title != '' && (
              <Text
                fontSize="md"
                fontWeight="medium"
                isTruncated
                noOfLines={1}
                userSelect="none"
              >
                {widget.title}
              </Text>
            )}

            {authPopoverContent != null && (
              <Tooltip
                placement="bottom"
                label={authTooltipMessage}
                openDelay={300}
              >
                {/* <WarningIcon fontSize="lg" /> */}
                <IconButton
                  pl="0"
                  color="red.400"
                  pb="1"
                  onClick={() => onEditWidget(widget)}
                  aria-label="Auth warning"
                  icon={<WarningIcon fontSize="lg" />}
                  variant="unstyled"
                />
              </Tooltip>
            )}

            <Spacer />

            <SlideFade in={isMobile ? true : isHovering}>
              <HStack ml={-1} h="100%" pb={1}>
                <IconButton
                  onClick={() => onEditWidget(widget)}
                  aria-label="Widget Settings"
                  size="md"
                  icon={<SettingsIcon />}
                  variant="unstyled"
                  color="gray.500"
                  _hover={{
                    color: 'gray.700',
                  }}
                />

                {/* {isAuthor && (
        <DeleteWidgetButton
          as={
            <IconButton
              onClick={() => setMenuOpen.on()}
              aria-label="Delete widget"
              size="md"
              icon={<DeleteIcon />}
              variant="unstyled"
              color="gray.500"
              _hover={{
                color: 'gray.700',
              }}
            />
          }
          onDelete={closeAfterHandlingEvent(() =>
            onDeleteWidget(widget?.id ?? '')
          )}
        />
      )} */}
              </HStack>
            </SlideFade>
          </HStack>

          {/* </Flex> */}
        </HStack>

        {widget.type == 'twitter' && <FakeTwitterPreview />}

        {isEditingDashboard && <EditingWidgetBlocker />}

        <Box
          bg="white"
          w="100%"
          h="calc(100% - 20px)"
          borderRadius="lg"
          zIndex={1}
          overflowY="scroll"
          px="0"
          top="25px"
          pos="absolute"
          boxShadow={
            widget.type == 'twitter'
              ? '0 0.75px 2.5px rgb(0 0 0 / 0.25)'
              : '0 0.75px 2.5px rgb(0 0 0 / 0.50)'
          }
          onClickCapture={handleEditingWidgetClick}
          className={`${widget.type}-widget`}
          // className={
          //   shouldShake
          //     ? `shake-constant my-custom-shake-${Math.floor(
          //         Math.random() * 10
          //       )}`
          //     : ''
          // }
        >
          {isNewWidget && isLoadingRefresh ? (
            <Flex w="100%" h="100%" align="center" justifyContent={'center'}>
              <Spinner />
            </Flex>
          ) : (
            <Flex w="100%" alignContent="center">
              {getViewForWidget(widget, data)}
            </Flex>
          )}
        </Box>
      </VStack>
      {/* <PopoverContent w="100%" ref={popoverRef} top="-5px">
          <Menu isOpen={menuOpen}>
            <MenuList>
              {authPopoverContent != null && (
                <MenuItem _hover={{ bg: 'none', cursor: 'default' }}>
                  {authPopoverContent}
                </MenuItem>
              )}
              <MenuItem
                onClick={closeAfterHandlingEvent(() => onEditWidget(widget))}
              >
                <SettingsIcon mr={2} />
                Settings
              </MenuItem>
              {isAuthor && (
                <MenuItem onClick={closeAfterHandlingEvent(handleEditLayout)}>
                  <EditIcon mr={2} />
                  Edit layout
                </MenuItem>
              )}

              {isAuthor && (
                <DeleteWidgetButton
                  as={
                    <MenuItem>
                      <DeleteIcon mr={2} />
                      Delete widget
                    </MenuItem>
                  }
                  onDelete={closeAfterHandlingEvent(() =>
                    onDeleteWidget(widget?.id ?? '')
                  )}
                />
              )}
            </MenuList>
          </Menu>
        </PopoverContent> */}
    </Box>
  )

  const [hoverableWidget] = useHover(widgetElement)

  return isEditingDashboard ? widgetElement(false) : <>{hoverableWidget}</>
}

export default WidgetView
