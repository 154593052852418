import React, {
  FocusEventHandler,
  KeyboardEventHandler,
  ReactElement,
} from 'react'
import { useState } from 'react'
import { ActionMeta, OnChangeValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { Badge, FormControl, FormHelperText, Wrap } from '@chakra-ui/react'

interface Props {
  phrases: string[]
  onChange: (func: (prev: string[]) => string[]) => void
  isDisabled: boolean
  validateInput?: (input: string) => string | undefined
  onCurrentInputChange?: (input: string) => void
  allowTyping?: boolean
  canEdit: boolean
}

const PhraseSelector = ({
  phrases,
  onChange,
  isDisabled,
  validateInput,
  onCurrentInputChange,
  canEdit,
  allowTyping = true,
}: Props): ReactElement => {
  const [inputValue, setInputValue] = useState('')
  const handleInputChange = (value: string) => {
    if (allowTyping || value == '') {
      onCurrentInputChange && onCurrentInputChange(value)
      setInputValue(value)
    }
  }
  const handleChange = (
    newValues: OnChangeValue<PhraseOption, true>,
    _actionMeta: ActionMeta<PhraseOption>
  ) => {
    const newPhrases = newValues.map((value) => value.value)
    onChange((_prevPhrases) => newPhrases)
  }

  const handleAddInputValue = () => {
    const trimmedInput = inputValue.trim()
    if (trimmedInput === '') return
    if (validateInput?.(inputValue) != undefined) return
    onChange((prevPhrases) => [...prevPhrases, trimmedInput])
    handleInputChange('')
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      handleAddInputValue()
      event.preventDefault()
    }
  }

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = () => {
    handleAddInputValue()
  }

  interface PhraseOption {
    label: string
    value: string
  }

  const value = phrases.map((phrase) => ({
    label: `"${phrase}"`,
    value: phrase,
  }))

  const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueContainer: (provided: any, state: any) => {
      const backgroundColor = state.isDisabled ? 'lightlightgray' : 'none'
      return { ...provided, backgroundColor, gap: '0px 4px' }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValue: (provided: any, state: any) => {
      const backgroundColor = state.isDisabled
        ? 'lightgray'
        : provided.backgroundColor

      return { ...provided, backgroundColor }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValueRemove: (provided: any, _state: any) => {
      const borderRadius = '0px'
      const hover = { backgroundColor: 'lightgray' }
      return {
        ...provided,
        borderRadius,
        ':hover': hover,
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (provided: any, _state: any) => {
      const border = canEdit ? provided.border : 'none'
      return {
        ...provided,
        border: border + ' !important',
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (provided: any, _state: any) => {
      const border = canEdit ? provided.border : 'none'
      return {
        ...provided,
        border: border + ' !important',
      }
    },
  }

  const validationErrorMessage = validateInput && validateInput(inputValue)
  const isInvalid = validationErrorMessage != undefined

  return (
    <FormControl isInvalid={isInvalid}>
      {canEdit ? (
        <>
          <CreatableSelect
            classNamePrefix="creatable-select"
            components={{
              DropdownIndicator: null,
            }}
            isDisabled={isDisabled || !canEdit}
            isClearable={false}
            inputValue={inputValue}
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
            value={value}
            styles={customStyles}
            placeholder=""
          />

          <FormHelperText color={isInvalid ? 'red' : 'gray'}>
            {isInvalid
              ? validationErrorMessage
              : 'Tab or Enter to separate phrases'}
          </FormHelperText>
        </>
      ) : (
        <Wrap mt={4}>
          {phrases.map((phrase, i) => (
            <Badge px={2} py={1} key={i}>
              {phrase}
            </Badge>
          ))}
        </Wrap>
      )}
    </FormControl>
  )
}

export default PhraseSelector
