/*
  Individual symbol row included in first view of stock widget
*/

import numeral from 'numeral'
import React, { ReactElement } from 'react'

import { Center, HStack, Spacer, Text, VStack } from '@chakra-ui/react'

import useElementSize from '../../../../utils/hooks/useElementSize'
import ThumbnailPlot from '../charts/ThumbnailPlot'
import { StockItemProps } from '../types'
import { percentChange } from '../utils'

const StockItem = ({ stock, bg, onClick }: StockItemProps): ReactElement => {
  const color =
    stock.quote.previousClose > stock.quote.latestPrice ? 'red' : 'green'
  const [ref, { width }] = useElementSize()
  const minWidth = 250

  const { companyName, securityName } = stock.info
  let title = ''

  // check if the security name is of a certain format and adjust the title to reduce redundancy
  const companyPrefix = `${companyName} - `
  if (securityName.startsWith(companyPrefix) && securityName.includes('ETF')) {
    title = securityName.substring(companyPrefix.length)
  } else {
    title = companyName
  }
  if (title.length == 0) {
    title = securityName
  }

  return (
    <VStack
      as="button"
      align="start"
      paddingY="1"
      paddingX="4"
      w="100%"
      h="100%"
      bg={bg}
      borderColor="gray.300"
      _hover={{
        bg: 'gray.50',
      }}
      onClick={() => onClick(stock)}
    >
      <HStack ref={ref} padding="2" h="100%" w="100%" maxW="100%">
        <VStack
          align="start"
          spacing="1"
          maxW="100%"
          w="100%"
          overflowX="hidden"
        >
          <Text
            fontSize="md"
            fontWeight="550"
            overflowX="hidden"
            noOfLines={1}
            maxW="100%"
            align="start"
            userSelect="none"
          >
            {stock.info.symbol}
          </Text>
          <Text
            fontSize="sm"
            maxW="100%"
            color="gray.500"
            fontWeight="normal"
            align="start"
            noOfLines={1}
            overflowX="hidden"
            userSelect="none"
          >
            {title}
          </Text>
        </VStack>

        <Spacer />

        {width >= minWidth ? (
          <HStack w="max-content" spacing="4">
            <Center w="100%" maxH="100%" overflow="hidden">
              <ThumbnailPlot stock={stock} />
            </Center>

            <VStack w="80%" align="stretch" spacing="1" overflow="hidden">
              <Text
                fontSize="md"
                pt="4px"
                w="100%"
                textAlign="right"
                fontWeight="medium"
                userSelect="none"
              >
                ${numeral(stock.quote.latestPrice).format('0.00')}
              </Text>
              <Text
                color={color}
                fontSize="sm"
                w="100%"
                textAlign="right"
                fontWeight="medium"
                userSelect="none"
              >
                {numeral(
                  percentChange(
                    stock.quote.previousClose,
                    stock.quote.latestPrice
                  )
                ).format('+0.00')}
                %
              </Text>
            </VStack>
          </HStack>
        ) : (
          <VStack align="end" spacing="1" w="max-content">
            <Text
              fontSize="md"
              pt="4px"
              textAlign="right"
              fontWeight="medium"
              userSelect="none"
            >
              ${numeral(stock.quote.latestPrice).format('0.00')}
            </Text>
            <Text
              color={color}
              fontSize="sm"
              textAlign="right"
              fontWeight="medium"
              userSelect="none"
            >
              {numeral(
                percentChange(
                  stock.quote.previousClose,
                  stock.quote.latestPrice
                )
              ).format('+0.00')}
              %
            </Text>
          </VStack>
        )}
      </HStack>
    </VStack>
  )
}

export default StockItem
