/**
 * Form for editing a Reddit widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import React, { ReactElement, useState, useRef } from 'react'
import { RedditToken, Subreddit } from '../types'
import { searchSubreddits } from '../redditClient'
import { VStack, Box, HStack, Flex } from '@chakra-ui/react'
import {
  Text,
  Spacer,
  IconButton,
  Image,
  InputRightElement,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  Fade,
} from '@chakra-ui/react'
import { CloseIcon, CheckIcon, SearchIcon } from '@chakra-ui/icons'
import SubredditOptionDisplay from './SubredditOptionDisplay'
import { useDebouncedCallback } from 'use-debounce'
import { useOutsideListener } from '../../../../utils/hooks/useOutsideListener'

interface Props {
  subreddits: Subreddit[]
  onChange: (subreddits: Subreddit[]) => void
  token: RedditToken | null
  canEdit: boolean
}

export interface SubredditOption {
  label: string
  value: Subreddit
}

const SubredditSelection = ({
  subreddits,
  onChange,
  token,
  canEdit,
}: Props): ReactElement => {
  const [searchFieldValue, setSearchFieldValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [subredditOptions, setSubredditOptions] = useState<Subreddit[]>([])
  const [showAddedSubredditBox, setShowAddedSubredditBox] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [recentlyAddedSubreddit, setRecentlyAddedSubreddit] =
    useState<Subreddit | null>(null)

  const handleClickOutside = () => setIsFocused(false)
  const formControlRef = useRef(null)
  useOutsideListener(formControlRef, handleClickOutside)

  const showAutocompleteMenu = isFocused && searchFieldValue.length > 0

  const removeSelectedSubredditFromOptions = (selectedId: string) => {
    setSubredditOptions((prev) =>
      prev.filter((option) => option.id != selectedId)
    )
  }

  const handleSelect = async (selection: Subreddit) => {
    if (selection != null) {
      onChange([...subreddits, selection])
      setRecentlyAddedSubreddit(selection)
      setShowAddedSubredditBox(true)
      setTimeout(() => setShowAddedSubredditBox(false), 3000)
      setTimeout(() => removeSelectedSubredditFromOptions(selection.id), 3000)
    }
  }

  const loadSubreddits = async (query: string) => {
    if (token === null) {
      return
    }
    setIsLoading(true)
    try {
      const results = await searchSubreddits(query, token.access_token)
      const filteredResults = results.filter(
        (result) =>
          subreddits.find(
            (alreadySelectedSubreddit) =>
              alreadySelectedSubreddit.id === result.id
          ) === undefined
      )
      setSubredditOptions(filteredResults)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const debouncedSearchForSubreddits = useDebouncedCallback(loadSubreddits, 400)

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchFieldValue(value)
    if (value === '') {
      setSubredditOptions([])
      return
    }
    debouncedSearchForSubreddits(value)
  }

  const removeSubredditForId = (id: string) => {
    onChange(subreddits.filter((subreddit) => subreddit.id != id))
  }

  return (
    <VStack align="start" w="100%">
      <Text as="b">
        {canEdit ? 'Which subreddits do you want to see?' : 'Subreddits:'}
      </Text>
      <FormControl ref={formControlRef} border="1px lightgray">
        {canEdit && (
          <InputGroup>
            <InputLeftElement justifyContent="center" w="10">
              <SearchIcon />
            </InputLeftElement>
            <Input
              value={searchFieldValue}
              onChange={handleSearchQueryChange}
              placeholder="Search for a subreddit"
              onFocus={() => setIsFocused(true)}
            />
            <InputRightElement w="min-content" justifyContent="center">
              <Fade
                transition={{
                  enter: { duration: 0, delay: 0 },
                  exit: { duration: 0.3 },
                }}
                in={showAddedSubredditBox}
              >
                <Box
                  padding="2px"
                  paddingX="6px"
                  backgroundColor="green.500"
                  borderRadius="4px"
                  w="max-content"
                >
                  <HStack>
                    <CheckIcon color="white" />
                    <Text
                      noOfLines={1}
                      color="white"
                    >{`Added ${recentlyAddedSubreddit?.name}`}</Text>
                  </HStack>
                </Box>
              </Fade>
              {searchFieldValue != '' && isFocused && (
                <IconButton
                  size="md"
                  aria-label="Clear search field"
                  onClick={() => setSearchFieldValue('')}
                  icon={<CloseIcon />}
                  variant="unstyled"
                />
              )}
            </InputRightElement>
          </InputGroup>
        )}

        {searchFieldValue.length > 0 && isFocused && (
          <Box
            w="100%"
            borderWidth="1px"
            borderRadius="lg"
            shadow="lg"
            p={2}
            maxH="15rem"
            overflowY="scroll"
          >
            {showAutocompleteMenu &&
              subredditOptions.length > 0 &&
              subredditOptions
                .slice(0, 10)
                .map((option) => (
                  <SubredditOptionDisplay
                    key={option.id}
                    subreddit={option}
                    onSelect={() => handleSelect(option)}
                  />
                ))}
            {subredditOptions.length == 0 && isLoading && (
              <Text>Searching...</Text>
            )}
            {subredditOptions.length == 0 && !isLoading && (
              <Text>No results</Text>
            )}
          </Box>
        )}
      </FormControl>
      {subreddits.map((sub) => (
        <HStack key={sub.id} width="100%" spacing={4} p={2}>
          <Image
            src={sub.thumbnail}
            width="40px"
            alt={`${sub.name} thumbnail`}
          />
          <Text
            as="a"
            href={`https://reddit.com/${sub.name}`}
            target="_blank"
            fontWeight="bold"
            _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {sub.name}
          </Text>
          <Spacer />
          {canEdit && (
            <Flex justifyContent="end">
              <IconButton
                aria-label="Remove user"
                icon={<CloseIcon />}
                variant="unstyled"
                onClick={() => removeSubredditForId(sub.id)}
              />
            </Flex>
          )}
        </HStack>
      ))}
    </VStack>
  )
}

export default SubredditSelection
