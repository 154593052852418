import { DateTime } from 'luxon'

import { StockHistoricalPrice, StockIntradayPrice } from './types'

export const mean = (arr: number[]): number =>
  arr.reduce((a, b) => a + b, 0) / arr.length

export const plotYDomain = (
  prices: StockIntradayPrice[],
  previousClose: number
): [number, number] => {
  let [min, max] = [1e10, 0]
  const priceVals: number[] = []

  prices.forEach((time) => {
    if (time.displayPrice != undefined && time.displayPrice != 0) {
      if (time.displayPrice < min) {
        min = time.displayPrice
      } else if (time.displayPrice > max) {
        max = time.displayPrice
      }
      priceVals.push(time.displayPrice)
    }
  })

  if (previousClose < min) {
    min = previousClose
  }

  if (previousClose > max) {
    max = previousClose
  }

  const offset = mean(priceVals) / 100

  return [min - offset, max + offset]
}

export const plotHistoricalYDomain = (
  prices: StockHistoricalPrice[] | undefined
): [number, number] => {
  if (prices == undefined) {
    return [0, 0]
  }
  let [min, max] = [1e10, 0]
  const priceVals: number[] = []

  prices.forEach((time) => {
    if (time.close != undefined) {
      if (time.close < min) {
        min = time.close
      } else if (time.close > max) {
        max = time.close
      }
      priceVals.push(time.close)
    }
  })

  const offset = mean(priceVals) / 100

  return [min - offset, max + offset]
}

export const percentChange = (price1: number, price2: number): number => {
  return ((price2 - price1) / price1) * 100
}

export const getLatestPrice = (prices: StockHistoricalPrice[]): number => {
  if (prices == undefined) {
    return 0
  }
  return prices.slice(-1)[0].close
}

export const getD = (
  prices: StockHistoricalPrice[],
  intervals: number
): StockHistoricalPrice[] => {
  const tempPrices = [...prices]
  const dailyPrices = tempPrices.slice(-intervals)
  dailyPrices.forEach((object, i) => (object.index = i + 1))

  return dailyPrices
}

export const getM = (
  prices: StockHistoricalPrice[],
  intervals: number,
  endTime: number
): StockHistoricalPrice[] => {
  const endDate = DateTime.fromMillis(endTime, {
    zone: 'America/New_York',
  })

  const startTime = endDate.minus({ months: intervals }).toMillis()

  const filteredPrices = prices.filter((price) => {
    const priceTime = DateTime.fromISO(price.date).toMillis()
    return priceTime > startTime && priceTime < endTime
  })

  filteredPrices.forEach((object, i) => (object.index = i + 1))

  return filteredPrices
}

export const getY = (
  prices: StockHistoricalPrice[],
  intervals: number,
  endTime: number
): StockHistoricalPrice[] => {
  const endDate = DateTime.fromMillis(endTime, {
    zone: 'America/New_York',
  })

  const startTime = endDate.minus({ years: intervals }).toMillis()

  const filteredPrices = prices.filter((price) => {
    const priceTime = DateTime.fromISO(price.date).toMillis()
    return priceTime > startTime && priceTime < endTime
  })

  filteredPrices.forEach((object, i) => (object.index = i + 1))

  return filteredPrices
}
