import React, { ReactElement, useState } from 'react'

import {
  Alert,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertIcon,
  Button,
  Input,
  Text,
} from '@chakra-ui/react'

import DeleteButton from '../../general/DeleteButton'
import { useRouter } from 'next/router'

interface AlertProps {
  body: ReactElement
  headerTitle?: string
  onConfirm: () => void
}

export const AlertContent = ({
  body,
  headerTitle = 'Are you sure?',
  onConfirm,
}: AlertProps): ReactElement => {
  const [inputFieldValue, setInputFieldValue] = useState('')

  const handleInputFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputFieldValue(e.target.value)
  }

  return (
    <AlertDialogContent>
      <AlertDialogCloseButton />
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {headerTitle}
      </AlertDialogHeader>
      <AlertDialogBody>
        {body}
        <div
          style={{ paddingTop: 20 }}
        >{`Please type "DELETE" to confirm`}</div>
        <Input value={inputFieldValue} onChange={handleInputFieldChange} />
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button
          colorScheme="red"
          isDisabled={inputFieldValue !== 'DELETE'}
          onClick={onConfirm}
          ml={3}
        >
          {`Delete Account & Dashboards`}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}

interface Props {
  onDelete: () => void
  username: string
}

const DeleteUserButton = ({ onDelete, username }: Props): ReactElement => {
  const router = useRouter()

  const handleDelete = () => {
    onDelete()

    // redirect to home upon deletion
    router.push('/')
  }
  return (
    <DeleteButton
      as={
        <Button colorScheme="red" variant="solid">
          {`Delete Account & Dashboards`}
        </Button>
      }
      renderAlertContent={() => (
        <AlertContent
          body={
            <>
              <Text mb={4}>
                You are <b>permanently</b> deleting your account (@{username})
                and all of your dashboards.
              </Text>
              <Alert status="error">
                <AlertIcon />
                This cannot be undone.
              </Alert>
            </>
          }
          onConfirm={handleDelete}
        />
      )}
    />
  )
}
export default DeleteUserButton
