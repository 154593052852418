import { WidgetType } from '../../widgets/types'
import { WidgetLayout } from '../types'

export const breakpoints = {
  xs: 0,
  md: 600,
}

export const initialDashboardLayouts = {
  xs: [],
  md: [],
}

export const columns = { md: 8, xs: 2 }

export const rowHeight = 35

export const margin: [number, number] = [15, 27]

export const widgetTypeToLayout: Record<WidgetType, WidgetLayout> = {
  stocks: {
    xs: { w: 3, x: 0, y: 0, h: 3, minW: 2, maxW: 4, minH: 2 },
    md: { w: 3, x: 0, y: 0, h: 3, minW: 2, maxW: 4, minH: 2 },
  },
  news: {
    xs: { w: 4, x: 0, y: 0, h: 6, minW: 2, minH: 2 },
    md: { w: 4, x: 0, y: 0, h: 6, minW: 2, minH: 2 },
  },
  reddit: {
    xs: { w: 4, x: 0, y: 0, h: 6, minW: 2, minH: 2 },
    md: { w: 4, x: 0, y: 0, h: 6, minW: 2, minH: 2 },
  },
  twitter: {
    xs: { w: 4, x: 0, y: 0, h: 6, minW: 2, maxW: 5, minH: 4 },
    md: { w: 4, x: 0, y: 0, h: 6, minW: 2, maxW: 5, minH: 4 },
  },
}
