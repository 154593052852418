/*
Stock widget that takes in symbol data & displays a variety of 
timeseries & plotting data
*/

import React, { ReactElement, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { Container, Divider, VStack } from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../../context/dashboard-navigation/context'
import { useMobileAlertBannerContext } from '../../../context/mobile-alert-banner/context'
import db from '../../../utils/firebase/firestore'
import NullWidget from '../NullWidget'
import StockDrawer from './display/StockDrawer'
import StockItem from './display/StockItem'
import {
  StockHistorical,
  StockStorage,
  StockWidgetDisplay,
  StockWidgetSettings,
  StockWidget as StockWidgetType,
  StockWidgetData,
} from './types'
import { getD, getM, getY } from './utils'

interface StocksWidgetProps {
  settings: StockWidgetSettings
  data: StockWidgetData | undefined
  widget: StockWidgetType
  onEditWidget: () => void
}

const StockWidget = ({ data }: StocksWidgetProps): ReactElement => {
  const { openBanner } = useMobileAlertBannerContext()
  const [selectedStock, setSelectedStock] = useState<
    StockWidgetDisplay | undefined
  >(undefined)

  // Use this to send the time of snapshot for historical querying
  const { selectedDashboardData: mostRecentView } =
    useDashboardNavigationContext()

  // TODO: make this more efficient, probably move to cloud function
  const pullHistoricalPrice = async (ticker: string) => {
    const historical: StockHistorical = await db
      .collection('stocks')
      .doc(ticker)
      .get()
      .then((doc) => {
        const stock = doc.data() as StockStorage
        return stock.historical
      })

    return historical
  }

  const handleExpandStock = (stock: StockWidgetDisplay) => {
    if (stock.historical == undefined) {
      pullHistoricalPrice(stock.info.symbol).then((info) => {
        stock.historical = {
          updated: info.updated,
          '5D': getD(info.prices, 5),
          '1M': getM(
            info.prices,
            1,
            mostRecentView?.refreshed || stock.quote.updated
          ),
          '6M': getM(
            info.prices,
            6,
            mostRecentView?.refreshed || stock.quote.updated
          ),
          '1Y': getY(
            info.prices,
            1,
            mostRecentView?.refreshed || stock.quote.updated
          ),
        }
        setSelectedStock(stock)
      })
    } else {
      setSelectedStock(stock)
    }
  }

  const handleCloseDrawer = () => {
    setSelectedStock(undefined)
  }

  const handleClickStockItem = (stock: StockWidgetDisplay) => {
    if (isMobile) {
      openBanner('stockWidget')
    } else {
      handleExpandStock(stock)
    }
  }

  if (data == undefined) {
    return <NullWidget emojis="🧐 📈" />
  }

  return (
    <Container centerContent={true} minW="100%" padding="0">
      <StockDrawer
        isOpen={selectedStock != null}
        onClose={handleCloseDrawer}
        stockData={selectedStock}
        onSelectStock={handleExpandStock}
        stocks={data}
      />
      <VStack
        divider={<Divider borderColor="gray.300" w="90%" />}
        spacing={0}
        w="100%"
        h="100%"

        // padding="0"
      >
        {data.map((stock) => {
          if (stock != undefined) {
            return (
              <StockItem
                key={stock.info.symbol}
                stock={stock}
                onClick={handleClickStockItem}
              />
            )
          }
        })}
      </VStack>
    </Container>
  )
}

export default StockWidget
