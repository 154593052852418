// modified from
// https://stackoverflow.com/questions/6248666/how-to-generate-short-uid-like-ax4j9z-in-js
export const generateRandomString = (length: number): string => {
  // Generate the UID from two parts here
  // to ensure the random number provides enough bits.
  const firstPart = (Math.random() * 46656) | 0
  const secondPart = (Math.random() * 46656) | 0
  const firstPartString = ('000' + firstPart.toString(36)).slice(-(length / 2))
  const secondPartString = ('000' + secondPart.toString(36)).slice(
    -(length - length / 2)
  )
  return firstPartString + secondPartString
}
