/*
Component that uses react-grid-layout to arrange widgets in a responsive grid. 
Widgets can be dragged around/resized if the dashboard is in the editing state.

 */

import React, { ReactElement, useCallback, useState } from 'react'
import ReactGridLayout, {
  Layouts,
  Responsive,
  WidthProvider,
} from 'react-grid-layout'

import { Center, Flex, Spinner, useMediaQuery } from '@chakra-ui/react'

import { Widget } from '../../widgets/types'
import * as gridLayoutSettings from '../utils/gridLayoutSettings'
import { DashboardData } from './DashboardDisplayV3'
import WidgetView from './Widget'
import NoWidgetsView from './nullViews/NoWidgetsView'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

interface Props {
  dashboardData: DashboardData | undefined
  widgets: Widget[]
  layouts: ReactGridLayout.Layouts
  onEditWidget?: (widget: Widget) => void
  isEditingDashboard?: boolean
  onChangeLayouts?: (
    currentLayout: ReactGridLayout.Layout[],
    allLayouts: Layouts
  ) => void
  isDarkMode?: boolean
  onDeleteWidget: (widgetId: string) => void
  isAuthor: boolean
  onEditLayout: () => void
  isLoading: boolean
  onOpenAddWidgetModal: () => void
  newWidgetId: string | undefined
  isLoadingRefresh: boolean
}

function WidgetLayout({
  dashboardData = {},
  widgets,
  layouts,
  onEditWidget,
  isEditingDashboard = true,
  onChangeLayouts,
  onDeleteWidget,
  isAuthor,
  isLoading,
  onOpenAddWidgetModal,
  newWidgetId,
  isLoadingRefresh,
}: Props): ReactElement {
  const fakeOnEditWidget = useCallback(() => {
    return
  }, [])

  const [isMobile] = useMediaQuery('(max-width: 840px)')

  const [widgetBeingDraggedId, setWidgetBeingDraggedId] = useState<
    string | null
  >(null)

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  if (widgets.length === 0) {
    return (
      <NoWidgetsView
        isAuthor={isAuthor}
        onOpenAddWidgetModal={onOpenAddWidgetModal}
      />
    )
  }

  const layoutsWithDummyItem: ReactGridLayout.Layouts = {}

  const dummyObject: ReactGridLayout.Layout = {
    i: '0',
    y: 0,
    x: 0,
    w: 0,
    h: 0,
    isResizable: false,
  }

  Object.keys(layouts).forEach(
    (key) => (layoutsWithDummyItem[key] = [dummyObject, ...layouts[key]])
  )

  // set the widget id of the widget being dragged or resized
  const handleWidgetMouseDown = (
    _layout: ReactGridLayout.Layout[],
    item: ReactGridLayout.Layout
  ) => {
    setWidgetBeingDraggedId(item.i)
  }
  // done dragging or resizing; set id of widget being dragged or resized to null
  const handleWidgetMouseUp = () => {
    setWidgetBeingDraggedId(null)
  }

  return (
    <ResponsiveReactGridLayout
      className="layout"
      layouts={layoutsWithDummyItem}
      breakpoints={gridLayoutSettings.breakpoints}
      cols={gridLayoutSettings.columns}
      rowHeight={gridLayoutSettings.rowHeight}
      isDraggable={isEditingDashboard}
      isResizable={isEditingDashboard}
      onLayoutChange={onChangeLayouts}
      // measureBeforeMount={true}
      containerPadding={[isMobile ? 10 : 75, 80]}
      margin={gridLayoutSettings.margin}
      onBreakpointChange={(_breakpoint, _cols) => {
        // console.log('breakpoint change', breakpoint, cols)
      }}
      onResizeStart={handleWidgetMouseDown}
      onResizeStop={handleWidgetMouseUp}
      onDragStart={handleWidgetMouseDown}
      onDragStop={handleWidgetMouseUp}
    >
      {widgets.map((widget) => {
        const data = dashboardData[widget.id]
        return (
          <Flex
            key={widget.id}
            _hover={{ cursor: isEditingDashboard ? 'pointer' : 'auto' }}
          >
            <WidgetView
              isLoadingRefresh={isLoadingRefresh}
              disablePopover={isEditingDashboard}
              isAuthor={isAuthor}
              onDeleteWidget={onDeleteWidget}
              widget={widget}
              data={data}
              onEditWidget={onEditWidget ?? fakeOnEditWidget}
              isNewWidget={newWidgetId === widget.id}
              isBeingDragged={widgetBeingDraggedId === widget.id}
            />
          </Flex>
        )
      })}
    </ResponsiveReactGridLayout>
  )
}

export default React.memo(WidgetLayout)
