import React, { ReactElement } from 'react'
import { Text, Icon, HStack } from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'

interface Props {
  url: string
  maxChars?: number
  disableClick?: boolean
}

const ShortenedLink = ({
  url,
  maxChars = 30,
  disableClick = false,
}: Props): ReactElement => {
  const blockClickHandler = (e: React.MouseEvent<HTMLParagraphElement>) => {
    if (disableClick) {
      e.preventDefault()
    }
  }
  return (
    <Text
      as="a"
      href={url}
      color="blue.400"
      _hover={{
        textDecoration: disableClick ? 'none' : 'underline',
        cursor: disableClick ? 'default' : 'pointer',
      }}
      target="_blank"
      w="max-content"
      onClick={blockClickHandler}
    >
      <HStack>
        <Text as="span">
          {url.slice(0, maxChars)}
          {url.length > maxChars && '...'}{' '}
        </Text>
        <Icon as={FiExternalLink} verticalAlign="center" viewBox="0 0 35 25" />{' '}
      </HStack>
    </Text>
  )
}

export default ShortenedLink
