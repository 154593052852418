import { FormikProps, useFormik } from 'formik'
import React, { ReactElement, useState } from 'react'
import * as yup from 'yup'

import WalkthroughStep4 from '../../../../public/onboarding/WalkthroughStep4.png'
import WalkthroughStep5 from '../../../../public/onboarding/WalkthroughStep5.png'
import WalkthroughStep6 from '../../../../public/onboarding/WalkthroughStep6.png'
import WalkthroughStep7 from '../../../../public/onboarding/WalkthroughStep7.png'
import { useCurrentUser } from '../../../context/user/context'
import { avatarUrl } from '../../../utils/constants'
import firebase from '../../../utils/firebase/firebase'
import db from '../../../utils/firebase/firestore'
import { UserInfoValues } from '../../forms/types'
import CreateDashboard from '../CreateDashboard'
import Interests from '../Interests'
import OnePager from '../OnePager'
import PersonalInfo from '../PersonalInfo'
import SuggestedDashboards from '../SuggestedDashboards'
import { Walkthroughs } from '../types'

interface ViewProps {
  progress: number
  type: Walkthroughs
  onNav: (increment: number, isLast?: boolean) => void
}

interface Flows {
  signUp: {
    [key: string]: ReactElement
  }
  whatIsThis: {
    [key: string]: ReactElement
  }
  createDashboard: {
    [key: string]: ReactElement
  }
  setInterests: {
    [key: string]: ReactElement
  }
}

const View = ({ progress = 0, type, onNav }: ViewProps): ReactElement => {
  const [interests, setInterests] = useState<string[]>([])

  const handleSelectInterest = (interest: string): void => {
    if (interests.includes(interest)) {
      setInterests((prevInterests) =>
        prevInterests.filter((t) => t !== interest)
      )
    } else {
      setInterests((prevInterests) => prevInterests.concat(interest))
    }
    // logInterestEvent('onboard-tag-remove', subtopic)
  }

  const { user } = useCurrentUser()

  const userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =
    db.doc(`users/${user?.uid}`)

  const validationSchema: yup.SchemaOf<UserInfoValues> = yup.object({
    displayName: yup.string().required('Required'),
    username: yup
      .string()
      .matches(/^[a-zA-Z0-9_.]*$/, 'No special characters')
      .min(3, 'Must be more than 2 characters')
      .required('Required'),
    bio: yup.string(),
    avatarUrl: yup.string(),
  })

  const initialValues: UserInfoValues = {
    displayName: user?.displayName ?? '',
    username: '',
    bio: user?.bio ?? '',
    avatarUrl: avatarUrl(user?.id) ?? '',
  }
  const initialStatus: string | undefined = undefined

  const formik: FormikProps<UserInfoValues> = useFormik({
    initialValues,
    initialStatus,
    validationSchema: validationSchema,
    onSubmit: (values: UserInfoValues) => {
      if (userRef != undefined) {
        userRef
          .update({
            bio: values.bio,
            username: values.username,
            displayName: values.displayName,
            avatarUrl: values.avatarUrl,
            completedProfile: true,
          })
          .catch((error) => {
            formik.setStatus(error.message)
            onNav(0)
          })
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  })

  const flows: Flows = {
    signUp: {
      0: <OnePager onNav={onNav} />,
      1: <PersonalInfo onNav={onNav} formik={formik} />,
      2: (
        <Interests
          onNav={onNav}
          interests={interests}
          onSelectInterest={handleSelectInterest}
        />
      ),
      3: (
        <SuggestedDashboards
          isLast={true}
          onNav={onNav}
          interests={interests}
        />
      ),
    },
    whatIsThis: {
      0: <OnePager onNav={onNav} isLast={true} />,
    },
    createDashboard: {
      0: (
        <CreateDashboard
          onNav={onNav}
          text="Fill your dashboard with widgets to source tweets, reddit posts, stock prices, and news articles'"
          image={WalkthroughStep4}
        />
      ),
      1: (
        <CreateDashboard
          onNav={onNav}
          text="Design your own custom filters for each widget"
          image={WalkthroughStep5}
        />
      ),
      2: (
        <CreateDashboard
          onNav={onNav}
          text="Refresh your dashboard to get real-time updates from each widget"
          image={WalkthroughStep6}
        />
      ),
      3: (
        <CreateDashboard
          isLast={true}
          onNav={onNav}
          text="Add more widgets, change your dashboard layout, and check out other dashboard settings"
          image={WalkthroughStep7}
        />
      ),
    },
    setInterests: {
      0: (
        <Interests
          onNav={onNav}
          interests={interests}
          onSelectInterest={handleSelectInterest}
        />
      ),
      1: (
        <SuggestedDashboards
          isLast={true}
          onNav={onNav}
          interests={interests}
        />
      ),
    },
  }

  return flows[type][progress]
}

export default View
