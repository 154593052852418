import { FormikProps, useFormik } from 'formik'
import { useRouter } from 'next/router'
import React, { ReactElement } from 'react'
import * as yup from 'yup'

import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useSettingsModalActions } from '../../../context/settings-modal/context'
import { useCurrentUser } from '../../../context/user/context'
import db from '../../../utils/firebase/firestore'
import AvatarUpload from '../../forms/fields/AvatarUpload'
import BioField from '../../forms/fields/BioField'
import NameField from '../../forms/fields/NameField'
import UsernameField from '../../forms/fields/UsernameField'
import { UserInfoValues } from '../../forms/types'
import { BODY_MARGIN } from '../SettingsModal'
import EmailField from './EmailField'
import DeleteUserButton from './DeleteUserButton'
import firebase from '../../../utils/firebase/firebase'
import { avatarUrl } from '../../../utils/constants'

const validationSchema: yup.SchemaOf<UserInfoValues> = yup.object({
  displayName: yup.string().required('Required'),
  username: yup
    .string()
    .matches(/^[a-zA-Z0-9_.]*$/, 'No special characters')
    .min(3, 'Must be more than 2 characters')
    .required('Required'),
  bio: yup.string(),
  avatarUrl: yup.string(),
  selectedTopics: yup.array(),
})

function EditProfile(): ReactElement {
  const { user, signOut } = useCurrentUser()
  const router = useRouter()
  const { closeSettings } = useSettingsModalActions()

  const handleSignOut = () => {
    closeSettings()
    signOut()
  }

  const initialValues: UserInfoValues = {
    displayName: user?.displayName ?? '',
    username: user?.username ?? '',
    bio: user?.bio ?? '',
    avatarUrl: avatarUrl(user?.id),
  }
  const initialStatus: string | undefined = undefined

  const formik: FormikProps<UserInfoValues> = useFormik({
    initialValues,
    initialStatus,
    validationSchema: validationSchema,
    onSubmit: async (values: UserInfoValues, actions) => {
      if (user != undefined) {
        actions.setSubmitting(true)
        try {
          await db.doc(`users/${user?.uid}`).update({
            bio: values.bio,
            username: values.username,
            displayName: values.displayName,
            avatarUrl: values.avatarUrl,
          })

          // Redirect to new user profile link if changed
          const oldUsername = user.username

          if (
            values.username != oldUsername &&
            router.asPath.indexOf(`/${oldUsername}`) === 0
          ) {
            router.replace(`/${values.username}`)
          }

          // Close settings modal
          closeSettings()
        } catch (error) {
          formik.setStatus(error)
        }
      }
      actions.setSubmitting(false)
    },
  })

  const onDeleteAccount = async () => {
    const deleteUserCF = firebase.functions().httpsCallable('users-delete')
    deleteUserCF()
      .then(() => {
        console.log('Successfully deleted account')
      })
      .catch((reason) => {
        console.error('Error deleting account:', reason)
      })
  }

  if (user == undefined) {
    console.error('User is undefined in settings modal')
    handleSignOut()
    return <></>
  }

  return (
    <Flex direction="column" h="100%" overflowY="hidden">
      <Box py={BODY_MARGIN.t} px={BODY_MARGIN.x} overflowY="scroll">
        <VStack align="stretch" spacing={4}>
          <Text as="b" fontSize="2xl">
            Profile
          </Text>
          <AvatarUpload formik={formik} />
          <NameField formik={formik} />
          <UsernameField formik={formik} />
          <EmailField />
          <BioField formik={formik} />
        </VStack>
        <Divider my={4} />
        <Button variant="outline" colorScheme="red" onClick={handleSignOut}>
          Log Out
        </Button>
        <Divider my={4} />
        <DeleteUserButton onDelete={onDeleteAccount} username={user.username} />
      </Box>
      <Spacer />
      <Flex
        borderTopWidth="0.25px"
        borderTopColor="gray.200"
        flexDir="row"
        py={5}
        px={8}
        w="100%"
      >
        <Spacer />
        <Button
          colorScheme="blue"
          isDisabled={
            !Boolean(formik.errors.username) &&
            !Boolean(formik.errors.displayName) &&
            !Boolean(formik.errors.bio) &&
            !Boolean(formik.errors.avatarUrl) &&
            formik.status === undefined
              ? false
              : true
          }
          isLoading={formik.isSubmitting}
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          Update Profile
        </Button>
      </Flex>
    </Flex>
  )
}

export default EditProfile
