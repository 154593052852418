import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { Dashboard } from '../../components/dashboard/dashboardDisplay/DashboardDisplayV3'
import useDashboards from '../dashboard-navigation/useDashboards'
import { useCurrentUser } from '../user/context'
interface DashboardSettingsModalContext {
  closeSettings: () => void
  isOpen: boolean
  openSettings: (dashboardId: string) => void
  dashboardId: string | undefined
  dashboardInSettings: Dashboard | undefined
}

export const DashboardSettingsModalContext =
  createContext<DashboardSettingsModalContext>({
    closeSettings: () => {
      return
    },
    isOpen: false,
    openSettings: () => {
      return
    },
    dashboardId: undefined,
    dashboardInSettings: undefined,
  })

export const useDashboardSettingsModalContext =
  (): DashboardSettingsModalContext => useContext(DashboardSettingsModalContext)

const DashboardSettingsModalContextProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [dashboardId, setDashboardId] = useState<string | undefined>(undefined)
  const [dashboard, setDashboard] = useState<Dashboard | undefined>(undefined)
  const closeSettings = useCallback(() => {
    setDashboardId(undefined)
    onClose()
  }, [onClose])

  const { user } = useCurrentUser()

  const { originalDashboards } = useDashboards(user)
  // const originalDashboards: Dashboard[] = []

  const openSettings = (newDashboardId: string) => {
    const dashboardInSettings = (originalDashboards ?? []).find(
      (d) => d.id === newDashboardId
    )
    setDashboardId(newDashboardId)
    setDashboard(dashboardInSettings)
    onOpen()
  }
  return (
    <DashboardSettingsModalContext.Provider
      value={{
        closeSettings,
        isOpen,
        openSettings,
        dashboardId,
        dashboardInSettings: dashboard,
      }}
    >
      {children}
    </DashboardSettingsModalContext.Provider>
  )
}

export default DashboardSettingsModalContextProvider
