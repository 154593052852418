import firebase from 'firebase/app'
import { FormikProps, useFormik } from 'formik'
import React, { ReactElement, useState } from 'react'
import * as yup from 'yup'
import { invalidPasswordRegex, passwordRequirementText } from '../utils'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
  useToast,
  Spinner,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../../context/auth-modal/context'
import SignUpEmail from '../fields/SignUpEmail'
import SignUpPassword from '../fields/SignUpPassword'
// import TermsAndConditions from '../fields/TermsAndConditions'
import { SignUpValues } from '../types'
import OrSignInWith from './OrSignInWith'

function SignUpForm(): ReactElement {
  const auth = firebase.auth()
  const [isLoading, setIsLoading] = useState(false)

  const { openAuthModal } = useAuthModalContext()
  const toast = useToast()

  const parseErrorCode = (
    code: string
  ): { title: string; description: string } => {
    switch (code) {
      case 'auth/email-already-in-use':
        return {
          title: 'Email already in use',
          description: 'Try logging in or using a different email.',
        }
      default:
        return {
          title: 'Sign up error',
          description: 'Try again later.',
        }
    }
  }

  const handleAuthError = (code: string): void => {
    const { title, description } = parseErrorCode(code)

    toast({
      title,
      status: 'error',
      description,
      isClosable: true,
      position: 'top',
      duration: 9000,
    })
  }

  const signUpWithEmailAndPassword: (email: string, password: string) => void =
    async (email, password) => {
      setIsLoading(true)

      await auth
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          formik.setStatus(undefined)

          // follow the default demo dashboard
        })
        .catch((error) => {
          console.error('sign up error', error)
          handleAuthError(error.code)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

  const validationSchema: yup.SchemaOf<SignUpValues> = yup.object({
    email: yup.string().email('Must be a valid email').required('Required'),
    password: yup
      .string()
      .min(8, 'Must be more than 8 characters')
      .matches(invalidPasswordRegex, passwordRequirementText)
      .required('Required'),
    termsAndConditions: yup.boolean().equals([true]).required(),
  })

  const initialValues: SignUpValues = {
    email: '',
    password: '',
    termsAndConditions: true,
  }
  const initialStatus: string | undefined = undefined

  const formik: FormikProps<SignUpValues> = useFormik({
    initialValues,
    initialStatus,
    validationSchema: validationSchema,
    onSubmit: (values: SignUpValues) => {
      signUpWithEmailAndPassword(values.email, values.password)
    },
    validateOnChange: false,
    validateOnBlur: false,
  })

  return (
    <VStack spacing={4} paddingTop="3">
      <Heading size="lg">Sign Up</Heading>
      <OrSignInWith formik={formik} />

      <SignUpEmail formik={formik} />
      <SignUpPassword formik={formik} />

      {/* <Spacer />

      <TermsAndConditions formik={formik} /> */}

      <Spacer />

      {formik.status && formik.status !== 'validating-errors' && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{formik.status}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => {
              formik.setStatus(undefined)
            }}
          />
        </Alert>
      )}

      <Button
        isDisabled={
          !Boolean(formik.errors.email) &&
          !Boolean(formik.errors.password) &&
          // formik.values.termsAndConditions === true &&
          Boolean(formik.touched.email) &&
          Boolean(formik.touched.password) &&
          formik.status != 'validating-errors'
            ? false
            : true
        }
        width="100%"
        colorScheme="blue"
        onClick={() => formik.handleSubmit()}
      >
        {isLoading ? <Spinner /> : 'Sign Up'}
      </Button>

      <HStack>
        <Text>Already have an account? </Text>
        <Button
          variant="link"
          colorScheme="blue"
          onClick={() => openAuthModal('logIn')}
        >
          Login
        </Button>
      </HStack>

      <Spacer />
    </VStack>
  )
}

export default SignUpForm
