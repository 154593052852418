import { DateTime } from 'luxon'
import router from 'next/router'
import React, { ReactElement, useRef, useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'

import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Text,
} from '@chakra-ui/react'

import { useCurrentUser } from '../../../../context/user/context'
import { routes } from '../../../../utils/constants'
import DefaultAvatar from '../../../general/DefaultAvatar'
import { HeaderProps } from '../types'

const Header = ({
  comment,
  isThread,
  onDeleteComment,
}: HeaderProps): ReactElement => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const popoverMenuRef = useRef(null)

  const { author, timestamp } = comment
  const { user } = useCurrentUser()

  const isAuthor = author.id == user?.uid

  const menuItemStyles = {
    _hover: { bg: 'gray.100', cursor: 'pointer' },
    w: '100%',
    align: 'center',
    py: 1,
    px: 2,
  }

  const handleClickUsername = () => {
    if (author?.username != undefined) {
      router.push(routes.profile(author.username))
    }
  }

  const timeDiff = DateTime.fromMillis(timestamp)
    .toRelative({
      style: 'short',
    })
    ?.replaceAll('.', '')

  return (
    <HStack w="100%" spacing={0}>
      <Text
        fontSize={isThread ? 'sm' : 'sm'}
        fontWeight="medium"
        color="brand.username"
        as="a"
        href={routes.profile(author.username)}
        onClick={handleClickUsername}
        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        <Box as="span" mr={2.5}>
          <DefaultAvatar size={isThread ? 'xs' : 'xs'} user={author} />
        </Box>
      </Text>
      <Text
        fontSize={isThread ? 'sm' : 'sm'}
        fontWeight="medium"
        color="brand.username"
        as="a"
        href={routes.profile(author.username)}
        onClick={handleClickUsername}
        _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        {author.displayName}
      </Text>
      <Text
        pl={1}
        fontSize={isThread ? 'sm' : 'sm'}
        fontWeight="medium"
        color="brand.username"
      >
        &bull; {timeDiff}
      </Text>

      <Spacer />

      {isAuthor && (
        <Popover
          isOpen={popoverIsOpen}
          onClose={() => setPopoverIsOpen(false)}
          placement="bottom-end"
        >
          <PopoverTrigger>
            <Center onClick={() => setPopoverIsOpen(true)}>
              <IconButton
                size={isThread ? 'sm' : 'xs'}
                aria-label="Comment menu"
                borderRadius={'lg'}
                variant="ghost"
              >
                <BsThreeDots color="gray.500" />
              </IconButton>
            </Center>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              borderRadius="md"
              w="100%"
              p={0}
              ref={popoverMenuRef}
            >
              <PopoverBody borderRadius="md" p={0}>
                <HStack
                  as="button"
                  spacing={2}
                  onClick={() => onDeleteComment(comment)}
                  {...menuItemStyles}
                >
                  <DeleteIcon />
                  <Text fontSize={14}> Delete comment</Text>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </HStack>
  )
}

export default Header
