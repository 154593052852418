import routes from './routes'

const avatarUrl = (userId: string | undefined): string | undefined => {
  if (userId == undefined) return undefined
  return `https://firebasestorage.googleapis.com/v0/b/probable-palm-tree-2.appspot.com/o/avatars%2F${userId}?alt=media`
}
const colorLuminance = (hex: string, lum: number) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  let rgb = '#'
  let c
  let i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}

const stringToColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return colorLuminance(color, 0.5)
}

const avatarColor = (userId: string | undefined): string => {
  if (userId == undefined) return '#BFBFBF'
  return stringToColor(userId)
}

// id of the dashboard automatically followed by all users upon signing up
const demoDashboardId = 'afz9hfuocwsnkgbj6srhbn'

// id of the author of the above dashboard
const demoDashboardAuthorId = 'kbvy2zOb8hRnJAnlQ8toIqAZTNk2'

const dashboardsListItemId = (dashboardId: string): string =>
  `dashboards-list-item-${dashboardId}`

const dashboardPreviewScreenshotUrl = (dashboardId: string): string =>
  `https://firebasestorage.googleapis.com/v0/b/probable-palm-tree-2.appspot.com/o/dashboard-previews%2F${dashboardId}?alt=media`

export {
  avatarColor,
  avatarUrl,
  dashboardsListItemId,
  dashboardPreviewScreenshotUrl,
  routes,
  demoDashboardId,
  demoDashboardAuthorId,
}
