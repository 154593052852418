// Format a number to display in terms of millions or thousands if large enough
export const numberFormatter = (num: number): string => {
  const abs = Math.abs(num)
  const sign = Math.sign(num)

  if (abs > 999999) {
    // >= millions
    // display "1.56m"
    return ((sign * abs) / 1000000).toFixed(2).toString() + 'm'
  } else if (abs > 999) {
    // >= thousands
    // display "125.2k"
    return ((sign * abs) / 1000).toFixed(1).toString() + 'k'
  }
  // less than thousands
  // display "128"
  return (sign * abs).toString()
}
