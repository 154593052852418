import React, { ReactElement, useState } from 'react'
import 'firebase/auth'

import firebase from 'firebase/app'
import { FormikProps, useFormik } from 'formik'
import NextLink from 'next/link'
import * as yup from 'yup'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  Heading,
  Spacer,
  VStack,
  useToast,
  Spinner,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../../context/auth-modal/context'
import LoginEmail from '../fields/LoginEmail'
import LoginPassword from '../fields/LoginPassword'
import { LoginValues } from '../types'
import OrSignInWith from './OrSignInWith'

function LoginForm(): ReactElement {
  const auth = firebase.auth()
  const [isLoading, setIsLoading] = useState(false)
  const { closeAuthModal } = useAuthModalContext()
  const toast = useToast()

  const parseErrorCode = (
    code: string
  ): { title: string; description: string } => {
    switch (code) {
      case 'auth/user-not-found':
        return {
          title: 'Incorrect email or password',
          description: 'Please double-check and try again.',
        }
      default:
        return {
          title: 'Log in error',
          description: 'Try again later.',
        }
    }
  }

  const handleAuthError = (code: string): void => {
    const { title, description } = parseErrorCode(code)

    toast({
      title,
      status: 'error',
      description,
      isClosable: true,
      position: 'top',
      duration: 9000,
    })
  }

  const signInWithEmailAndPassword: (email: string, password: string) => void =
    async (email, password) => {
      setIsLoading(true)
      await auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          formik.setStatus(undefined)
        })
        .catch((error) => {
          // formik.setStatus(error.message)
          handleAuthError(error.code)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

  const validationSchema: yup.SchemaOf<LoginValues> = yup.object({
    email: yup.string().email('Must be a valid email').required('Required'),
    password: yup.string().required('Required'),
  })

  const initialValues: LoginValues = {
    email: '',
    password: '',
  }
  const initialStatus: string | undefined = undefined

  const formik: FormikProps<LoginValues> = useFormik({
    initialValues,
    initialStatus,
    validationSchema: validationSchema,
    onSubmit: (values: LoginValues) => {
      signInWithEmailAndPassword(values.email, values.password)
    },
    validateOnChange: false,
    validateOnBlur: false,
  })

  return (
    <VStack spacing={4}>
      <Heading size="lg">Log In</Heading>

      <OrSignInWith formik={formik} />

      <LoginEmail formik={formik} />
      <LoginPassword formik={formik} />

      <Spacer />

      {formik.status && formik.status !== 'validating-errors' && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{formik.status}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => {
              formik.setStatus(undefined)
            }}
          />
        </Alert>
      )}

      <Button
        isDisabled={
          Boolean(formik.touched.email) && Boolean(formik.touched.password)
            ? false
            : true
        }
        width="100%"
        colorScheme="blue"
        onClick={() => {
          formik.handleSubmit()
        }}
      >
        {isLoading ? <Spinner /> : 'Login'}
      </Button>

      <NextLink href="/forgot">
        <Button
          variant="link"
          colorScheme="blue"
          onClick={() => closeAuthModal()}
        >
          Forgot Password?
        </Button>
      </NextLink>

      {/* <HStack>
        <Text>Don&apos;t have an account? </Text>
        <Button
          variant="link"
          colorScheme="blue"
          onClick={() => openAuthModal('signUp')}
        >
          Sign Up
        </Button>
      </HStack> */}
    </VStack>
  )
}

export default LoginForm
