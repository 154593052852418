import React, {
  ReactNode,
  ReactElement,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useCurrentUser } from '../user/context'
import { useEffect } from 'react'

export type ModalContent = 'logIn' | 'signUp'

interface AuthModalContext {
  modalContent: ModalContent | undefined
  closeAuthModal: () => void
  isOpen: boolean
  openAuthModal: (initialContent?: ModalContent) => void
}

export const AuthModalContext = createContext<AuthModalContext>({
  modalContent: undefined,
  closeAuthModal: () => {
    return
  },
  isOpen: false,
  openAuthModal: () => {
    return
  },
})

export const useAuthModalContext = (): AuthModalContext =>
  useContext(AuthModalContext)

const AuthModalContextProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [activeModalContent, setActiveModalContent] = useState<
    ModalContent | undefined
  >()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user } = useCurrentUser()

  const closeAuthModal = useCallback(() => {
    setActiveModalContent(undefined)
    onClose()
  }, [onClose])

  const openAuthModal = useCallback(
    (initialContent?: ModalContent) => {
      setActiveModalContent(initialContent ?? 'logIn')
      onOpen()
    },
    [onOpen]
  )

  useEffect(() => {
    if (user != null) {
      setActiveModalContent(undefined)
      onClose()
    }
  }, [user, onClose])

  return (
    <AuthModalContext.Provider
      value={{
        modalContent: activeModalContent,
        closeAuthModal,
        isOpen,
        openAuthModal,
      }}
    >
      {children}
    </AuthModalContext.Provider>
  )
}

export default AuthModalContextProvider
