import BlurImage from './BlurImage'
import { Tweet } from '../types'
import { Box, Flex, HStack, Link, Text } from '@chakra-ui/react'
import CompactTweetDisplay from './CompactTweet'
import PollDisplay from './PollDisplay'
import MediaDisplay from './MediaDisplay'
import RepliedIndicator from './tweet-type-indicators/replied'
import RetweetedIndicator from './tweet-type-indicators/retweeted'
import MetricsRow from './MetricsRow'
import LinkPreviewDisplay from './LinkPreviewDisplay'
import { DateTime } from 'luxon'

interface Props {
  tweet: Tweet
  isRepliedTo?: boolean
}

export default function TweetDisplay({
  tweet,
  isRepliedTo,
}: Props): React.ReactElement {
  const referenced_tweets = tweet.referenced_tweets
  const isRetweet =
    referenced_tweets?.some((rf) => rf.type === 'retweeted') || false
  const retweeterName = tweet.author.name
  tweet =
    referenced_tweets?.find((rf) => rf.type === 'retweeted')?.tweet || tweet
  const id = tweet.id
  const text = tweet.text
  const author = tweet.author
  const media = tweet.attachments
  const video = tweet.video
  const polls = tweet.polls
  const url_meta = tweet.url_meta
  const created_at = tweet.created_at
  const public_metrics = tweet.public_metrics

  const authorUrl = `https://twitter.com/${author.username}`
  const tweetUrl = `https://twitter.com/${author.username}/status/${id}`
  const createdAt = new Date(created_at)
  const timestampString = DateTime.fromJSDate(createdAt)
    .toLocal()
    .toRelative({ style: 'narrow' })
    ?.replace(' sec. ago', 's')
    .replace(' min. ago', 'm')
    .replace(' hr. ago', 'h')
    .replace(' day ago', 'd')
    .replace(' days ago', 'd')
    .replace(' week ago', 'w')
    .replace(' weeks ago', 'w')

  const regexToMatchURL =
    /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/
  const formattedText = text
    .replace(regexToMatchURL, (match) => {
      // format all hyperlinks
      return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none" href="${match}" target="_blank">${match.replace(
        /^http(s?):\/\//i,
        ''
      )}</a>`
    })
    .replace(/\B\@([\w\-]+)/gim, (match) => {
      // format all @ mentions
      return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none" href="https://twitter.com/${match.replace(
        '@',
        ''
      )}" target="_blank">${match}</a>`
    })
    .replace(/(#+[a-zA-Z0-9(_)]{1,})/g, (match) => {
      // format all # hashtags
      return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none" href="https://twitter.com/hashtag/${match.replace(
        '#',
        ''
      )}" target="_blank">${match}</a>`
    })

  const quoteTweet =
    referenced_tweets && referenced_tweets.find((t) => t.type === 'quoted')

  const repliedTo =
    referenced_tweets && referenced_tweets.find((t) => t.type === 'replied_to')

  return (
    <Box
      className={`tweet bg-white dark:bg-black ${
        !isRepliedTo ? 'px-4' : ''
      } w-full overflow-hidden`}
      style={{ alignContent: 'stretch' }}
    >
      {repliedTo && author.id !== repliedTo.tweet.author.id && (
        <RepliedIndicator authorName={author.name} />
      )}
      {isRetweet && <RetweetedIndicator retweeterName={retweeterName} />}
      {repliedTo && <TweetDisplay tweet={repliedTo.tweet} isRepliedTo />}
      <HStack align="stretch" w="100%">
        <div className="flex mt-1 flex-col items-center">
          <Link
            className="flex h-12 w-12 overflow-hidden"
            href={authorUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Box
              height={'48px'}
              width={'48px'}
              overflow="hidden"
              borderRadius="full"
              pos="relative"
            >
              <BlurImage alt={author.username} src={author.profile_image_url} />
            </Box>
          </Link>
          {isRepliedTo && (
            <div
              className="overflow-hidden mt-2 rounded-lg"
              style={{
                width: '2px',
                flexGrow: 1,
                backgroundColor: 'lightgray',
              }}
            />
          )}
        </div>
        <Flex className="flex flex-col overflow-hidden w-full -pt-2">
          <HStack spacing={0}>
            <Link
              href={authorUrl}
              target="_blank"
              rel="noreferrer"
              _hover={{ textDecoration: 'none' }}
              maxW="80%"
            >
              <HStack spacing={0}>
                <Text
                  className="font-bold"
                  mr={1}
                  title={author.name}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'nowrap'}
                >
                  {author.name}
                </Text>
                {author.verified && (
                  <Text>
                    <svg
                      aria-label="Verified Account"
                      className="text-blue-500 dark:text-white inline h-5 w-5"
                      viewBox="0 0 27 27"
                    >
                      <g fill="currentColor">
                        <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z" />
                      </g>
                    </svg>
                  </Text>
                )}
                <Text
                  noOfLines={1}
                  color="gray.500"
                  title={`@${author.username}`}
                  pl={2}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'nowrap'}
                >
                  @{author.username}
                </Text>{' '}
              </HStack>
            </Link>
            <HStack color="gray.500" w="100%" spacing={0}>
              <Text px={2}> · </Text>
              <Link target="_blank" href={tweetUrl}>
                <Text noOfLines={1}>{`${timestampString}`}</Text>{' '}
              </Link>{' '}
            </HStack>
          </HStack>

          <div
            className="leading-normal whitespace-pre-wrap text-md pr-4 !text-gray-700 dark:!text-gray-300"
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
          {media.length > 0 && <MediaDisplay media={media} video={video} />}
          {media.length == 0 &&
            url_meta?.images &&
            url_meta.images.length > 0 && (
              <LinkPreviewDisplay url_meta={url_meta} />
            )}
          {polls.length > 0 && (
            <PollDisplay polls={polls} tweetUrl={tweetUrl} />
          )}
          {quoteTweet && <CompactTweetDisplay tweet={quoteTweet.tweet} />}
          <MetricsRow
            tweetUrl={tweetUrl}
            tweetId={id}
            publicMetrics={public_metrics}
          />
        </Flex>
      </HStack>
    </Box>
  )
}
