import React, { ReactElement } from 'react'

import { Box, Button, Flex, ModalFooter, Spacer, Text } from '@chakra-ui/react'

interface FooterProps {
  isLast?: boolean
  interestsLabel?: string
  isNextDisabled: boolean
  backLabel: string | undefined
  nextLabel: string
  onSubmit?: () => void | undefined
  onNav: (increment: number, isLast?: boolean) => void
}

const Footer = ({
  isLast = false,
  interestsLabel,
  isNextDisabled,
  backLabel,
  nextLabel,
  onSubmit,
  onNav,
}: FooterProps): ReactElement => {
  return (
    <ModalFooter px={0} pb={4} pt={0}>
      <Box w="100%" borderTopWidth="1px" pt={4} px={6}>
        <Flex w="100%">
          {backLabel && <Button onClick={() => onNav(-1)}>{backLabel}</Button>}

          <Spacer />

          {interestsLabel && (
            <Text pt="2" pr="3" color="gray.500" h="100%">
              {interestsLabel}
            </Text>
          )}

          <Button
            colorScheme="blue"
            isDisabled={isNextDisabled}
            onClick={() => {
              onNav(1, isLast)
              if (onSubmit) onSubmit()
            }}
          >
            {nextLabel}
          </Button>
        </Flex>
      </Box>
    </ModalFooter>
  )
}

export default Footer
