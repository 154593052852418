import BlurImage from './BlurImage'
import { format } from 'date-fns'
import { Tweet } from '../types'

import { Box, Text, Link, HStack } from '@chakra-ui/react'
import PollDisplay from './PollDisplay'

interface Props {
  tweet: Tweet
  isRepliedTo?: boolean
}

const AVATAR_SIZE = 24 // in px
const USER_DISPLAY_HSTACK_SPACING = 8 // in px

export default function TweetDisplay({ tweet }: Props): React.ReactElement {
  const id = tweet.id
  const text = tweet.text
  const author = tweet.author
  const media = tweet.attachments
  const video = tweet.video
  const polls = tweet.polls
  const url_meta = tweet.url_meta
  const created_at = tweet.created_at

  const authorUrl = `https://twitter.com/${author.username}`
  const tweetUrl = `https://twitter.com/${author.username}/status/${id}`
  const createdAt = new Date(created_at)

  const regexToMatchURL =
    /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/
  const formattedText = text
    .replace(regexToMatchURL, (match) => {
      // format all hyperlinks
      return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none" href="${match}" target="_blank">${match.replace(
        /^http(s?):\/\//i,
        ''
      )}</a>`
    })
    .replace(/\B\@([\w\-]+)/gim, (match) => {
      // format all @ mentions
      return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none" href="https://twitter.com/${match.replace(
        '@',
        ''
      )}" target="_blank">${match}</a>`
    })
    .replace(/(#+[a-zA-Z0-9(_)]{1,})/g, (match) => {
      // format all # hashtags
      return `<a style="color: rgb(29,161,242); font-weight:normal; text-decoration: none" href="https://twitter.com/hashtag/${match.replace(
        '#',
        ''
      )}" target="_blank">${match}</a>`
    })

  return (
    <div
      className={`tweet border border-gray-300 rounded-lg bg-white dark:bg-black my-2 px-4 py-2 w-full overflow-hidden`}
    >
      <HStack spacing={USER_DISPLAY_HSTACK_SPACING / 4}>
        <Box>
          <a
            className="flex h-6 w-6 rounded-full overflow-hidden"
            href={authorUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Box pos="relative" height={AVATAR_SIZE} width={AVATAR_SIZE}>
              <BlurImage alt={author.username} src={author.profile_image_url} />
            </Box>
          </a>
        </Box>
        <Link
          href={authorUrl}
          target="_blank"
          rel="noreferrer"
          _hover={{ textDecoration: 'none' }}
          w={`calc(100% - ${AVATAR_SIZE + USER_DISPLAY_HSTACK_SPACING}px)`}
        >
          <HStack spacing={0}>
            <Text
              className="font-bold"
              mr={1}
              title={author.name}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={'nowrap'}
            >
              {author.name}
            </Text>
            {author.verified && (
              <Text>
                <svg
                  aria-label="Verified Account"
                  className="text-blue-500 dark:text-white inline h-5 w-5"
                  viewBox="0 0 27 27"
                >
                  <g fill="currentColor">
                    <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z" />
                  </g>
                </svg>
              </Text>
            )}
            <Text
              noOfLines={1}
              color="gray.500"
              title={`@${author.username}`}
              pl={1}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={'nowrap'}
            >
              @{author.username}
            </Text>{' '}
          </HStack>
        </Link>
      </HStack>

      <div
        className="my-1 leading-normal whitespace-pre-wrap text-md !text-gray-700 dark:!text-gray-300"
        dangerouslySetInnerHTML={{ __html: formattedText }}
      />
      {media && media.length ? (
        <div
          className={
            media.length === 1
              ? 'inline-grid grid-cols-1 gap-x-2 gap-y-2 my-2'
              : 'inline-grid grid-cols-2 gap-x-2 gap-y-2 my-2'
          }
        >
          {media.map((m, i) => (
            <div key={i}>
              {m.type == 'video' || m.type == 'animated_gif' ? (
                video ? (
                  <video
                    className="rounded-2xl -mt-10"
                    loop
                    width={'100%'}
                    height={'100%'}
                    // autoPlay
                    // muted
                    controls
                    playsInline
                    src={video.url}
                  >
                    Your browser does not support this video player. Open in
                    twitter to see the video.
                  </video>
                ) : (
                  <Box pos="relative" width={'100%'}>
                    <BlurImage
                      key={m.media_key}
                      alt={text}
                      src={m.preview_image_url}
                      className="rounded-2xl hover:brightness-90 transition-all ease-in-out duration-150"
                    />
                  </Box>
                )
              ) : m.type == 'photo' ? (
                <Box
                  pos="relative"
                  width={2048}
                  height={m.height * (2048 / m.width)}
                >
                  {' '}
                  <BlurImage
                    key={m.media_key}
                    alt={text}
                    src={m.url}
                    className="rounded-2xl hover:brightness-90 transition-all ease-in-out duration-150"
                  />
                </Box>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
      {url_meta?.images ? (
        <a
          className="!no-underline"
          href={url_meta.unwound_url}
          target="_blank"
          rel="noreferrer"
        >
          <div className="rounded-2xl overflow-hidden border border-gray-200 drop-shadow-sm mb-5">
            <Box pos="relative" width="100%">
              <BlurImage
                key={url_meta.unwound_url}
                alt={url_meta.title ?? ''}
                src={url_meta?.images ? url_meta?.images[0].url : ''}
                className="hover:brightness-90 transition-all ease-in-out duration-150"
              />
            </Box>
            <div className="w-full bg-white px-8 py-2">
              <p className="!m-0">{url_meta.title}</p>
              <p className="text-sm">{url_meta.description}</p>
            </div>
          </div>
        </a>
      ) : null}
      {polls.length > 0 && <PollDisplay polls={polls} tweetUrl={tweetUrl} />}

      <Link
        color="gray.500"
        className="block mt-1 hover:!underline !no-underline"
        href={tweetUrl}
        target="_blank"
        rel="noreferrer"
      >
        <time
          title={`Time Posted: ${createdAt.toUTCString()}`}
          dateTime={createdAt.toISOString()}
        >
          {format(createdAt, 'h:mm a - MMM d, y')}
        </time>
      </Link>
    </div>
  )
}
