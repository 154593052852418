import { IEXCloudSymbol } from '../../components/widgets/stocks/types'
import { IEXGetStockTickersResponse } from '../../pages/api/iex/getStockTickers'

export interface IEXCloudCryptoSymbol {
  symbol: string
  name: string
  exchange?: string
  date: string
  type: 'crypto'
  iexId: string
  region: string
  currency: string
  isEnabled: boolean
}

export const getStockTickers = async (
  query: string
): Promise<IEXCloudSymbol[]> => {
  const data = JSON.stringify({
    query,
  })

  const response = await fetch(`/api/iex/getStockTickers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  if (!response.ok) {
    console.error(`Error: ${response.status}`)
  }

  const responseData = (await response.json()) as IEXGetStockTickersResponse
  const tickers = responseData.tickers

  return tickers
}
