import React, { ReactElement, useEffect, useState } from 'react'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  HStack,
  IconButton,
  Link,
  SkeletonText,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'
import firebase from '../../../../utils/firebase/firebase'
import db from '../../../../utils/firebase/firestore'
import { Article, NewsDisplayProps } from '../types'

interface ArticleSummary {
  text?: string
  main_image?: string
  authors?: string[]
  title?: string
  images?: string[]
  links?: string[]
  summary?: string
  keywords?: string[]
  exists: boolean
}

const NewsItem = ({
  article,
  handleExpandArticle,
  byline,
}: NewsDisplayProps): ReactElement => {
  const [summary, setSummary] = useState<string | undefined>(undefined)

  const { isEditingDashboard } = useDashboardNavigationContext()

  const summaryIsQuality = (content: ArticleSummary | undefined): boolean => {
    const badCases = ['please enable javascript and cookies in your browser']
    const textLower = content?.summary?.toLowerCase()
    if (
      textLower != undefined &&
      !badCases.some((badCase) => textLower.includes(badCase))
    )
      return true
    else return false
  }

  useEffect(() => {
    const populateSummaries = async (article: Article) => {
      const summaryDoc = await db.doc(`summaries/${article._id}`).get()

      if (summaryDoc.exists) {
        const summaryContent = summaryDoc.data() as ArticleSummary

        if (summaryContent.exists == true) {
          setSummary(summaryContent.summary)
        } else {
          setSummary('')
        }
      } else {
        // Not found in cache
        const cloudFunctions = firebase.functions()
        const getSummary = cloudFunctions.httpsCallable('readers-summary')

        const summaryResponse = await getSummary({
          article,
        })

        const summaryContent: ArticleSummary = summaryResponse.data

        // Check for summary quality
        if (summaryIsQuality(summaryContent)) setSummary(summaryContent.summary)
        else setSummary('')
      }
    }

    try {
      populateSummaries(article)
    } catch (error) {
      console.error(error)
      setSummary('')
    }
  }, [article])

  const handleClickLink = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    // if (isEditingDashboard) {
    //   e.preventDefault()
    // }
  }

  return (
    <VStack
      align="start"
      paddingY="4"
      paddingX="5"
      // paddingRight="8"
      w="100%"
      bg="white"
      borderColor="gray.300"
      justifyContent="flex-start"
      onClickCapture={(e) => {
        if (isEditingDashboard) {
          e.stopPropagation()
        }
      }}
      onMouseOverCapture={(e) => {
        if (isEditingDashboard) {
          e.stopPropagation()
        }
      }}
      _hover={{
        cursor: 'pointer',
        bg: isEditingDashboard ? 'auto' : 'gray.50',
        pointer: isEditingDashboard ? 'inherit' : 'pointer',
      }}
      spacing={1}
      onClick={() => handleExpandArticle(article)}
    >
      <VStack align="start" spacing={0} maxW="100%">
        <Text align="start" as="b" noOfLines={3} userSelect="none">
          {article.title}
        </Text>

        <HStack maxW="100%" spacing="1">
          <Text color="gray.500" fontSize="sm" isTruncated userSelect="none">
            {byline}
          </Text>
          <Tooltip
            placement="right"
            label="Open in new tab"
            openDelay={1000}
            isDisabled={isEditingDashboard}
          >
            <Link href={article.link} onClick={handleClickLink} isExternal>
              <IconButton
                onClick={handleClickLink}
                color="gray.500"
                variant="ghost"
                size="sm"
                aria-label="Open in new tab"
                icon={<ExternalLinkIcon />}
              />
            </Link>
          </Tooltip>
        </HStack>
      </VStack>

      {summary == undefined ? (
        <SkeletonText noOfLines={3} spacing="4" w="100%" />
      ) : (
        summary != '' && (
          <Text
            overflowX="hidden"
            noOfLines={3}
            align="start"
            fontSize="sm"
            color="gray.600"
            pr="6"
            userSelect="none"
          >
            {summary}
          </Text>
        )
      )}
    </VStack>
  )
}

export default NewsItem
