export const encodeStringToBase64 = (normalString: string): string => {
  return Buffer.from(normalString).toString('base64')
}

export const encodeJSONToBase64 = (
  jsonObject: Record<string, unknown>
): string => {
  const jsonString = JSON.stringify(jsonObject)
  return encodeStringToBase64(jsonString)
}

export const decodeBase64 = (encodedString: string): string => {
  return Buffer.from(encodedString as string, 'base64').toString()
}

export const decodeToJSON = (
  encodedString: string
): Record<string, unknown> => {
  const decodedString = decodeBase64(encodedString)
  return JSON.parse(decodedString)
}
