// embed video URL as youtube src if "you.tube" type link;
// return original URL otherwise
export const generateYoutubeSrc = (url: string): string => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  // console.log(match, match && match[2])

  if (match && match[2].length === 11) {
    const videoId = match[2]
    return 'www.youtube.com/watch?v=' + videoId
  }
  return url
}
