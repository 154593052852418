import { TwitterUrlEntity } from '../types'
import Image from 'next/image'

interface Props {
  url_meta: TwitterUrlEntity
}

export default function LinkPreviewDisplay({
  url_meta,
}: Props): React.ReactElement {
  const image = url_meta?.images ? url_meta?.images[0] : undefined
  if (image) {
    return (
      <a
        className="!no-underline"
        href={url_meta.unwound_url}
        target="_blank"
        rel="noreferrer"
      >
        <div className="rounded-2xl border border-gray-200 drop-shadow-sm mt-1">
          <Image
            width={image.width}
            height={image.height}
            layout="responsive"
            src={image.url}
            alt={url_meta.title}
          />

          <div className="w-full bg-gray px-8 py-2">
            <p className="!m-0 line-clamp-2">{url_meta.title}</p>
            <p className="text-sm line-clamp-2">{url_meta.description}</p>
          </div>
        </div>
      </a>
    )
  }
  return (
    <a
      className="!no-underline"
      href={url_meta.unwound_url}
      target="_blank"
      rel="noreferrer"
    >
      <div className="rounded-2xl overflow-hidden border border-gray-200 drop-shadow-sm mb-5">
        <div className="w-full bg-white px-8 py-2">
          <p className="!m-0">{url_meta.title}</p>
          <p className="text-sm">{url_meta.description}</p>
        </div>
      </div>
    </a>
  )
}
