import { TwitterMedia, TweetVideoVariant } from '../types'
import BlurImage from './BlurImage'
import {
  Grid,
  GridItem,
  GridItemProps,
  Box,
  useDimensions,
} from '@chakra-ui/react'
import { useRef } from 'react'

interface Props {
  media: TwitterMedia[]
  video: TweetVideoVariant | undefined
}

type LengthOfMediaArray = 1 | 2 | 3 | 4

const gridItemStyles: Record<LengthOfMediaArray, GridItemProps[]> = {
  1: [
    {
      borderRadius: '2xl',
      colSpan: 2,
      rowSpan: 2,
    },
  ],
  2: [
    {
      borderTopLeftRadius: '2xl',
      borderBottomLeftRadius: '2xl',
      colSpan: 1,
      rowSpan: 2,
    },
    {
      borderTopRightRadius: '2xl',
      borderBottomRightRadius: '2xl',
      colSpan: 1,
      rowSpan: 2,
    },
  ],
  3: [
    {
      borderTopLeftRadius: '2xl',
      borderBottomLeftRadius: '2xl',
      colSpan: 1,
      rowSpan: 2,
    },
    {
      borderTopRightRadius: '2xl',
      colSpan: 1,
      rowSpan: 1,
    },
    {
      borderBottomRightRadius: '2xl',
      colSpan: 1,
      rowSpan: 1,
    },
  ],
  4: [
    {
      borderTopLeftRadius: '2xl',
      colSpan: 1,
      rowSpan: 1,
    },
    {
      borderTopRightRadius: '2xl',
      colSpan: 1,
      rowSpan: 1,
    },
    {
      borderBottomLeftRadius: '2xl',
      colSpan: 1,
      rowSpan: 1,
    },
    {
      borderBottomRightRadius: '2xl',
      colSpan: 1,
      rowSpan: 1,
    },
  ],
}

export default function MediaDisplay({
  media,
  video,
}: Props): React.ReactElement {
  const ref = useRef(null)

  //media = [...media, ...media, ...media]

  const dimensions = useDimensions(ref)
  if (media.length == 0) return <></>

  const gridHeight = dimensions?.contentBox.height ?? 0

  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(2, 1fr)"
      gap={'1px'}
      my={2}
      ref={ref}
    >
      {media.map((m, i) => {
        const rowSpan =
          gridItemStyles[media.length as LengthOfMediaArray][i].rowSpan

        // TODO: if just one media item, we don't need the whole grid and we can respect
        // the dimensions of that item (as full width)
        return (
          <GridItem
            key={i}
            {...gridItemStyles[media.length as LengthOfMediaArray][i]}
            overflow="hidden"
            width={'100%'}
            // height={m.height * (gridWidth / m.width)} // dimensions valid for a single media item only
            borderWidth={'1px'}
            pos="relative"
          >
            {m.type == 'video' && video && (
              <Box pos="relative" minH="100%">
                <video
                  width={'100%'}
                  height={'100%'}
                  // autoPlay
                  // muted
                  controls
                  playsInline
                  src={video.url}
                >
                  Your browser does not support this video player. Open in
                  twitter to see the video.
                </video>
              </Box>
            )}
            {m.type == 'animated_gif' && video && (
              <Box pos="relative" minH="100%">
                <video
                  loop
                  width={'100%'}
                  height={'100%'}
                  autoPlay
                  muted
                  playsInline
                  src={video.url}
                >
                  Your browser does not support this video player. Open in
                  twitter to see the gif.
                </video>{' '}
              </Box>
            )}
            {(m.type == 'video' || m.type == 'animated_gif') && !video && (
              <Box pos="relative" minH="100%">
                <BlurImage
                  key={m.media_key}
                  alt="twitter video"
                  src={m.preview_image_url}
                  objectFit={'cover'}
                  className="hover:brightness-90 transition-all ease-in-out duration-150"
                />{' '}
              </Box>
            )}
            {m.type == 'photo' && (
              <Box
                pos="relative"
                width={'100%'}
                minH={(gridHeight / 2.0) * (rowSpan as number)}
              >
                <BlurImage
                  key={m.media_key}
                  alt="twitter photo"
                  src={m.url}
                  layout="responsive"
                  objectFit={'cover'}
                  className="hover:brightness-90 transition-all ease-in-out duration-150"
                />
              </Box>
            )}
          </GridItem>
        )
      })}
    </Grid>
  )
}
