import React, { ReactElement } from 'react'
import { DateTime } from 'luxon'
import {
  Box,
  HStack,
  Text,
  VStack,
  Spacer,
  useBoolean,
  Icon,
  Collapse,
  Tooltip,
} from '@chakra-ui/react'
import { RedditComment } from '../types'
import { TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons'
import { FiExternalLink } from 'react-icons/fi'
import { parseRedditMardownToHTML } from '../utils/parseRedditMarkdownToHTML'

interface Props {
  comment: RedditComment
  postPermalink: string
}

const Comment = ({ comment, postPermalink }: Props): ReactElement => {
  // const [isHovering, toggleIsHovering] = useBoolean(false)
  const [collapseReplies, toggleCollapseReplies] = useBoolean(false)

  const commentLink = postPermalink + comment.id

  const authorDisplay =
    comment.author === '[deleted]' ? (
      <Text fontSize="14" color="gray.600">
        {comment.author}
      </Text>
    ) : (
      <Text
        as="a"
        href={`https://www.reddit.com/user/${comment.author}`}
        _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
        fontSize="14"
        color="gray.600"
      >
        {comment.author}
      </Text>
    )

  return (
    <VStack
      alignItems={'flex-start'}
      w="100%"
      pb={0}
      mb={0}
      borderLeftColor={'gray.100'}
      borderLeftWidth={'3px'}
      pl={3}
      overflowX={'scroll'}
    >
      <VStack
        alignItems={'flex-start'}
        // onMouseEnter={toggleIsHovering.on}
        // onMouseLeave={toggleIsHovering.off}
        w="100%"
      >
        <HStack>
          {authorDisplay}
          <Text color="gray.600" fontSize="14">
            •
          </Text>
          <Text
            as="a"
            color="gray.600"
            fontSize="14"
            href={commentLink}
            _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
            target={'_blank'}
          >
            {DateTime.fromMillis(comment.created * 1000).toRelative()}
          </Text>
          <Spacer />
          {comment.replies.length > 0 && (
            <Text
              color="gray.400"
              fontSize="14"
              _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={toggleCollapseReplies.toggle}
              ml={2}
            >
              {collapseReplies ? 'Expand replies' : 'Collapse replies'}
            </Text>
          )}
        </HStack>
        <Text
          dangerouslySetInnerHTML={{
            __html: parseRedditMardownToHTML(comment.body),
          }}
        />
        <HStack color={'gray.500'} w="100%" spacing={1}>
          {/* <BiUpArrow />{' '} */}
          <TriangleUpIcon />
          <Text color={'black'} fontWeight={'medium'}>
            {comment.numUpvotes}
          </Text>{' '}
          {/* <BiDownArrow /> */}
          <TriangleDownIcon />
          <Tooltip
            label="View comment in Reddit"
            openDelay={2000}
            closeDelay={1000}
          >
            <Text as="a" href={commentLink} target="_blank" color="gray.500">
              <Icon
                as={FiExternalLink}
                verticalAlign="center"
                viewBox="0 0 25 25"
                mt="-1.5"
                ml={4}
              />
            </Text>
          </Tooltip>
          <Spacer />
        </HStack>
      </VStack>
      <Collapse animateOpacity in={!collapseReplies} startingHeight={'1px'}>
        <Box pl={1} mt="1px">
          {comment.replies.map((reply) => (
            <Comment
              comment={reply}
              key={reply.id}
              postPermalink={postPermalink}
            />
          ))}
        </Box>
      </Collapse>
    </VStack>
  )
}

export default Comment
