import React from 'react'
import { Box, VStack, Text } from '@chakra-ui/react'
import RedditAuthButton from './RedditAuthButton'
import { ReactElement } from 'react'

const RedditAuthPopover = (): ReactElement => {
  return (
    <Box w="100%" pos="relative">
      <VStack w="100%" spacing={3} p={5}>
        <Text w="100%" color="gray.500">
          <VStack>
            <RedditAuthButton />
            <Text ml={1}>to refresh.</Text>
          </VStack>
        </Text>
      </VStack>
    </Box>
  )
}

export default RedditAuthPopover
