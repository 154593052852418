import React, { ReactElement, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
} from '@chakra-ui/react'

interface Props {
  onUnfollow: () => Promise<void>
  buttonComponent: ReactElement
  preventDefaultClickAction?: boolean
  stopClickPropagation?: boolean
  dashboardTitle: string
}

const UnfollowDashboardButton = ({
  onUnfollow,
  dashboardTitle,
  buttonComponent,
  preventDefaultClickAction = false,
  stopClickPropagation = false,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleClose = () => setIsOpen(false)
  const handleOpen = () => setIsOpen(true)

  const handleUnfollow = () => {
    handleClose()
    onUnfollow()
  }

  const cancelRef = React.useRef(null)

  return (
    <>
      <Flex
        w="100%"
        onClick={(e) => {
          if (preventDefaultClickAction) {
            e.preventDefault()
          }
          if (stopClickPropagation) {
            e.stopPropagation()
          }
          handleOpen()
        }}
      >
        {buttonComponent}
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unfollow Dashboard
            </AlertDialogHeader>
            <AlertDialogBody>
              You will no longer be able to refresh <b>{dashboardTitle} </b>.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleUnfollow} ml={3}>
                Unfollow
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default UnfollowDashboardButton
