import React, { ReactElement } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent as ChakraModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import {
  useAuthModalContext,
  ModalContent,
} from '../../../context/auth-modal/context'
import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'

const AuthModal = (): ReactElement => {
  const { modalContent, closeAuthModal, isOpen } = useAuthModalContext()

  const getModalContent = (content: ModalContent) => {
    switch (content) {
      case 'logIn':
        return <LoginForm />
      case 'signUp':
        return <SignUpForm />
    }
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={closeAuthModal}>
      <ModalOverlay />
      <ChakraModalContent p={2} pb={3}>
        <ModalBody>
          {modalContent != undefined && getModalContent(modalContent)}
        </ModalBody>
      </ChakraModalContent>
    </Modal>
  )
}

export default AuthModal
