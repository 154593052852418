import React, { ReactElement, useEffect, useState } from 'react'

import {
  Center,
  Heading,
  ModalBody,
  ModalHeader,
  Spinner,
  VStack,
} from '@chakra-ui/react'

import firebase from '../../utils/firebase/firebase'
import {
  DashboardSearchResult,
  ElasticDashboardSchema,
} from '../discover/DashboardCardOld'
import DashboardSearchResultCard from '../discover/DashboardSearchResult'
import Footer from './Footer'

interface SuggestedDashboardProps {
  isLast?: boolean
  interests: string[]
  onNav: (increment: number, isLast?: boolean) => void
}

const SuggestedDashboards = ({
  interests,
  isLast,
  onNav,
}: SuggestedDashboardProps): ReactElement => {
  const [searchResults, setSearchResults] = useState<DashboardSearchResult[]>(
    []
  )

  useEffect(() => {
    const cloudFunctions = firebase.functions()
    const search = cloudFunctions.httpsCallable('queries-searchDashboards')

    search({ query: interests })
      .then((response) => {
        const formattedResults: DashboardSearchResult[] = response.data.map(
          (rawResult: Record<string, unknown>) => {
            return {
              id: rawResult._id as string,
              type: 'dashboard',
              score: rawResult._score as number,
              data: rawResult._source as ElasticDashboardSchema,
            }
          }
        )
        setSearchResults(formattedResults)
      })
      .catch((reason) => console.warn(reason))
  }, [interests])

  return (
    <>
      <ModalHeader p={6}>
        <Heading size="lg">For you</Heading>
      </ModalHeader>
      <ModalBody>
        {searchResults.length > 0 ? (
          <VStack alignItems="start">
            {searchResults.map(({ data }) => {
              const created = new Date(data.created)
              return (
                <DashboardSearchResultCard
                  author={data.author}
                  created={created}
                  description={data.description}
                  followerCount={data.followerCount}
                  id={data.id}
                  isPublic={true}
                  tags={data.tags}
                  title={data.title}
                  widgetCounts={data.widgetCounts}
                  key={data.id}
                  suggestionModal={true}
                />
              )
            })}
          </VStack>
        ) : (
          <Center h="100%">
            <Spinner size="lg" />
          </Center>
        )}
      </ModalBody>
      <Footer
        isLast={isLast}
        isNextDisabled={false}
        backLabel={'Back'}
        nextLabel={'Get started'}
        onNav={onNav}
      />
    </>
  )
}

export default SuggestedDashboards
