/**
 * Component to render text with designated key words highlighted
 *
 * Modified from:
 * https://stackoverflow.com/questions/29652862/highlight-text-using-reactjs
 */

import React, { ReactElement } from 'react'
interface Props {
  text: string
  keyWords: string[]
  highlightFunction?: (text: string) => string
  isHighlightingActive: boolean
  excludedRanges?: number[][]
}

const defaultHighlightFunction = (text: string, color = '#bedcf1') => {
  return `<mark style="background-color:${color}">${text}</mark>`
}

const Highlighted = ({
  text,
  keyWords,
  highlightFunction = defaultHighlightFunction,
  isHighlightingActive,
  excludedRanges = [],
}: Props): ReactElement => {
  // Split on highlighted terms and include term into parts, ignore case

  const parts = text.split(new RegExp(`(${keyWords.join('|')})`, 'gi'))
  const shouldAvoidPart: boolean[] = []

  let currPartStartIndex = 0
  parts.forEach((part) => {
    if (
      excludedRanges.find((range) => {
        return range[0] <= currPartStartIndex && currPartStartIndex < range[1]
      }) != undefined
    ) {
      shouldAvoidPart.push(true)
    } else {
      shouldAvoidPart.push(false)
    }
    currPartStartIndex += part.length
  })

  const keyWordsLowerCase = keyWords.map((keyWord) => keyWord.toLowerCase())
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: isHighlightingActive
          ? parts
              .map((part, index) =>
                !shouldAvoidPart[index] &&
                keyWordsLowerCase.includes(part.toLowerCase())
                  ? highlightFunction(part)
                  : part
              )
              .join('')
          : text,
      }}
    />
  )
}

export default Highlighted
