/**
 * Form for editing a stock widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import React, { ReactElement, useMemo, useRef, useState } from 'react'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { useDebouncedCallback } from 'use-debounce'

import { CheckIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Fade,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useOutsideListener } from '../../../../utils/hooks/useOutsideListener'
import { getStockTickers } from '../../../../utils/widgetData/getIEXCloudSymbols'
import { IEXCloudSymbol } from '../types'
import TickerItem from './TickerItem'
import TickerOptionDisplay from './TickerOptionDisplay'

interface Props {
  tickers: IEXCloudSymbol[]
  onChange: (newTickers: IEXCloudSymbol[]) => void
  canEdit: boolean
}

export interface TickerOption {
  label: string
  value: IEXCloudSymbol
}

const TickerSelection = ({
  tickers,
  onChange,
  canEdit,
}: Props): ReactElement => {
  const [searchFieldValue, setSearchFieldValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [tickerOptions, setTickerOptions] = useState<IEXCloudSymbol[]>([])
  const [showAddedTickerBox, setShowAddedTickerBox] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [recentlyAddedTicker, setRecentlyAddedTicker] =
    useState<IEXCloudSymbol | null>(null)

  const handleClickOutside = () => setIsFocused(false)
  const formControlRef = useRef(null)
  useOutsideListener(formControlRef, handleClickOutside)

  const showAutocompleteMenu = isFocused && searchFieldValue.length > 0

  const removeSelectedTickerFromOptions = (symbol: string) => {
    setTickerOptions((prev) => prev.filter((option) => option.symbol != symbol))
  }
  const handleSelect = async (selection: IEXCloudSymbol) => {
    if (selection != null) {
      onChange([...tickers, selection])
      setRecentlyAddedTicker(selection)
      setShowAddedTickerBox(true)
      setSearchFieldValue('')
      setTimeout(() => setShowAddedTickerBox(false), 3000)
      setTimeout(() => removeSelectedTickerFromOptions(selection.symbol), 100)
    }
  }

  const loadTickers = async (query: string) => {
    setIsLoading(true)
    try {
      const results = await getStockTickers(query)
      const filteredResults = results.filter(
        (result) =>
          tickers.find(
            (alreadySelectedTicker) =>
              alreadySelectedTicker.symbol === result.symbol
          ) === undefined
      )

      setTickerOptions(filteredResults)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const debouncedSearchForTickers = useDebouncedCallback(loadTickers, 300)

  const reorder = (
    list: IEXCloudSymbol[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      tickers,
      result.source.index,
      result.destination.index
    )

    onChange(items)
  }

  const tickerButtons = useMemo(() => {
    const buttons = tickers.map((ticker, idx) => (
      <TickerItem
        key={`Stock-ticker-select-${idx}`}
        ticker={ticker}
        index={idx}
        tickerList={tickers}
        canEdit={canEdit}
        onChange={onChange}
      />
    ))
    return buttons
  }, [tickers, canEdit, onChange])

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchFieldValue(value)
    if (value === '') {
      setTickerOptions([])
      return
    }
    debouncedSearchForTickers(value)
  }

  return (
    <VStack align="start" w="100%">
      <Text as="b">
        {canEdit ? 'Which stock tickers do you want to follow?' : 'Tickers:'}
      </Text>
      <FormControl ref={formControlRef} border="1px lightgray">
        {canEdit && (
          <InputGroup>
            <InputLeftElement justifyContent="center" w="10">
              <SearchIcon />
            </InputLeftElement>
            <Input
              value={searchFieldValue}
              onChange={handleSearchQueryChange}
              placeholder="Search for a ticker"
              onFocus={() => setIsFocused(true)}
            />
            {showAddedTickerBox && (
              <InputRightElement w="min-content" justifyContent="center" pr="4">
                <Fade
                  transition={{
                    enter: { duration: 0, delay: 0 },
                    exit: { duration: 0.3 },
                  }}
                  in={true}
                >
                  <Box
                    padding="2px"
                    paddingX="6px"
                    backgroundColor="green.500"
                    borderRadius="4px"
                    w="max-content"
                  >
                    <HStack>
                      <CheckIcon color="white" />
                      <Text
                        noOfLines={1}
                        color="white"
                      >{`Added ${recentlyAddedTicker?.symbol}!`}</Text>
                    </HStack>
                  </Box>
                </Fade>
                {searchFieldValue != '' && isFocused && (
                  <IconButton
                    size="md"
                    aria-label="Clear search field"
                    onClick={() => setSearchFieldValue('')}
                    icon={<CloseIcon />}
                    variant="unstyled"
                  />
                )}
              </InputRightElement>
            )}
          </InputGroup>
        )}

        {searchFieldValue.length > 0 && isFocused && (
          <Box
            w="100%"
            minH="15rem"
            borderWidth="1px"
            borderRadius="lg"
            shadow="lg"
            p={2}
            maxH="15rem"
            overflowY="scroll"
          >
            {showAutocompleteMenu &&
              tickerOptions.length > 0 &&
              !isLoading &&
              tickerOptions
                .slice(0, 10)
                .map((option) => (
                  <TickerOptionDisplay
                    key={`${option.symbol}-${option.exchangeName}`}
                    ticker={option}
                    onSelect={() => handleSelect(option)}
                  />
                ))}
            {isLoading && (
              <Center h="10rem" w="100%">
                <Spinner size="md" />
              </Center>
            )}
            {tickerOptions.length == 0 && !isLoading && (
              <Center h="10rem" w="100%">
                <Text>No results</Text>
              </Center>
            )}
          </Box>
        )}
      </FormControl>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable isDropDisabled={false} droppableId="stocks-droppable">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              w="100%"
              overflowY="scroll"
            >
              {tickerButtons}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </VStack>
  )
}

export default TickerSelection
