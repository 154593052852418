import { init } from 'next-firebase-auth'
import '../firebase/firebase'
// import absoluteUrl from 'next-absolute-url'
// import { routes } from '../constants'

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000

const initAuth = (): void =>
  init({
    debug: false,
    // authPageURL: ({ ctx }) => {
    //   const isServerSide = typeof window === 'undefined'
    //   const origin = isServerSide
    //     ? absoluteUrl(ctx.req).origin
    //     : window.location.origin
    //   const destPath =
    //     typeof window === 'undefined' ? ctx.resolvedUrl : window.location.href
    //   const destURL = new URL(destPath, origin)
    //   return `/?destination=${encodeURIComponent(destURL.toString())}`

    // },
    authPageURL: '/',
    appPageURL: '/home',

    // ({ ctx }) => {
    //   const isServerSide = typeof window === 'undefined'
    //   const origin = isServerSide
    //     ? absoluteUrl(ctx.req).origin
    //     : window.location.origin
    //   const params = isServerSide
    //     ? new URL(ctx.req?.url ?? '', origin).searchParams
    //     : new URLSearchParams(window.location.search)
    //   const destination = params.get('destination')
    //   const destinationParamVal =
    //     destination != null ? decodeURIComponent(destination) : undefined

    //   let destURL = routes.home
    //   if (destinationParamVal) {
    //     const allowedHosts = ['localhost:3010', 'insert-prod-domain']
    //     const allowed =
    //       allowedHosts.indexOf(new URL(destinationParamVal).host) > -1
    //     if (allowed) {
    //       destURL = destinationParamVal
    //     } else {
    //       // eslint-disable-next-line no-console
    //       console.warn(
    //         `Redirect destination host must be one of ${allowedHosts.join(
    //           ', '
    //         )}.`
    //       )
    //     }
    //   }
    //   return destURL
    // },

    loginAPIEndpoint: '/api/login',
    logoutAPIEndpoint: '/api/logout',
    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? '',
        clientEmail: process.env.FIREBASE_CLIENT_EMAIL ?? '',
        privateKey:
          process.env.FIREBASE_PRIVATE_KEY?.replace(/\\n/g, '\n') ?? '',
      },
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL ?? '',
    },
    firebaseClientInitConfig: {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY ?? '',
    },
    cookies: {
      name: 'Signalist',
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: TWELVE_DAYS_IN_MS,
      overwrite: true,
      path: '/',
      sameSite: 'strict',
      secure: process.env.NEXT_PUBLIC_COOKIE_SECURE === 'true',
      signed: true,
    },
  })

export default initAuth
