/**
 * Functions to interact with the Reddit API to:
 * 1) Receive auth tokens on initial authorization
 * 2) Get refresh tokens for subsequent requests on authorized users' behalfs
 * 3) Search subreddits
 */

import axios from 'axios'
import { generateRandomString } from '../../../utils/general/generateRandomString'
import { encodeJSONToBase64 } from '../../../utils/general/base64StringUtils'
import { setLocalItem } from '../../../utils/general/localStorageUtils'
import { RedditToken, SubredditSearchResult, Listing, Subreddit } from './types'
import firebase from 'firebase/app'
import { routes } from '../../../utils/constants'

const listingTypeToIDPrefix: Record<Listing, string> = {
  comment: 't1',
  account: 't2',
  link: 't3',
  message: 't4',
  subreddit: 't5',
  award: 't6',
}

// redirect Uri, as entered in the Reddit API settings for this app
const redirectUri = 'https://signalist.co' + routes.thirdPartyAuth
// const redirectUri = 'http://localhost:3010' + routes.thirdPartyAuth

// scopes for API requests. see more here (left sidebar maps scopes -> endpoints):
// https://www.reddit.com/dev/api/oauth
const scope = ['vote', 'read', 'wikiread', 'save', 'identity', 'flair']

export const createRedditAuthUrl = (
  dashboardId: string,
  widgetId: string
): string => {
  const randomString = generateRandomString(10) // length 10 random string
  setLocalItem('authState', randomString)
  const stateString = encodeJSONToBase64({
    [randomString]: {
      dashboardId,
      widgetId,
      authProvider: 'reddit',
    },
  })
  return `https://www.reddit.com/api/v1/authorize?client_id=${process.env.NEXT_PUBLIC_REDDIT_APP_ID}&duration=permanent&response_type=code&state=${stateString}&redirect_uri=${redirectUri}&scope=${scope}`
}

const redditAPIClient = axios.create({
  baseURL: 'https://oauth.reddit.com',
})

const getAccessToken = async (payload: unknown): Promise<RedditToken> => {
  const response = await fetch(`/api/reddit/getOauth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    console.log(`Error: ${response.status}`)
  }
  const oauthData = await response.json()

  const headers = {
    Authorization: 'Bearer ' + oauthData.access_token,
  }
  const { data: identity } = await redditAPIClient.get(`/api/v1/me`, {
    headers,
  })

  return {
    type: 'reddit',
    title: 'Reddit',
    connectedDate: firebase.firestore.Timestamp.fromDate(new Date()), // TODO: should this be the initial date or the date it was refreshed?
    username: identity.name,
    access_token: oauthData.access_token,
    token_type: 'bearer',
    expires_in: oauthData.expires_in,
    scope: oauthData.scope,
    refresh_token: oauthData.refresh_token,
    expires_at: Date.now() + oauthData.expires_in,
  }
}

// get access & refresh token for a user who has just authorized on reddit.com
// and was redirected back to us
export const getRedditTokens = async (
  authCode: string
): Promise<RedditToken> => {
  const body = {
    code: authCode,
    redirect_uri: redirectUri,
    grant_type: 'authorization_code',
  }

  return getAccessToken(body)
}

export const accessTokenIsValid = (token: RedditToken): boolean => {
  const time = Date.now()
  return time < token.expires_at
}

export const getNewAccessToken = async (
  refreshToken: string
): Promise<RedditToken> => {
  const body = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  }

  return getAccessToken(body)
}

export const searchSubreddits = async (
  query: string,
  accessToken: string
): Promise<Subreddit[]> => {
  const params = { q: query }
  const headers = { Authorization: 'Bearer ' + accessToken }
  const { data } = await redditAPIClient.get(`/subreddits/search`, {
    params,
    headers,
  })

  const results = data.data.children.map((sub: SubredditSearchResult) => {
    let thumbnail =
      sub.data.community_icon != ''
        ? sub.data.community_icon
        : sub.data.icon_img

    const thumbnailComponents = thumbnail.match(
      /(.+)(\.png|\.jpg|\.jpeg|\.gif)(.+)/
    )

    if (thumbnailComponents != null) {
      thumbnail = thumbnailComponents[1] + thumbnailComponents[2]
    }

    if (thumbnail === '') {
      thumbnail =
        'https://external-preview.redd.it/iDdntscPf-nfWKqzHRGFmhVxZm4hZgaKe5oyFws-yzA.png?auto=webp&s=38648ef0dc2c3fce76d5e1d8639234d8da0152b2'
    }

    return {
      name: sub.data.display_name_prefixed,
      id: sub.data.id,
      numSubscribers: sub.data.subscribers,
      thumbnail,
    }
  })

  return results
}

export const getListingByID = async (
  listingType: Listing,
  id: string,
  accessToken: string
): Promise<unknown> => {
  const fullName = listingTypeToIDPrefix[listingType] + '_' + id
  const headers = { Authorization: 'Bearer ' + accessToken }
  const { data } = await redditAPIClient.get('/by_id/' + fullName, {
    headers,
  })

  return data
}
