/**
 * Modal for editing a dashboard's settings
 */
import React, { ReactElement, ReactNode } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  ModalFooter,
  HStack,
  Spacer,
  IconButton,
  useMediaQuery,
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

interface Props {
  children: ReactNode
  footer?: ReactElement
  hideDivider?: boolean
  isOpen: boolean
  onClose: () => void
  size?: string
  title: string
  shortDeviceMaxHeight?: string
}

export const SHORT_DEVICE_MAX_HEIGHT = '720px'
export const MODAL_HEADER_HEIGHT = 68 // px

const Modal = ({
  children,
  footer,
  hideDivider = false,
  isOpen,
  onClose,
  size = '3xl',
  title,
}: Props): ReactElement => {
  const [isShortDevice] = useMediaQuery(
    `(max-height: ${SHORT_DEVICE_MAX_HEIGHT})`
  )

  return (
    <ChakraModal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onClose}
      size={isShortDevice ? 'full' : size}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent w="min(100%, 800px)">
        <HStack align="center" w="100%" pr={4} h="100%">
          <ModalHeader flexGrow={1} as="b" fontSize="2xl">
            {title}
          </ModalHeader>
          <Spacer />
          <IconButton
            aria-label="close"
            icon={<CloseIcon />}
            onClick={onClose}
            variant="ghost"
          />
          {/* <ModalCloseButton pos="relative" /> */}
        </HStack>
        {!hideDivider && <Box w="100%" h="1px" bg="gray.200" />}
        <ModalBody padding={0} m={0} h="100%">
          {children}
        </ModalBody>
        {footer != undefined && <ModalFooter p={0}>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  )
}
export default Modal
