import numeral from 'numeral'
import React, { ReactElement } from 'react'
import { BiDislike, BiLike } from 'react-icons/bi'
import { FiMessageSquare } from 'react-icons/fi'

import {
  Box,
  Circle,
  HStack,
  IconButton,
  Text,
  createIcon,
} from '@chakra-ui/react'

import { useCurrentUser } from '../../../../context/user/context'
import { FooterProps, ReactionButtonProps, ReplyButtonProps } from '../types'
import ActivityList from './ActivityList'
import useIsFollowingDashboard from '../../../../utils/hooks/useIsFollowingDashboard'
import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'

const BiDislikeSolid = createIcon({
  displayName: 'BiDislikeSolid',
  path: (
    <path
      fill="currentColor"
      d="M20 3h-1v13h1a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM4 16h7l-1.122 3.368A2 2 0 0 0 11.775 22H12l5-5.438V3H6l-3.937 8.649-.063.293V14a2 2 0 0 0 2 2z"
    />
  ),
})

const BiLikeSolid = createIcon({
  displayName: 'BiLikeSolid',
  path: (
    <path
      fill="currentColor"
      d="M4 21h1V8H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2zM20 8h-7l1.122-3.368A2 2 0 0 0 12.225 2H12L7 7.438V21h11l3.912-8.596L22 12v-2a2 2 0 0 0-2-2z"
    />
  ),
})

const ReactionButton = ({
  isReactionLoading,
  // reactions,
  comment,
  onReact,
  type,
}: ReactionButtonProps): ReactElement => {
  const { user } = useCurrentUser()

  const activityList = {
    like: comment.activity.likes,
    dislike: comment.activity.dislikes,
  }

  let isReacted = false

  if (user?.uid != undefined) {
    isReacted = Object.keys(activityList[type]).includes(user.uid)
  }

  const { selectedDashboardId: dashboardId } = useDashboardNavigationContext()

  const isLoggedIn = user?.uid != undefined
  const isFollowing = useIsFollowingDashboard(dashboardId ?? '')

  const counts = {
    like: comment.activityCounts.likes,
    dislike: comment.activityCounts.dislikes,
  }

  const icons = {
    like: {
      solid: <BiLikeSolid />,
      outline: <BiLike />,
    },
    dislike: {
      solid: <BiDislikeSolid />,
      outline: <BiDislike />,
    },
  }

  const colors = {
    like: 'green',
    dislike: 'red',
  }

  const iconType = isReacted ? 'solid' : 'outline'
  const icon = icons[type][iconType]

  const count = counts[type]

  return (
    <ActivityList comment={comment} type={type}>
      <IconButton
        isDisabled={!isFollowing || !isLoggedIn}
        isLoading={
          isReactionLoading &&
          isReactionLoading.id == comment.id &&
          isReactionLoading.type == type
        }
        aria-label={`${type} button`}
        size="sm"
        _hover={{
          color: `${colors[type]}.500`,
          bg: `${colors[type]}.50`,
          fontColor: `${colors[type]}.500`,
        }}
        onClick={() => onReact(type, comment, isReacted)}
        color={isReacted ? `${colors[type]}.500` : 'black'}
        fill={colors[type]}
        icon={
          <HStack px={2}>
            {icon}
            {count > 0 && (
              <Text fontSize="sm">{numeral(count).format('0a')}</Text>
            )}
          </HStack>
        }
        variant="ghost"
      />
    </ActivityList>
  )
}

const ReplyButton = ({
  comment,
  onReply,
  showUnreadBadge,
}: ReplyButtonProps): ReactElement => {
  const { replies } = comment.activityCounts

  return (
    <IconButton
      aria-label="reply button"
      size="sm"
      _hover={{
        color: 'blue.500',
        bg: 'blue.50',
      }}
      bg={showUnreadBadge ? 'blue.50' : 'inherit'}
      color={showUnreadBadge ? 'blue.500' : 'inherit'}
      onClick={() => onReply(comment)}
      icon={
        <HStack px={2}>
          <FiMessageSquare />
          {replies > 0 && (
            <Text fontSize="sm">{numeral(replies).format('0a')}</Text>
          )}
        </HStack>
      }
      variant="unstyled"
    />
  )
}

const Footer = ({
  comment,
  isThread = false,
  onOpenThread,
  showUnreadBadge,
  isReactionLoading,
  onReact,
}: FooterProps): ReactElement => {
  return (
    <HStack h="6" w="100%" pos="relative" spacing={4}>
      <Box pos="absolute" left="0">
        <ReactionButton
          comment={comment}
          onReact={onReact}
          type={'like'}
          isReactionLoading={isReactionLoading}
        />
      </Box>

      <Box pos="absolute" left="25%">
        <ReactionButton
          comment={comment}
          onReact={onReact}
          type={'dislike'}
          isReactionLoading={isReactionLoading}
        />
      </Box>

      {!isThread && onOpenThread && (
        <HStack spacing={1} pos="absolute" left="55%">
          <ReplyButton
            comment={comment}
            onReply={() => onOpenThread(comment)}
            showUnreadBadge={showUnreadBadge}
          />
          {showUnreadBadge && <Circle size="6px" bg="blue.300" />}
        </HStack>
      )}
    </HStack>
  )
}

export default Footer
