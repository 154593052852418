import numeral from 'numeral'
import { ReactElement } from 'react'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

import { Container, Text } from '@chakra-ui/react'

const IntradayTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>): ReactElement => {
  if (active && payload && payload.length) {
    let time = payload[0].payload.label
    if (!time.includes(':')) {
      time = `${time.substring(0, time.indexOf(' '))}:00 ${time.slice(-2)}`
    }
    return (
      <Container
        borderRadius="md"
        paddingTop={1}
        paddingLeft={2}
        paddingRight={2}
        paddingBottom={1}
        w="100%"
        bg="white"
        borderWidth="1px"
        borderColor="gray.300"
      >
        <Text fontSize="xs" fontWeight="600">
          USD ${numeral(payload[0].payload.displayPrice).format('0,0.00')}
        </Text>
        <Text fontSize="xs">{time}</Text>
      </Container>
    )
  }

  return <></>
}

export default IntradayTooltip
