/**
 * Form for editing a Twitter widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import {
  Image,
  Text,
  VStack,
  HStack,
  Skeleton,
  SkeletonCircle,
  Badge,
  Spacer,
  Tooltip,
  Link,
  IconButton,
  Flex,
} from '@chakra-ui/react'
import NextImage from 'next/image'
import { ExternalLinkIcon, LockIcon } from '@chakra-ui/icons'
import React, { ReactElement } from 'react'
import { TwitterUser } from '../../types'
import formatMetric from '../../formatMetric'
import TwitterVerifiedIcon from './../../../../../../public/twitter/verified.svg'

interface TwitterUserDisplayProps {
  user: TwitterUser | undefined
  showFollowingTag?: boolean
  showFollowersCount?: boolean
  showLoading?: boolean
  showOpenInTwitterButton?: boolean
  profilePicSize: number
}
const TwitterUserDisplay = ({
  user,
  showFollowingTag = false,
  showFollowersCount = false,
  showLoading = false,
  showOpenInTwitterButton = false,
  profilePicSize,
}: TwitterUserDisplayProps): ReactElement => {
  const iconSize = Math.floor(profilePicSize / 2.68)

  // user is undefined but don't show loading
  if (user == undefined && !showLoading) {
    return <></>
  }

  // user is undefined, do show loading
  if (user == undefined) {
    return (
      <HStack spacing={`${Math.floor(profilePicSize / 4)}px`}>
        <SkeletonCircle size={`${profilePicSize}px`} />
        <VStack
          align="start"
          spacing="1"
          justify="center"
          w="80%"
          h={`${Math.floor(profilePicSize * 0.8)}px`}
        >
          <Skeleton w="40%" h={`${Math.floor(profilePicSize * 0.3)}px`} />
          <Skeleton w="30%" h={`${Math.floor(profilePicSize * 0.3)}px`} />
        </VStack>
      </HStack>
    )
  }

  let subtitleText = `@${user.username}`
  if (showFollowersCount)
    subtitleText += ` · ${formatMetric(
      user.public_metrics.followers_count
    )} followers`

  // user is defined, display user
  return (
    <HStack
      flexGrow={1}
      key={user.id}
      spacing={`${Math.floor(profilePicSize / 4)}px`}
    >
      <Image
        boxSize={`${profilePicSize}px`}
        borderRadius={`${Math.floor(profilePicSize / 2)}px`}
        src={user.profile_image_url}
        alt={`${user.name} twitter profile picture`}
      />
      <VStack align="start" w="100%" spacing="-0.5">
        <HStack align="center" maxW="100%" overflowX="scroll" spacing={'1'}>
          <Text as="b" noOfLines={1} textOverflow="ellipsis">
            {user.name}
          </Text>
          {user.protected && (
            <LockIcon w={`${iconSize}px`} h={`${iconSize}px`} />
          )}
          {user.verified && (
            <NextImage
              alt={'twitter verified icon'}
              height={iconSize}
              src={TwitterVerifiedIcon}
              width={iconSize}
            />
          )}
          {showFollowingTag && (
            <Flex align="center">
              <Badge ml="1">FOLLOWING</Badge>
            </Flex>
          )}
        </HStack>
        <Text textColor="#657786">{subtitleText}</Text>
      </VStack>
      <Spacer />
      {showOpenInTwitterButton && (
        <Tooltip placement="left" label="View on twitter.com" openDelay={300}>
          <Link href={`https://twitter.com/${user.username}`} isExternal>
            <IconButton
              mr={1}
              onClick={(e) => e.stopPropagation()}
              color="gray.500"
              variant="ghost"
              size="sm"
              aria-label="Open in new tab"
              icon={<ExternalLinkIcon />}
            />
          </Link>
        </Tooltip>
      )}
    </HStack>
  )
}

export default TwitterUserDisplay
