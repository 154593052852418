/*
  Reusable avatar uploading field to add custom profile
  pictures
*/

import { ReactElement, useEffect, useRef, useState } from 'react'
import { RiPencilFill } from 'react-icons/ri'

import { Icon } from '@chakra-ui/icons'
import {
  AvatarBadge,
  Button,
  Center,
  FormControl,
  IconButton,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useCurrentUser } from '../../../context/user/context'
import {
  deleteAvatar,
  uploadAvatar,
} from '../../../utils/firebase/uploadImageToFirebase'
import DefaultAvatar from '../../general/DefaultAvatar'
import { UserInfoFieldProps } from './types'

const AvatarUpload = ({ formik }: UserInfoFieldProps): ReactElement => {
  const [avatarUploading, setAvatarUploading] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const { user } = useCurrentUser()

  const handleAvatarUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setAvatarUploading(true)

    uploadAvatar(e, user?.uid, formik)
  }
  const handleAvatarDelete = async () => {
    setAvatarUploading(true)
    deleteAvatar(user?.uid, formik)
  }

  const handleAvatarClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  //TODO: Make avatar uploading better here by using setstatus with formik
  //TODO: Display errors on frontend
  useEffect(() => {
    if (Boolean(formik.errors.avatarUrl)) {
      setAvatarUploading(false)
    }
    setAvatarUploading(false)
  }, [formik])

  if (user == undefined) return <></>
  return (
    <FormControl>
      {/* <FormLabel>Add Photo</FormLabel> */}
      <Center h="150px">
        <VStack>
          <input
            accept="image/jpeg, image/png"
            style={{ display: 'none' }}
            type="file"
            ref={hiddenFileInput}
            onChange={(e) => {
              handleAvatarUpload(e)
              e.target.value = ''
            }}
          />
          {avatarUploading ? (
            <Spinner size="xl" thickness="3px" />
          ) : (
            <DefaultAvatar
              size="2xl"
              user={user}
              overrideUrl={formik.values.avatarUrl}
            >
              <AvatarBadge bottom="3" right="3" boxSize="0.6em">
                <IconButton
                  size="sm"
                  aria-label="Upload profile picture"
                  onClick={handleAvatarClick}
                  isRound
                  colorScheme="blue"
                  icon={<Icon boxSize={4} color="white" as={RiPencilFill} />}
                />
              </AvatarBadge>
            </DefaultAvatar>
          )}
          {formik.values.avatarUrl != '' && !avatarUploading && (
            <Button
              fontSize="small"
              onClick={handleAvatarDelete}
              variant="link"
            >
              Remove
            </Button>
          )}
          <Text textColor="red">{formik.errors.avatarUrl}</Text>
        </VStack>
      </Center>
    </FormControl>
  )
}

export default AvatarUpload
