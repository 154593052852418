/**
 * Form for editing a Reddit widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

// subreddit posts of a unique structure

import React, { ReactElement, useEffect } from 'react'

import { Button, Center, HStack, Text, VStack } from '@chakra-ui/react'

import useAuthToken from '../../../../utils/hooks/useAuthToken'
import EditWidgetDrawerTemplate from '../../../dashboard/dashboardDisplay/EditWidgetDrawerTemplate'
import PhraseSelector from '../../twitter/TwitterSettings/MultiPhraseSelector'
import RedditAuthButton from '../RedditAuthButton'
import { accessTokenIsValid, getNewAccessToken } from '../redditClient'
import { RedditWidgetSettings, Subreddit } from '../types'
import AndOrDropdown from './AndOrDropdown'
import ReadOnlyRedditSettings from './ReadOnlyRedditSettings'
import SubredditSearch from './SubredditSearch'

interface Props {
  onChange: (settings: RedditWidgetSettings) => void
  settings: RedditWidgetSettings
  canEdit: boolean
}

const RedditSettings = ({
  settings,
  onChange,
  canEdit,
}: Props): ReactElement => {
  const [token, updateToken] = useAuthToken('reddit')

  const handleChangeRequireWords = (func: (prev: string[]) => string[]) => {
    const requiredWords = func(settings.requiredWords)

    onChange({ ...settings, requiredWords })
  }

  const handleChangeExcludedWords = (func: (prev: string[]) => string[]) => {
    const excludedWords = func(settings.excludedWords)
    onChange({ ...settings, excludedWords })
  }

  const tokenIsValid = token != null && accessTokenIsValid(token)
  const refreshToken = token?.refresh_token

  useEffect(() => {
    const getNewToken = async (refreshToken: string) => {
      const newToken = await getNewAccessToken(refreshToken)
      updateToken(newToken)
    }

    if (refreshToken == null) {
      // prompt authorization
      return
    }
    if (!tokenIsValid) {
      getNewToken(refreshToken)
    }
  }, [updateToken, tokenIsValid, refreshToken])

  const handleSubredditsChange = (newSubreddits: Subreddit[]) => {
    onChange({ ...settings, subreddits: newSubreddits })
  }
  let preventSaveMessage = undefined
  if (settings?.subreddits?.length === 0)
    preventSaveMessage = 'Add at least one subreddit'

  if (token === undefined && settings?.subreddits?.length === 0) {
    preventSaveMessage = 'Log in with reddit to add this widget'
  }

  return (
    <EditWidgetDrawerTemplate preventSaveMessage={preventSaveMessage}>
      {!canEdit ? (
        <ReadOnlyRedditSettings
          isAuthed={token !== undefined}
          settings={settings}
        />
      ) : token === undefined ? (
        <Center h="80%">
          <VStack align="center" spacing={2}>
            <RedditAuthButton />
            <Text as="a" target="_blank" href={`/connected-accounts-policy`}>
              <Button
                fontSize="10pt"
                variant="link"
              >{`What's this for?`}</Button>
            </Text>
          </VStack>
        </Center>
      ) : (
        <VStack align="start" spacing="6">
          <VStack align="start" w="100%">
            <SubredditSearch
              subreddits={settings.subreddits}
              canEdit={true}
              token={token}
              onChange={handleSubredditsChange}
            />
            {settings.subreddits.length === 0 && (
              <Center w="100%" justifyContent="center">
                <Text fontStyle="italic" textColor="gray.500">
                  No subreddits selected
                </Text>
              </Center>
            )}
          </VStack>

          <VStack align="stretch" w="100%">
            <HStack py={1}>
              <Text as="b">
                Only including posts with
                <AndOrDropdown
                  canEdit={true}
                  value={settings.requiredWordsJoiner}
                  onSelect={(requiredWordsJoiner: 'any' | 'all') =>
                    onChange({ ...settings, requiredWordsJoiner })
                  }
                />{' '}
                of these words:
              </Text>
            </HStack>
            <PhraseSelector
              phrases={settings.requiredWords}
              onChange={handleChangeRequireWords}
              isDisabled={false}
              canEdit={true}
            />
          </VStack>

          <VStack align="stretch" w="100%">
            <HStack py={1}>
              <Text as="b">
                Filter out posts with{' '}
                <AndOrDropdown
                  value={settings.excludedWordsJoiner}
                  onSelect={(excludedWordsJoiner: 'any' | 'all') =>
                    onChange({ ...settings, excludedWordsJoiner })
                  }
                  canEdit={true}
                />{' '}
                of these words:
              </Text>
            </HStack>
            <PhraseSelector
              phrases={settings.excludedWords}
              onChange={handleChangeExcludedWords}
              isDisabled={false}
              canEdit={true}
            />
          </VStack>
        </VStack>
      )}
    </EditWidgetDrawerTemplate>
  )
}

export default RedditSettings
