import firebase from 'firebase/app'
// import Image from 'next/image'
import { ReactElement } from 'react'
import { FcGoogle } from 'react-icons/fc'

import { Button, Center, Text, VStack } from '@chakra-ui/react'

import { SignUpFieldProps } from '../fields/types'

const OrSignInWith = ({ formik }: SignUpFieldProps): ReactElement => {
  const auth = firebase.auth()

  const signInWithGoogle: () => void = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({
      display: 'popup',
    })

    await auth
      .signInWithPopup(provider)
      .then(() => {
        formik.setStatus('')
      })
      .catch((error) => {
        formik.setStatus(error.message)
      })
  }

  return (
    <VStack w="100%">
      <Center p={2} w="100%">
        <Button
          w={'full'}
          maxW={'md'}
          variant={'outline'}
          leftIcon={<FcGoogle />}
          onClick={() => {
            signInWithGoogle()
          }}
        >
          <Center>
            <Text>Continue with Google</Text>
          </Center>
        </Button>
      </Center>

      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            borderBottom: '1px solid lightgray',
            width: '350%',
          }}
        />
        <span
          style={{
            color: 'darkgray',
            textAlign: 'center',
            flexBasis: '100%',
          }}
        >
          or
        </span>
        <div
          style={{
            borderBottom: '1px solid lightgray',
            width: '350%',
          }}
        />
      </div>
      {/* <Divider borderColor="gray.300" /> */}
    </VStack>
  )
}

export default OrSignInWith
