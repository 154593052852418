/*
  Plot included in the second view of stock widget – allows for toggle of historical
  timeframe
*/

import { DateTime } from 'luxon'
import { ReactElement } from 'react'
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  FocusedPlotProps,
  StockHistoricalPrice,
  StockIntradayPrice,
} from '../types'
import { plotHistoricalYDomain, plotYDomain } from '../utils'
import HistoricalTooltip from './HistoricalTooltip'
import IntradayTooltip from './IntradayTooltip'

// TODO: Add a y-axis

const FocusedPlot = ({
  stock,
  timeframe,
  color,
  width,
}: FocusedPlotProps): ReactElement => {
  let timeSeriesData: StockIntradayPrice[] | StockHistoricalPrice[] | undefined
  let dataKey: string
  let xAxis: ReactElement
  let yAxis: ReactElement
  let tooltip: ReactElement

  if (timeframe == '1D') {
    timeSeriesData = stock.intraday.prices
    dataKey = 'displayPrice'
    xAxis = (
      <XAxis
        dataKey="timeDigit"
        type="number"
        ticks={[10, 11, 12, 13, 14, 15]}
        domain={[9.5, 16]}
        tickFormatter={function formatXAxis(value) {
          if (value >= 13) {
            return value % 6
          }
          return value
        }}
        dy={5}
      />
    )
    yAxis = (
      <YAxis
        axisLine={false}
        tickLine={false}
        dx={-5}
        padding={{
          top: 10,
        }}
        hide={true}
        domain={plotYDomain(stock.intraday.prices, stock.quote.previousClose)}
      />
    )
    tooltip = (
      <Tooltip
        position={{ y: -20 }}
        offset={-20}
        isAnimationActive={false}
        content={<IntradayTooltip />}
      />
    )
  } else {
    timeSeriesData = stock.historical?.[timeframe]

    dataKey = 'close'
    xAxis = (
      <XAxis
        dataKey="date"
        type="category"
        minTickGap={30}
        tickFormatter={function formatXAxis(value) {
          const date = DateTime.fromISO(value)

          return date.toLocaleString({
            month: 'short',
            day: 'numeric',
          })
        }}
        dy={5}
      />
    )
    yAxis = (
      <YAxis
        axisLine={false}
        tickLine={false}
        dx={-5}
        padding={{
          top: 10,
        }}
        hide={true}
        domain={plotHistoricalYDomain(timeSeriesData)}
      />
    )
    tooltip = (
      <Tooltip
        position={{ y: -20 }}
        offset={-20}
        isAnimationActive={false}
        content={<HistoricalTooltip />}
      />
    )
  }

  return (
    <ResponsiveContainer width={'100%'} aspect={2}>
      <AreaChart
        data={timeSeriesData}
        margin={{ top: 10, right: 30, bottom: 10, left: 30 }}
      >
        <defs>
          <linearGradient id="colorPlot" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.3} />
            <stop offset="90%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          isAnimationActive={true}
          animationDuration={500}
          connectNulls={true}
          type="linear"
          dataKey={dataKey}
          stroke={color}
          dot={false}
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorPlot)"
        />
        {xAxis} {yAxis} {tooltip}
        {timeframe == '1D' && (
          <ReferenceLine
            y={stock.quote.previousClose}
            label={{
              position: 'top',
              // value: `Prev Close: \$${stock.quote.previousClose}`,
              dx: width / 4,
              fill: '#808080',
              fontSize: 14,
            }}
            stroke="#bababa"
            strokeDasharray="10"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default FocusedPlot
