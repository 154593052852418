import React, { ReactElement } from 'react'
import { VStack, Divider, Text, HStack } from '@chakra-ui/react'
import { RedditComment } from '../types'
import SingleCommentDisplay from './Comment'
import { FaAward } from 'react-icons/fa'
interface Props {
  comments: RedditComment[]
  postPermalink: string
}
const CommentsDisplay = ({ comments, postPermalink }: Props): ReactElement => {
  return (
    <VStack spacing={3} alignItems={'flex-start'}>
      <HStack>
        <FaAward />
        <Text fontSize={'lg'} as="span">
          Top comments
        </Text>
      </HStack>
      <Divider />
      {comments.length == 0 ? (
        <Text color="gray.500">Nothing to see here.</Text>
      ) : (
        comments.map((comment) => (
          <>
            <SingleCommentDisplay
              key={comment.id}
              comment={comment}
              postPermalink={postPermalink}
            />
            <Divider />
          </>
        ))
      )}
    </VStack>
  )
}

export default CommentsDisplay
