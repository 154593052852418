import { useDisclosure } from '@chakra-ui/react'
import React, {
  ReactNode,
  ReactElement,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { DateTime } from 'luxon'

type BannerContext = 'stockWidget' | 'newsWidget' | 'redditWidget'

interface MobileAlertBannerContext {
  closeBanner: () => void
  isOpen: boolean
  openBanner: (bannerContext?: BannerContext) => void
  timeLastClosedInMilliseconds: number | null
  bannerContext: BannerContext | undefined
}

const MILLIS_PER_DAY = 1000 * 60 * 60 * 24

export const MobileAlertBannerContext = createContext<MobileAlertBannerContext>(
  {
    closeBanner: () => {
      return
    },
    isOpen: false,
    openBanner: () => {
      return
    },
    timeLastClosedInMilliseconds: null,
    bannerContext: undefined,
  }
)

export const useMobileAlertBannerContext = (): MobileAlertBannerContext =>
  useContext(MobileAlertBannerContext)

const MobileAlertBannerContextProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [timeLastClosed, setTimeLastClosed] = useState<number | null>(null)
  const [bannerContext, setBannerContext] = useState<BannerContext | undefined>(
    undefined
  )

  useEffect(() => {
    const lastTimeBannerClosed = localStorage.getItem(
      'mobileWarningBannerClosedTime'
    ) as number | null

    setTimeLastClosed(lastTimeBannerClosed)
    if (
      lastTimeBannerClosed == null ||
      DateTime.now().toMillis() - lastTimeBannerClosed > MILLIS_PER_DAY
    ) {
      onOpen()
    }
  }, [onOpen])

  const handleOpen = (context?: BannerContext) => {
    setBannerContext(context)
    onOpen()
  }

  const handleClose = useCallback(() => {
    const currTime = DateTime.now().toMillis()

    localStorage.setItem('mobileWarningBannerClosedTime', currTime.toString())
    setTimeLastClosed(currTime)

    onClose()
  }, [onClose])

  return (
    <MobileAlertBannerContext.Provider
      value={{
        closeBanner: handleClose,
        isOpen,
        openBanner: handleOpen,
        timeLastClosedInMilliseconds: timeLastClosed,
        bannerContext,
      }}
    >
      {children}
    </MobileAlertBannerContext.Provider>
  )
}

export default MobileAlertBannerContextProvider
