import React, { ReactElement, useState, useRef } from 'react'
import {
  PopoverBody,
  IconButton,
  PopoverContent,
  Popover,
  PopoverTrigger,
  Portal,
  Box,
  Placement,
} from '@chakra-ui/react'
import { GrEmoji } from 'react-icons/gr'
import { isMobile } from 'react-device-detect'
import { Token } from '@chakra-ui/styled-system/dist/types/utils'
import * as CSS from 'csstype'
import { useClickAway } from 'react-use'
import { IEmojiData } from 'emoji-picker-react'

import dynamic from 'next/dynamic'
const Picker = dynamic(() => import('emoji-picker-react'), { ssr: false })

interface Props {
  onSelectEmoji: (emoji: string) => void
  placement?: Placement
  popoverMargin?: Token<CSS.Property.Margin | number, 'space'>
  isDisabled?: boolean
}
const EmojiPicker = ({
  onSelectEmoji,
  placement = 'top-start',
  popoverMargin = 2,
  isDisabled = false,
}: Props): ReactElement => {
  const handleSelectEmoji = (e: React.MouseEvent, data: IEmojiData) => {
    onSelectEmoji(data.emoji)
  }

  const [popoverIsOpen, setPopoverIsOpen] = useState(false)

  const handleClickOutside = () => {
    setPopoverIsOpen(false)
  }

  const ref = useRef(null)

  useClickAway(ref, handleClickOutside)

  if (isMobile) {
    return <></>
  }
  return (
    <Popover
      isOpen={popoverIsOpen}
      // onClose={() => setPopoverIsOpen(false)}
      placement={placement}
      closeOnBlur={true}
      closeOnEsc={true}
    >
      <PopoverTrigger>
        <Box>
          <IconButton
            isDisabled={isDisabled}
            variant={'ghost'}
            onClick={() => setPopoverIsOpen(true)}
            icon={<GrEmoji fontSize={20} />}
            p={0}
            aria-label={'Add an emoji'}
          />{' '}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg="none"
          m={popoverMargin}
          p={0}
          w="max-content"
          h="max-content"
        >
          <PopoverBody p={0} ref={ref}>
            <Picker onEmojiClick={handleSelectEmoji} native />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default EmojiPicker
