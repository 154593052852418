import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import React, { ReactElement } from 'react'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  IconButton,
  Link,
  SkeletonText,
  Text,
  Tooltip,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react'

import Emoji from '../../../general/Emoji'
import VerticalDrawer from '../../../layout/VerticalDrawer'
import { ReaderViewProps } from '../types'

const cleanHtml = (html: string): string => {
  const clean = DOMPurify.sanitize(html)

  // Convert string to DOM object
  const template = document.createElement('template')
  template.innerHTML = clean

  // Style html elements
  template.content.querySelectorAll('p').forEach((element) => {
    element.style['marginBottom'] = '16px'
    element.style['fontSize'] = '1.15em'
    // if (element.hasAttributes() == false) {
    //   element.style['marginBottom'] = '16px'
    // } else {
    //   element.remove()
    // }
  })

  template.content.querySelectorAll('ul').forEach((element) => {
    if (element.hasAttributes() == false) {
      element.style.width = '90%'
      element.style['marginTop'] = '12px'
      element.style['marginBottom'] = '12px'
      element.style['marginLeft'] = 'auto'
      element.style['marginRight'] = 'auto'
    } else {
      element.remove()
    }
  })

  template.content.querySelectorAll('img').forEach((element) => {
    if (element.naturalHeight < 10 && element.naturalWidth < 10) {
      element.style['marginTop'] = '24px'
      // element.style['marginBottom'] = '24px'
      element.style['marginLeft'] = 'auto'
      element.style['marginRight'] = 'auto'
      // element.style['maxHeight'] = '400px'
    } else {
      element.remove()
    }
  })

  template.content.querySelectorAll('blockquote').forEach((element) => {
    element.style['marginTop'] = '24px'
    element.style['marginBottom'] = '24px'
    // element.style['width'] = '600px'
    element.style['marginLeft'] = 'auto'
    element.style['marginRight'] = 'auto'
    element.style['paddingLeft'] = '20px'
    element.style['paddingRight'] = '20px'
    element.style['borderLeft'] = '2px'
  })

  template.content.querySelectorAll('h2').forEach((element) => {
    element.style['fontSize'] = '1.25em'
    element.style['fontWeight'] = 'bold'
    element.style['marginBottom'] = '6px'
    element.style['marginTop'] = '24px'
  })

  template.content.querySelectorAll('a').forEach((element) => {
    element.style.color = '#4299E1'
    // element.style['fontWeight'] = 'medium'
  })

  // Remove unnecessary elements
  const elementsRemoved = [
    'time',
    'pre',
    'h4',
    'h5',
    'h6',
    'span',
    'hr',
    'small',
    'nav',
    'header',
    'svg',
    // 'figcaption',
  ]

  elementsRemoved.forEach((tag) => {
    template.content.querySelectorAll(tag).forEach((element) => {
      if (element.parentElement) {
        element.parentElement.removeChild(element)
      }
    })
  })

  const newHtml = template.outerHTML
    .replace('<template>', '')
    .replace('</template>', '')

  if (newHtml !== undefined) {
    return newHtml
  }

  return ''
}

const ReaderView = ({
  isOpen,
  onClose,
  isLoading,
  expandedArticle,
  selectedArticle,
  byline,
}: ReaderViewProps): ReactElement => {
  const [isMobile] = useMediaQuery('(max-width: 840px)')

  return (
    <VerticalDrawer
      isOpen={isOpen}
      onClose={onClose}
      padding={isMobile ? 2 : 10}
      paddingTop={6}
    >
      <VStack align="start" p={5}>
        <Heading as="h1" size="lg">
          {selectedArticle?.title}
        </Heading>
        <HStack maxW="100%" spacing="1">
          <Text color="gray.500" fontSize="lg" isTruncated>
            {byline}
          </Text>
          <Tooltip placement="right" label="Open in new tab" openDelay={1000}>
            <Link href={selectedArticle?.link} isExternal>
              <IconButton
                onClick={(e) => e.stopPropagation()}
                color="gray.500"
                variant="ghost"
                size="sm"
                aria-label="Open in new tab"
                icon={<ExternalLinkIcon />}
              />
            </Link>
          </Tooltip>
        </HStack>
        {/* {selectedArticle?.media != undefined && (
          <Image
            paddingTop="8"
            src={selectedArticle.media}
            width="100%"
            alt="Header image"
          />
        )} */}

        {!isLoading ? (
          expandedArticle &&
          expandedArticle.reader &&
          expandedArticle.reader.content ? (
            <Box paddingTop="6">
              {parse(cleanHtml(expandedArticle.reader.content))}
            </Box>
          ) : (
            expandedArticle && (
              <Center h="100%" w="100%" paddingTop="20">
                <VStack spacing="6">
                  <Text fontSize="1.4em">
                    Unable to load article <Emoji symbol="😔" label="sad" />{' '}
                  </Text>
                  <Link
                    href={expandedArticle.article.link}
                    isExternal
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      onClick={() => {
                        onClose()
                      }}
                      rightIcon={<ExternalLinkIcon />}
                      variant="outline"
                    >
                      Read in another tab
                    </Button>
                  </Link>
                </VStack>
              </Center>
            )
          )
        ) : (
          <SkeletonText
            paddingTop="6"
            w="90%"
            h="200px"
            // spacing="10"
            spacing="5"
            skeletonHeight={5}
            mt={4}
            noOfLines={5}
          />
        )}
      </VStack>
    </VerticalDrawer>
  )
}

export default ReaderView

// <VStack spacing="5" align="start" padding="1rem">
{
  /* {expandedArticle.article.media != undefined &&
                !expandedArticle.article.media_content.includes(
                  expandedArticle.article.media
                ) && (
                  <Image
                    paddingTop="8"
                    src={expandedArticle.article.media}
                    width="100%"
                    alt="Header image"
                  />
                )} */
}

{
  /* <Skeleton height="35px" w="80%" />

            <Skeleton marginTop="30px" height="25px" w="40%" /> */
}
{
  /* </VStack> */
}
