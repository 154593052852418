import { DateTime } from 'luxon'
/**
 * Modal for editing a dashboard's settings
 */
import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { Box, Button, Flex, Spacer, Tooltip } from '@chakra-ui/react'
import { useCurrentUser } from '../../../context/user/context'

import { addDoc } from '../../../utils/firebase/firebaseCustoms'
import db from '../../../utils/firebase/firestore'
import Modal from '../../settings/Modal'
// import AutomaticSnapshotSettings, {
//   SnapshotSettings,
//   defaultSnapshotSettings,
//   DayOfWeek,
// } from './AutomaticSnapshotSettings'
import AuthorDashboardSettings, {
  AuthorSettings,
} from './AuthorDashboardSettings'
import {
  DayOfWeek,
  SnapshotSettings,
  defaultSnapshotSettings,
} from './AutomaticSnapshotSettings'
import { useDashboardSettingsModalContext } from '../../../context/dashboard-settings-modal/context'
import { useRouter } from 'next/router'
import { routes } from '../../../utils/constants'
import useDashboards from '../../../context/dashboard-navigation/useDashboards'

interface Props {
  snapshotsEnabled: boolean
}

const DashboardSettingsModal = ({
  snapshotsEnabled: initialSnapshotsEnabled,
}: Props): ReactElement => {
  const {
    dashboardId,
    isOpen,
    closeSettings,
    dashboardInSettings: dashboard,
  } = useDashboardSettingsModalContext()

  const { user } = useCurrentUser()
  const userId = user?.uid

  const isAuthor = true // TODO: change if allowing non-authors to have any sort of settings

  const [authorSettings, setAuthorSettings] = useState<
    AuthorSettings | undefined
  >(undefined)

  const [scheduledSnapshot, setScheduledSnapshot] = useState<SnapshotSettings>(
    defaultSnapshotSettings
  )
  const [initialScheduledSnapshot] = useState<SnapshotSettings | undefined>(
    undefined
  )
  const [scheduledSnapshotDocId, setScheduledSnapshotDocId] = useState<
    string | null
  >()
  const [activeTab, setActiveTab] = useState<
    'authorSettings' | 'snapshotSettings' | null
  >('authorSettings')

  const [saved, setSaved] = useState(false)
  const [edited, setEdited] = useState(false)
  const [snapshotsEnabled, setSnapshotsEnabled] = useState(
    initialSnapshotsEnabled
  )

  const { allDashboards } = useDashboards(user)

  // Load initial values
  useEffect(() => {
    if (dashboard != undefined) {
      setAuthorSettings({
        title: dashboard.title,
        description: dashboard.description,
        tags: dashboard.tags,
        isPublic: dashboard.isPublic,
      })
    } else {
      const foundDashboard = allDashboards?.find((d) => d.id == dashboardId)
      if (foundDashboard != undefined) {
        setAuthorSettings({
          title: foundDashboard.title,
          description: foundDashboard.description,
          tags: foundDashboard.tags,
          isPublic: foundDashboard.isPublic,
        })
      }
    }
    return () => {
      setAuthorSettings(undefined)
    }
  }, [dashboard?.id, dashboard, allDashboards, dashboardId])

  useEffect(() => {
    setSaved(false)
    return () => {
      setSaved(false)
      setEdited(false)
    }
  }, [activeTab])

  useEffect(() => {
    if (activeTab == null) {
      if (authorSettings != null) {
        setActiveTab('authorSettings')
      } else {
        setActiveTab('snapshotSettings')
      }
    }
  }, [activeTab, authorSettings])

  // Get scheduled snapshot
  // useEffect(() => {
  //   if (userId == undefined) return
  //   const scheduledSnapshotQuery = db
  //     .collection('scheduledSnapshots')
  //     .where('userId', '==', userId)
  //     .where('dashboardId', '==', dashboardId)
  //     .limit(1)
  //   const unsubscribe = scheduledSnapshotQuery.onSnapshot(
  //     (scheduledSnapshotQuerySnapshot) => {
  //       let scheduledSnapshot
  //       if (scheduledSnapshotQuerySnapshot.docs.length == 1) {
  //         const doc = scheduledSnapshotQuerySnapshot.docs[0]
  //         scheduledSnapshot = doc.data()
  //         setScheduledSnapshotDocId(doc.id)
  //       }
  //       if (scheduledSnapshot != undefined) {
  //         setInitialScheduledSnapshot(scheduledSnapshot as SnapshotSettings)
  //         setScheduledSnapshot(scheduledSnapshot as SnapshotSettings)
  //       }
  //     }
  //   )

  //   return () => {
  //     unsubscribe()
  //     setScheduledSnapshot(defaultSnapshotSettings)
  //     setScheduledSnapshotDocId(null)
  //   }
  // }, [userId, dashboardId])

  // // Functions to fill in missing/undefined fields in settings objects
  // // TODO: figure out types
  // // TODO: combine into one function (tried this, types were wack)
  // const fillMissingAuthorFields = (
  //   settingsObject: PartialAuthorSettings | null
  // ) => {
  //   const defaultValues: AuthorSettings = defaultAuthorSettings
  //   if (settingsObject == null) {
  //     return defaultValues
  //   }

  //   const settingsKeys: (keyof AuthorSettings)[] = Object.keys(
  //     defaultValues
  //   ) as (keyof AuthorSettings)[]

  //   settingsKeys.forEach((property: keyof AuthorSettings) => {
  //     if (settingsObject[property] != undefined) {
  //       defaultValues[property] = settingsObject[property]
  //     } else {
  //     }
  //   })
  //   return settingsObject as AuthorSettings
  // }

  const handleClose = () => {
    closeSettings()
    setEdited(true)
    setSaved(false)
    setAuthorSettings(authorSettings)
    setScheduledSnapshot(scheduledSnapshot)
    setSnapshotsEnabled(initialSnapshotsEnabled)
  }

  const handleAuthorSettingsChange = (settings: AuthorSettings) => {
    setEdited(true)
    setSaved(false)

    setAuthorSettings(settings)
  }

  // const handleSnapshotSettingsChange = (settings: SnapshotSettings) => {
  //   setEdited(true)
  //   setSaved(false)

  //   setScheduledSnapshot(settings)
  // }

  const dashboardRef = db.doc(`dashboards/${dashboardId}`)
  const settingsRef = db.doc(`users/${userId}/dashboardSettings/${dashboardId}`)

  const arraysHaveSameValues = (
    arr1: Array<unknown> | undefined,
    arr2: Array<unknown> | undefined
  ): boolean => {
    if (arr1 == undefined) {
      return arr2 == undefined
    }
    if (arr2 == undefined) {
      return arr1 == undefined
    }
    for (const el in arr1) {
      if (!arr2.includes(el)) {
        return false
      }
    }
    for (const el in arr2) {
      if (!arr1.includes(el)) {
        return false
      }
    }
    return true
  }

  const calcNextExecution = (days: DayOfWeek[], time: number): Date => {
    const allDays: DayOfWeek[] = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]

    const now = DateTime.now()
    const nowDayNumber = now.weekday - 1
    const nowInMinutes = now.toUTC().hour * 60 + now.toUTC().minute

    const executions = days.map((day) => {
      let dayDelta = (allDays.indexOf(day) + 7 - nowDayNumber) % 7
      if (dayDelta == 0) {
        dayDelta = time < nowInMinutes ? 7 : 0
      }
      return DateTime.now().toUTC().startOf('day').plus({
        days: dayDelta,
        minutes: time,
      })
    })
    const nextExecution = DateTime.min(...executions)
    return nextExecution.toJSDate()
  }

  const handleSaveSettings = () => {
    if (activeTab == 'authorSettings') {
      handleSaveAdminSettings()
    } else {
      // change to if (activeTab == 'snapshotSettings') if we add more tabs
      handleSaveSnapshotSettings()
    }
    setSaved(true)
    setEdited(false)
    handleClose()
  }

  const handleSaveSnapshotSettings = async () => {
    // AUTOMATIC SNAPSHOT SETTINGS

    const { days, time } = scheduledSnapshot
    // check if automatic snapshot settings have changed. if so, update

    const snapshotDocRef = db.doc(
      `scheduledSnapshots/${scheduledSnapshotDocId}`
    )

    await settingsRef.update({
      snapshotsEnabled: snapshotsEnabled != null ? snapshotsEnabled : false,
    })

    // if disabled, delete the snapshot doc
    // we maintain settings for the snapshot doc

    if (!snapshotsEnabled) {
      if (scheduledSnapshotDocId != undefined) {
        await snapshotDocRef.delete()
        setScheduledSnapshotDocId(null)
      }
      return
    }

    if (
      initialScheduledSnapshot?.time !== time ||
      !arraysHaveSameValues(initialScheduledSnapshot?.days, days)
    ) {
      const nextExecution = calcNextExecution(days, time)
      // Update automatic snapshot
      if (scheduledSnapshotDocId != undefined) {
        await snapshotDocRef.update({ days, time, nextExecution })
      } else {
        // No snapshot exists, add a new one
        await addDoc(db.collection('scheduledSnapshots'), {
          userId,
          dashboardId,
          days,
          time,
          nextExecution,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      }
    }
  }

  const handleSaveAdminSettings = async () => {
    //ADMIN SETTINGS
    await dashboardRef.update({
      ...authorSettings,
    })
    //onClose()
  }

  const { closeSettings: handleCloseSettingsModal } =
    useDashboardSettingsModalContext()
  const router = useRouter()

  const handleDeleteDashboard = useCallback(async () => {
    if (dashboardId == undefined) return
    await db
      .doc(`dashboards/${dashboardId}`)
      .delete()
      .catch((error) => {
        console.error(error)
        return
      })

    router.push({
      pathname: routes.home,
      query: {},
    })

    handleCloseSettingsModal()
  }, [dashboardId, router, handleCloseSettingsModal])

  const saveButton =
    activeTab == 'snapshotSettings' ? (
      <Tooltip
        isDisabled={!snapshotsEnabled || scheduledSnapshot.days.length > 0}
        hasArrow
        label="Provide at least one day for automatic snapshotting."
        bg="red.600"
      >
        <Button
          colorScheme="blue"
          onClick={handleSaveSettings}
          disabled={snapshotsEnabled && scheduledSnapshot.days.length === 0}
        >
          {saved && !edited ? 'Saved!' : 'Save'}
        </Button>
      </Tooltip>
    ) : (
      <Tooltip
        hidden={authorSettings?.title != ''}
        bg="gray.500"
        hasArrow
        label="Add a title"
        placement="top-end"
      >
        <span>
          <Button
            colorScheme="blue"
            onClick={handleSaveSettings}
            disabled={authorSettings?.title == ''}
          >
            {saved && !edited ? 'Saved!' : 'Save'}
          </Button>
        </span>
      </Tooltip>
    )

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeSettings}
      size="xl"
      title="Dashboard Settings"
      footer={
        <Flex
          borderTopWidth="0.25px"
          borderTopColor="gray.200"
          flexDir="row"
          py={5}
          px={6}
          w="100%"
        >
          <Spacer />
          {saveButton}
        </Flex>
      }
    >
      <Box px={6} py={2} overflowY={'scroll'}>
        {isAuthor && (
          <AuthorDashboardSettings
            onDeleteDashboard={handleDeleteDashboard}
            settings={authorSettings}
            onChange={handleAuthorSettingsChange}
          />
        )}
      </Box>
      {/* <Tabs align="center" isFitted>
            <TabList>
              {isAuthor && (
                <Tab onClick={() => setActiveTab('authorSettings')}>
                  Dashboard
                </Tab>
              )}
              <Tab onClick={() => setActiveTab('snapshotSettings')}>
                Snapshots
              </Tab>
            </TabList> 
          <TabPanels pt={5}>*/}
      {/* {isAuthor && authorSettings != null && (
            <TabPanel p={5}>
              <AuthorDashboardSettings
                onDeleteDashboard={onDeleteDashboard}
                settings={authorSettings}
                onChange={handleAuthorSettingsChange}
              />
            </TabPanel>
          )} */}

      {/* <TabPanel p={5}>
            <AutomaticSnapshotSettings
              settings={scheduledSnapshot}
              enabled={snapshotsEnabled}
              onEnabledChange={handleEnabledChange}
              onChange={handleSnapshotSettingsChange}
            />
          </TabPanel> */}
      {/* </TabPanels>
          {/* </Tabs> */}
    </Modal>
  )
}
export default DashboardSettingsModal
