import React, { ReactElement } from 'react'

import { Box } from '@chakra-ui/react'

const EditingWidgetBlocker = (): ReactElement => {
  return (
    <Box
      bg="white"
      w="100%"
      h="calc(100% - 20px)"
      borderRadius="lg"
      zIndex={2}
      top="25px"
      pos="absolute"
      opacity={0.5}
    />
  )
}

export default EditingWidgetBlocker
