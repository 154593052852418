import Image from 'next/image'
import React, { ReactElement } from 'react'

import { Center, ModalBody, Text, VStack } from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../context/dashboard-navigation/context'
import { useCurrentUser } from '../../context/user/context'
import firebase from '../../utils/firebase/firebase'
import db from '../../utils/firebase/firestore'
import Footer from './Footer'

interface CreateDashboardProps {
  image: StaticImageData
  text: string
  isLast?: boolean
  onNav: (increment: number, isLast?: boolean) => void
}

const CreateDashboard = ({
  image,
  text,
  isLast,
  onNav,
}: CreateDashboardProps): ReactElement => {
  const { user } = useCurrentUser()
  const { toggleOverviewModalOpen } = useDashboardNavigationContext()

  const userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =
    db.doc(`users/${user?.uid}`)

  const handleSubmit = (): void => {
    if (user)
      userRef.update({
        completedCreateDashboard: true,
      })
    else toggleOverviewModalOpen()
  }

  const width = 1440
  const height = 790

  return (
    <>
      <ModalBody w="100%">
        <VStack spacing={6} w="100%" h="100%" minH="100%" pt={4}>
          <Center padding={0} borderWidth="2px">
            <Image
              src={image}
              alt={`Onboarding step`}
              width={width}
              height={height}
            />
          </Center>
          <Text w="90%" align="center" fontSize="xl" fontWeight="400">
            {text}
          </Text>
        </VStack>
      </ModalBody>

      <Footer
        isNextDisabled={false}
        isLast={isLast}
        backLabel={isLast ? undefined : 'Back'}
        nextLabel={isLast ? 'Close' : 'Next'}
        onNav={onNav}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default CreateDashboard
