import Image from 'next/image'
import React, { ReactElement } from 'react'

import {
  Center,
  Grid,
  GridItem,
  HStack,
  Heading,
  ModalBody,
  Text,
  VStack,
  Spacer,
} from '@chakra-ui/react'

import WhatIsSignalist from '../../../public/onboarding/WhatIsSignalist.svg'
import { useDashboardNavigationContext } from '../../context/dashboard-navigation/context'
import { useCurrentUser } from '../../context/user/context'
import firebase from '../../utils/firebase/firebase'
import db from '../../utils/firebase/firestore'
import Footer from './Footer'

import { isMobile } from 'react-device-detect'

interface OnePagerProps {
  isLast?: boolean
  onNav: (increment: number, isLast?: boolean) => void
}

const OnePager = ({ isLast = false, onNav }: OnePagerProps): ReactElement => {
  const { user } = useCurrentUser()

  const { toggleOverviewModalOpen } = useDashboardNavigationContext()

  const userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =
    db.doc(`users/${user?.uid}`)

  const handleSubmit = (): void => {
    if (user)
      userRef.update({
        completedOverview: true,
      })
    else toggleOverviewModalOpen()
  }

  const width = 390

  return (
    <>
      <ModalBody w="100%" h="100%" pt="6">
        <Center
          px={isMobile ? '1' : '5'}
          pl={isMobile ? '2' : '6'}
          w="100%"
          h="100%"
        >
          <VStack spacing={isMobile ? 8 : 14} h="100%">
            <Spacer />
            <HStack spacing={isMobile ? 5 : 6}>
              <Image
                alt={`What is Signalist? graphic`}
                width={width}
                height={width / 1.91}
                src={WhatIsSignalist}
              />
              <VStack align="start">
                <Heading size={isMobile ? 'md' : 'lg'}>
                  Quieter consumption
                </Heading>
                <Text fontSize={isMobile ? 'md' : 'xl'} color="#8D8D8D">
                  Stream information from social, markets, and news
                </Text>
              </VStack>
            </HStack>

            <Grid
              px={isMobile ? 4 : 7}
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(2, 1fr)"
              gap={isMobile ? '5' : '6'}
              pb={isMobile ? '3' : '6'}
            >
              <GridItem
                rowSpan={1}
                colSpan={1}
                fontSize={isMobile ? 'sm' : 'md'}
              >
                <VStack align="start" spacing="1">
                  <Text fontSize="lg" fontWeight="bold">
                    Build
                  </Text>
                  <Text color="#8D8D8D" fontSize="md" mr="-2">
                    Create dashboards with widgets for news, stocks, and more
                  </Text>
                </VStack>
              </GridItem>

              <GridItem rowSpan={1} colSpan={1}>
                <VStack align="start" spacing="1">
                  <Text fontSize="lg" fontWeight="bold">
                    Discover
                  </Text>
                  <Text color="#8D8D8D" fontSize="md">
                    Learn from other users by following their dashboards
                  </Text>
                </VStack>
              </GridItem>

              <GridItem rowSpan={1} colSpan={1}>
                <VStack align="start" spacing="1">
                  <Text fontSize="lg" fontWeight="bold">
                    Filter
                  </Text>
                  <Text color="#8D8D8D" fontSize="md">
                    Avoid unwanted information with custom widget rules
                  </Text>
                </VStack>
              </GridItem>

              <GridItem rowSpan={1} colSpan={1}>
                <VStack align="start" spacing="1">
                  <Text fontSize="lg" fontWeight="bold">
                    Refresh
                  </Text>
                  <Text color="#8D8D8D" fontSize="md">
                    Stay up-to-date with real-time data streaming
                  </Text>
                </VStack>
              </GridItem>
            </Grid>
            <Spacer />
          </VStack>
        </Center>
      </ModalBody>

      <Footer
        isNextDisabled={false}
        isLast={isLast}
        backLabel={isLast ? undefined : 'Back'}
        nextLabel={isLast ? 'Close' : 'Next'}
        onNav={onNav}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default OnePager
