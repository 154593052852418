import { HStack, Text, Link, Button } from '@chakra-ui/react'
import React from 'react'
import formatMetric from '../formatMetric'
import { TweetPublicMetrics } from '../types'

interface Props {
  tweetId: string
  tweetUrl: string
  publicMetrics: TweetPublicMetrics
}

export default function Replied({
  tweetId,
  tweetUrl,
  publicMetrics,
}: Props): React.ReactElement {
  const [copied, setCopied] = React.useState(false)

  const likeUrl = `https://twitter.com/intent/like?tweet_id=${tweetId}`
  const retweetUrl = `https://twitter.com/intent/retweet?tweet_id=${tweetId}`
  const replyUrl = `https://twitter.com/intent/tweet?in_reply_to=${tweetId}`

  const ICON_SIZE = 20
  return (
    <HStack spacing={'auto'} pt={2} pb={4} maxW="320px">
      <Link
        color="gray.500"
        className="flex items-center group transition !no-underline space-x-1"
        href={likeUrl}
        target="_blank"
        rel="noreferrer"
      >
        <HStack spacing={1}>
          <div className="group-hover:!text-red-600 rounded-full flex items-center">
            <svg width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 24 24">
              <path
                className="fill-current"
                d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.813-1.148 2.353-2.73 4.644-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.375-7.454 13.11-10.037 13.156H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.035 11.596 8.55 11.658 1.52-.062 8.55-5.917 8.55-11.658 0-2.267-1.822-4.255-3.902-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.015-.03-1.426-2.965-3.955-2.965z"
              />
            </svg>
          </div>
          <span className="group-hover:!text-red-600 group-hover:!underline">
            {formatMetric(publicMetrics.like_count)}
          </span>
        </HStack>
      </Link>

      <Link
        color="gray.500"
        className="flex items-center mr-4 group transition !no-underline space-x-1"
        href={retweetUrl}
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        <HStack spacing={1}>
          <div className="group-hover:!text-purple-600 rounded-full flex items-center">
            <svg width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 24 24">
              <path
                className="fill-current"
                d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"
              />
            </svg>
          </div>
          <span className="group-hover:!text-purple-600 group-hover:!underline">
            {formatMetric(publicMetrics.retweet_count)}
          </span>
        </HStack>{' '}
      </Link>
      <Link
        color="gray.500"
        className="flex items-center mr-4 group transition !no-underline space-x-1"
        href={replyUrl}
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        <HStack spacing={1}>
          <div className="group-hover:!text-[#1da1f2] rounded-full flex items-center">
            <svg width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 24 24">
              <path
                className="fill-current"
                d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.045.286.12.403.143.225.385.347.633.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.368-3.43-7.788-7.8-7.79zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.334-.75-.75-.75h-.395c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
              />
            </svg>
          </div>
          <span className="group-hover:!text-[#1da1f2] group-hover:!underline">
            {formatMetric(publicMetrics.reply_count)}
          </span>
        </HStack>{' '}
      </Link>
      <Button
        variant="unstyled"
        color="gray.500"
        fontWeight={'normal'}
        className="flex items-center group transition !no-underline space-x-1"
      >
        <HStack
          spacing={1}
          onClick={() => {
            navigator.clipboard.writeText(tweetUrl)
            setCopied(true)
            setTimeout(() => setCopied(false), 5000)
          }}
        >
          <div className="group-hover:!text-green-600 rounded-full flex items-center">
            <svg width={ICON_SIZE} height={ICON_SIZE} viewBox="0 0 24 24">
              <path
                className="fill-current"
                d={
                  copied
                    ? 'M9 20c-.264 0-.52-.104-.707-.293l-4.785-4.785c-.39-.39-.39-1.023 0-1.414s1.023-.39 1.414 0l3.946 3.945L18.075 4.41c.32-.45.94-.558 1.395-.24.45.318.56.942.24 1.394L9.817 19.577c-.17.24-.438.395-.732.42-.028.002-.057.003-.085.003z'
                    : 'M11.96 14.945c-.067 0-.136-.01-.203-.027-1.13-.318-2.097-.986-2.795-1.932-.832-1.125-1.176-2.508-.968-3.893s.942-2.605 2.068-3.438l3.53-2.608c2.322-1.716 5.61-1.224 7.33 1.1.83 1.127 1.175 2.51.967 3.895s-.943 2.605-2.07 3.438l-1.48 1.094c-.333.246-.804.175-1.05-.158-.246-.334-.176-.804.158-1.05l1.48-1.095c.803-.592 1.327-1.463 1.476-2.45.148-.988-.098-1.975-.69-2.778-1.225-1.656-3.572-2.01-5.23-.784l-3.53 2.608c-.802.593-1.326 1.464-1.475 2.45-.15.99.097 1.975.69 2.778.498.675 1.187 1.15 1.992 1.377.4.114.633.528.52.928-.092.33-.394.547-.722.547z M7.27 22.054c-1.61 0-3.197-.735-4.225-2.125-.832-1.127-1.176-2.51-.968-3.894s.943-2.605 2.07-3.438l1.478-1.094c.334-.245.805-.175 1.05.158s.177.804-.157 1.05l-1.48 1.095c-.803.593-1.326 1.464-1.475 2.45-.148.99.097 1.975.69 2.778 1.225 1.657 3.57 2.01 5.23.785l3.528-2.608c1.658-1.225 2.01-3.57.785-5.23-.498-.674-1.187-1.15-1.992-1.376-.4-.113-.633-.527-.52-.927.112-.4.528-.63.926-.522 1.13.318 2.096.986 2.794 1.932 1.717 2.324 1.224 5.612-1.1 7.33l-3.53 2.608c-.933.693-2.023 1.026-3.105 1.026z'
                }
              />
            </svg>
          </div>
          <Text
            noOfLines={1}
            className="group-hover:!text-green-600 group-hover:!underline sm:block hidden"
          >
            {copied ? 'Copied!' : 'Copy link'}
          </Text>
        </HStack>{' '}
      </Button>
    </HStack>
  )
}
