import {
  Box,
  FormControl,
  FormHelperText,
  Text,
  HStack,
  Wrap,
  VStack,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'

interface RadioCardsProps {
  choices: string[]
  selectedChoice: string
  onCardSelection: (choice: string) => void
  canEdit: boolean
}

interface CardProps {
  choice: string
  isSelected: boolean
  onCardSelection: (choice: string) => void
  canEdit: boolean
}

const Card = ({
  choice,
  isSelected,
  onCardSelection,
  canEdit,
}: CardProps): ReactElement => {
  return (
    <Box
      cursor="pointer"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="sm"
      bg={isSelected ? 'blue.600' : 'white'}
      color={isSelected ? 'white' : 'auto'}
      borderColor={isSelected ? 'blue.600' : 'auto'}
      _focus={{
        boxShadow: 'outline',
      }}
      px={3}
      py={2}
      onClick={() => {
        if (canEdit) {
          onCardSelection(choice)
        }
      }}
      _hover={{ cursor: canEdit ? 'pointer' : 'default' }}
    >
      {choice}
    </Box>
  )
}

const RadioCards = ({
  choices,
  selectedChoice,
  onCardSelection,
  canEdit,
}: RadioCardsProps): ReactElement => {
  return (
    <FormControl>
      <VStack align={'flex-start'}>
        <Text as="b">Category</Text>
        <HStack spacing={5}>
          <Wrap align="center">
            {choices.map((value) => {
              return (
                <Card
                  key={`radio-${value}`}
                  isSelected={
                    selectedChoice.toLowerCase() == value.toLowerCase()
                  }
                  choice={value}
                  onCardSelection={() => onCardSelection(value)}
                  canEdit={canEdit}
                />
              )
            })}
          </Wrap>
        </HStack>
        {canEdit && (
          <FormHelperText>Optional (You may only select one)</FormHelperText>
        )}
      </VStack>
    </FormControl>
  )
}

export default RadioCards
