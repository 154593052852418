// // import { Image } from '@chakra-ui/react'
// import NextImage from 'next/image'
//import { useState } from 'react'
// import cn from 'clsx'

// // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// export default function BlurImage(props: any): React.ReactElement {
//   const [isLoading, setLoading] = useState(true)

//   return (
//     <NextImage
//       {...props}
//       alt={props.alt}
//       width={props.width}
//       height={props.height}
//       layout="fill"
//       src={props.src}
//       className={cn(
//         props.className,
//         'duration-200 ease-in-out',
//         isLoading
//           ? 'grayscale blur-2xl scale-110'
//           : 'grayscale-0 blur-0 scale-100'
//       )}
//       onLoadingComplete={() => setLoading(false)}
//     />
//   )
// }

// Attempt to integrate chakra styling with next/image
// Modified slightly from https://gist.github.com/TheThirdRace/0f439acef8d9cb6bf5d7e69c54704086
import { chakra, ThemingProps } from '@chakra-ui/react'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import React, { ReactElement, useState } from 'react'

/**
 * ? Because NextJs typing is preventing auto-suggest for layout, width and height
 * ? we declare the styles differently in this component and will manage the switch
 * ? to NextJs typings when calling NextJs Image component
 */
type LayoutValue = 'fixed' | 'intrinsic' | 'responsive' | undefined

type LayoutAndSize =
  | {
      layout: 'fill'
    }
  | {
      layout: LayoutValue
      height: number
      width: number
    }

/**
 * Types for the Image component
 * Picking only the props I want to allow, you can adjust to your liking
 */
type ImageProps = Pick<
  NextImageProps,
  | 'className'
  | 'loading'
  | 'objectFit'
  | 'objectPosition'
  | 'priority'
  | 'quality'
  | 'src'
  | 'unoptimized'
  | 'onLoadingComplete'
  | 'placeholder'
> &
  Pick<Required<NextImageProps>, 'alt'> &
  Pick<ThemingProps, 'variant'> & {
    dimensions?: [number, number]
    layout?: 'fill' | LayoutValue
  }

/**
 * Wrap NextJs Image component in Chakra's factory function
 * This is what will allow to use the theme and the styling properties on the component
 */
const ImageWithChakra = chakra(
  ({
    className,
    dimensions = [0, 0],
    layout = 'fill',
    loading,
    objectFit,
    objectPosition,
    priority,
    quality,
    src,
    unoptimized,
    onLoadingComplete,
    placeholder,
    ...nextjsInternals
  }: ImageProps): ReactElement => {
    /**
     * As explained earlier, NextJs typing is preventing auto-suggest for layout, width and height
     * Here we actual convert our component typing to NextJs typing
     */
    const [width, height] = dimensions

    const layoutAndSize: LayoutAndSize =
      height > 0 || width > 0
        ? {
            height,
            layout: layout === 'fill' ? 'intrinsic' : layout,
            width,
          }
        : {
            layout: 'fill',
          }

    /**
     * The actual NextImage component
     */
    return (
      <NextImage
        className={className}
        loading={loading}
        objectFit={objectFit}
        objectPosition={objectPosition}
        priority={priority}
        quality={quality}
        src={src}
        unoptimized={unoptimized}
        onLoadingComplete={onLoadingComplete}
        placeholder={placeholder}
        {...layoutAndSize}
        {...nextjsInternals}
      />
    )
  }
)

const BlurImage = ({ ...props }: ImageProps): ReactElement => {
  /**
   * This components serves as an interface to pass Chakra's styles
   * You can use the theme and/or styling properties (eg. backgroundColor='red.200')
   */

  const [isLoading, setLoading] = useState(true)

  return (
    <ImageWithChakra
      {...props}
      bg="gray.200"
      blur={isLoading ? 'none' : 'none'}
      scale={isLoading ? 110 : 100}
      transition="200ms ease-in-out"
      onLoadingComplete={() => setLoading(false)}
    />
  )
}

export default BlurImage
