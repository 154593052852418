import { TwitterPoll } from '../types'
import { differenceInMilliseconds } from 'date-fns'
import { Text } from '@chakra-ui/react'
import cn from 'clsx'

function getRemainingTime(ISOString: string) {
  const currentTime = new Date()
  const endTime = new Date(ISOString)
  const diff = differenceInMilliseconds(endTime, currentTime)
  if (diff > 36e5 * 24) {
    const days = Math.floor(diff / (36e5 * 24))
    const hours = Math.floor((diff - days * 36e5 * 24) / 36e5)
    return `${days} day${days > 1 ? 's' : ''} ${hours} hours`
  } else if (diff > 36e5) {
    return `${Math.floor(diff / 36e5)} hours`
  } else if (diff > 60e3) {
    return `${Math.floor(diff / 60e3)} minutes`
  } else {
    return 'Less than a minute'
  }
}

interface Props {
  polls: TwitterPoll[]
  tweetUrl: string
}

export default function TweetDisplay({
  polls,
  tweetUrl,
}: Props): React.ReactElement {
  return (
    <div className="mt-5">
      {polls.map((poll) => {
        const totalVotes = poll.options.reduce(
          (sum, option) => sum + option.votes,
          0
        )
        return poll.voting_status == 'open' ? (
          <div>
            {poll.options.map((option, i) => (
              <a
                key={i}
                href={tweetUrl}
                target="_blank"
                rel="noreferrer"
                className="!no-underline"
              >
                <div className="text-center font-bold text-[#1da1f2] border border-[#1da1f2] rounded-3xl my-2 hover:bg-[#1da1f2] hover:bg-opacity-10 transition-all ease-in-out duration-150">
                  {option.label}
                </div>
              </a>
            ))}
            <Text color="gray.500">
              {totalVotes} votes · {getRemainingTime(poll.end_datetime)} left
            </Text>
          </div>
        ) : (
          <div>
            {poll.options.map((option) => (
              <>
                <div
                  className={cn(
                    option.position == 1 ? 'font-bold' : '',
                    'relative text-black my-2 cursor-pointer px-3 whitespace-nowrap flex justify-between'
                  )}
                >
                  <p className="!my-0 z-10">{option.label}</p>
                  <p className="!my-0 z-10">{`${(
                    (option.votes / totalVotes) *
                    100
                  )
                    .toFixed(1)
                    .replace('.0', '')}%`}</p>
                  <div
                    className={cn(
                      option.position == 1
                        ? 'font-bold bg-[#1da1f2]'
                        : 'bg-gray-300',
                      'absolute top-0 left-0 rounded-md w-full h-full'
                    )}
                    style={{
                      width: `${Math.round(
                        (option.votes / totalVotes) * 100
                      )}%`,
                    }}
                  />
                </div>
              </>
            ))}
            <Text color="gray.500" mt={4}>
              {totalVotes} votes · Final results
            </Text>
          </div>
        )
      })}
    </div>
  )
}
