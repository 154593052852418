import { NextRouter } from 'next/router'

export const goToProfile = (
  router: NextRouter,
  username: string | undefined
): void => {
  if (username != undefined) {
    router.push(`/${username}`)
  }
}
