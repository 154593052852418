/**
 * Form for editing a Reddit widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

// subreddit posts of a unique structure

import React, { ReactElement } from 'react'

import { Button, Center, Text, VStack } from '@chakra-ui/react'

import PhraseSelector from '../../twitter/TwitterSettings/MultiPhraseSelector'
import RedditAuthButton from '../RedditAuthButton'
import { RedditWidgetSettings } from '../types'
import SubredditSearch from './SubredditSearch'

interface Props {
  isAuthed: boolean
  settings: RedditWidgetSettings
}

const ReadOnlyRedditSettings = ({
  isAuthed,
  settings,
}: Props): ReactElement => {
  const dummyEventHandler = () => {
    return
  }

  return (
    <VStack align="start" spacing={7}>
      {!isAuthed && (
        <Center h="80%" w="100%">
          <VStack align="center" spacing={2}>
            <RedditAuthButton />
            <Text as="a" target="_blank" href={`/connected-accounts-policy`}>
              <Button
                fontSize="10pt"
                variant="link"
              >{`What's this for?`}</Button>
            </Text>
          </VStack>
        </Center>
      )}

      <VStack align="start" w="100%">
        <SubredditSearch
          subreddits={settings.subreddits}
          canEdit={false}
          token={null}
          onChange={dummyEventHandler}
        />
        {settings.subreddits.length === 0 && (
          <Center w="100%" justifyContent="center">
            <Text fontStyle="italic" textColor="gray.500">
              No subreddits selected
            </Text>
          </Center>
        )}
      </VStack>
      <VStack align="stretch" w="100%">
        <Text>
          {settings.requiredWords.length > 0 && (
            <>
              <Text as="b">Including</Text> posts with{' '}
              <Text as="b">{settings.requiredWordsJoiner}</Text> of these words:{' '}
              <PhraseSelector
                phrases={settings.requiredWords}
                onChange={dummyEventHandler}
                isDisabled={false}
                canEdit={false}
              />
            </>
          )}
        </Text>
      </VStack>
      <VStack align="stretch" w="100%">
        <Text>
          {settings.excludedWords.length > 0 && (
            <>
              <Text as="b">Filtering out</Text>
              {'  '}posts with{' '}
              <Text as="b">{settings.excludedWordsJoiner}</Text> of these words:{' '}
              <PhraseSelector
                phrases={settings.excludedWords}
                onChange={dummyEventHandler}
                isDisabled={false}
                canEdit={false}
              />
            </>
          )}
        </Text>
      </VStack>
    </VStack>
  )
}

export default ReadOnlyRedditSettings
