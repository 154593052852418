/*
Object defining the routes for easy refactoring
*/

import { SearchInputState } from '../../pages/discover'

const discoverRoute = '/discover'

const routes = {
  baseUrl: (): string => {
    if (window == undefined) {
      return 'https://signalist.co'
    }
    const { protocol, hostname, port } = window.location
    const portString = port === '' ? '' : `:${port}`
    return `${protocol}//${hostname}${portString}`
  },
  dashboard: (id: string, threadId?: string): string => {
    let route = `/signal/${id}`
    if (threadId) {
      route += `?thread=${threadId}`
    }
    return route
  },
  discover: discoverRoute,
  discoverWithParams: (params: Partial<SearchInputState>): string => {
    let paramsString = '?'
    paramsString += `q=${params.searchInput ?? ''}`
    paramsString += `&type=${params.searchType ?? ''}`
    paramsString += `&filterTags=${params.filterTags ?? ''}`
    paramsString += `&sortBy=${params.sortBy ?? ''}`
    return discoverRoute + paramsString
  },
  home: '/home',
  homeOriginals: '/home/originals',
  homeFollowing: '/home/following',
  landingPage: '/',
  profile: (username: string): string => `/${username}`,
  shareableDashboardLink: (id: string): string =>
    routes.baseUrl() + routes.dashboard(id),
  thirdPartyAuth: '/thirdpartyauth',
}

export default routes
