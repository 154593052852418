import React, { ChangeEvent, ReactElement } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Spacer,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'

import { UserInfoFieldProps } from './types'
import EmojiPicker from '../../general/EmojiPicker'

const BioField = ({ formik }: UserInfoFieldProps): ReactElement => {
  const debouncedValidation = useDebouncedCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      formik.validateField(e.target.name)
      formik.setStatus(undefined)
    },
    500
  )

  const handleFieldChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.name, undefined)
    formik.handleChange(e)
    formik.setFieldTouched(e.target.name)
    formik.setStatus('validating-errors')
    debouncedValidation(e)
  }

  const handleSelectEmoji = (emoji: string) => {
    formik.setFieldValue('bio', formik.values.bio + emoji, true)
    // formik.handleChange(e)
    // formik.setFieldTouched(e.target.name)
    // formik.setStatus('validating-errors')
    // debouncedValidation(e)
  }
  return (
    <FormControl h="100%">
      <HStack align="right">
        <FormLabel>Bio</FormLabel>
        <Spacer />
        {formik.values.bio && (
          <Text fontSize="sm" colorScheme="gray">
            {formik.values.bio.length} / 240
          </Text>
        )}
      </HStack>
      <VStack
        w="100%"
        align="middle"
        p={'5px'}
        borderWidth="1px"
        _focusWithin={{
          borderColor: 'blue.400',
          borderWidth: '2px',
          padding: '4px',
        }}
        borderRadius={5}
        spacing={0}
      >
        <Textarea
          id="bio"
          name="bio"
          spellCheck={false}
          placeholder="What interests you?"
          onChange={handleFieldChange}
          value={formik.values.bio}
          maxLength={240}
          borderWidth="0px"
          h="100%"
          maxH={'12'}
          borderRadius="md"
          px={2}
          variant="unstyled"
          overflowY={'scroll'}
          resize="none"
          display={'block'}
        />
        <HStack>
          <Spacer />
          <EmojiPicker onSelectEmoji={handleSelectEmoji} />
        </HStack>
      </VStack>

      {Boolean(formik.errors.bio) && formik.touched.bio && (
        <FormHelperText color="red">{formik.errors.bio}</FormHelperText>
      )}
    </FormControl>
  )
}

export default BioField
