import { ChangeEvent, ReactElement } from 'react'
import { FaCheck, FaExclamation } from 'react-icons/fa'
import { useDebouncedCallback } from 'use-debounce'

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react'

import checkUsernameAvailability from '../../../utils/auth/checkUsernameAvailability'
import { UserInfoFieldProps } from './types'

const UsernameCheck = ({ formik }: UserInfoFieldProps): ReactElement => {
  if (!formik.touched.username) {
    return <></>
  }
  if (formik.values.username.length == 0) {
    return <FaExclamation color="red" />
  }
  if (formik.status == 'validating-username') {
    return <Spinner size="sm" />
  }

  if (
    formik.values.username == formik.initialValues.username &&
    formik.values.username !== ''
  ) {
    return <FaCheck color="green" />
  }

  if (Boolean(formik.errors.username)) {
    return <FaExclamation color="red" />
  }

  return <FaCheck color="green" />
}

const UsernameError = ({ formik }: UserInfoFieldProps): ReactElement => {
  if (!formik.touched.username) {
    return <FormHelperText>No special characters</FormHelperText>
  }

  if (
    formik.values.username == formik.initialValues.username &&
    formik.values.username !== ''
  ) {
    return <FormHelperText>No special characters</FormHelperText>
  }

  if (formik.errors.username == 'unavailable') {
    return (
      <FormHelperText color="red">
        @{formik.values.username} is not available
      </FormHelperText>
    )
  }

  if (Boolean(formik.errors.username)) {
    return <FormHelperText color="red">{formik.errors.username}</FormHelperText>
  }

  return <FormHelperText>No special characters</FormHelperText>
}

const UsernameField = ({ formik }: UserInfoFieldProps): ReactElement => {
  const debouncedValidation = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      formik.validateField(e.target.name)
      if (e.target.value) {
        checkUsernameAvailability(e.target.value).then((available) => {
          if (!available) {
            formik.setFieldError('username', 'unavailable')
            formik.setStatus(undefined)
          }
          formik.setStatus(undefined)
        })
      }
    },
    500
  )

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldError(e.target.name, undefined)
    formik.handleChange(e)
    formik.setFieldTouched(e.target.name)
    formik.setStatus('validating-username')
    debouncedValidation(e)
  }

  return (
    <FormControl>
      <FormLabel>Username</FormLabel>
      <InputGroup>
        {/* <InputLeftElement children="@" /> */}
        <Input
          id="username"
          name="username"
          spellCheck={false}
          onChange={(e) => {
            handleFieldChange(e)
            // debounceUsername(e.target.value)
          }}
          value={formik.values.username}
          maxLength={30}
          // variant="outline"
        />

        <InputRightElement>
          <UsernameCheck formik={formik} />
        </InputRightElement>
      </InputGroup>

      <UsernameError formik={formik} />
    </FormControl>
  )
}

export default UsernameField
