import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { Box, Button, HStack, Spacer, Textarea, VStack } from '@chakra-ui/react'

import { useCurrentUser } from '../../../context/user/context'
import firebase from '../../../utils/firebase/firebase'
import useIsFollowingDashboard from '../../../utils/hooks/useIsFollowingDashboard'
import { useOutsideListener } from '../../../utils/hooks/useOutsideListener'
import EmojiPicker from '../../general/EmojiPicker'
import { CommentData } from './types'

interface Props {
  isThread: boolean
  dashboardId: string | undefined
  comment: CommentData | undefined
}

const CommentTextBox = ({
  isThread,
  dashboardId,
  comment = undefined,
}: Props): ReactElement => {
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useCurrentUser()
  const isLoggedIn = user?.uid != undefined
  const isFollowing = useIsFollowingDashboard(dashboardId ?? '')

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [textAreaHeight, setTextAreaHeight] = useState(0)
  useEffect(() => {
    setTextAreaHeight(textAreaRef.current?.scrollHeight ?? 30)
  }, [textAreaRef.current?.scrollHeight])

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value)
  }

  const handleClickAway = () => {
    if (input === '') {
      setTextAreaHeight(0)
    }
  }

  useOutsideListener(textAreaRef, handleClickAway)

  const handlePostComment = async (text: string) => {
    const cloudFunctions = firebase.functions()
    const postCommentFunction = cloudFunctions.httpsCallable('comments-post')

    setIsLoading(true)
    try {
      await postCommentFunction({
        text,
        dashboardId,
      })
      setInput('')
    } catch (e) {
      console.error('Error refreshing:')
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePostReply = async (
    text: string,
    parentCommentId: string | undefined,
    parentCommentAuthorId: string | undefined,
    dashboardId: string | undefined
  ) => {
    const cloudFunctions = firebase.functions()
    const postReplyFunction = cloudFunctions.httpsCallable('comments-post')

    setIsLoading(true)
    try {
      await postReplyFunction({
        text: text,
        dashboardId: dashboardId,
        parentCommentId: parentCommentId,
        parentCommentAuthorId: parentCommentAuthorId,
      })
      setInput('')
    } catch (e) {
      console.error('Error refreshing:')
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  // const onSubmit = isThread ? handlePostReply : handlePostComment

  const handleSelectEmoji = (emoji: string) => {
    setInput(input + emoji)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey && input != '' && input != '\n') {
        handlePostComment(input)
      } else if (e.shiftKey) {
        setInput(input + '\n')
      }
    }
  }

  const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const cannotComment = !isFollowing || !isLoggedIn

  const placeholderText = !isLoggedIn
    ? 'Log in and follow this dashboard to join the discussion.'
    : !isFollowing
    ? 'Follow this dashboard to join the discussion.'
    : 'Join the discussion...'

  return (
    <Box w="100%" maxH="20rem" p="2">
      <VStack
        w="100%"
        align="middle"
        p={'5px'}
        borderWidth="1px"
        _focusWithin={{
          borderColor: 'blue.400',
          borderWidth: '2px',
          padding: '4px',
        }}
        borderRadius={5}
        spacing={0}
        minH="3.5rem"
        maxH="10rem"
      >
        <Textarea
          ref={textAreaRef}
          borderWidth="0px"
          borderRadius="md"
          px={2}
          variant="unstyled"
          placeholder={placeholderText}
          value={input}
          onChange={handleInputChange}
          onSubmit={() =>
            isThread
              ? handlePostReply(
                  input,
                  comment?.id,
                  comment?.author.id,
                  dashboardId
                )
              : handlePostComment(input)
          }
          onKeyUp={handleKeyPress}
          overflowY={'scroll'}
          resize="none"
          display={'block'}
          rows={
            textAreaHeight > 38 ? 1 + Math.ceil((textAreaHeight - 38) / 12) : 1
          }
          onKeyDown={handleEnter}
          isDisabled={cannotComment}
        ></Textarea>
        <HStack w="100%" pr={1} h="2.5rem">
          <EmojiPicker
            isDisabled={cannotComment}
            onSelectEmoji={handleSelectEmoji}
            placement={'left'}
            popoverMargin={5}
          />
          <Spacer />
          <Button
            aria-label="Submit comment"
            colorScheme={'blackAlpha'}
            bg="black"
            size="sm"
            onClick={() =>
              isThread
                ? handlePostReply(
                    input,
                    comment?.id,
                    comment?.author.id,
                    dashboardId
                  )
                : handlePostComment(input)
            }
            isLoading={isLoading}
            isDisabled={cannotComment}
          >
            Post
          </Button>
        </HStack>
      </VStack>
    </Box>
  )
}

export default CommentTextBox
