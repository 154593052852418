import { ChangeEvent, ReactElement } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react'

import { SignUpFieldProps } from './types'

const SignUpEmail = ({ formik }: SignUpFieldProps): ReactElement => {
  const debouncedValidation = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      formik.validateField(e.target.name)
      formik.setStatus(undefined)
    },
    500
  )

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldError(e.target.name, undefined)
    formik.handleChange(e)
    formik.setFieldTouched(e.target.name)
    formik.setStatus('validating-errors')
    debouncedValidation(e)
  }

  return (
    <FormControl>
      <FormLabel>Email</FormLabel>
      <Input
        id="email"
        name="email"
        onChange={handleFieldChange}
        value={formik.values.email}
        spellCheck={false}
        maxLength={30}
        variant="outline"
        w="100%"
      />

      {Boolean(formik.errors.email) && formik.touched.email && (
        <FormHelperText color="red">{formik.errors.email}</FormHelperText>
      )}
    </FormControl>
  )
}

export default SignUpEmail
