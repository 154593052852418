// Button to link the user's Twitter account

import { ReactElement } from 'react'
import firebase from 'firebase/app'
import { TwitterToken } from '../types'
import TwitterActionButton from './TwitterActionButton'

interface Props {
  onUpdateToken: (token: TwitterToken) => void
  onError?: (errorMessage: string) => void
}

const TwitterAuthButton = ({ onUpdateToken, onError }: Props): ReactElement => {
  const handleTwitterAuth: () => void = async () => {
    const currentUser = firebase.auth().currentUser
    if (currentUser == undefined) {
      throw new Error('firebase auth currentUser is undefined')
    }
    const providerIds = currentUser.providerData.map(
      (provider) => provider?.providerId
    )
    const hasLinkedTwitter = providerIds.includes('twitter.com')
    const provider = new firebase.auth.TwitterAuthProvider()
    provider.setCustomParameters({
      display: 'popup',
    })

    const successHandler = async (authResult: firebase.auth.UserCredential) => {
      const credential =
        authResult.credential as firebase.auth.OAuthCredential | null
      if (credential == undefined) {
        throw new Error('Twitter credential is undefined')
      }
      const { accessToken, secret } = credential

      const twitterUserInfo = authResult.additionalUserInfo
      const username = twitterUserInfo?.username
      const twitterProfile = authResult.additionalUserInfo?.profile as Record<
        string,
        unknown
      >
      const twitterId = twitterProfile.id_str as string

      if (accessToken != undefined && secret != undefined) {
        await onUpdateToken({
          type: 'twitter',
          title: 'Twitter',
          twitterId,
          username: username != undefined ? `@${username}` : undefined,
          connectedDate: firebase.firestore.Timestamp.fromDate(new Date()),
          accessToken,
          secret,
        })
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorHandler = (error: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const messageForError = (error: any): string => {
        const code = error.code

        const accountInUseMessage =
          'The Twitter account you attempted to link is already being used by another Signalist account. Please use a different Twitter account.'

        switch (code) {
          case 'auth/credential-already-in-use':
            return accountInUseMessage
          case 'auth/user-mismatch':
            return accountInUseMessage
          default:
            return code.message
        }
      }
      if (onError != undefined) {
        onError(messageForError(error))
      }
      console.error(error)
    }

    if (hasLinkedTwitter) {
      await currentUser
        .reauthenticateWithPopup(provider)
        .then(successHandler)
        .catch(errorHandler)
    } else {
      await currentUser
        .linkWithPopup(provider)
        .then(successHandler)
        .catch(errorHandler)
    }
  }

  return (
    <TwitterActionButton
      title="Sign in with Twitter"
      onClick={handleTwitterAuth}
    />
  )
}

export default TwitterAuthButton
