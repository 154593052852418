import { FormControl, Input, FormHelperText, FormLabel } from '@chakra-ui/react'
import { ReactElement, ChangeEvent } from 'react'
import { SignUpFieldProps } from './types'
import PasswordInputGroup from './PasswordInputGroup'
import { useDebouncedCallback } from 'use-debounce'
import { passwordRequirementText } from '../utils'

const SignUpPassword = ({ formik }: SignUpFieldProps): ReactElement => {
  const debouncedValidation = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      formik.validateField(e.target.name)
      formik.setStatus(undefined)
    },
    500
  )

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldError(e.target.name, undefined)
    formik.handleChange(e)
    formik.setFieldTouched(e.target.name)
    formik.setStatus('validating-errors')
    debouncedValidation(e)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      formik.handleSubmit()
    }
  }

  return (
    <FormControl>
      <FormLabel>Password</FormLabel>
      <PasswordInputGroup>
        {(showPassword) => (
          <Input
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            onChange={handleFieldChange}
            onKeyDown={handleKeyDown}
            value={formik.values.password}
            maxLength={30}
            variant="outline"
            w="100%"
          />
        )}
      </PasswordInputGroup>

      <FormHelperText
        color={
          Boolean(formik.errors.password) && formik.touched.password
            ? 'red'
            : 'gray'
        }
      >
        {passwordRequirementText}
      </FormHelperText>
    </FormControl>
  )
}

export default SignUpPassword
