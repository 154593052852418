/*
  Second, more detailed view of the stock widget – allows the user to toggle
  historical data and switch between symbols
*/

import React, { ReactElement, useCallback } from 'react'

import { Grid, GridItem } from '@chakra-ui/react'

import VerticalDrawer from '../../../layout/VerticalDrawer'
import ExpandedInfo from './ExpandedInfo'
import StockSelectionSidebar from './StockSelectionSidebar'
import { StockWidgetDisplay } from '../types'

interface Props {
  isOpen: boolean
  onClose: () => void
  stockData: StockWidgetDisplay | undefined
  onSelectStock: (stock: StockWidgetDisplay) => void
  stocks: StockWidgetDisplay[] | undefined
}

const StockDrawer = ({
  isOpen,
  onClose,
  stockData,
  onSelectStock,
  stocks,
}: Props): ReactElement => {
  const shiftSelection = useCallback(
    (direction: 'next' | 'prev') => {
      if (stocks == undefined || stockData == undefined) return
      const currIdx = stocks.findIndex(
        (stock) => stockData.info.symbol === stock.info.symbol
      )
      const delta = direction === 'next' ? 1 : -1
      const newIdx = (stocks.length + currIdx + delta) % stocks.length
      onSelectStock(stocks[newIdx])
    },
    [onSelectStock, stockData, stocks]
  )

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (!isOpen) return
      e.preventDefault()
      const key = e.key
      if (key === 'ArrowDown' || key === 'ArrowRight') {
        shiftSelection('next')
      } else if (key === 'ArrowUp' || key === 'ArrowLeft') {
        shiftSelection('prev')
      }
    },
    [isOpen, shiftSelection]
  )

  React.useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)

    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyDownHandler])

  if (stockData == undefined || stocks == undefined) {
    return <></>
  }

  return (
    <VerticalDrawer
      isOpen={isOpen}
      onClose={onClose}
      padding={10}
      paddingTop={8}
    >
      <Grid
        templateColumns="repeat(6, 1fr)"
        width="100%"
        h="100%"
        gap={12}
        maxHeight="100%"
      >
        <GridItem w="100%" colSpan={2}>
          <StockSelectionSidebar
            stocks={stocks}
            selectedSymbol={stockData.info.symbol}
            onSelectStock={onSelectStock}
          />
        </GridItem>
        <GridItem colSpan={4} w="100%" overflowY="scroll">
          <ExpandedInfo stockData={stockData} />
        </GridItem>
      </Grid>
    </VerticalDrawer>
  )
}

export default StockDrawer
