/**
 * Form for editing a news widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import { ReactElement } from 'react'

import { Text, VStack } from '@chakra-ui/react'

import PhraseSelector from '../../twitter/TwitterSettings/MultiPhraseSelector'
import { NewsWidgetSettings } from '../types'
import RadioCards from './RadioCards'
// import SourceSelection from './SourceSelectionV2'

// TODO: Add 'domains' filter, both include and exclude, under advanced settings

interface Props {
  settings: NewsWidgetSettings
}

const ReadOnlyNewsSettings = ({ settings }: Props): ReactElement => {
  const availableCategories = [
    'World',
    'Finance',
    'Politics',
    'Business',
    'Economics',
    'Entertainment',
    'Tech',
    'Beauty',
    'Travel',
    'Music',
    'Food',
    'Sports',
    'Science',
    'General',
  ]

  const dummyEventHandler = () => {
    return
  }

  return (
    <VStack align="start" spacing="8" w="100%">
      {settings.category != '' && (
        <RadioCards
          choices={availableCategories}
          selectedChoice={settings.category}
          onCardSelection={() => {
            return
          }}
          canEdit={false}
        />
      )}
      {/* {settings.sources.length > 0 && (
        <SourceSelection
          phrases={settings.sources}
          onChange={() => {
            return
          }}
          isDisabled={true}
          canEdit={false}
          onCurrentInputChange={() => {
            return
          }}
          allowTyping={false}
          validateInput={() => {
            return undefined
          }}
        />
      )} */}
      <VStack align="start" spacing={6} w="100%">
        {/* {filtersCharacterCount >= 100 && (
               <Text flexGrow={2} textAlign="end">{`${Math.min(
                 filtersCharacterCount,
                 200
               )}/200 characters`}</Text>
             )} */}

        <VStack align="stretch" w="100%">
          <Text>
            {settings.requiredWords.length > 0 && (
              <>
                <Text as="b">Including</Text> news articles with{' '}
                <Text as="b">{settings.requiredWordsJoiner}</Text> of these
                words:{' '}
                <PhraseSelector
                  phrases={settings.requiredWords}
                  onChange={dummyEventHandler}
                  isDisabled={false}
                  canEdit={false}
                />
              </>
            )}
          </Text>
        </VStack>
        <VStack align="stretch" w="100%">
          <Text>
            {settings.excludedWords.length > 0 && (
              <>
                <Text as="b">Filtering out</Text> news articles with{' '}
                <Text as="b">{settings.excludedWordsJoiner}</Text> of these
                words:{' '}
                <PhraseSelector
                  phrases={settings.excludedWords}
                  onChange={dummyEventHandler}
                  isDisabled={false}
                  canEdit={false}
                />
              </>
            )}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default ReadOnlyNewsSettings
