import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { ConnectedAccount } from '../../components/settings/ManageConnectedAccounts'
import firebase from '../../utils/firebase/firebase'
import db from '../../utils/firebase/firestore'
import { User } from './types'

export interface UserContextType {
  connectedAccounts: ConnectedAccount[]
  handleFollowDashboard: (id: string) => Promise<void>
  handleUnfollowDashboard: (id: string) => Promise<void>
  user: User | undefined
  signOut: () => Promise<void>
}

export const UserContext = createContext<UserContextType>({
  connectedAccounts: [],
  handleFollowDashboard: async () => {
    return
  },
  handleUnfollowDashboard: async () => {
    return
  },
  user: undefined,
  signOut: (): Promise<void> => firebase.auth().signOut(),
})

export const useCurrentUser = (): UserContextType => useContext(UserContext)

const UserContextProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [authedUserUid, setAuthedUserUid] = useState<string | undefined>(
    undefined
  )
  const [user, setUser] = useState<User | undefined>(undefined)
  const [connectedAccounts, setConnectedAccounts] = useState<
    ConnectedAccount[]
  >([])

  const handleUnfollowDashboard = useCallback(
    async (id: string) => {
      if (authedUserUid == undefined) {
        return
      }
      await db.doc(`users/${authedUserUid}`).update({
        dashboardIdsInOrder: firebase.firestore.FieldValue.arrayRemove(id),
        followedDashboards: firebase.firestore.FieldValue.arrayRemove(id),
      })
    },
    [authedUserUid]
  )

  const handleFollowDashboard = useCallback(
    async (id: string) => {
      if (user == undefined) {
        return
      }
      // add to followed dashboards
      await db.doc(`users/${user.uid}`).update({
        dashboardIdsInOrder: [id, ...user.dashboardIdsInOrder],
        followedDashboards: firebase.firestore.FieldValue.arrayUnion(id),
      })
    },
    [user]
  )

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser != null) {
        firebase.analytics().setUserId(authUser.uid)
        setAuthedUserUid(authUser.uid)
      } else {
        setUser(undefined)
      }
    })
    return unsubscribe
  }, [])

  const signOut = useCallback(async (): Promise<void> => {
    // if (router.pathname != '/') {
    //   router.push('/')
    // }
    if (firebase.auth().currentUser == null) {
      return
    }
    return firebase.auth().signOut()
  }, [])

  useEffect(() => {
    if (authedUserUid == undefined) {
      setUser(undefined)
      // signOut()
      return
    }
    const unsubscribeUserListener = db
      .doc(`users/${authedUserUid}`)
      .onSnapshot((userDoc) => {
        const user = userDoc.data() as User | undefined

        setUser(user)
      })

    const unsubscribeConnectedAccountsListener = db
      .collection(`users/${authedUserUid}/connectedAccounts`)
      .onSnapshot((snapshot) => {
        const accounts = snapshot.docs.map((doc) => {
          return doc.data() as ConnectedAccount
        })
        setConnectedAccounts(accounts)
      })
    return () => {
      unsubscribeUserListener()
      unsubscribeConnectedAccountsListener()
    }
  }, [authedUserUid, signOut])

  return (
    <UserContext.Provider
      value={{
        connectedAccounts,
        handleFollowDashboard,
        handleUnfollowDashboard,
        user,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
