/**
 * A single stock ticker option rendered upon searching the ticker async
 * select in stock settings
 */

import React, { ReactElement } from 'react'

import { Box, HStack, Text, VStack } from '@chakra-ui/react'

import { IEXCloudSymbol } from '../types'

interface Props {
  ticker: IEXCloudSymbol
  onSelect: () => void
}
const TickerOptionDisplay = ({ ticker, onSelect }: Props): ReactElement => {
  const { name, symbol, exchangeName } = ticker

  return (
    <Box
      _hover={{
        cursor: 'pointer',
        backgroundColor: 'gray.300',
      }}
      onClick={onSelect}
      p={2}
      maxW="100%"
    >
      <VStack align="start" w="100%" spacing={0} maxW="100%">
        <Text textOverflow="ellipsis" w="max-content" maxW="80%" as="b">
          {name}
        </Text>
        <HStack spacing="1" maxW="100%">
          <Text textColor="gray.500" overflowX="hidden" noOfLines={1}>
            {symbol} &bull; {exchangeName}
          </Text>
          {/* <Text textColor="#657786" overflowX="hidden"></Text> */}
        </HStack>
      </VStack>
    </Box>
  )
}

export default TickerOptionDisplay
