import { DateTime } from 'luxon'

const convertUnixToFormattedDate = (unixTimeInMilliseconds: number): string => {
  const date = DateTime.fromMillis(unixTimeInMilliseconds)
  const now = DateTime.now()
  const isToday = date.toISODate() === now.toISODate()
  const isYesterday = date.toISODate() === now.minus({ days: 1 }).toISODate()
  const isThisYear = date.year === now.year
  const numDaysAgo = Math.abs(date.diffNow('days').days)

  const time = date.toLocaleString(DateTime.TIME_SIMPLE)

  if (isToday) return `Today at ${time}`
  if (isYesterday) return `Yesterday at ${time}`
  if (numDaysAgo < 6)
    return `${date.toLocaleString({ weekday: 'long' })} at ${time}`
  if (isThisYear) return date.toLocaleString({ month: 'long', day: '2-digit' })
  return date.toLocaleString({
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export default convertUnixToFormattedDate
