import { useRouter } from 'next/router'
import React, { ReactElement } from 'react'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Divider,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../../../context/auth-modal/context'
import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'
import { useDashboardSettingsModalContext } from '../../../../context/dashboard-settings-modal/context'
import { useCurrentUser } from '../../../../context/user/context'
import convertUnixToFormattedDate from '../../../../utils/general/convertUnixToFormattedDate'
import { goToProfile } from '../../../../utils/general/goToProfile'
import DefaultAvatar from '../../../general/DefaultAvatar'
import { MARGIN } from '../../../layout/navigation/utils'
import { DashboardAuthor } from '../DashboardDisplayV3'
import Link from 'next/link'
interface Props {
  actionButtons: ReactElement
  author: DashboardAuthor
  clonedFrom:
    | {
        id: string
        title: string
      }
    | undefined

  dashboardId: string
  description: string
  followerCount: number
  refreshed: number | undefined
  created: number | undefined
  tags: string[]
  title: string
  isFollowing: boolean
  onFollow: () => void
}

const DashboardHeader = ({
  actionButtons,
  author,
  followerCount,
  refreshed,
  title,
  isFollowing,
  onFollow,
}: Props): ReactElement => {
  // const actionButtonsAlignment = useBreakpointValue({
  //   base: 'center',
  //   md: 'flex-end',
  // })

  const { user } = useCurrentUser()
  const router = useRouter()

  const userId = user?.uid
  const isAuthor = userId != null && author?.id === userId

  const { openAuthModal } = useAuthModalContext()

  const avatar = (
    <DefaultAvatar
      size="xs"
      user={author}
      onClick={() => goToProfile(router, author.username)}
    />
  )

  const { openSettings: handleOpenSettingsModal } =
    useDashboardSettingsModalContext()

  const { selectedDashboardId } = useDashboardNavigationContext()

  const handleOpenSettings = () =>
    handleOpenSettingsModal(selectedDashboardId ?? '')

  // const createdTimeAsFormattedString =
  //   created != undefined ? convertUnixToFormattedDate(created) : ''
  const refreshedTimeString =
    refreshed != undefined ? convertUnixToFormattedDate(refreshed) : undefined

  const [isMobile] = useMediaQuery('(max-width: 840px)')

  const dummyEventHandler = () => {
    return
  }

  return (
    <Box w="100%">
      <Flex w="100%">
        <VStack align={'flex-start'} w="100%">
          {
            // if not following (either not logged in or logged in and not following)
            // display alert banner
            !isFollowing && (
              <Alert status="warning" px={6}>
                <AlertIcon />
                <AlertTitle mr={2}>Old data!</AlertTitle>
                <AlertDescription>
                  {user == undefined ? (
                    <>
                      <Text
                        as="span"
                        color="blue.500"
                        _hover={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => openAuthModal('logIn')}
                      >
                        Log in
                      </Text>{' '}
                      or{' '}
                      <Text
                        as="span"
                        color="blue.500"
                        _hover={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => openAuthModal('signUp')}
                      >
                        sign up
                      </Text>{' '}
                      to refresh.
                    </>
                  ) : (
                    <>
                      <Text
                        onClick={onFollow}
                        as="span"
                        color="blue.500"
                        _hover={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        Follow this dashboard
                      </Text>{' '}
                      to refresh.
                    </>
                  )}
                </AlertDescription>
              </Alert>
            )
          }
          <Box
            w="100%"
            pb={0}
            px={isMobile ? 10 : 20}
            pt={isFollowing && !isMobile ? MARGIN - 2 : 2}
          >
            <Flex w="100%" p={0} m={0}>
              <Flex w="100%" h="100%" align="stretch">
                <Text
                  as="span"
                  onClick={isAuthor ? handleOpenSettings : dummyEventHandler}
                  _hover={{ cursor: isAuthor ? 'pointer' : 'default' }}
                  fontSize={30}
                  fontWeight={'medium'}
                  m={0}
                >
                  <Text as="span" mr={3}>
                    {title}
                  </Text>
                  {/* {isMobile && (
                    <Icon
                      fontSize={21}
                      _hover={{ cursor: 'pointer' }}
                      onClick={toggleExpanded}
                      top={'0px'}
                      pos="relative"
                      display={'inline'}
                    >
                      <AiOutlineInfoCircle />
                    </Icon>
                  )} */}
                </Text>
              </Flex>
              <Spacer />
              <Flex align={'flex-start'} pos="relative" top={'7px'}>
                {actionButtons}
              </Flex>
            </Flex>
            <Flex w="100%" mb={2}>
              {author != null && (
                <HStack spacing="0" pt="1">
                  {avatar}
                  <Box
                    pl="2"
                    pr="1"
                    as="a"
                    _hover={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    color="gray.800"
                    fontSize="sm"
                    zIndex={1000}
                  >
                    <Link href={`/${author.username}`}>
                      {author.displayName}
                    </Link>
                  </Box>
                  <Text as="a" color="gray.800" fontSize="sm">
                    &bull;{' '}
                    {`${followerCount} ${
                      followerCount == 1 ? 'follower' : 'followers'
                    }`}
                  </Text>
                </HStack>
              )}
              <Spacer />
              {refreshedTimeString != null && (
                <Text
                  fontSize={'sm'}
                  color={'black'}
                  pr="3"
                  pt="2"
                  align="end"
                  w="max-content"
                >
                  {`Refreshed `}
                  <b>{refreshedTimeString}</b>
                </Text>
              )}
            </Flex>

            {/* {clonedFrom != null && (
                    <WrapItem maxW="100%">
                      <HStack spacing={2} h="20px">
                        <FaRegClone size="1em" />
                        <Text
                          as="span"
                          display={'inline-block'}
                          fontSize="0.8rem"
                        >
                          Cloned from{' '}
                          <Link href={`/?dashboard=${clonedFrom.id}`} passHref>
                            <Text
                              as="span"
                              _hover={{ cursor: 'pointer' }}
                              color="brand.pageLink"
                              fontSize="0.8rem"
                            >
                              {clonedFrom.title}
                            </Text>
                          </Link>
                        </Text>
                      </HStack>
                    </WrapItem>
                  )} */}
            {/* {isAuthor && thingsToAddString != '' && (
                <Text
                  color="gray.400"
                  fontSize="0.8rem"
                  _hover={{ cursor: 'pointer' }}
                  onClick={handleOpenSettingsModal}
                  my={2}
                >
                  {thingsToAddString}
                </Text>
              )} */}
            <Divider borderWidth="1px" borderColor="gray.300" />
          </Box>
        </VStack>
      </Flex>
    </Box>
  )
}

export default DashboardHeader
