/*
News widget that takes in news data & displays summaries &
scrollable news article sources
*/

import { DateTime } from 'luxon'
import React, { ReactElement, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { Container, Divider, VStack } from '@chakra-ui/react'

import { useMobileAlertBannerContext } from '../../../context/mobile-alert-banner/context'
import firebase from '../../../utils/firebase/firebase'
import db from '../../../utils/firebase/firestore'
import NullWidget from '../NullWidget'
import NewsItem from './NewsDisplay/NewsItem'
import ReaderView from './NewsDisplay/ReaderView'
import {
  Article,
  ArticleReader,
  ExpandedArticle,
  NewsWidget,
  NewsWidgetData,
} from './types'

// import { useFirstMountState } from 'react-use'

interface NewsWidgetProps {
  widget: NewsWidget
  data: NewsWidgetData | undefined
  onEditWidget: () => void
}

const concatByline = (article: Article | null): string => {
  if (article == null) {
    return ''
  }

  let byline = ''

  if (article.published_date) {
    const date = DateTime.fromISO(article.published_date.split(' ').join('T'), {
      zone: 'America/New_York',
    })

    byline = byline.concat(
      '',
      date.toLocaleString({
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      }) + ' · '
    )
  }

  // if (article.author) {
  //   byline = byline.concat('', `${article.author} · `)
  // }

  if (article.clean_url) {
    byline = byline.concat('', `${article.clean_url}`)
  }

  return byline
}

const NewsWidgetView = ({ data }: NewsWidgetProps): ReactElement => {
  const { openBanner } = useMobileAlertBannerContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCancelled, setIsCancelled] = useState<boolean>(false)
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(
    undefined
  )
  const [expandedArticle, setExpandedArticle] = useState<
    ExpandedArticle | undefined
  >(undefined)
  const [byline, setByline] = useState<string>('')

  // const isFirstMount = useFirstMountState()

  // TODO: Move majority of this to cloud function
  const fetchArticle = async (article: Article) => {
    // Expand this list
    const paywalled = ['medium.com', 'wsj.com']

    try {
      // Exit for paywalled sites
      if (paywalled.includes(article.clean_url)) {
        setExpandedArticle({
          article: article,
          reader: undefined,
        })
      } else {
        // Check if article in cache
        const articleDoc = await db
          .collection('articles')
          .doc(article._id)
          .get()

        // Found article in cache
        if (articleDoc.exists) {
          const articleContent = articleDoc.data() as ArticleReader

          setExpandedArticle({
            article: article,
            reader: articleContent,
          })
        } else {
          // Not found in cache
          const cloudFunctions = firebase.functions()
          const scrapeArticle = cloudFunctions.httpsCallable('readers-article')

          const response = await scrapeArticle({
            query: article.link,
          })

          const articleContent: ArticleReader = response.data

          // No returned content
          if (articleContent == null) {
            setExpandedArticle({
              article: article,
              reader: undefined,
            })
          }

          // Returned content – add to cache, display
          else {
            await db
              .collection('articles')
              .doc(article._id)
              .set({
                ...articleContent,
              })
            setExpandedArticle({
              article: article,
              reader: articleContent,
            })
          }
        }
      }
    } catch (error) {
      setExpandedArticle({
        article: article,
        reader: undefined,
      })
    }

    setIsLoading(false)
  }

  const handleExpandArticle = (article: Article) => {
    setIsLoading(true)
    setIsCancelled(false)
    setSelectedArticle(article)

    fetchArticle(article)
    setByline(concatByline(article))
  }

  const handleCloseArticle = () => {
    setIsLoading(false)
    setIsCancelled(true)
    setExpandedArticle(undefined)
    setByline('')
  }

  const handleClickArticle = (article: Article) => {
    if (isMobile) {
      openBanner('newsWidget')
    } else {
      handleExpandArticle(article)
    }
  }
  if (data == undefined || data.articles.length === 0) {
    return <NullWidget emojis="🧐 📰" />
  }

  return (
    <Container centerContent={true} minW="100%" padding="0">
      <ReaderView
        isOpen={!isCancelled && (isLoading || expandedArticle != null)}
        onClose={handleCloseArticle}
        isLoading={isLoading}
        expandedArticle={expandedArticle}
        selectedArticle={selectedArticle}
        byline={byline}
      />

      <VStack
        p="0"
        w="100%"
        divider={<Divider borderColor="gray.300" w="95%" />}
        spacing={0}
      >
        {data?.articles.map((article) => {
          return (
            <NewsItem
              key={article._id}
              handleExpandArticle={handleClickArticle}
              article={article}
              byline={concatByline(article)}
            />
          )
        })}
      </VStack>
    </Container>
  )
}

export default NewsWidgetView
