/**
 * Form for editing a Twitter widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import React, { ReactElement } from 'react'

import { Checkbox, CheckboxGroup, HStack, Text, VStack } from '@chakra-ui/react'

import { TwitterWidgetSettings } from '../types'
import PhraseSelector from './MultiPhraseSelector'
import UserSelection from './UserSelection'

interface Props {
  settings: TwitterWidgetSettings
}

const ReadOnlyTwitterSettings = ({ settings }: Props): ReactElement => {
  const dummyEventHandler = () => {
    return
  }

  return (
    <VStack align="start" spacing={7}>
      {settings.authorIds.length > 0 && (
        <VStack align="start" w="100%">
          <Text as="b">Twitter users:</Text>
          <UserSelection
            userIds={settings.authorIds}
            onChangeUserIds={dummyEventHandler}
            canEdit={false}
          />
        </VStack>
      )}

      <VStack align="start" w="100%">
        <Text as="b">Included Tweet Types </Text>
        <CheckboxGroup
          value={settings.allowedTweetTypes}
          onChange={dummyEventHandler}
        >
          <HStack spacing="5">
            {[
              { name: 'quote', label: 'Quote Tweets' },
              { name: 'reply', label: 'Replies' },
              { name: 'retweet', label: 'Retweets' },
            ].map(({ name, label }) => (
              <Checkbox key={name} value={name} _hover={{ cursor: 'default' }}>
                {label}
              </Checkbox>
            ))}
          </HStack>
        </CheckboxGroup>
      </VStack>
      <VStack align="stretch" w="100%">
        <Text>
          {settings.requiredWords.length > 0 && (
            <>
              <Text>
                <Text as="b">Including</Text> tweets with{' '}
                <Text as="b">{settings.requiredWordsJoiner}</Text> of these
                words:{' '}
              </Text>
              <PhraseSelector
                isDisabled={false}
                onChange={() => {
                  return
                }}
                phrases={settings.requiredWords}
                onCurrentInputChange={dummyEventHandler}
                canEdit={false}
              />
            </>
          )}
        </Text>
      </VStack>
      <VStack align="stretch" w="100%">
        <Text>
          {settings.excludedWords.length > 0 && (
            <>
              <Text as="b">Filtering out</Text>
              {'  '}tweets with{' '}
              <Text as="b">{settings.excludedWordsJoiner}</Text> of these words:{' '}
              <PhraseSelector
                phrases={settings.excludedWords}
                onChange={dummyEventHandler}
                isDisabled={false}
                canEdit={false}
              />
            </>
          )}
        </Text>
      </VStack>
    </VStack>
  )
}

export default ReadOnlyTwitterSettings
