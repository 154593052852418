import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { BsPersonFill } from 'react-icons/bs'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  HStack,
  Spacer,
  Text,
  Tooltip,
  VStack,
  Wrap,
} from '@chakra-ui/react'

import { useCurrentUser } from '../../context/user/context'
import { routes } from '../../utils/constants'
import useIsFollowingDashboard from '../../utils/hooks/useIsFollowingDashboard'
import { DashboardAuthor } from '../dashboard/dashboardDisplay/DashboardDisplayV3'
import DashboardTag from '../dashboard/dashboardDisplay/header/DashboardTag'
import DefaultAvatar from '../general/DefaultAvatar'
import WidgetCountPill from '../profile/DashboardCard/WidgetCountPill'
import { WidgetType } from '../widgets/types'

interface Props {
  author: DashboardAuthor
  created: Date
  description: string
  followerCount: number
  id: string
  isPublic: boolean
  hideAuthor?: boolean
  tags: string[]
  title: string
  widgetCounts: Record<WidgetType, number>
  suggestionModal?: boolean
}

function DashboardSearchResult({
  author,
  description,
  followerCount,
  id,
  hideAuthor = false,
  tags,
  title,
  widgetCounts,
  suggestionModal,
}: Props): ReactElement {
  const { user, handleFollowDashboard } = useCurrentUser()

  const router = useRouter()

  const isFollowing = useIsFollowingDashboard(id)
  const isAuthor = author.id === user?.uid

  const dashboardWidgetCounts = (
    Object.keys(widgetCounts ?? {}) as Array<WidgetType>
  )
    .map((widgetKey) => ({ type: widgetKey, count: widgetCounts[widgetKey] }))
    .filter(({ count }) => count > 0)
    .sort((a, b) => a.type.localeCompare(b.type))
    .sort((a, b) => b.count - a.count)

  const url = routes.dashboard(id)

  const handleClickTitle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (suggestionModal) window.open(url, '_blank')
    else router.push(url)
  }

  const handleClickUsername = () => {
    if (author?.username != undefined) {
      router.push(routes.profile(author.username))
    }
  }

  const numWidgets = dashboardWidgetCounts
    .map(({ count }) => count)
    .reduce((count, val) => val + count, 0)

  return (
    <VStack
      align="start"
      py={3}
      px={4}
      w="100%"
      h="min-content"
      spacing={1}
      // as="a"
      // href={url}
      // onClick={handleClickCard}
      // _hover={{
      //   bg: 'gray.50',
      // }}
    >
      <HStack w="100%" spacing={4} align={'flex-start'}>
        <Text
          as="a"
          fontSize="xl"
          fontWeight="600"
          onClick={handleClickTitle}
          href={url}
          _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {title}
          {suggestionModal && <ExternalLinkIcon pb="1.5" ml="0.5" />}
        </Text>

        <Text>
          {isFollowing && !isAuthor && (
            <Badge
              // color="white"
              // ml={1}
              bg="gray.200"
              color="black"
              mt={'3px'}
              // bg="brand.following"
              px={2}
              py={'4px'}
              fontSize="11px"
            >
              Following
            </Badge>
          )}
          {isAuthor && (
            <Badge
              //  ml={1}
              mt={'2px'}
              color="gray.200"
              bg="gray.700"
              px={2}
              py={'4px'}
              fontSize="11px"
            >
              Creator
            </Badge>
          )}
        </Text>

        <Spacer />
        <HStack spacing={4}>
          {suggestionModal && !(isFollowing || isAuthor) && (
            <Button
              size="sm"
              mr={1}
              onClick={() => handleFollowDashboard(id)}
              colorScheme="blue"
            >
              Follow
            </Button>
          )}
          <Tooltip
            label="Followers"
            m={1}
            hasArrow
            placement="bottom"
            openDelay={250}
          >
            <HStack spacing={1}>
              <BsPersonFill color="black" />
              <Text color="black">{followerCount}</Text>
            </HStack>
          </Tooltip>
        </HStack>
      </HStack>
      {!hideAuthor && author?.username != undefined && (
        <Text
          textAlign="center"
          color="brand.username"
          as="a"
          href={routes.profile(author.username)}
          onClick={handleClickUsername}
          _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          <Box as="span" mr={1.5}>
            <DefaultAvatar size="xs" user={author} />
          </Box>
          {author.displayName}
        </Text>
      )}

      <Text size="sm" align="start">
        {description}
      </Text>

      {(tags ?? []).length > 0 && (
        <Wrap pt={1}>
          {tags.map((tag) => (
            <DashboardTag tag={tag} key={tag} />
          ))}
        </Wrap>
      )}
      <Wrap pt={2}>
        {dashboardWidgetCounts.map(({ type, count }) => (
          <WidgetCountPill key={type} type={type} count={count} />
        ))}
        {numWidgets === 0 && (
          <Text color="gray.400" fontSize={14} fontStyle="oblique" pl={1}>
            No widgets
          </Text>
        )}
      </Wrap>
    </VStack>
  )
}

export default DashboardSearchResult
