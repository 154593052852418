/**
 * Form for editing a Reddit widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */
import React, { ReactElement } from 'react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Center, Flex } from '@chakra-ui/react'
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Text,
} from '@chakra-ui/react'

interface Props {
  value: 'any' | 'all'
  onSelect: (value: 'any' | 'all') => void
  canEdit: boolean
}

const AndOrDropdown = ({ value, onSelect, canEdit }: Props): ReactElement => {
  const getLabelFor = (value: 'any' | 'all') => {
    return value
    // switch (value) {
    //   case 'any':
    //     return 'include any'
    //   case 'all':
    //     return 'include all'
    // }
  }
  return (
    <Flex
      // mx={0.75}
      display="inline-block"
    >
      <Menu>
        <MenuButton
          as={Flex}
          px={0.75}
          py={1}
          align="start"
          transition="all 0.2s"
          color="blue.400"
          _hover={{ cursor: canEdit ? 'pointer' : 'auto' }}
          _expanded={{ color: 'blue.400' }}
          _focus={{ boxShadow: 'outline' }}
          _disabled={{
            _hover: { cursor: 'auto' },
            color: 'gray.500',
            borderColor: 'gray.500',
          }}
          w="3.5rem"
          display="flex"
        >
          <Box position="relative">
            <Text w="3rem" display="inline-block">
              <Center>{getLabelFor(value)}</Center>
            </Text>
            <ChevronDownIcon w="1rem" position="absolute" right={0} top={1.5} />
          </Box>
        </MenuButton>
        <MenuList minW="0">
          <MenuItemOption
            onClick={() => onSelect('any')}
            isChecked={value == 'any'}
          >
            {getLabelFor('any')}
          </MenuItemOption>
          <MenuItemOption
            onClick={() => {
              onSelect('all')
            }}
            isChecked={value == 'all'}
          >
            {getLabelFor('all')}
          </MenuItemOption>
        </MenuList>
      </Menu>
    </Flex>
  )
}

export default AndOrDropdown
