import firebase from '../../../utils/firebase/firebase'
import React, { ReactElement } from 'react'
import db from '../../../utils/firebase/firestore'
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import Image from 'next/image'
import { ConnectedAccount } from '.'

import RedditIcon from './../../../../public/roundedSocials/reddit.svg'
import TwitterIcon from './../../../../public/roundedSocials/twitter.svg'

interface Props {
  account: ConnectedAccount
}

const ConnectedAccountRow = ({ account }: Props): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const connectedDateString = account.connectedDate
    .toDate()
    .toLocaleDateString('default', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })

  const handleUnlink = async () => {
    const user = firebase.auth().currentUser
    if (user != undefined) {
      const deleteConnectedAccountPromise = db
        .doc(`users/${user.uid}/connectedAccounts/${account.type}`)
        .delete()
      let unlinkAuthProvider = null
      if (account.type === 'twitter') {
        unlinkAuthProvider = await user.unlink(
          firebase.auth.TwitterAuthProvider.PROVIDER_ID
        )
      }
      await Promise.all([deleteConnectedAccountPromise, unlinkAuthProvider])
    }
  }

  const connectedAccountIcons: Record<
    ConnectedAccount['type'],
    StaticImageData
  > = {
    reddit: RedditIcon,
    twitter: TwitterIcon,
  }

  return (
    <>
      <HStack spacing="4">
        <Image
          alt={`${account.title} icon`}
          height={56}
          loading="eager"
          src={connectedAccountIcons[account.type]}
          width={56}
        />
        <Flex grow={2}>
          <VStack align="start" spacing="0">
            <Text>
              <b>{account.title}</b> · {account.username}
            </Text>
            <Text color="gray.500">{`Connected ${connectedDateString}`}</Text>
          </VStack>
          <Spacer />
          <Button colorScheme="red" variant="outline" onClick={onOpen}>
            Unlink
          </Button>
        </Flex>
      </HStack>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unlink {account.title} account</ModalHeader>
          <ModalBody>
            <Text>{`If you unlink your ${account.title} account, you won't be able to use ${account.title} widgets. Are you sure?`}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleUnlink}>
              Unlink
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConnectedAccountRow
