import { ReactElement } from 'react'

import { HStack, Text, Tooltip, WrapItem } from '@chakra-ui/react'
import Image from 'next/image'
import RedditIcon from './../../../../public/redditWhite.svg'
import TwitterIcon from './../../../../public/twitterIconWhite.svg'
import NewsIcon from './../../../../public/news-emoji.png'
import StocksIcon from './../../../../public/stocks-emoji.png'

import { WidgetType } from '../../widgets/types'

interface Props {
  type: WidgetType
  count: number
}
const WidgetCountPill = ({ type, count }: Props): ReactElement => {
  interface PillStyle {
    icon: StaticImageData
    color: string
    textColor?: string
  }
  const pillStyleForWidgetType = (type: WidgetType): PillStyle => {
    switch (type) {
      // case 'crypto':
      //   return { icon: '', color: 'green.200' }
      case 'news':
        return { icon: NewsIcon, color: 'purple.200' }
      case 'reddit':
        return {
          icon: RedditIcon,
          color: '#FF4500',
          textColor: 'white',
        }
      case 'stocks':
        return { icon: StocksIcon, color: 'gray.200' }
      case 'twitter':
        return {
          icon: TwitterIcon,
          color: '#1DA1F2',
          textColor: 'white',
        }
    }
  }
  const { icon, color, textColor } = pillStyleForWidgetType(type)

  return (
    <Tooltip
      label={`${count} ${type} widget${count > 1 ? 's' : ''}`}
      m={1}
      hasArrow
      placement="bottom"
      openDelay={250}
    >
      <WrapItem bg={color} borderRadius="full" px={3} py={1} minW="initial">
        <HStack spacing={2.5}>
          <Image
            loading="eager"
            src={icon}
            alt={`${type} icon`}
            width={16}
            height={16}
          />
          <Text
            fontWeight="semibold"
            fontSize="sm"
            textColor={textColor ?? 'black'}
          >
            {count}
          </Text>
        </HStack>
      </WrapItem>
    </Tooltip>
  )
}

export default WidgetCountPill
