/**
 * Form for editing a Twitter widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import {
  InputGroup,
  Input,
  InputLeftElement,
  Text,
  FormControl,
  Box,
  Popover,
  VStack,
  StackDivider,
  InputRightElement,
  IconButton,
  HStack,
  Fade,
  FormHelperText,
  Spinner,
  useBoolean,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons'
import React, { ReactElement, useState, useRef, useCallback } from 'react'
import firebase from 'firebase/app'
import { TwitterUser } from '../../types'
import TwitterUserDisplay from './TwitterUserDisplay'
import { useOutsideListener } from '../../../../../utils/hooks/useOutsideListener'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useMemo } from 'react'

const usersSearchCF = firebase.functions().httpsCallable('twitter-usersSearch')

interface Props {
  addAccountsToCache: (users: TwitterUser[]) => void
  followedAccounts: TwitterUser[]
  onAddUserId: (id: string) => void
  selectedUserIds: string[]
}

const TwitterUserSelection = ({
  addAccountsToCache,
  followedAccounts,
  onAddUserId,
  selectedUserIds,
}: Props): ReactElement => {
  const [searchFieldValue, setSearchFieldValue] = useState<string>('')
  const [searchResults, setSearchResults] = useState<TwitterUser[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isShowingAddedUserSuccess, setIsShowingAddedUserSuccess] =
    useBoolean(false)

  const searchForUsers = useCallback(
    async (query: string) => {
      setIsLoading(true)
      try {
        const response = await usersSearchCF({ query })
        const returnedUsers: TwitterUser[] = response.data.users
        addAccountsToCache(returnedUsers)
        setSearchResults(returnedUsers)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [addAccountsToCache]
  )

  const debouncedSearchForUsers = AwesomeDebouncePromise(searchForUsers, 400)

  const handleAddUser = (user: TwitterUser) => {
    onAddUserId(user.id)
    setRecentlyAddedUser(user)
    setIsShowingAddedUserSuccess.on()
    debouncedHideAddedUsernameBox()
  }

  // const debouncedHideAddedUsernameBox = useDebouncedCallback(() => {
  //   setIsShowingAddedUserSuccess.off()
  //   setTimeout(() => setRecentlyAddedUser(undefined), 500)
  // }, 2500)
  const debouncedHideAddedUsernameBox = AwesomeDebouncePromise(() => {
    setIsShowingAddedUserSuccess.off()
    setTimeout(() => setRecentlyAddedUser(undefined), 500)
  }, 2500)

  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchFieldValue(value)
    if (value === '') {
      setSearchResults([])
      return
    }
    debouncedSearchForUsers(value)
  }

  const [isFocused, setIsFocused] = useState(false)
  const [recentlyAddedUser, setRecentlyAddedUser] = useState<
    TwitterUser | undefined
  >()

  const profilePicSize = 48

  const returnedAccountIds = searchResults.map(({ id }) => id)
  const followedAccountIds = followedAccounts.map(({ id }) => id)

  // The accounts returned by search that the user follows
  const returnedFollowedAccounts = searchResults.filter(({ id }) =>
    followedAccountIds.includes(id)
  )

  // Followed accounts that match the query but weren't returned by the twitter api
  const otherMatchingFollowedAccounts = followedAccounts
    .filter(({ id }) => !returnedAccountIds.includes(id))
    .filter((user) => {
      return (
        user.username.toLowerCase().includes(searchFieldValue.toLowerCase()) ||
        user.name.toLowerCase().includes(searchFieldValue.toLowerCase())
      )
    })
    .sort((a, b) => {
      const lowercaseSearchValue = searchFieldValue.toLowerCase()
      let aUsernameIdx = a.username.toLowerCase().indexOf(lowercaseSearchValue)
      if (aUsernameIdx == -1) aUsernameIdx = 10000
      let aNameIdx = a.name.toLowerCase().indexOf(lowercaseSearchValue)
      if (aNameIdx == -1) aNameIdx = 10000
      let bUsernameIdx = b.username.toLowerCase().indexOf(lowercaseSearchValue)
      if (bUsernameIdx == -1) bUsernameIdx = 10000
      let bNameIdx = b.name.toLowerCase().indexOf(lowercaseSearchValue)
      if (bNameIdx == -1) bNameIdx = 10000

      return Math.min(aUsernameIdx, aNameIdx) - Math.min(bUsernameIdx, bNameIdx)
    })

  // Accounts returned by the search api that the user doesn't follow
  const returnedNotFollowedAccounts = searchResults.filter(
    ({ id }) => !followedAccountIds.includes(id)
  )

  const accountsToShow = useMemo(
    () =>
      [
        ...returnedFollowedAccounts,
        ...otherMatchingFollowedAccounts,
        ...returnedNotFollowedAccounts,
      ]
        .filter(({ id }) => !selectedUserIds.includes(id))
        .slice(0, 5),
    [
      otherMatchingFollowedAccounts,
      returnedFollowedAccounts,
      returnedNotFollowedAccounts,
      selectedUserIds,
    ]
  )

  const clickOutsideHadler = useCallback(() => setIsFocused(false), [])
  const formControlRef = useRef(null)
  useOutsideListener(formControlRef, clickOutsideHadler)

  const showAutocompleteMenu = isFocused && searchFieldValue.length > 0

  return (
    <FormControl border="1px lightgray" ref={formControlRef}>
      <InputGroup>
        <InputLeftElement justifyContent="center" w="10">
          <SearchIcon />
        </InputLeftElement>
        <Input
          borderBottomRadius={showAutocompleteMenu ? '0px' : ''}
          onChange={handleChangeInputValue}
          onFocus={() => setIsFocused(true)}
          paddingLeft="2.1em"
          placeholder="Search by name or username"
          value={searchFieldValue}
        />
        <InputRightElement justifyContent="center" w="min-content">
          <AddedUserIndicator
            username={recentlyAddedUser?.username}
            visible={isShowingAddedUserSuccess}
          />
          {isLoading && searchFieldValue != '' && (
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="md"
            />
          )}
          {searchFieldValue != '' && isFocused && (
            <IconButton
              size="md"
              aria-label="Clear search field"
              onClick={() => setSearchFieldValue('')}
              icon={<CloseIcon />}
              variant="unstyled"
            />
          )}
        </InputRightElement>
      </InputGroup>
      {showAutocompleteMenu && (
        <Popover isOpen={showAutocompleteMenu}>
          <Box
            hidden={accountsToShow.length == 0 && isLoading}
            shadow="md"
            borderWidth="1px"
            paddingY="6px"
            borderBottomRadius="10px"
          >
            {accountsToShow.length > 0 && (
              <VStack
                align="stretch"
                divider={
                  <StackDivider style={{ margin: 0 }} borderColor="gray.300" />
                }
              >
                {accountsToShow.map((account) => {
                  const following = followedAccountIds.includes(account.id)
                  return (
                    <Box
                      _hover={{
                        cursor:
                          selectedUserIds.length == 10 ? 'default' : 'pointer',
                        backgroundColor:
                          selectedUserIds.length == 10 ? 'white' : 'gray.200',
                      }}
                      key={account.id}
                      p="0.5rem"
                      pl="4"
                      onClick={() =>
                        selectedUserIds.length < 10 && handleAddUser(account)
                      }
                    >
                      <TwitterUserDisplay
                        user={account}
                        profilePicSize={profilePicSize}
                        showFollowersCount
                        showFollowingTag={following}
                        showOpenInTwitterButton
                      />
                    </Box>
                  )
                })}
              </VStack>
            )}
            {accountsToShow.length == 0 && !isLoading && (
              <Text ml={4}>No results</Text>
            )}
          </Box>
        </Popover>
      )}
      <FormHelperText
        color={
          showAutocompleteMenu && selectedUserIds.length == 10 ? 'red' : 'gray'
        }
      >
        {selectedUserIds.length == 0
          ? `Select at least 1 account`
          : `Select up to 10 accounts`}
      </FormHelperText>
    </FormControl>
  )
}

export default TwitterUserSelection

interface AddedUserIndicatorProps {
  username: string | undefined
  visible: boolean
}
const AddedUserIndicator = ({ username, visible }: AddedUserIndicatorProps) => {
  return (
    <Fade
      transition={{
        enter: { duration: 0, delay: 0 },
        exit: { duration: 0.3 },
      }}
      in={visible}
    >
      <Box
        backgroundColor="green.500"
        borderRadius="4px"
        marginRight="1"
        padding="2px"
        paddingX="6px"
        w="max-content"
      >
        <HStack>
          <CheckIcon color="white" />
          <Text color="white" noOfLines={1}>{`Added @${username}`}</Text>
        </HStack>
      </Box>
    </Fade>
  )
}
