import { ReactElement } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import { ThumbnailPlotProps } from '../types'
import { plotYDomain } from '../utils'

const ThumbnailPlot = ({ stock }: ThumbnailPlotProps): ReactElement => {
  const color =
    stock.quote.previousClose > stock.quote.latestPrice ? 'red' : 'green'

  const thumbnailPrices = stock.intraday.prices.filter((value, index) => {
    return index % 2 == 0
  })

  // random number used to avoid collisions between the thumbnail in the widget and in the expanded view
  const rand = Math.floor(Math.random() * 1000000)
  const id = `${stock.info.symbol}colorPlot-${rand}`

  return (
    <ResponsiveContainer width={90} aspect={1.7}>
      <AreaChart data={thumbnailPrices}>
        <defs>
          <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.3} />
            <stop offset="90%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <ReferenceLine
          y={stock.quote.previousClose}
          stroke={color}
          strokeDasharray="5"
        />
        <CartesianGrid horizontal={false} vertical={false} />
        <Area
          isAnimationActive={false}
          connectNulls={true}
          type="linear"
          dataKey="displayPrice"
          stroke={color}
          dot={false}
          strokeWidth={2}
          fillOpacity={1}
          fill={`url(#${id}`}
        />
        <XAxis
          dataKey="timeDigit"
          type="number"
          hide={true}
          ticks={[10, 11, 12, 13, 14, 15]}
          domain={[9.5, 16]}
        />
        <YAxis
          hide={true}
          domain={plotYDomain(thumbnailPrices, stock.quote.previousClose)}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default ThumbnailPlot

// Moving average testing:

// const thumbnailMAPrices: StockIntradayPrice[] = []
// const movingAverage: number[] = []
// const window = 2

// modTest.forEach((price) => {
//   if (price.average != undefined) {
//     if (movingAverage.length == window) {
//       movingAverage.push(price.average)
//       movingAverage.shift()

//       price.average = mean(movingAverage)

//       thumbnailMAPrices.push(price)
//     } else {
//       movingAverage.push(price.average)
//       price.average = mean(movingAverage)

//       thumbnailMAPrices.push(price)
//     }
//   }
// })

// console.log(thumbnailMAPrices)
