/**
 * A single Subreddit option rendered upon searching the Subreddit async
 * select in Reddit settings
 */
import {
  Image,
  Text,
  VStack,
  HStack,
  Box,
  // Flex,
  // Tag,
  // TagLabel,
  // InputRightElement,
  // IconButton,
  // Fade,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { Subreddit } from '../types'
import { numberFormatter } from '../../../../utils/general/numberFormatter'
interface Props {
  subreddit: Subreddit
  onSelect: () => void
}
const SubredditOptionDisplay = ({
  subreddit,
  onSelect,
}: Props): ReactElement => {
  const { name, thumbnail, numSubscribers, id } = subreddit
  // const [showSelected, setShowSelected] = useState(false)
  // const handleSelect = () => {
  //   //setShowSelected(true)
  //   onSelect()
  // }
  return (
    <Box
      _hover={{
        cursor: 'pointer',
        backgroundColor: 'gray.300',
      }}
      onClick={onSelect}
      p={2}
    >
      <HStack key={id} spacing={`10px`}>
        <Image src={thumbnail} width="40px" alt={`${name} thumbnail`} />
        <VStack align="start" w="100%" spacing={0}>
          <Text textOverflow="ellipsis" w="max-content" maxW="80%" as="b">
            {name}
          </Text>
          <Text textColor="#657786">{`${numberFormatter(
            numSubscribers
          )} members`}</Text>
        </VStack>
      </HStack>
    </Box>
  )
}

export default SubredditOptionDisplay
