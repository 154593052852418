import { DateTime } from 'luxon'
import numeral from 'numeral'
import { ReactElement } from 'react'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

import { Container, Text } from '@chakra-ui/react'

const HistoricalTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>): ReactElement => {
  if (active && payload && payload.length) {
    const date = payload[0].payload.date

    return (
      <Container
        borderRadius="md"
        paddingTop={1}
        paddingLeft={2}
        paddingRight={2}
        paddingBottom={1}
        w="100%"
        bg="white"
        borderWidth="1px"
        borderColor="gray.300"
      >
        <Text fontSize="xs" fontWeight="600">
          USD ${numeral(payload[0].payload.close).format('0,0.00')}
        </Text>
        <Text fontSize="xs">
          {DateTime.fromISO(date).toLocaleString({
            month: 'long',
            day: '2-digit',
            year: 'numeric',
          })}
        </Text>
      </Container>
    )
  }

  return <></>
}

export default HistoricalTooltip
