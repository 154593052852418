import React, { ReactElement, useEffect, useState } from 'react'

import {
  Modal,
  ModalContent,
  ModalOverlay,
  useMediaQuery,
} from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../../context/dashboard-navigation/context'
import { useCurrentUser } from '../../../context/user/context'
import { Walkthroughs } from '../types'
import View from './View'

const OnboardModal = (): ReactElement => {
  const [progress, setProgress] = useState(0)
  const [finished, setFinished] = useState(true)
  const [type, setType] = useState<Walkthroughs>('signUp')

  const { user } = useCurrentUser()
  const { overviewModalOpen, toggleOverviewModalOpen } =
    useDashboardNavigationContext()

  //const { onClose } = useDisclosure()
  const isAuthed = user != undefined

  const handleNav = (increment: number, end = false): void => {
    if (end || !isAuthed) {
      setProgress(0)
      setFinished(true)
    } else setProgress((oldProgress) => oldProgress + increment)
  }

  const [isShortDevice, isNarrow] = useMediaQuery([
    '(max-height: 720px)',
    '(max-width: 720px)',
  ])

  // TODO: Change starting progress point based on what has been done in the past
  useEffect(() => {
    if (!isAuthed && overviewModalOpen) {
      setType('whatIsThis')
      setFinished(false)
    }
    // Authed, not in the middle of another walkthrough
    if (isAuthed && finished) {
      // User hasn't completed profile info
      if (!user?.completedProfile) {
        setFinished(false)
        setType('signUp')
      }

      // User hasn't seen product overview
      // else if (!user?.completedOverview) {
      //   setFinished(false)
      //   setType('whatIsThis')
      // }

      // User hasn't selected interests
      else if (!user?.completedInterests) {
        setFinished(false)
        setType('setInterests')
      }

      // User hasn't done create dashboard walkthrough
      // else if (!user?.completedCreateDashboard) {
      //   setFinished(false)
      //   setType('createDashboard')
      // }
    }
  }, [isAuthed, user, finished, type, overviewModalOpen])

  return (
    <Modal
      isOpen={!finished}
      onClose={toggleOverviewModalOpen}
      size={isShortDevice && isNarrow ? 'full' : '2xl'}
      scrollBehavior="inside"
      preserveScrollBarGap
      closeOnEsc={!isAuthed}
      closeOnOverlayClick={!isAuthed}
    >
      <ModalOverlay />

      <ModalContent h="75%">
        <View type={type} progress={progress} onNav={handleNav} />
      </ModalContent>
    </Modal>
  )
}

export default OnboardModal
