/**
 * function to map list of user-input key words to all key words
 * that reddit will match based on due to stemming
 *
 * eg:
 *
 * ["tacos", "friends", "yummy"] =>
 * ["tacos", "friends", "yummy", "taco", "friend"]
 */

export const extendKeyWordsToSearchMatches = (keyWords: string[]): string[] => {
  const allMatches = [...keyWords]
  keyWords.forEach((word) => {
    if (word.length > 1 && word.endsWith('s')) {
      allMatches.push(word.slice(0, word.length - 1))
    }
  })
  return allMatches
}
