import { useEffect, useState } from 'react'
import { Dashboard } from '../../components/dashboard/dashboardDisplay/DashboardDisplayV3'
import db from '../../utils/firebase/firestore'
import { User } from '../user/types'

interface DashboardsReturn {
  allDashboards: Dashboard[] | undefined
  followedDashboards: Dashboard[] | undefined
  originalDashboards: Dashboard[] | undefined
}

export default function useDashboards(
  user: User | undefined
): DashboardsReturn {
  const userId = user?.uid
  const [originalDashboards, setOriginalDashboards] = useState<
    Dashboard[] | undefined
  >([])
  const [followedDashboards, setFollowedDashboards] = useState<
    Dashboard[] | undefined
  >([])
  const [allDashboards, setAllDashboards] = useState<Dashboard[] | undefined>(
    []
  )

  // Listen for dashboards that have the current user as the author
  useEffect(() => {
    if (userId == undefined) {
      setOriginalDashboards(undefined)
      return
    }
    const unsubscribe = db
      .collection('dashboards')
      .where('author.id', '==', userId)
      .onSnapshot((snapshot) => {
        const fetchedDashboards = snapshot.docs.map(
          (doc) => doc.data() as Dashboard
        )
        setOriginalDashboards(fetchedDashboards)
      })
    return unsubscribe
  }, [userId])

  // Listen for changes to current user to detect if their followed dashboards have changed
  useEffect(() => {
    if (userId == undefined) {
      setFollowedDashboards(undefined)
      return
    }
    const unsubscribe = db.doc(`users/${userId}`).onSnapshot((doc) => {
      const user = doc.data()
      if (user == null) return
      const followedDashboardIDs = user.followedDashboards
      // TODO: to make this more efficient, we should only fetch the document for dashbaords that we don't already have. Maybe use useMemo?
      const followedDashboardPromises: Promise<Dashboard>[] =
        followedDashboardIDs.map((dashboardID: string) => {
          return db
            .doc(`dashboards/${dashboardID}`)
            .get()
            .then((doc) => {
              return doc.data() as Dashboard
            })
        })
      Promise.all(followedDashboardPromises).then((dashboards) =>
        setFollowedDashboards(dashboards.filter(Boolean))
      )
    })
    return unsubscribe
  }, [userId])

  const [dashboardIdsOrderList, setDashboardIdsOrderList] = useState<string[]>(
    []
  )
  useEffect(
    () => setDashboardIdsOrderList(user?.dashboardIdsInOrder ?? []),
    [user?.dashboardIdsInOrder]
  )

  useEffect(() => {
    const dashboards = dashboardIdsOrderList
      .map((dashboardId) => {
        const dashboard: Dashboard | undefined =
          (originalDashboards ?? []).find(
            (dashboard) => dashboard.id === dashboardId
          ) ??
          (followedDashboards ?? []).find(
            (dashboard) => dashboard.id === dashboardId
          )
        return dashboard
      })
      .filter((x): x is Dashboard => x != undefined)
    setAllDashboards(dashboards)
  }, [originalDashboards, followedDashboards, dashboardIdsOrderList])

  return {
    allDashboards,
    followedDashboards,
    originalDashboards,
  }
}
