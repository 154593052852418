import { FormControl, Input, FormLabel } from '@chakra-ui/react'
import { ChangeEvent, ReactElement } from 'react'
import { SignUpFieldProps } from './types'
import PasswordInputGroup from './PasswordInputGroup'

const LoginPassword = ({ formik }: SignUpFieldProps): ReactElement => {
  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
    formik.setFieldTouched(e.target.name)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      formik.handleSubmit()
    }
  }

  return (
    <FormControl>
      <FormLabel>Password</FormLabel>
      <PasswordInputGroup>
        {(showPassword) => (
          <Input
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            onChange={handleFieldChange}
            onKeyDown={handleKeyDown}
            value={formik.values.password}
            maxLength={30}
            variant="outline"
            w="100%"
          />
        )}
      </PasswordInputGroup>
    </FormControl>
  )
}

export default LoginPassword
