interface Props {
  authorName: string
}

export default function Replied({ authorName }: Props): React.ReactElement {
  return (
    <div className="flex items-center   mb-1">
      <svg
        aria-label="Replied"
        className="text-gray-500 inline h-4 w-4"
        viewBox="0 0 24 24"
      >
        <g fill="currentColor">
          <path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788z"></path>
        </g>
      </svg>
      <div className="text-gray-500   ml-1 -pb-1">
        {`${authorName} replied `}
      </div>
    </div>
  )
}
