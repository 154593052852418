import React, { ReactElement, useState } from 'react'

import {
  Alert,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertIcon,
  Button,
  Input,
  Text,
} from '@chakra-ui/react'

import DeleteButton from '../../general/DeleteButton'

interface AlertProps {
  body: ReactElement
  headerTitle?: string
  onConfirm: () => void
}

export const AlertContent = ({
  body,
  headerTitle = 'Are you sure?',
  onConfirm,
}: AlertProps): ReactElement => {
  const [inputFieldValue, setInputFieldValue] = useState('')

  const handleInputFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputFieldValue(e.target.value)
  }

  return (
    <AlertDialogContent>
      <AlertDialogCloseButton />
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {headerTitle}
      </AlertDialogHeader>
      <AlertDialogBody>
        {body}
        <div
          style={{ paddingTop: 20 }}
        >{`Please type "DELETE" to confirm`}</div>
        <Input value={inputFieldValue} onChange={handleInputFieldChange} />
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button
          colorScheme="red"
          isDisabled={inputFieldValue !== 'DELETE'}
          onClick={onConfirm}
          ml={3}
        >
          Delete Dashboard
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}

interface Props {
  onDelete: () => void
  as?: ReactElement
  dashboardTitle: string
  shouldStopClickPropagation?: boolean
}
const DeleteDashboardButton = ({
  onDelete,
  as,
  dashboardTitle,
  shouldStopClickPropagation = false,
}: Props): ReactElement => {
  return (
    <DeleteButton
      as={as}
      shouldStopClickPropagation={shouldStopClickPropagation}
      renderAlertContent={() => (
        <AlertContent
          body={
            <>
              <Text mb={4}>
                You are attempting to delete the dashboard{' '}
                <b>{dashboardTitle}</b>.
              </Text>
              <Alert status="warning">
                <AlertIcon />
                Deleting a dashboard cannot be undone, and will delete the
                dashboard for all its followers.
              </Alert>
            </>
          }
          onConfirm={onDelete}
        />
      )}
    />
  )
}
export default DeleteDashboardButton
