import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import db from '../../utils/firebase/firestore'
import { useCurrentUser } from '../user/context'

export interface TopLevelCommentNotification {
  commentIds: string[]
  dashboardId: string
  id: string
  isUnread: boolean
  timestamp: number
  type: 'top-level-comment'
}

export interface ThreadReplyNotification {
  commentIds: string[]
  dashboardId: string
  id: string
  parentCommentId: string
  isUnread: boolean
  timestamp: number
  type: 'thread-reply'
}

export interface CommentReactionNotification {
  commentId: string
  dashboardId: string
  id: string
  parentCommentId?: string
  isUnread: boolean
  reactions: { likes: number; dislikes: number }
  timestamp: number
  type: 'comment-reaction'
}

export interface DashboardFollowNotification {
  dashboardId: string
  followerIds: string[]
  id: string
  isUnread: boolean
  timestamp: number
  type: 'dashboard-follow'
}

export type Notif =
  | CommentReactionNotification
  | DashboardFollowNotification
  | ThreadReplyNotification
  | TopLevelCommentNotification

interface NotificationsContext {
  activityBadgeNumber: number
  dashboardBadgeNumberMap: Map<string, number>
  markAllUnreadAsRead: () => Promise<unknown>
  markNotificationAsRead: (notifId: string) => Promise<unknown>
  notifications: Notif[]
  recordNotificationsPopoverView: () => Promise<unknown>
  setCurrentlyViewedDashboardAndThread: (
    dashboardId: string | undefined,
    parentCommentId?: string | undefined
  ) => Promise<unknown>
  threadBadgeNumberMap: Map<string, number>
}

export const NotificationsContext = createContext<NotificationsContext>({
  activityBadgeNumber: 0,
  dashboardBadgeNumberMap: new Map<string, number>(),
  markAllUnreadAsRead: async () => null,
  markNotificationAsRead: async () => null,
  notifications: [],
  recordNotificationsPopoverView: async () => null,
  setCurrentlyViewedDashboardAndThread: async () => null,

  threadBadgeNumberMap: new Map<string, number>(),
})

export const useNotificationsContext = (): NotificationsContext =>
  useContext(NotificationsContext)

const NotificationsContextProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const { user } = useCurrentUser()
  const userId = user?.uid
  const [notifications, setNotifications] = useState<Notif[]>([])

  const [openDashboardId, setOpenDashboardId] = useState<string>()
  const [openThreadId, setOpenThreadId] = useState<string>()

  const markNotificationsAsRead = useCallback(
    (notifsToMark: Notif[]) => {
      const unreadNotifs = notifsToMark.filter((notif) => notif.isUnread)
      const promises = unreadNotifs.map((notif) => {
        const copyNotif = notif
        copyNotif.isUnread = false
        const batch = db.batch()
        const newId = Math.random().toString(16).substring(2, 15)
        batch.set(db.doc(`users/${userId}/notifications/${newId}`), copyNotif)
        batch.delete(db.doc(`users/${userId}/notifications/${notif.id}`))
        return batch.commit()
      })
      return Promise.all(promises)
    },
    [userId]
  )

  useEffect(() => {
    if (userId == undefined) {
      setNotifications([])
      return
    }
    const unsubscribeNotificationsListener = db
      .collection(`users/${userId}/notifications`)
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        let notifications = snapshot.docs.map((doc) => {
          return doc.data() as Notif
        })
        const notificationsToRead: Notif[] = []
        const isBeingViewed = (notif: Notif): boolean => {
          if (
            notif.type == 'top-level-comment' &&
            notif.dashboardId == openDashboardId &&
            openThreadId == undefined
          ) {
            return true
          }
          if (
            notif.type == 'thread-reply' &&
            notif.dashboardId == openDashboardId &&
            notif.parentCommentId == openThreadId
          ) {
            return true
          }
          if (
            notif.type == 'comment-reaction' &&
            notif.dashboardId == openDashboardId &&
            notif.parentCommentId === openThreadId
          ) {
            return true
          }
          return false
        }
        notifications = notifications.filter((notif) => {
          const isNotifForValidDashboard = user?.dashboardIdsInOrder.includes(
            notif.dashboardId
          )
          if (
            isNotifForValidDashboard &&
            isBeingViewed(notif) &&
            notif.isUnread
          ) {
            notificationsToRead.push(notif)
            notif.isUnread = false
          }
          return isNotifForValidDashboard
        })

        markNotificationsAsRead(notificationsToRead)
        setNotifications(notifications)
      })

    return unsubscribeNotificationsListener
  }, [
    markNotificationsAsRead,
    openDashboardId,
    openThreadId,
    userId,
    user?.dashboardIdsInOrder,
  ])

  const markDashboardCommentsAsRead = useCallback(
    async (dashboardId: string) => {
      // find all notifs regarding that dashboard
      const notificationsToMarkAsRead = notifications.filter((notif) => {
        notif.type === 'top-level-comment' && notif.dashboardId === dashboardId
      })
      return markNotificationsAsRead(notificationsToMarkAsRead)
    },
    [markNotificationsAsRead, notifications]
  )

  const markThreadCommentsAsRead = useCallback(
    async (dashboardId: string, parentCommentId: string) => {
      const notificationsToMarkAsRead = notifications.filter((notif) => {
        notif.type === 'thread-reply' &&
          notif.dashboardId === dashboardId &&
          notif.parentCommentId === parentCommentId
      })
      return markNotificationsAsRead(notificationsToMarkAsRead)
    },
    [markNotificationsAsRead, notifications]
  )

  const recordNotificationsPopoverView = useCallback(async () => {
    const notificationsToMarkAsRead = notifications
      .filter((notif) => notif.isUnread)
      .filter(
        (notif) =>
          notif.type === 'comment-reaction' || notif.type === 'dashboard-follow'
      )
    return markNotificationsAsRead(notificationsToMarkAsRead)
  }, [markNotificationsAsRead, notifications])

  const markNotificationAsRead = useCallback(
    (notifId: string) => {
      const notificationsToMarkAsRead = notifications.filter(
        (notif) => notif.id === notifId
      )
      return markNotificationsAsRead(notificationsToMarkAsRead)
    },
    [markNotificationsAsRead, notifications]
  )

  const markAllUnreadAsRead = useCallback(() => {
    const unreadNotifications = notifications.filter((notif) => notif.isUnread)
    return markNotificationsAsRead(unreadNotifications)
  }, [markNotificationsAsRead, notifications])

  const numberForNotif = (notif: Notif): number => {
    switch (notif.type) {
      case 'dashboard-follow':
        return notif.followerIds.length
      case 'thread-reply':
      case 'top-level-comment':
        return notif.commentIds.length
      case 'comment-reaction':
        return (
          Math.max(notif.reactions.likes, 0) +
          Math.max(notif.reactions.dislikes, 0)
        )
    }
  }

  const [dashboardBadgeNumberMap, setDashboardBadgeNumberMap] = useState(
    new Map<string, number>()
  )
  const [threadBadgeNumberMap, setThreadBadgeNumberMap] = useState(
    new Map<string, number>()
  )

  useEffect(() => {
    // Create dashboard badge map
    const unreadNotifications = notifications.filter((notif) => notif.isUnread)
    const newDashboardBadgeMap = new Map<string, number>()
    unreadNotifications.forEach((notif) => {
      if (
        notif.type === 'comment-reaction' ||
        notif.type === 'dashboard-follow'
      ) {
        return
      }
      const notifNumber = numberForNotif(notif)
      const { dashboardId } = notif
      newDashboardBadgeMap.set(
        dashboardId,
        (newDashboardBadgeMap.get(dashboardId) ?? 0) + notifNumber
      )
    })
    setDashboardBadgeNumberMap(newDashboardBadgeMap)

    // Threads badge map
    const newThreadBadgeMap = new Map<string, number>()
    unreadNotifications.forEach((notif) => {
      if (notif.type !== 'thread-reply') {
        return
      }

      const notifNumber = numberForNotif(notif)
      const { parentCommentId } = notif
      newThreadBadgeMap.set(
        parentCommentId,
        (newThreadBadgeMap.get(parentCommentId) ?? 0) + notifNumber
      )
    })
    setThreadBadgeNumberMap(newThreadBadgeMap)
  }, [notifications])

  const setCurrentlyViewedDashboardAndThread = useCallback(
    async (
      dashboardId: string | undefined,
      parentCommentId?: string | undefined
    ) => {
      setOpenDashboardId(dashboardId)
      setOpenThreadId(parentCommentId)
      if (dashboardId != undefined && parentCommentId == undefined) {
        markDashboardCommentsAsRead(dashboardId)
      } else if (dashboardId != undefined && parentCommentId != undefined) {
        markThreadCommentsAsRead(dashboardId, parentCommentId)
      }
    },
    [markDashboardCommentsAsRead, markThreadCommentsAsRead]
  )

  const activityBadgeNumber = notifications
    .filter((notif) => notif.isUnread)
    .reduce((t, notif) => {
      const notifNumber = numberForNotif(notif)
      return t + notifNumber
    }, 0)

  return (
    <NotificationsContext.Provider
      value={{
        activityBadgeNumber,
        dashboardBadgeNumberMap,
        markAllUnreadAsRead,
        markNotificationAsRead,
        notifications,
        recordNotificationsPopoverView,
        setCurrentlyViewedDashboardAndThread,
        threadBadgeNumberMap,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsContextProvider

/*
 Hook to listen to notifications of current user
 */

// function useNotifications(userId: string | undefined): Notif[] | undefined {
//   const [notifications, setNotifications] = useState<Notif[]>()

//   useEffect(() => {
//     if (userId == undefined) {
//       setNotifications([])
//       return
//     }
//     const unsubscribeNotificationsListener = db
//       .collection(`users/${userId}/notifications`)
//       .onSnapshot((snapshot) => {
//         const notifications = snapshot.docs.map((doc) => {
//           return doc.data() as Notif
//         })
//         console.log(notifications)
//         setNotifications(notifications)
//       })
//     return unsubscribeNotificationsListener
//   }, [userId])

//   return notifications
// }
