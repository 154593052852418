/**
 * Form for editing a news widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import { ReactElement, useState } from 'react'

import { Text, VStack } from '@chakra-ui/react'

import EditWidgetDrawerTemplate from '../../../dashboard/dashboardDisplay/EditWidgetDrawerTemplate'
import AndOrDropdown from '../../reddit/settings/AndOrDropdown'
import PhraseSelector from '../../twitter/TwitterSettings/MultiPhraseSelector'
import { NewsWidgetSettings } from '../types'
import RadioCards from './RadioCards'
import ReadOnlyNewsSettings from './ReadOnlyNewsSettings'

// import SourceSelection from './SourceSelectionV2'

interface Props {
  onChange: (settings: NewsWidgetSettings) => void
  settings: NewsWidgetSettings
  canEdit: boolean
}

const NewsSettings = ({ onChange, settings, canEdit }: Props): ReactElement => {
  const [requiredWordsInput, setRequiredWordsInput] = useState('')
  const [excludedWordsInput, setExcludedWordsInput] = useState('')

  // const handleChangeSources = (fn: (prev: string[]) => string[]) => {
  //   const sources = fn(settings.sources)
  //   onChange({ ...settings, sources })
  // }

  const handleChangeCategory = (nextValue: string) => {
    const category =
      nextValue.toLowerCase() == settings.category.toLowerCase()
        ? ''
        : nextValue.toLowerCase()
    onChange({ ...settings, category })
  }

  const handleChangeRequiredWords = (func: (prev: string[]) => string[]) => {
    const requiredWords = func(settings.requiredWords)
    onChange({ ...settings, requiredWords })
  }

  const handleChangeExcludedWords = (func: (prev: string[]) => string[]) => {
    const excludedWords = func(settings.excludedWords)
    onChange({ ...settings, excludedWords })
  }

  const availableCategories = [
    'World',
    'Finance',
    'Politics',
    'Business',
    'Economics',
    'Entertainment',
    'Tech',
    'Beauty',
    'Travel',
    'Music',
    'Food',
    'Sports',
    'Science',
    'General',
  ]

  const validateNoPararentheses = (value: string): string | undefined => {
    if (value.includes('(') || value.includes(')')) {
      return 'Parentheses are not allowed'
    }
    return undefined
  }

  let filtersCharacterCount = 0
  settings.requiredWords.forEach(
    (phrase) => (filtersCharacterCount += phrase.length)
  )
  filtersCharacterCount += requiredWordsInput.length
  settings.excludedWords.forEach(
    (phrase) => (filtersCharacterCount += phrase.length)
  )
  filtersCharacterCount += excludedWordsInput.length

  let preventSaveMessage = undefined
  if (settings.requiredWords.length === 0)
    preventSaveMessage = 'Add at least one required phrase filter'

  return (
    <EditWidgetDrawerTemplate preventSaveMessage={preventSaveMessage}>
      {!canEdit ? (
        <ReadOnlyNewsSettings settings={settings} />
      ) : (
        <>
          <VStack align="start" spacing="8" w="100%" pb={10}>
            <RadioCards
              choices={availableCategories}
              selectedChoice={settings.category}
              onCardSelection={handleChangeCategory}
              canEdit={true}
            />

            <VStack align="start" spacing={3} w="100%">
              <VStack align="stretch" w="100%" paddingBottom={2}>
                <Text as="b">
                  Require that articles include{' '}
                  <AndOrDropdown
                    value={settings.requiredWordsJoiner}
                    onSelect={(requiredWordsJoiner: 'any' | 'all') =>
                      onChange({ ...settings, requiredWordsJoiner })
                    }
                    canEdit={true}
                  />{' '}
                  of these phrases:
                </Text>
                <PhraseSelector
                  allowTyping={filtersCharacterCount < 200}
                  isDisabled={false}
                  onChange={handleChangeRequiredWords}
                  phrases={settings.requiredWords}
                  validateInput={validateNoPararentheses}
                  onCurrentInputChange={setRequiredWordsInput}
                  canEdit={true}
                />
              </VStack>
              <VStack align="stretch" w="100%">
                <Text as="b">
                  Filter out articles with{' '}
                  <AndOrDropdown
                    value={settings.excludedWordsJoiner}
                    onSelect={(excludedWordsJoiner: 'any' | 'all') =>
                      onChange({ ...settings, excludedWordsJoiner })
                    }
                    canEdit={canEdit}
                  />{' '}
                  of these phrases:
                </Text>
                <PhraseSelector
                  allowTyping={filtersCharacterCount < 200}
                  isDisabled={false}
                  onChange={handleChangeExcludedWords}
                  phrases={settings.excludedWords}
                  validateInput={validateNoPararentheses}
                  onCurrentInputChange={setExcludedWordsInput}
                  canEdit={true}
                />
              </VStack>
            </VStack>

            {/* <VStack align="stretch" w="100%" paddingBottom={2}>
              <Text as="b">Fetch articles only from these sources:</Text>
              <SourceSelection
                allowTyping={filtersCharacterCount < 200}
                isDisabled={false}
                onChange={handleChangeSources}
                phrases={settings.sources}
                validateInput={validateNoPararentheses}
                onCurrentInputChange={setRequiredWordsInput}
                canEdit={true}
              />
            </VStack> */}
          </VStack>
        </>
      )}
    </EditWidgetDrawerTemplate>
  )
}

export default NewsSettings
