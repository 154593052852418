import React, { ReactElement } from 'react'
import { Spinner, VStack } from '@chakra-ui/react'

const DashboardLoadingView = (): ReactElement => {
  return (
    <VStack w="100%" h="100%" justify="center">
      <Spinner />
    </VStack>
  )
}

export default DashboardLoadingView
