import generateTwitterLink from './generateTwitterLink'
import { DashboardAuthor } from '../../components/dashboard/dashboardDisplay/DashboardDisplayV3'
import { User } from '../../context/user/types'
import { routes } from '../constants'

interface SharableDashboard {
  title: string
  id: string
  author: DashboardAuthor
}
const shareToTwitter = (
  dashboard: SharableDashboard,
  user: User | undefined
): void => {
  const urlSafeTitle = dashboard?.title.replaceAll('&', '%26') ?? ''

  const tweetText =
    user != undefined && dashboard?.author.id === user.uid
      ? `Check out my dashboard on Signalist: '${urlSafeTitle}'!`
      : `Check out ${dashboard?.author.displayName}'s dashboard on Signalist: '${urlSafeTitle}'!`

  const destination = generateTwitterLink(
    routes.shareableDashboardLink(dashboard.id),
    tweetText
  )
  window.open(destination, '_blank')
}

export default shareToTwitter
