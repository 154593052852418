import { FormControl, Input, FormLabel } from '@chakra-ui/react'
import { ChangeEvent, ReactElement } from 'react'
import { SignUpFieldProps } from './types'

const LoginEmail = ({ formik }: SignUpFieldProps): ReactElement => {
  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
    formik.setFieldTouched(e.target.name)
  }

  return (
    <FormControl>
      <FormLabel>Email</FormLabel>
      <Input
        id="email"
        name="email"
        onChange={handleFieldChange}
        value={formik.values.email}
        spellCheck={false}
        maxLength={30}
        variant="outline"
        w="100%"
      />
    </FormControl>
  )
}

export default LoginEmail
