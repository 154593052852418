import React, { ReactElement, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { Container, StackDivider, VStack } from '@chakra-ui/react'

import { useMobileAlertBannerContext } from '../../../context/mobile-alert-banner/context'
import NullWidget from '../NullWidget'
import RedditPostDrawer from './postDisplay/RedditPostDrawer'
import RedditPostPreview from './postDisplay/RedditPostPreview'
import {
  RedditPost,
  RedditWidgetSettings,
  RedditWidgetSnapshotData,
  SubredditStyles,
} from './types'

interface Props {
  data: RedditWidgetSnapshotData
}
const RedditWidgetView = ({ data: fullWidgetData }: Props): ReactElement => {
  const { openBanner } = useMobileAlertBannerContext()

  const [postExpanded, setPostExpanded] = useState<RedditPost | null>(null)
  const [expandedPostIndex, setExpandedPostIndex] = useState<number | null>(
    null
  )

  let posts: RedditPost[] = []
  let settings: Partial<RedditWidgetSettings> | null = null
  let styles: Record<string, SubredditStyles> | null = {}

  if (fullWidgetData != null) {
    posts = fullWidgetData.data.posts
    styles = fullWidgetData.data.styles
    settings = fullWidgetData.settings
  }

  const handleExpandPost = (subredditName: string, post: RedditPost) => {
    setPostExpanded(post)
    if (posts != null) {
      setExpandedPostIndex(
        posts?.findIndex((postWithIndex) => postWithIndex.id == post.id)
      )
    }
  }

  const handleSetPostIndex = (index: number) => {
    setPostExpanded(posts != null ? posts[index] : null)
    setExpandedPostIndex(index)
  }

  const handleCloseRedditModal = () => {
    setPostExpanded(null)
  }

  const keyWords = settings?.requiredWords != null ? settings.requiredWords : []

  if (posts?.length === 0 || fullWidgetData?.data == null) {
    return <NullWidget emojis="🧐 🤖" />
  }

  const handleClickPost = (subredditName: string, post: RedditPost) => {
    if (isMobile) {
      openBanner('redditWidget')
    } else {
      handleExpandPost(subredditName, post)
    }
  }

  return (
    <Container centerContent={true} minW="100%" padding="0">
      <RedditPostDrawer
        currentPostIndex={expandedPostIndex}
        isOpen={postExpanded != null}
        keyWords={settings?.requiredWords != null ? settings.requiredWords : []}
        numPosts={posts?.length ?? 0}
        onClose={handleCloseRedditModal}
        onSetPostIndex={handleSetPostIndex}
        postData={postExpanded}
        subredditName={postExpanded != null ? postExpanded.subreddit : null}
        subredditStyles={
          postExpanded != null && styles != null
            ? styles[postExpanded.subreddit]
            : null
        }
      />
      <VStack
        w="100%"
        divider={<StackDivider borderColor="gray.200" />}
        spacing={0}
      >
        {/* {redditToken == null && nonAuthedContent} */}
        {posts.map((post) => (
          <RedditPostPreview
            key={post.id}
            postData={post}
            keyWords={keyWords}
            onExpandPost={() => handleClickPost(post.subreddit, post)}
            subredditName={post.subreddit}
            subredditStyles={
              styles != null && styles[post.subreddit]
                ? styles[post.subreddit]
                : null
            }
          />
        ))}
      </VStack>
    </Container>
  )
}

export default RedditWidgetView
