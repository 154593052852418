export default function formatMetric(count: number | undefined): string {
  if (count == undefined) return '?'
  if (count < Math.pow(10, 3)) {
    // Less than one thousand
    return `${count}`
  }
  if (count < Math.pow(10, 6)) {
    // Less than one million
    const display = Math.trunc(count / Math.pow(10, 2)) / 10
    return `${display}K`
  } else {
    // at least one million
    const display = Math.trunc(count / Math.pow(10, 5)) / 10
    return `${display}M`
  }
}
