/**
 * Component to show that no data exists because a widget has yet to
 * be refreshed
 */
import React, { ReactElement } from 'react'
import { VStack, Text, Tooltip } from '@chakra-ui/react'
interface Props {
  emojis: string
  text?: string
}
const NullDataWidgetText = ({ emojis, text }: Props): ReactElement => {
  return (
    <VStack
      w="100%"
      position="absolute"
      top="50%"
      transform="translateY(-50%);"
      spacing={3}
      p={5}
    >
      <Text fontSize={30}>{emojis}</Text>
      <Tooltip label="Refresh to get new data" maxW="12rem">
        <Text align="center" color="gray.500">
          {text ?? `No data to show.`}
        </Text>
      </Tooltip>
    </VStack>
  )
}

export default NullDataWidgetText
