import { setDoc } from '../../utils/firebase/firebaseCustoms'
import { User } from '../user/types'
import db from '../../utils/firebase/firestore'
import { initialDashboardLayouts } from '../../components/dashboard/utils/gridLayoutSettings'
import { WidgetType } from '../../components/widgets/types'

type dashboardId = string

const createNewDashboard = async (
  user: User,
  numDashboardsAsAuthor: number
): Promise<dashboardId> => {
  const authorUsername = user.username
  if (authorUsername == undefined) {
    throw new Error('User.username is undefined')
  }
  const newDashboard = {
    author: {
      displayName: user.displayName,
      id: user.uid,
      username: authorUsername,
    },
    description: '',
    followerCount: 0,
    tags: [],
    title: `My Dashboard #${numDashboardsAsAuthor + 1}`,
    layouts: initialDashboardLayouts,
    widgetCounts: {} as Record<WidgetType, number>,
    widgetCount: 0,
    isPublic: true,
  }
  const newDashboardId = await setDoc(db.collection('dashboards'), newDashboard)

  await db.doc(`users/${user.uid}`).update({
    dashboardIdsInOrder: [newDashboardId, ...user.dashboardIdsInOrder],
  })

  return newDashboardId
}

export default createNewDashboard
