/**
 * Form for editing a Twitter widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import {
  Center,
  VStack,
  Text,
  CheckboxGroup,
  HStack,
  Checkbox,
  Button,
} from '@chakra-ui/react'
import { StringOrNumber } from '@chakra-ui/utils'
import React, { ReactElement } from 'react'
import useAuthToken from '../../../../utils/hooks/useAuthToken'
import { TweetType, TwitterWidgetSettings } from '../types'
import TwitterAuthButton from '../TwitterAuthButton'
import UserSelection from './UserSelection'
import PhraseSelector from './MultiPhraseSelector'
import AndOrDropdown from '../../reddit/settings/AndOrDropdown'
import { useState } from 'react'
import EditWidgetDrawerTemplate from '../../../dashboard/dashboardDisplay/EditWidgetDrawerTemplate'
import ReadOnlyTwitterSettings from './ReadOnlyTwitterSettings'

interface Props {
  onChange: (settings: TwitterWidgetSettings) => void
  settings: TwitterWidgetSettings
  canEdit: boolean
}

const TwitterSettings = ({
  onChange,
  settings,
  canEdit,
}: Props): ReactElement => {
  const [token, updateToken] = useAuthToken('twitter')
  const [requiredWordsInput, setRequiredWordsInput] = useState('')
  const [excludedWordsInput, setExcludedWordsInput] = useState('')
  const [authErrorMessage, setAuthErrorMessage] = useState<string | undefined>()
  if (token == null) {
    return (
      <EditWidgetDrawerTemplate>
        <Center h="80%">
          <VStack align="center" spacing={12}>
            <VStack align="center" spacing={2}>
              <TwitterAuthButton
                onError={setAuthErrorMessage}
                onUpdateToken={updateToken}
              />
              <Text as="a" target="_blank" href={`/connected-accounts-policy`}>
                <Button
                  fontSize="10pt"
                  variant="link"
                >{`What's this for?`}</Button>
              </Text>
            </VStack>
            <Text w="90%" textAlign="center" textColor="red">
              {authErrorMessage}
            </Text>
          </VStack>
        </Center>
      </EditWidgetDrawerTemplate>
    )
  }

  const handleChangeAuthorIds = (fn: (prev: string[]) => string[]) => {
    const authorIds = fn(settings.authorIds)
    onChange({ ...settings, authorIds })
  }

  const handleChangeIncludedTweetTypes = (value: StringOrNumber[]) => {
    onChange({ ...settings, allowedTweetTypes: value as TweetType[] })
  }

  const handleChangeRequireWords = (func: (prev: string[]) => string[]) => {
    const requiredWords = func(settings.requiredWords)
    onChange({ ...settings, requiredWords })
  }

  const handleChangeExcludedWords = (func: (prev: string[]) => string[]) => {
    const excludedWords = func(settings.excludedWords)
    onChange({ ...settings, excludedWords })
  }

  const validateNoPararentheses = (value: string): string | undefined => {
    if (value.includes('(') || value.includes(')')) {
      return 'Parentheses are not allowed'
    }
    return undefined
  }

  let filtersCharacterCount = 0
  settings.requiredWords.forEach(
    (phrase) => (filtersCharacterCount += phrase.length)
  )
  filtersCharacterCount += requiredWordsInput.length
  settings.excludedWords.forEach(
    (phrase) => (filtersCharacterCount += phrase.length)
  )
  filtersCharacterCount += excludedWordsInput.length

  let preventSaveMessage = undefined
  if (settings.authorIds.length === 0)
    preventSaveMessage = 'Specify at least one tweet author filter'

  return (
    <EditWidgetDrawerTemplate preventSaveMessage={preventSaveMessage}>
      {!canEdit ? (
        <ReadOnlyTwitterSettings settings={settings} />
      ) : (
        <VStack align="start" spacing="6">
          <VStack align="start" w="100%">
            <Text as="b">Filter by tweet author</Text>
            <UserSelection
              userIds={settings.authorIds}
              onChangeUserIds={handleChangeAuthorIds}
              canEdit={canEdit}
            />
          </VStack>
          <VStack align="start" w="100%">
            <Text as="b">Included Tweet Types </Text>
            <CheckboxGroup
              value={settings.allowedTweetTypes}
              onChange={handleChangeIncludedTweetTypes}
            >
              <HStack spacing="5">
                {[
                  { name: 'quote', label: 'Quote Tweets' },
                  { name: 'reply', label: 'Replies' },
                  { name: 'retweet', label: 'Retweets' },
                ].map(({ name, label }) => (
                  <Checkbox
                    key={name}
                    value={name}
                    _hover={{ cursor: 'default' }}
                  >
                    {label}
                  </Checkbox>
                ))}
              </HStack>
            </CheckboxGroup>
          </VStack>
          <VStack align="start" spacing={0} w="100%">
            <HStack w="100%">
              <Text as="b">Text Filters</Text>
              {filtersCharacterCount >= 100 && (
                <Text flexGrow={2} textAlign="end">{`${Math.min(
                  filtersCharacterCount,
                  200
                )}/200 characters`}</Text>
              )}
            </HStack>
            <VStack align="stretch" w="100%" paddingBottom={2}>
              <Text>
                Require that tweets include{' '}
                <AndOrDropdown
                  value={settings.requiredWordsJoiner}
                  onSelect={(requiredWordsJoiner: 'any' | 'all') =>
                    onChange({ ...settings, requiredWordsJoiner })
                  }
                  canEdit={true}
                />{' '}
                of these phrases:
              </Text>
              <PhraseSelector
                allowTyping={filtersCharacterCount < 200}
                isDisabled={false}
                onChange={handleChangeRequireWords}
                phrases={settings.requiredWords}
                validateInput={validateNoPararentheses}
                onCurrentInputChange={setRequiredWordsInput}
                canEdit={true}
              />
            </VStack>
            <VStack align="stretch" w="100%">
              <Text>
                Filter out tweets with{' '}
                <AndOrDropdown
                  value={settings.excludedWordsJoiner}
                  onSelect={(excludedWordsJoiner: 'any' | 'all') =>
                    onChange({ ...settings, excludedWordsJoiner })
                  }
                  canEdit={canEdit}
                />{' '}
                of these phrases:
              </Text>
              <PhraseSelector
                allowTyping={filtersCharacterCount < 200}
                isDisabled={false}
                onChange={handleChangeExcludedWords}
                phrases={settings.excludedWords}
                validateInput={validateNoPararentheses}
                onCurrentInputChange={setExcludedWordsInput}
                canEdit={true}
              />
            </VStack>
          </VStack>
        </VStack>
      )}
    </EditWidgetDrawerTemplate>
  )
}

export default TwitterSettings
