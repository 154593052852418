/*
Form within the dashboard settings modal that controls automatic snapshotting settings
 */
import React, { ReactElement } from 'react'
import {
  Input,
  FormLabel,
  FormControl,
  Flex,
  HStack,
  Text,
  Grid,
  GridItem,
  Switch,
} from '@chakra-ui/react'
import DayButton from './DayButton'
import moment from 'moment'
import styled from 'styled-components'
export type DayOfWeek =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'

type PremadeScheme = 'Everyday' | 'Weekdays' | 'Weekends'

export interface SnapshotSettings {
  time: number // minutes since UTC midnight
  days: DayOfWeek[] // eg {Monday: true, Tuesday: false, ..., Friday: false}
}

interface Props {
  settings: SnapshotSettings
  onChange: (settings: SnapshotSettings) => void
  enabled: boolean
  onEnabledChange: (enabled: boolean) => void
}

export const defaultSnapshotSettings: SnapshotSettings = {
  days: [],
  time: 0,
}

// takes time in format "15:25", local time
// converts to number of minutes since midnight UTC time
const convertTimeStringToNumber = (time: string) => {
  const timeAsMoment = moment(time, 'HH:mm').utc()
  const hours = timeAsMoment.hours()
  const minutes = timeAsMoment.minutes()
  return hours * 60 + minutes
}
// convert number of minutes since midnight UTC time
// to a time string in the format "15:25" displaying local time
const convertTimeNumberToString = (time: number) => {
  const hours = Math.floor(time / 60)
  const minutes = time % 60
  const timeUTC = moment.utc({ hour: hours, minute: minutes })

  const timeStringLocal = timeUTC.local().format()

  return timeStringLocal.slice(-14, -9)
}

const TimeSchemeLink = styled(Text)`
  color: blue.700;

  &:hover {
    cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  }
`

const AutomaticSnapshotSettings = ({
  settings,
  onChange,
  enabled,
  onEnabledChange,
}: Props): ReactElement => {
  const { days, time } = settings

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const setDays = (updated: DayOfWeek[]) => onChange({ days: updated, time })
  const setTime = (updated: number) => onChange({ days, time: updated })
  const toggleEnabled = () => {
    onEnabledChange(!enabled)
  }
  const toggleDay = (day: DayOfWeek) => {
    const newDays = [...days]
    const idx = newDays.indexOf(day)
    if (idx === -1) {
      newDays.push(day)
    } else {
      newDays.splice(idx, 1)
    }
    setDays(newDays)
  }

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = convertTimeStringToNumber(e.target.value)
    setTime(newTime)
  }

  const allDays: DayOfWeek[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  const premadeSchemes: Record<PremadeScheme, DayOfWeek[]> = {
    Everyday: allDays,
    Weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    Weekends: ['Saturday', 'Sunday'],
  }

  const handleLoadPremadeScheme = (scheme: PremadeScheme) => {
    setDays(premadeSchemes[scheme])
  }

  const timeString = convertTimeNumberToString(time)
  return (
    <FormControl>
      <Grid templateColumns="repeat(2, 1fr)" gap={2} alignItems="center">
        <GridItem colSpan={2} mb={6}>
          <Flex>
            <FormLabel m={0} mr={2} p={0}>
              Enable automatic snapshotting
            </FormLabel>
            <Switch
              size="lg"
              defaultChecked={enabled}
              onChange={toggleEnabled}
            />{' '}
          </Flex>
        </GridItem>
        <GridItem>
          <FormLabel m={0} mr={2} p={0}>
            Repeat:
          </FormLabel>
        </GridItem>
        <GridItem>
          <HStack>
            {days != null &&
              allDays.map((day) => {
                return (
                  <DayButton
                    key={day}
                    day={day}
                    isSelected={days.includes(day)}
                    onClick={() => toggleDay(day)}
                    disabled={!enabled}
                  />
                )
              })}
          </HStack>
        </GridItem>
        <GridItem />
        <GridItem p={0}>
          <Flex justifyContent="center">
            <HStack>
              {(Object.keys(premadeSchemes) as PremadeScheme[]).map(
                (scheme) => {
                  return (
                    <TimeSchemeLink
                      key={scheme}
                      fontSize={12}
                      textDecoration="underline"
                      color="blue.400"
                      onClick={() => {
                        enabled && handleLoadPremadeScheme(scheme)
                      }}
                      disabled={!enabled}
                    >
                      {scheme}
                    </TimeSchemeLink>
                  )
                }
              )}
            </HStack>
          </Flex>
        </GridItem>
        <GridItem>
          <FormLabel m={0}>At:</FormLabel>
        </GridItem>
        <GridItem>
          <Flex>
            <Input
              m={0}
              p={2}
              type="time"
              onChange={handleTimeChange}
              value={timeString}
              disabled={!enabled}
            />
          </Flex>
        </GridItem>
        <GridItem />
        <GridItem m={0}>
          <Flex>
            <Text fontSize={12} m={0} p={0}>
              Your timezone is currently set to
              <Text
                fontSize={12}
                //textDecoration="underline"
                color={'blue.400'}
                //_hover={{ color: 'blue.700', cursor: 'pointer' }}
                //onClick={handleChangeTimezone}
              >
                {timezone}
              </Text>
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      {/* <Text fontSize={10} color="red">
        {enabled && days.length === 0
          ? 'You must provide at least one day for automatic snapshotting.'
          : ''}
      </Text> */}
    </FormControl>
  )
}

export default AutomaticSnapshotSettings
