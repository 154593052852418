import React, {
  ReactNode,
  ReactElement,
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react'
import { SettingsTab } from '../../components/settings/SettingsModal'

interface SettingsModalContext {
  activeSettingsTab: SettingsTab | undefined
  closeSettings: () => void
  openSettings: (initialTab?: SettingsTab) => void
}

export const SettingsModalContext = createContext<SettingsModalContext>({
  activeSettingsTab: undefined,
  closeSettings: () => {
    return
  },
  openSettings: () => {
    return
  },
})

interface SettingsModalState {
  activeSettingsTab: SettingsTab | undefined
  isOpen: boolean
}

export const useSettingsModalState = (): SettingsModalState => {
  const { activeSettingsTab } = useContext(SettingsModalContext)
  const value: SettingsModalState = useMemo(
    () => ({ activeSettingsTab, isOpen: activeSettingsTab != undefined }),
    [activeSettingsTab]
  )
  return value
}

interface SettingsModalActions {
  closeSettings: () => void
  openSettings: (initialTab?: SettingsTab) => void
}
export const useSettingsModalActions = (): SettingsModalActions => {
  const { closeSettings, openSettings } = useContext(SettingsModalContext)
  const value: SettingsModalActions = useMemo(
    () => ({ closeSettings, openSettings }),
    [closeSettings, openSettings]
  )
  return value
}

const SettingsModalContextProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [activeSettingsTab, setActiveSettingsTab] = useState<
    SettingsTab | undefined
  >()

  const closeSettings = useCallback(() => {
    setActiveSettingsTab(undefined)
  }, [])
  const openSettings = useCallback((initialTab?: SettingsTab) => {
    setActiveSettingsTab(initialTab ?? 'profile')
  }, [])

  const value: SettingsModalContext = useMemo(() => {
    return {
      activeSettingsTab,
      closeSettings,
      openSettings,
    }
  }, [activeSettingsTab, closeSettings, openSettings])

  return (
    <SettingsModalContext.Provider value={value}>
      {children}
    </SettingsModalContext.Provider>
  )

  // return (
  //   <SettingsModalContext.Provider
  //     value={{
  //       activeSettingsTab,
  //       closeSettings,
  //       isOpen,
  //       openSettings,
  //     }}
  //   >
  //     {children}
  //   </SettingsModalContext.Provider>
  // )
}

export default SettingsModalContextProvider
