import React, { ReactElement, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  Heading,
  ModalBody,
  ModalHeader,
  Tag,
  TagLabel,
  VStack,
} from '@chakra-ui/react'

import { useCurrentUser } from '../../../context/user/context'
import firebase from '../../../utils/firebase/firebase'
import db from '../../../utils/firebase/firestore'
import Footer from '../Footer'
import { topicsRef } from './topicsReference'

interface InterestsProps {
  interests: string[]
  onSelectInterest: (interest: string) => void
  onNav: (increment: number, isLast?: boolean) => void
}

function Interests({
  interests,
  onSelectInterest,
  onNav,
}: InterestsProps): ReactElement {
  const [error, setError] = useState<string | undefined>(undefined)

  const { user } = useCurrentUser()
  const userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =
    db.doc(`users/${user?.uid}`)

  const handleSubmit = (): void => {
    userRef
      .update({
        interests,
        completedInterests: true,
      })
      .catch(() => {
        setError('Unable to update interests. Try again...')
      })
  }

  return (
    <>
      <ModalHeader p={6}>
        <Heading size="lg">Interests</Heading>
      </ModalHeader>

      <ModalBody>
        <VStack alignItems="left" divider={<Divider />} spacing="3vh">
          {Object.keys(topicsRef).map((topic) => (
            <Box key={topic}>
              <Heading size="md" paddingBottom="2vh">
                {topic}
              </Heading>
              <Box>
                {topicsRef[topic].map((interest: string) => (
                  <Button
                    key={interest}
                    variant="unstyled"
                    padding="0.75vh"
                    onClick={() => {
                      onSelectInterest(interest)
                    }}
                  >
                    {interests.includes(interest) ? (
                      <Tag
                        size="lg"
                        borderRadius="full"
                        variant="solid"
                        colorScheme="green"
                      >
                        <TagLabel>{interest}</TagLabel>
                      </Tag>
                    ) : (
                      <Tag size="lg" borderRadius="full">
                        <TagLabel>{interest}</TagLabel>
                      </Tag>
                    )}
                  </Button>
                ))}
              </Box>
            </Box>
          ))}
        </VStack>
      </ModalBody>

      <Footer
        interestsLabel="(Select at least 5)"
        isNextDisabled={interests.length < 5 || error != undefined}
        backLabel={'Back'}
        nextLabel={'Next'}
        onNav={onNav}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default Interests
