import { DateTime } from 'luxon'
import numeral from 'numeral'
import { ReactElement, useState } from 'react'

import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import {
  Container,
  Divider,
  Flex,
  HStack,
  Spacer,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'

import useElementSize from '../../../../utils/hooks/useElementSize'
import FocusedPlot from '../charts/FocusedPlot'
import { ExpandedInfoProps, Timeframe } from '../types'
import { getLatestPrice, percentChange } from '../utils'

const ExpandedInfo = ({ stockData }: ExpandedInfoProps): ReactElement => {
  const [timeframe, setTimeframe] = useState<Timeframe>('1D')

  // const [stockPercentChange, setStockPercentChange] = useState<number>(
  //   Math.abs(
  //     percentChange(stockData.quote.previousClose, stockData.quote.latestPrice)
  //   )
  // )
  // const [color, setColor] = useState<string>(
  //   percentChange(stockData.quote.previousClose, stockData.quote.latestPrice) >
  //     0
  //     ? 'green'
  //     : 'red'
  // )
  // const [arrowIcon, setArrowIcon] = useState<ReactElement>(
  //   percentChange(stockData.quote.previousClose, stockData.quote.latestPrice) >
  //     0 ? (
  //     <ArrowUpIcon color="green.600" />
  //   ) : (
  //     <ArrowDownIcon color="red.600" />
  //   )
  // )

  const calculatePercentChange = (): number => {
    if (timeframe == '1D') {
      return percentChange(
        stockData.quote.previousClose,
        stockData.quote.latestPrice
      )
    } else if (stockData.historical?.[timeframe] !== undefined) {
      return percentChange(
        stockData.historical?.[timeframe][0].close,
        getLatestPrice(stockData.historical?.[timeframe])
      )
    } else {
      return 0
    }
  }

  const stockPercentChange = calculatePercentChange()
  const color = stockPercentChange >= 0 ? 'green' : 'red'
  const arrowIcon =
    stockPercentChange >= 0 ? (
      <ArrowUpIcon color="green.600" />
    ) : (
      <ArrowDownIcon color="red.600" />
    )

  const [ref, { width }] = useElementSize()

  const statPadding = '3'
  const statSize = 'sm'

  const timeframes: Timeframe[] = ['1D', '5D', '1M', '6M', '1Y']

  const updateTime = DateTime.fromMillis(
    stockData.intraday.updated
  ).toLocaleString({
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  })

  const statLabelColor = 'gray.500'
  const statLabelWeight = '600'
  const tableSpacing = -4

  const { companyName, securityName } = stockData.info
  let title = ''

  // check if the security name is of a certain format and adjust the title to reduce redundancy
  const companyPrefix = `${companyName} - `
  if (securityName.startsWith(companyPrefix) && securityName.includes('ETF')) {
    title = securityName.substring(companyPrefix.length)
  } else {
    title = companyName
  }

  if (title.length == 0) {
    title = securityName
  }

  return (
    <VStack align="start" w="100%" spacing={3}>
      <VStack align="start" spacing={3} w="100%">
        <VStack w="100%" align="start" spacing={2}>
          <Text fontSize="sm" fontWeight="500" color="gray.500">
            {stockData.info.symbol} &bull; {stockData.quote.primaryExchange}
          </Text>

          <Flex w="100%">
            <Text fontSize="2xl" fontWeight="400" noOfLines={1} mr="4">
              {title}
            </Text>
            <Spacer />
            <HStack spacing={4}>
              <Text fontSize="2xl" fontWeight="500">
                ${numeral(stockData.quote.latestPrice).format('0.00')}
              </Text>
              <Container
                borderRadius="md"
                padding="1"
                w="100%"
                bg={
                  color == 'green'
                    ? 'rgba(47,133,90,0.1)'
                    : 'rgba(197,48,48,0.1)'
                }
                centerContent={true}
              >
                <HStack spacing={1}>
                  {arrowIcon}
                  <Text color={`${color}.600`} fontWeight="600">
                    {numeral(stockPercentChange).format('0.00')}%
                  </Text>
                </HStack>
              </Container>
            </HStack>
          </Flex>

          <Divider borderColor="gray.400" />
        </VStack>
      </VStack>

      <VStack w="100%" spacing={6} ref={ref}>
        <Flex w="100%">
          <Tabs w="100%">
            <TabList borderBottomWidth="0px" borderBottomColor="gray.300">
              {timeframes.map((time) => {
                const isSelected = time === timeframe
                return (
                  <Tab
                    fontWeight="500"
                    onClick={() => setTimeframe(time)}
                    key={
                      isSelected
                        ? `${time}-button-selected`
                        : `${time}-button-unselected`
                    }
                  >
                    {time}
                  </Tab>
                )
              })}
            </TabList>
          </Tabs>
          <Spacer />
          <Text
            h="100%"
            fontSize="sm"
            color="gray.500"
            w="100%"
            align="end"
            verticalAlign="middle"
          >
            Updated: {updateTime}
          </Text>
        </Flex>

        <FocusedPlot
          stock={stockData}
          timeframe={timeframe}
          width={width}
          color={color}
        />
      </VStack>

      <VStack paddingTop={2} align="start" w="100%" spacing={2}>
        <Stack
          direction="row"
          w="90%"
          h="100px"
          spacing={6}
          marginLeft="auto"
          marginRight="auto"
        >
          <VStack h="100%" spacing={tableSpacing} w="95%">
            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                Open
              </Text>
              <Spacer />

              {stockData?.quote.open ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {stockData?.quote.open}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>

            {/* High */}
            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                High
              </Text>
              <Spacer />

              {stockData?.quote.high ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {stockData?.quote.high}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>

            {/* Low */}
            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                Low
              </Text>
              <Spacer />

              {stockData?.quote.low ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {stockData?.quote.low}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>
          </VStack>

          <Divider orientation="vertical" borderColor="gray.400" />

          <VStack
            h="100%"
            // divider={<StackDivider borderColor="gray.200" />}
            spacing={tableSpacing}
            w="95%"
          >
            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                Vol
              </Text>
              <Spacer />

              {stockData?.quote.volume ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {numeral(stockData.quote.volume)
                    .format('0.00a')
                    .toUpperCase()}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>

            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                P/E
              </Text>
              <Spacer />

              {stockData?.quote.peRatio ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {stockData.quote.peRatio}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>

            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                Mkt Cap
              </Text>
              <Spacer />

              {stockData?.quote.marketCap ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {numeral(stockData?.quote.marketCap)
                    .format('0.00a')
                    .toUpperCase()}{' '}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>
          </VStack>

          <Divider orientation="vertical" borderColor="gray.400" />

          <VStack
            h="100%"
            // divider={<StackDivider borderColor="gray.200" />}
            spacing={tableSpacing}
            w="90%"
          >
            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                Avg Vol
              </Text>
              <Spacer />

              {stockData?.quote.avgTotalVolume ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {numeral(stockData?.quote.avgTotalVolume)
                    .format('0.00a')
                    .toUpperCase()}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>
            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                52W H
              </Text>
              <Spacer />

              {stockData?.quote.week52High ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {stockData.quote.week52High}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>

            <Flex w="100%" paddingTop={statPadding} paddingBottom={statPadding}>
              <Text
                fontWeight={statLabelWeight}
                color={statLabelColor}
                fontSize={statSize}
              >
                52W L
              </Text>
              <Spacer />

              {stockData?.quote.week52Low ? (
                <Text fontSize={statSize} fontWeight="bold">
                  {stockData.quote.week52Low}
                </Text>
              ) : (
                <Text fontSize={statSize} fontWeight="bold" paddingRight="2">
                  --
                </Text>
              )}
            </Flex>
          </VStack>
        </Stack>
      </VStack>
      <Spacer />
    </VStack>
  )
}

export default ExpandedInfo
