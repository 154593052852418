/**
 * Component for displaying a (preview of) a Reddit post from the widget view
 */

import { DateTime } from 'luxon'
import React, { ReactElement } from 'react'

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Grid, GridItem, Spacer, Text, VStack } from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'
import { numberFormatter } from '../../../../utils/general/numberFormatter'
import DisablableExternalLink from '../../../general/DisablableExternalLink'
//import Highlighted from '../../../layout/Highlighted'
import { RedditPost, SubredditStyles } from '../types'
//import { extendKeyWordsToSearchMatches } from '../utils/extendKeyWordsToSearchMatches'
import Flair from './Flair'

interface Props {
  postData: RedditPost
  keyWords: string[]
  subredditName: string | null
  subredditStyles: SubredditStyles | null | undefined // TODO: figure out why undefined
  titleIsLink: boolean
}

const PostHeader = ({
  postData,
  //keyWords,
  subredditName,
  subredditStyles,
  titleIsLink,
}: Props): ReactElement => {
  const { isEditingDashboard } = useDashboardNavigationContext()
  return (
    <Grid
      templateColumns="repeat(16,1fr)"
      gap={0}
      mt={1}
      columnGap={3}
      w="100%"
    >
      <GridItem rowSpan={1} colSpan={1} w="100%">
        <VStack h="100%" w="100%" minW={8} justifyContent={'center'}>
          <Spacer />
          <TriangleUpIcon />
        </VStack>
      </GridItem>
      <GridItem colSpan={15} w="100%">
        <Text fontSize="sm" color="gray.600" w="100%">
          <DisablableExternalLink
            color="blue.400"
            fontWeight={'bold'}
            href={`https://reddit.com/r/${subredditName}`}
            isDisabled={isEditingDashboard}
          >
            r/{subredditName}{' '}
          </DisablableExternalLink>
          {'  '}
          • Posted by{' '}
          <DisablableExternalLink
            href={`https://www.reddit.com/user/${postData.author}`}
            isDisabled={isEditingDashboard}
          >
            u/{postData.author}
          </DisablableExternalLink>{' '}
          <DisablableExternalLink
            href={postData.url}
            isDisabled={isEditingDashboard}
          >
            {DateTime.fromMillis(postData.created * 1000).toRelative()}
          </DisablableExternalLink>
        </Text>
      </GridItem>
      <GridItem rowSpan={1} colSpan={1} w="100%">
        <VStack w="100%">
          <Text mb={0}> {numberFormatter(postData.numUpvotes)} </Text>
          <TriangleDownIcon mt="0rem !important" p={0} />
        </VStack>
      </GridItem>
      <GridItem colSpan={14} w="100%">
        {titleIsLink ? (
          <DisablableExternalLink
            href={postData.url}
            fontWeight="semibold"
            mr={1}
            isDisabled={isEditingDashboard}
          >
            {/* <Highlighted
              isHighlightingActive={false}
              text={postData.title}
              keyWords={extendKeyWordsToSearchMatches(keyWords)}
            /> */}
            {postData.title}
          </DisablableExternalLink>
        ) : (
          <Text as="span" fontWeight={'semibold'} mr={1}>
            {postData.title}
          </Text>
        )}
        <span>{'   '}</span>
        {postData.linkFlair?.text != null && (
          <Flair
            size="md"
            text={postData.linkFlair.text}
            template={
              subredditStyles != null
                ? subredditStyles['linkFlair'][postData.linkFlair.templateId]
                : null
            }
            subredditName={subredditName}
          />
        )}
      </GridItem>
    </Grid>
  )
}

export default PostHeader
