const formatHashtag = (hashtag: string) => {
  return hashtag.replace(/ (\w)/, (_, p1: string) => {
    return p1.toUpperCase()
  })
}

const generateTwitterLink = (
  url: string,
  content: string,
  hashtags?: []
): string =>
  `https://twitter.com/intent/tweet?url=${url}&text=${content}&hashtags=${
    hashtags != undefined ? hashtags.map(formatHashtag) : []
  }`

export default generateTwitterLink
