/**
 * Form for editing a Twitter widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import {
  VStack,
  HStack,
  Flex,
  IconButton,
  Center,
  Text,
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useState } from 'react'
import firebase from 'firebase/app'
import { TwitterUser } from '../../types'
import { useEffect } from 'react'
import UserSearchInput from './UserSearchInput'
import TwitterUserDisplay from './TwitterUserDisplay'

const getUsersByIdsCF = firebase.functions().httpsCallable('twitter-usersByIds')

const getFollowedTwitterAccountsCF = firebase
  .functions()
  .httpsCallable('twitter-followedAccounts')

interface Props {
  userIds: string[]
  onChangeUserIds: (fn: (userIds: string[]) => string[]) => void
  canEdit: boolean
}

const TwitterUserSelection = ({
  userIds,
  onChangeUserIds,
  canEdit,
}: Props): ReactElement => {
  const [twitterAccountsCache, setTwitterAccountsCache] = useState<
    Map<string, TwitterUser>
  >(new Map())
  const [followedTwitterAccounts, setFollowedTwitterAccounts] = useState<
    TwitterUser[]
  >([])
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)

  const addAccountsToCache = useCallback((users: TwitterUser[]) => {
    setTwitterAccountsCache((cache) => {
      users.forEach((user) => cache.set(user.id, user))
      return new Map(cache)
    })
  }, [])

  // Get twitter accounts this user follows
  useEffect(() => {
    getFollowedTwitterAccountsCF().then((response) => {
      const followedUsers: TwitterUser[] = response.data.users
      setFollowedTwitterAccounts(followedUsers)
      addAccountsToCache(followedUsers)
    })
  }, [addAccountsToCache])

  // Get full TwitterUser objects for ids that aren't in the cache
  useEffect(() => {
    const userIdsToFetch = userIds.filter(
      (userId) => !twitterAccountsCache.has(userId)
    )
    if (userIdsToFetch.length > 0) {
      setIsLoadingUsers(true)
      // TODO: don't send userId (it's for the functions emulator)
      getUsersByIdsCF({ userIds: userIdsToFetch })
        .then((response) => {
          const users: TwitterUser[] = response.data.users
          addAccountsToCache(users)
        })
        .finally(() => setIsLoadingUsers(false))
    }
  }, [userIds, twitterAccountsCache, addAccountsToCache])

  const users: TwitterUser[] = useMemo(
    () =>
      userIds
        .map((id) => twitterAccountsCache.get(id))
        .filter((x): x is TwitterUser => x != undefined),
    [twitterAccountsCache, userIds]
  )

  const handleAddUserId = (id: string) => {
    onChangeUserIds((prevIds) => [...prevIds, id])
  }

  const removeUserForId = (idToRemove: string) => {
    onChangeUserIds((prevIds) => {
      const newIds = [...prevIds].filter((id) => id !== idToRemove)
      return newIds
    })
  }

  const profilePicSize = 48

  return (
    <VStack align="start" w="100%" spacing="4">
      {canEdit && (
        <UserSearchInput
          addAccountsToCache={addAccountsToCache}
          followedAccounts={followedTwitterAccounts}
          onAddUserId={handleAddUserId}
          selectedUserIds={userIds}
        />
      )}
      <VStack align="stretch" w="100%">
        {users.map((user) => {
          return (
            <HStack key={user.id}>
              <TwitterUserDisplay user={user} profilePicSize={profilePicSize} />
              {canEdit && (
                <Flex grow={2} justifyContent="flex-end">
                  <IconButton
                    aria-label="Remove user"
                    icon={<CloseIcon />}
                    variant="unstyled"
                    onClick={() => removeUserForId(user.id)}
                  />
                </Flex>
              )}
            </HStack>
          )
        })}
        {userIds.length === 0 && canEdit && (
          <Center w="100%" justifyContent="center">
            <Text fontStyle="italic" textColor="gray.500">
              No accounts selected
            </Text>
          </Center>
        )}
        {isLoadingUsers && (
          <TwitterUserDisplay
            user={undefined}
            showLoading
            profilePicSize={profilePicSize}
          />
        )}
      </VStack>
    </VStack>
  )
}

export default TwitterUserSelection
