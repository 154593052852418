import React, { ReactElement, useState } from 'react'

import { AlertDialog, AlertDialogOverlay, Box, Button } from '@chakra-ui/react'

interface Props {
  renderAlertContent: ({ onSuccess }: { onSuccess: () => void }) => ReactElement
  as?: ReactElement
  shouldStopClickPropagation?: boolean
}

const DeleteButton = ({
  renderAlertContent,
  as,
  shouldStopClickPropagation = false,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleClose = () => setIsOpen(false)
  const handleOpen = () => setIsOpen(true)

  const cancelRef = React.useRef(null)

  const handleClick = (e: React.MouseEvent) => {
    if (shouldStopClickPropagation) {
      e.stopPropagation()
    }
    handleOpen()
  }

  return (
    <>
      {as != undefined ? (
        <Box onClick={handleClick} w="100%">
          {as}
        </Box>
      ) : (
        <Button variant="outline" colorScheme="red" onClick={handleClick}>
          Delete
        </Button>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleClose}
        isCentered
      >
        <AlertDialogOverlay>
          {renderAlertContent({ onSuccess: handleClose })}
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default DeleteButton
