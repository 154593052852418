/*
Overall dashboard page display that combines the actual dashboard (widgets),
navigation bar, and editing capabilities
 */

import * as htmlToImage from 'html-to-image'
import { useRouter } from 'next/router'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactGridLayout from 'react-grid-layout'

import { Box, Fade, HStack, Skeleton } from '@chakra-ui/react'

import { useDashboardNavigationContext } from '../../../context/dashboard-navigation/context'
import { useDashboardSettingsModalContext } from '../../../context/dashboard-settings-modal/context'
import { useCurrentUser } from '../../../context/user/context'
import { routes } from '../../../utils/constants'
import firebase from '../../../utils/firebase/firebase'
import db from '../../../utils/firebase/firestore'
import { Widget, WidgetType } from '../../widgets/types'
import AddWidgetModal from '../AddWidgetModal'
import DashboardComments from '../DashboardComments'
import { WidgetLayoutKey } from '../types'
import { widgetTypeToLayout } from '../utils/gridLayoutSettings'
import EditWidgetDrawerV2 from './EditWidgetDrawerV2'
import WidgetLayout from './WidgetLayout'
import ActionButtons from './header/ActionButtons'
import DashboardHeader from './header/DashboardHeaderV2'
import DashboardLoadingView from './nullViews/DashboardLoadingView'

export type DashboardLayouts = ReactGridLayout.Layouts

const COMMENTS_WIDTH_TRANSITION_DURATION = 500

interface FirestoreTimestamp {
  seconds: number
  nanoseconds: number
}

export interface DashboardAuthor {
  displayName: string
  id: string
  username: string
}

export interface Dashboard {
  author: DashboardAuthor
  clonedFrom?: {
    id: string
    title: string
  }
  created: FirestoreTimestamp
  description: string
  followerCount: number
  id: string
  isPublic: boolean
  layouts: DashboardLayouts
  tags: string[]
  title: string
  widgetCount: number
  widgetCounts: Record<WidgetType, number>
}

export type DashboardData = Record<string, unknown>

export interface Snapshot {
  refreshed: number
  created: FirestoreTimestamp
  data: DashboardData
  id: string
  title: string
}

export interface MostRecentView {
  refreshed: number
  data: DashboardData
}

export const uploadDashboardPreviewImage = async (
  id: string | undefined
): Promise<void> => {
  if (id == undefined) return
  const el = document.getElementById(`screenshot-dashboardDisplay-${id}`)
  if (el == null || el.offsetWidth < 600) return

  const filter = (el: HTMLElement): boolean => {
    const exclusionClasses = ['twitter-widget']
    if (el.classList == undefined) return true
    return !exclusionClasses.some((classname) =>
      el.classList.contains(classname)
    )
  }

  try {
    const pngBlob = await htmlToImage.toBlob(el, {
      backgroundColor: 'rgb(246, 247, 249)',
      width: el.offsetWidth,
      height: el.offsetWidth / 1.91,
      quality: 0.5,
      filter: filter,
    })
    if (pngBlob == null) return
    const avatarsBucket = firebase.storage().ref('dashboard-previews')
    const uploadTask = avatarsBucket.child(id).put(pngBlob)

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (res) => console.log(res),
      (error) => {
        console.error('Error uploading dashboard preview', error)
      }
    )
  } catch (e) {
    console.error('Error generating or uploading dashboard preview', e)
  }
}

interface Props {
  dashboard?: Dashboard | undefined
}

const DashboardDisplayV3 = ({}: Props): ReactElement => {
  const [widgets, setWidgets] = useState<Widget[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [newWidgetId, setNewWidgetId] = useState<string | undefined>(undefined)
  const {
    addWidgetModalOpen,
    handleCloseAddWidgetModal,
    handleOpenAddWidgetModal,
    isEditingDashboard: isEditingLayout,
    isViewingComments,
    refreshSelectedDashboard,
    selectedDashboard: dashboard,
    selectedDashboardData: mostRecentView,
    selectedDashboardDataStatus,
    selectedDashboardLayouts: editedLayouts,
    selectedWidgetId,
    setIsEditingDashboard,
    setSelectedDashboardLayouts: setEditedLayouts,
    setSelectedWidgetId,
  } = useDashboardNavigationContext()

  const isRefreshing = selectedDashboardDataStatus === 'refreshing'

  const dashboardId = dashboard?.id

  const { closeSettings: handleCloseSettingsModal } =
    useDashboardSettingsModalContext()

  const router = useRouter()

  // State to track if dashboard is being edited
  // const [isEditingLayout, setIsEditingLayout] = useState(false)

  // and which, if any, widget is being edited
  const [widgetBeingEdited, setWidgetBeingEdited] = useState<
    Widget | undefined
  >(undefined)

  // state of the title (for keeping track of edits)
  const [editedTitle, setEditedTitle] = useState<string | undefined>(
    dashboard?.title
  )
  // state of the layouts (for keeping track of edits)
  // const [editedLayouts, setEditedLayouts] = useState<
  //   DashboardLayouts | undefined
  // >(dashboard?.layouts)

  // Get the authed user object
  /* TODO replace with using context to see user object that's being
  listened to in the index.tsx page
  */

  const { user, handleFollowDashboard, handleUnfollowDashboard } =
    useCurrentUser()
  const userId = user?.uid

  const author = dashboard?.author
  const isAuthor = userId != null && author?.id === userId

  // Get widgets on mount
  useEffect(() => {
    if (dashboardId != undefined) {
      db.collection(`dashboards/${dashboardId}/widgets`)
        .get()
        .then((widgetsSnapshot) => {
          const widgets: Widget[] = widgetsSnapshot.docs.map((widgetDoc) => {
            return widgetDoc.data() as Widget
          })
          setWidgets(widgets)
          setIsLoading(false)
        })
    }
    return () => {
      setWidgets([])
      setIsLoading(true)
    }
  }, [dashboardId])

  // Set editing to false when dashboardId changes
  useEffect(
    () => setIsEditingDashboard(false),
    [dashboardId, setIsEditingDashboard]
  )

  useEffect(() => {
    if (selectedWidgetId != undefined) {
      const selectedWidget = widgets.find((w) => w.id == selectedWidgetId)
      setWidgetBeingEdited(selectedWidget)
    }
  }, [selectedWidgetId, widgets])

  // Get widgets and layouts
  // useEffect(() => {
  //   if (dashboardId != undefined && initialWidgetsSet) {
  //     const widgetsRef = db.collection(`dashboards/${dashboardId}/widgets`)
  //     widgetsRef.get().then((widgetsSnapshot) => {
  //       const widgets: Widget[] = widgetsSnapshot.docs.map((widgetDoc) => {
  //         return widgetDoc.data() as Widget
  //       })
  //       setWidgets(widgets)
  //     })
  //   }
  // }, [dashboardId, initialWidgetsSet])

  const handleFollow = useCallback(async () => {
    if (dashboardId == undefined) return
    return handleFollowDashboard(dashboardId)
  }, [dashboardId, handleFollowDashboard])
  const handleUnfollow = useCallback(async () => {
    if (dashboardId == undefined) return
    return handleUnfollowDashboard(dashboardId)
  }, [dashboardId, handleUnfollowDashboard])

  // useEffect(() => {
  //   if (
  //     selectedWidgetId != undefined &&
  //     selectedWidgetId != widgetBeingEdited?.id
  //   ) {
  //     setWidgetBeingEdited(widgets.filter((w) => w.id === selectedWidgetId)[0])
  //   }
  // }, [selectedWidgetId, widgets, widgetBeingEdited])

  //initialize layouts and title once a dashboard is loaded
  useEffect(() => {
    if (dashboard != null) {
      setEditedLayouts(dashboard.layouts)
      setEditedTitle(dashboard.title)
    }
    return () => {
      setEditedLayouts(undefined)
      setEditedTitle('')
    }
  }, [dashboard, setEditedLayouts])

  const toggleEditingDashboard = useCallback(
    () => setIsEditingDashboard((wasEditing) => !wasEditing),
    [setIsEditingDashboard]
  )

  const handleSetWidgetBeingEdited = useCallback(
    (widget: Widget | undefined) => {
      setWidgetBeingEdited(widget)
      setSelectedWidgetId(widget?.id)
    },
    [setSelectedWidgetId]
  )

  const handleCloseDrawer = useCallback(() => {
    handleSetWidgetBeingEdited(undefined)
  }, [handleSetWidgetBeingEdited])

  // Control whether or not the widget adding modal is open
  // const closeAddWidgetModal = useCallback(
  //   () => setIsShowingAddWidgetModal(false),
  //   []
  // )

  const bottomRef = useRef(null as null | HTMLDivElement)

  // save author-only-editable settings: title, layouts
  const handleAuthorSaveDashboard = useCallback(() => {
    const cleanLayoutsObject = cleanLayouts(editedLayouts)
    setEditedLayouts(cleanLayoutsObject)
    if (dashboard?.id != undefined) {
      db.doc(`dashboards/${dashboard.id}`).update({
        title: editedTitle,
        layouts: cleanLayoutsObject,
      })
    }

    setIsEditingDashboard(false)
  }, [
    editedLayouts,
    editedTitle,
    dashboard?.id,
    setIsEditingDashboard,
    setEditedLayouts,
  ])

  // Handle user adding a widget from the modal by closing the modal, adding the widget,
  // scrolling to it, and opening the editing drawer for the new widget

  const updateNewWidgetInLayouts = useCallback(
    (widgetId: string, widgetType: WidgetType) => {
      const updatedLayouts = editedLayouts ?? {} // fill all breakpoints with defaults for this widget
      const breakpointKeys = Object.keys(
        widgetTypeToLayout[widgetType]
      ) as WidgetLayoutKey[]
      breakpointKeys.forEach((breakpoint: WidgetLayoutKey) => {
        // if (editedLayouts != undefined && updatedLayouts != undefined) {

        //
        // const widgetsMatch = editedLayouts[breakpoint]?.filter(
        //   (item: ReactGridLayout.Layout) => item.i === widgetId
        // )

        const newWidgetLayout = {
          i: widgetId,
          ...widgetTypeToLayout[widgetType][breakpoint],
        }

        // assign the new widget a y position of the last row + 1
        newWidgetLayout.y =
          editedLayouts != undefined &&
          editedLayouts[breakpoint] != undefined &&
          editedLayouts[breakpoint].length > 0
            ? 1 +
              Math.max(
                ...editedLayouts[breakpoint].map(
                  (item: ReactGridLayout.Layout) => item.y
                )
              )
            : 0

        newWidgetLayout.minW =
          widgetTypeToLayout[widgetType][breakpoint]['minW']

        newWidgetLayout.maxW =
          widgetTypeToLayout[widgetType][breakpoint]['maxW']
        // if (widgetsMatch != null) {
        //   if (widgetsMatch.length == 0) {
        //     updatedLayouts[breakpoint] = [
        //       ...updatedLayouts[breakpoint],
        //       newWidgetLayout,
        //     ]
        //   } else {
        //
        updatedLayouts[breakpoint] = [
          ...updatedLayouts[breakpoint].filter(
            (item: ReactGridLayout.Layout) => item.i != widgetId
          ),
          newWidgetLayout,
        ]
        // }
        // } else {
        //   updatedLayouts[breakpoint] = [newWidgetLayout]
        // }
        // }
      })
      setEditedLayouts(updatedLayouts)
      handleAuthorSaveDashboard()
      return updatedLayouts
    },
    [editedLayouts, handleAuthorSaveDashboard, setEditedLayouts]
  )

  const handleSelectedNewWidget = (widget: Widget) => {
    handleCloseAddWidgetModal()
    widget.id = 'new'
    setTimeout(() => setWidgetBeingEdited(widget), 100)
  }

  const handleCreatedWidget = useCallback(
    async (newWidget: Widget) => {
      setWidgets((widgets) => [...widgets, newWidget])

      setNewWidgetId(newWidget.id)

      const updatedLayouts = cleanLayouts(
        updateNewWidgetInLayouts(newWidget.id, newWidget.type)
      )

      // update layouts
      if (dashboardId != undefined) {
        db.doc(`dashboards/${dashboardId}`).update({
          layouts: updatedLayouts,
        })
      }

      // scroll to element
      bottomRef?.current != null &&
        bottomRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })

      await refreshSelectedDashboard()

      setNewWidgetId(undefined)
    },
    [dashboardId, updateNewWidgetInLayouts, refreshSelectedDashboard]
  )

  // do not do a full clean of the layouts - only remove layouts with no entries
  // do a full clean when updating firebase only

  const handleLayoutsChange = useCallback(
    (_: ReactGridLayout.Layout[] | null, allLayouts: DashboardLayouts) => {
      Object.keys(allLayouts).forEach((breakpoint) => {
        allLayouts[breakpoint].length === 0 && delete allLayouts[breakpoint]
      })

      setEditedLayouts(allLayouts)
    },
    [setEditedLayouts]
  )

  // remove properties deep within the layouts object that are undefined
  // otherwise, firebase errors out when updating
  const cleanLayouts = (
    layouts: DashboardLayouts | undefined
  ): DashboardLayouts | undefined => {
    if (layouts == undefined) {
      return undefined
    }

    const cleanLayoutsObject: Record<string, ReactGridLayout.Layout[]> = {}

    Object.keys(layouts).forEach((breakpoint: keyof DashboardLayouts) => {
      const cleanBreakpointArray = layouts[breakpoint].map(
        (widgetLayout: ReactGridLayout.Layout) => {
          const keys = Object.keys(widgetLayout) as Array<
            keyof ReactGridLayout.Layout
          >
          keys.forEach((layoutProp) => {
            widgetLayout[layoutProp] == undefined &&
              delete widgetLayout[layoutProp]
          })
          return widgetLayout
        }
      )
      if (cleanBreakpointArray.length > 0) {
        cleanLayoutsObject[breakpoint] = cleanBreakpointArray
      }
    })

    return cleanLayoutsObject
  }

  const handleSaveWidgetSettings = useCallback(
    (updatedWidget: Widget) => {
      setNewWidgetId(updatedWidget.id)

      setWidgets((widgets) => {
        const updatedWidgets = widgets.map((widget) => {
          if (widget.id === updatedWidget.id) {
            return updatedWidget
          }
          return widget
        })
        return updatedWidgets
      })

      refreshSelectedDashboard()
    },
    [refreshSelectedDashboard]
  )

  const handleDeleteWidget = useCallback(
    (widgetId: string) => {
      const widgetRef = db.doc(`dashboards/${dashboardId}/widgets/${widgetId}`)
      widgetRef
        .delete()
        .then(() => {
          handleSetWidgetBeingEdited(undefined)
          // TODO: trigger re-render to get updated widgets

          // remove deleted widgets from current state
          setWidgets((widgets) =>
            widgets.filter((previousWidget) => previousWidget.id != widgetId)
          )
        })
        .catch((error) => {
          console.error(error)
        })
    },
    [dashboardId, handleSetWidgetBeingEdited]
  )

  const handleDeleteDashboard = useCallback(async () => {
    if (dashboardId == undefined) return
    await db
      .doc(`dashboards/${dashboardId}`)
      .delete()
      .catch((error) => {
        console.error(error)
        return
      })

    router.push({
      pathname: routes.home,
      query: {},
    })

    handleCloseSettingsModal()
  }, [dashboardId, router, handleCloseSettingsModal])

  useEffect(() => {
    const resizeDelay = isViewingComments ? 100 : 1200
    setTimeout(
      () => window.dispatchEvent(new Event('resize')),
      COMMENTS_WIDTH_TRANSITION_DURATION + resizeDelay
    )
  }, [isViewingComments])

  const canEditSettings = isAuthor

  // EARLY EXIT when dashboard is null or loading
  if (dashboard == null || isLoading) {
    return <DashboardLoadingView />
  }

  const refreshedTime = mostRecentView?.refreshed

  const isFollowing = dashboardId
    ? user?.followedDashboards.includes(dashboardId) ?? false
    : false

  // TODO: delete these things and use them locally within ActionButtons
  const actionButtons = (
    <ActionButtons
      isAuthor={isAuthor}
      isFollowing={isFollowing}
      isLoadingRefresh={isRefreshing}
      onDeleteDashboard={handleDeleteDashboard}
      onFollow={handleFollow}
      onRefresh={refreshSelectedDashboard}
      onUnfollow={handleUnfollow}
      widgets={widgets}
    />
  )

  return (
    <>
      <HStack maxW="100%" minH="100%" h="100%" w="100%" spacing={0}>
        <Box
          id={`screenshot-dashboardDisplay-${dashboardId}`}
          h="100%"
          overflowY="scroll"
          w="100%"
          overflowX={'hidden'}
        >
          <Fade in={isRefreshing}>
            <Skeleton
              height="10px"
              borderRadius={0}
              fadeDuration={1}
              w="100%"
              speed={0.7}
              isLoaded={!isRefreshing}
            />
          </Fade>
          <DashboardHeader
            actionButtons={actionButtons}
            author={dashboard.author}
            created={dashboard.created.seconds * 1000}
            clonedFrom={dashboard.clonedFrom}
            dashboardId={dashboard.id}
            description={dashboard.description}
            followerCount={dashboard.followerCount}
            isFollowing={true}
            onFollow={handleFollow}
            refreshed={refreshedTime}
            tags={dashboard.tags}
            title={dashboard.title}
          />
          {widgetBeingEdited != undefined && (
            <EditWidgetDrawerV2
              canEdit={canEditSettings}
              dashboardId={dashboard.id}
              onClose={handleCloseDrawer}
              onCreatedWidget={handleCreatedWidget}
              onDeleteWidget={() => handleDeleteWidget(widgetBeingEdited.id)}
              onSaveEdits={handleSaveWidgetSettings}
              widget={widgetBeingEdited}
            />
          )}
          <AddWidgetModal
            dashboardId={dashboard.id}
            isOpen={addWidgetModalOpen}
            onAddWidget={handleSelectedNewWidget}
            onClose={handleCloseAddWidgetModal}
          />
          <br />
          <Box
            px={6}
            // pt={-100}
            mt={-20}
            h="100%"
            w="100%"
            // onClick={handleStopEditingDashboard}
          >
            <WidgetLayout
              isLoadingRefresh={isRefreshing}
              onOpenAddWidgetModal={handleOpenAddWidgetModal}
              isLoading={isLoading}
              onEditLayout={toggleEditingDashboard}
              isAuthor={isAuthor}
              dashboardData={mostRecentView?.data}
              isEditingDashboard={isEditingLayout}
              layouts={dashboard?.layouts}
              onEditWidget={handleSetWidgetBeingEdited}
              widgets={widgets}
              onChangeLayouts={handleLayoutsChange}
              onDeleteWidget={handleDeleteWidget}
              newWidgetId={newWidgetId}
            />
            {/* <Box ref={bottomRef} /> */}
          </Box>
        </Box>
        {/* <Box w="100%"> */}
        <Box
          right="0"
          w={isViewingComments ? '100%' : '0'}
          maxW="350px"
          transition={`width ${COMMENTS_WIDTH_TRANSITION_DURATION}ms`}
          transitionTimingFunction={'linear'}
          h="100%"
        >
          <DashboardComments />
        </Box>
        {/* </Box> */}
        {/* </Box> */}
      </HStack>
    </>
  )
}

export default React.memo(DashboardDisplayV3)
