/**
 * Form for editing a Stock widget's settings
 * Submission of the form is handled in the parent component (EditWidgetDrawer)
 */

import React, { ReactElement } from 'react'

import { Center, Text, VStack } from '@chakra-ui/react'

import EditWidgetDrawerTemplate from '../../../dashboard/dashboardDisplay/EditWidgetDrawerTemplate'
import { IEXCloudSymbol, StockWidgetSettings } from '../types'
import TickerSelection from './TickerSelection'

interface Props {
  canEdit: boolean
  onChange: (settings: StockWidgetSettings) => void
  settings: StockWidgetSettings
}

const StockSettings = ({
  canEdit,
  onChange,
  settings,
}: Props): ReactElement => {
  const { tickers } = settings

  const handleTickersChange = (newTickers: IEXCloudSymbol[]) => {
    onChange({
      ...settings,
      tickers: newTickers,
    })
  }

  let preventSaveMessage = undefined
  if (tickers.length === 0) preventSaveMessage = 'Add at least one stock ticker'

  return (
    <EditWidgetDrawerTemplate preventSaveMessage={preventSaveMessage}>
      {/* {!canEdit ? (
        <></> //TODO: make read-only stock settings
      ) : ( */}
      <VStack align="start" spacing="6">
        <VStack align="start" w="100%">
          <TickerSelection
            tickers={settings.tickers}
            canEdit={canEdit}
            onChange={handleTickersChange}
          />
          {settings.tickers.length === 0 && (
            <Center w="100%" justifyContent="center">
              <Text fontStyle="italic" textColor="gray.500">
                No tickers selected
              </Text>
            </Center>
          )}
        </VStack>
      </VStack>
      {/* )} */}
    </EditWidgetDrawerTemplate>
  )
}

export default StockSettings
