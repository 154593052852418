import Image from 'next/image'
import React, { ReactElement } from 'react'

import {
  Badge,
  Box,
  Button,
  Flex,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react'

import { AddWidgetOption } from '.'

interface Props {
  option: AddWidgetOption
  isDisabled?: boolean
}

const AddWidgetSelector = ({
  option,
}: // isDisabled = false,
Props): ReactElement => {
  const [isMobile] = useMediaQuery('(max-width: 840px)')

  const {
    description,
    imageSrc,
    isNew,
    isDisabled,
    subtitle,
    title,
    handleAddWidget,
  } = option

  return (
    <Box
      // _hover={{ boxShadow: 'lg', borderWidth: '2px', cursor: 'pointer' }}
      position="relative"
      h="min(200px,40vw,40vh)"
      w="min(200px,40vw,40vh)"
      borderWidth="3px"
      borderColor="#F2F2F2"
      bg={isDisabled ? 'gray.50' : 'white'}
      borderRadius="10px"
      overflow="hidden"
      className={isDisabled ? '' : 'AddWidgetOption'}
      onClick={
        isMobile || isDisabled
          ? () => {
              return
            }
          : handleAddWidget
      }
    >
      <Flex
        className={isDisabled ? '' : 'AddWidgetOption__cover'}
        direction="column"
        h="100%"
      >
        <Badge
          position="absolute"
          left={2}
          top={2}
          hidden={!isNew}
          variant="subtle"
          colorScheme="green"
          fontSize="sm"
          paddingX="6px"
        >
          New
        </Badge>
        <VStack
          align="center"
          p={1}
          spacing={3}
          w="100%"
          justify="center"
          h="100%"
          top={0}
        >
          <Image
            src={imageSrc}
            width={isMobile ? '50%' : '70%'}
            height={isMobile ? '50%' : '70%'}
            alt={`${title} widget icon`}
            color="#FFFFFF"
          />
          <VStack spacing={2}>
            {subtitle != undefined && (
              <Text as="b" fontSize="sm">
                {subtitle}
              </Text>
            )}
            <Text
              as="b"
              fontSize="xl"
              color={isDisabled ? 'gray.500' : 'gray.700'}
            >
              {title}
            </Text>
            {isDisabled && <Text color="gray.500">Coming soon!</Text>}
          </VStack>
        </VStack>
      </Flex>
      <VStack
        className="AddWidgetOption__details"
        direction="column"
        h="100%"
        px={2}
        zIndex={0}
      >
        <VStack spacing={0} p={0}>
          {subtitle != undefined && (
            <Text as="b" fontSize="sm">
              {subtitle}
            </Text>
          )}
          <Text as="b" fontSize={isMobile ? 'lg' : 'xl'}>
            {title}
          </Text>
        </VStack>
        <Text textAlign="center" fontSize={isMobile ? '12px' : 'auto'}>
          {description}
        </Text>
        {isMobile && (
          <Button
            position="absolute"
            right="2"
            bottom="2"
            mt={2}
            variant="link"
            onClick={handleAddWidget}
            fontSize={'sm'}
          >
            Add
          </Button>
        )}
      </VStack>
    </Box>
  )
}

export default AddWidgetSelector
