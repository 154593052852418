import React, { ReactElement } from 'react'
import { WrapItem, Text } from '@chakra-ui/react'
import { routes } from '../../../../utils/constants'

interface Props {
  tag: string
}

const DashboardTag = ({ tag }: Props): ReactElement => {
  // TODO @CooperEdmunds : fill with actual filter params
  const url = `${routes.discover}/?q=${tag}&type=dashboards&filterTags=&sortBy=relevance`

  return (
    <WrapItem
      bg="gray.200"
      borderRadius="md"
      px={2}
      py={0.5}
      minW="initial"
      as="a"
      target="_blank"
      href={url}
      _hover={{ cursor: 'pointer', bg: 'gray.300' }}
      onClick={(e) => e.stopPropagation()}
    >
      <Text fontSize="sm">{tag}</Text>
    </WrapItem>
  )
}

export default DashboardTag
