import { useRouter } from 'next/router'
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useBoolean, useDisclosure } from '@chakra-ui/react'

import {
  Dashboard,
  MostRecentView,
} from '../../components/dashboard/dashboardDisplay/DashboardDisplayV3'
import routes from '../../utils/constants/routes'
import { useDashboardSettingsModalContext } from '../dashboard-settings-modal/context'
import { useCurrentUser } from '../user/context'
import createNewDashboard from './createNewDashboard'
import extractNavigationQueryParams, {
  SelectedDashboardViews,
} from './extractNavigationQueryParams'
import useDashboard from './useDashboard'
import useDashboardData, { DashboardDataStatus } from './useDashboardData'
import useDashboards from './useDashboards'

export type DashboardLayouts = ReactGridLayout.Layouts

interface DashboardNavigationContext {
  createNewDashboard: () => Promise<void>
  dashboards: Dashboard[] | undefined
  isEditingDashboard: boolean // TODO: put in separate context?
  refreshSelectedDashboard: () => Promise<void>
  setScrollPosition: (_scrollPos: number) => void
  setIsEditingDashboard: React.Dispatch<React.SetStateAction<boolean>>
  scrollPosition: number
  selectThreadId: (threadId: string | undefined) => void
  selectedDashboard: Dashboard | undefined
  selectedDashboardId: string | undefined
  selectedDashboardData: MostRecentView | undefined
  selectedDashboardDataStatus: DashboardDataStatus | undefined
  selectedThreadId: string | undefined
  setSelectedWidgetId: (id: string | undefined) => void
  selectedWidgetId?: string
  addWidgetModalOpen: boolean
  handleOpenAddWidgetModal: () => void
  handleCloseAddWidgetModal: () => void
  selectedDashboardLayouts: DashboardLayouts | undefined
  setSelectedDashboardLayouts: (layout: DashboardLayouts | undefined) => void
  toggleViewComments: () => void
  isViewingComments: boolean
  overviewModalOpen: boolean
  toggleOverviewModalOpen: () => void
}

export const DashboardNavigationContext =
  createContext<DashboardNavigationContext>({
    createNewDashboard: async () => {
      return
    },
    dashboards: [],
    refreshSelectedDashboard: async () => {
      return
    },
    setScrollPosition: () => {
      return
    },
    scrollPosition: 0,
    selectThreadId: () => {
      return
    },
    selectedDashboard: undefined,
    selectedDashboardData: undefined,
    selectedDashboardDataStatus: undefined,
    selectedDashboardId: undefined,
    selectedDashboardLayouts: undefined,
    setSelectedDashboardLayouts: () => {
      return
    },
    selectedThreadId: undefined,
    setSelectedWidgetId: () => {
      return
    },
    selectedWidgetId: undefined,
    addWidgetModalOpen: false,
    handleCloseAddWidgetModal: () => {
      return
    },
    handleOpenAddWidgetModal: () => {
      return
    },
    isEditingDashboard: false,
    setIsEditingDashboard: () => {
      return
    },
    toggleViewComments: () => {
      return
    },
    isViewingComments: false,
    overviewModalOpen: false,
    toggleOverviewModalOpen: () => {
      return
    },
  })

export const useDashboardNavigationContext = (): DashboardNavigationContext =>
  useContext(DashboardNavigationContext)

const DashboardNavigationContextProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const { user } = useCurrentUser()
  const router = useRouter()
  const initialSelectedViews = extractNavigationQueryParams(router.query)

  // Selected dashboard id
  const [selectedDashboardId, setSelectedDashboardId] = useState<
    string | undefined
  >(undefined)

  // Selected thread id
  const [selectedThreadId, setSelectedThreadId] = useState<string | undefined>(
    undefined
  )

  // Widget being edited/viewed id
  const [selectedWidgetId, setSelectedWidgetId] = useState<string | undefined>(
    undefined
  )

  // Selected dashboard's settings
  // const selectedDashboardSettings =
  //   useDashboardSettingsListener(selectedDashboardId)

  const {
    refreshSelectedDashboard,
    selectedDashboardData,
    selectedDashboardDataStatus,
  } = useDashboardData(selectedDashboardId)

  // Dashboards
  const { allDashboards } = useDashboards(user)

  useEffect(() => {
    setSelectedDashboardId(initialSelectedViews.id)
    setSelectedThreadId(initialSelectedViews.thread)
    setSelectedWidgetId(initialSelectedViews.widget)
  }, [
    initialSelectedViews.id,
    initialSelectedViews.thread,
    initialSelectedViews.widget,
  ])

  // Selected dashboard
  const selectedDashboardFromUserList = (allDashboards ?? []).find(
    ({ id }) => id === selectedDashboardId
  )

  // Settings modal state
  //const [settingsModalOpen, setSettingsModalOpen] = useState(false)

  const {
    isOpen: addWidgetModalOpen,
    onOpen: handleOpenAddWidgetModal,
    onClose: handleCloseAddWidgetModal,
  } = useDisclosure()

  const isFollowing = selectedDashboardFromUserList != null

  // Only if not following, add additional listener for this dashboard
  const selectedDashboardIfNotFollowing = useDashboard(
    selectedDashboardId,
    !isFollowing
  )

  // useEffect(() => {
  //   if (currPathname != routes.viewer) {
  //     setSelectedDashboardId(undefined)
  //   }
  // }, [currPathname])

  const selectedDashboard = isFollowing
    ? selectedDashboardFromUserList
    : selectedDashboardIfNotFollowing

  // Selected dashboard edited layout
  const [selectedDashboardLayouts, setSelectedDashboardLayouts] = useState<
    DashboardLayouts | undefined
  >(selectedDashboard?.layouts)

  // Scroll position
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  const [isEditingDashboard, setIsEditingDashboard] = useState(false)

  // Viewing comments state
  const [isViewingComments, { toggle: toggleViewComments }] = useBoolean(false)

  const [overviewModalOpen, { toggle: toggleOverviewModalOpen }] =
    useBoolean(false)

  const { openSettings: handleOpenDashboardSettingsModal } =
    useDashboardSettingsModalContext()

  const setQueryParams = useCallback(
    (queryParams: Partial<SelectedDashboardViews>) => {
      const queryParamKeys = Object.keys(queryParams) as Array<
        keyof SelectedDashboardViews
      >
      const id = queryParams.id
      delete queryParams['id']
      queryParamKeys.forEach((key) => {
        if (queryParams[key] == undefined || queryParams[key] == '') {
          delete queryParams[key]
        }
      })

      if (id) {
        router.push({
          pathname: routes.dashboard(id),
          query: queryParams,
        })
      }
    },
    [router]
  )

  // const selectDashboard = useCallback(
  //   (dashboardId: string) => {
  //     setQueryParams({
  //       id: dashboardId,
  //     })
  //     setSelectedThreadId(undefined)
  //     setSelectedDashboardId(dashboardId)
  //   },
  //   [setQueryParams]
  // )

  const selectThreadId = useCallback(
    (threadId: string | undefined) => {
      setQueryParams({
        id: selectedDashboardId,
        thread: threadId,
      })
      setSelectedThreadId(threadId)
    },
    [selectedDashboardId, setQueryParams]
  )

  const createDashboard = useCallback(async () => {
    if (user == undefined) {
      throw new Error("User is undefined; can't create new dashboard")
    }

    const numDashboardsAsAuthor =
      allDashboards?.filter((dashboard) => dashboard.author.id == user.uid)
        .length ?? 0

    // create new dashboard
    const newDashboardId = await createNewDashboard(user, numDashboardsAsAuthor)

    // select new dashboard
    router.push(routes.dashboard(newDashboardId))

    // open settings modal
    handleOpenDashboardSettingsModal(newDashboardId)
  }, [user, allDashboards, router, handleOpenDashboardSettingsModal])

  const handleSetSelectedWidgetId = useCallback(
    (widgetId: string | undefined) => {
      setQueryParams({
        id: selectedDashboardId,
        widget: widgetId,
      })

      setSelectedWidgetId(widgetId)
    },
    [selectedDashboardId, setQueryParams]
  )

  return (
    <DashboardNavigationContext.Provider
      value={{
        createNewDashboard: createDashboard,
        dashboards: allDashboards,
        refreshSelectedDashboard,
        setScrollPosition,
        selectThreadId,
        selectedDashboard,
        selectedDashboardId,
        selectedDashboardData,
        selectedDashboardDataStatus,
        selectedThreadId,
        setSelectedWidgetId: handleSetSelectedWidgetId,
        selectedWidgetId,
        selectedDashboardLayouts,
        setSelectedDashboardLayouts,
        scrollPosition,
        addWidgetModalOpen,
        handleCloseAddWidgetModal,
        handleOpenAddWidgetModal,
        isEditingDashboard,
        setIsEditingDashboard,
        toggleViewComments,
        isViewingComments,
        overviewModalOpen,
        toggleOverviewModalOpen,
      }}
    >
      {children}
    </DashboardNavigationContext.Provider>
  )
}

export default DashboardNavigationContextProvider
