import firebase from './firebase'
import 'firebase/storage'
import React from 'react'
import { FormikProps } from 'formik'
import { UserInfoValues } from '../../components/forms/types'

//TODO: Updating state from within function is not good but
//      not sure how to pull firebaseURL out otherwise

export const deleteAvatar: (
  uid: string | undefined,
  formik: FormikProps<UserInfoValues>
) => void = async (uid, formik) => {
  const storageRef = firebase.storage().ref()
  const avatarRef = storageRef.child(`avatars/${uid}`)

  if (avatarRef != null) {
    avatarRef
      .delete()
      .then(() => {
        formik.setFieldValue('avatarUrl', '')
      })
      .catch((error: string) => {
        formik.setFieldError('avatarUrl', error)
      })
  }

  return
}

export const uploadAvatar: (
  e: React.ChangeEvent<HTMLInputElement>,
  uid: string | undefined,
  formik: FormikProps<UserInfoValues>
) => void = async (e, uid, formik) => {
  e.preventDefault()
  const files = e.target.files

  if (uid == null) {
    console.error('Not signed in')
    formik.setFieldError('avatarUrl', 'No file uploaded')
    return
  }

  if (files == null || files.length === 0) {
    formik.setFieldError('avatarUrl', 'No file uploaded')
    return
  }

  const image = files[0]

  const allowedFileTypes = ['image/jpeg', 'image/png']
  if (!allowedFileTypes.includes(image.type)) {
    formik.setFieldError('avatarUrl', `Please only upload .png or .jpeg`)
    return
  }

  const avatarsBucket = firebase.storage().ref('avatars')
  const uploadTask = avatarsBucket.child(uid).put(image)

  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    null,
    async (error) => {
      formik.setFieldError('avatarUrl', error.message)
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((imageUrl: string) => {
        formik.setFieldValue('avatarUrl', imageUrl)
      })
    }
  )

  return
}
