import { useState, ReactElement } from 'react'
import { AiFillRobot } from 'react-icons/ai'
import { Avatar } from '@chakra-ui/react'

import { User } from '../../context/user/types'
import { DashboardAuthor } from '../dashboard/dashboardDisplay/DashboardDisplayV3'
import { UserSearchResultData } from '../discover/types'

import * as constants from '../../utils/constants'

interface Props {
  size: string
  user: DashboardAuthor | User | UserSearchResultData | undefined
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined
  children?: JSX.Element
  overrideUrl?: string
}

const DefaultAvatar = ({
  size,
  onClick,
  user,
  overrideUrl,
}: Props): ReactElement => {
  const avatarUrl = overrideUrl ?? constants.avatarUrl(user?.id)
  const avatarColor = constants.avatarColor(user?.id)

  const [isLoading, setLoading] = useState(true)

  if (avatarUrl == undefined) {
    return (
      <Avatar
        bg={avatarColor}
        _hover={{ cursor: 'pointer' }}
        icon={<AiFillRobot />}
        onClick={onClick}
        size={size}
      />
    )
  }

  return (
    <Avatar
      bg={isLoading ? undefined : avatarColor}
      icon={isLoading ? undefined : <AiFillRobot />}
      _hover={{ cursor: 'pointer' }}
      onClick={onClick}
      size={size}
      src={avatarUrl}
      onError={() => setLoading(false)}
    />
  )
}

export default DefaultAvatar
