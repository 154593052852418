import React, { ReactElement } from 'react'

import { Button, Heading } from '@chakra-ui/react'
import { Center, Text, VStack } from '@chakra-ui/react'

import { useAuthModalContext } from '../../../../context/auth-modal/context'
import { useDashboardNavigationContext } from '../../../../context/dashboard-navigation/context'
import { useCurrentUser } from '../../../../context/user/context'
import { routes } from '../../../../utils/constants'

interface Props {
  onOpenAddWidgetModal: () => void
  isAuthor: boolean
}

const NoWidgetsView = ({
  onOpenAddWidgetModal,
  isAuthor,
}: Props): ReactElement => {
  const { createNewDashboard } = useDashboardNavigationContext()
  const { user } = useCurrentUser()
  const { openAuthModal } = useAuthModalContext()

  if (isAuthor) {
    return (
      <Center h="80%">
        <VStack w="80%" spacing={4} align="center">
          <Heading as="h1" size="4xl">
            🎨
          </Heading>
          <Text align="center" fontSize="2xl">
            <b> This is your blank canvas</b>
          </Text>
          <Text align="center" fontSize="2xl">
            <Button colorScheme="blue" onClick={onOpenAddWidgetModal}>
              Add widgets
            </Button>
          </Text>
        </VStack>
      </Center>
    )
  }

  const nullViewHeading = (
    <>
      {' '}
      <Heading as="h1" size="4xl">
        🤷‍♀️
      </Heading>
      <Text align="center" fontSize="2xl">
        <b>{'No widgets to see here.'}</b>
      </Text>
    </>
  )
  if (user != undefined) {
    return (
      <Center h="80%">
        <VStack w="80%" spacing={4} align="center">
          {nullViewHeading}
          <Text align="center">
            {"Don't worry, you can "}
            <Text
              as="a"
              color="brand.pageLink"
              _hover={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              href={routes.discover}
            >
              browse other dashboards
            </Text>{' '}
            or{' '}
            <Text
              color="brand.pageLink"
              _hover={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={createNewDashboard}
            >
              create your own.
            </Text>
          </Text>
        </VStack>
      </Center>
    )
  }

  return (
    <Center h="80%">
      <VStack w="80%" spacing={4} align="center">
        {nullViewHeading}
        <Text align="center">
          <Text
            as="span"
            color="brand.pageLink"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => openAuthModal('logIn')}
          >
            Log in
          </Text>{' '}
          or{' '}
          <Text
            as="span"
            color="brand.pageLink"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => openAuthModal('signUp')}
          >
            sign up
          </Text>{' '}
          to browse other dashboards or create your own.
        </Text>
      </VStack>
    </Center>
  )
}

export default NoWidgetsView
