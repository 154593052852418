/*
Right drawer showing options for editing a widget's settings. Appears when 
the user clicks the "edit" button on a widget
 */
import React, { ReactElement, createContext, useEffect, useState } from 'react'

import { Drawer, DrawerOverlay } from '@chakra-ui/react'

import NewsSettings from '../../widgets/news/NewsSettings'
// import CryptoSettings from '../../widgets/crypto/CryptoSettings'
import RedditSettings from '../../widgets/reddit/settings/RedditSettings'
import StockSettings from '../../widgets/stocks/settings/StockSettings'
import TwitterSettings from '../../widgets/twitter/TwitterSettings'
import { Widget, WidgetSettings } from '../../widgets/types'

interface WidgetSettingsDrawerContext {
  canEdit: boolean
  dashboardId: string
  onClose: () => void
  onCreatedWidget: (newWidget: Widget) => void
  onDeleteWidget: () => void
  onSaveEdits: (updatedWidget: Widget) => void
  widget: Widget | undefined
  onChangeTitle: (title: string) => void
}

export const WidgetSettingsDrawerContext =
  createContext<WidgetSettingsDrawerContext>({
    dashboardId: '',
    onClose: () => {
      return
    },
    onDeleteWidget: () => {
      return
    },
    onSaveEdits: () => {
      return
    },
    onCreatedWidget: () => {
      return
    },
    widget: undefined,
    onChangeTitle: () => {
      return
    },
    canEdit: false,
  })

interface Props {
  dashboardId: string
  onClose: () => void
  onCreatedWidget: (newWidget: Widget) => void
  onDeleteWidget: () => void
  onSaveEdits: (updatedWidget: Widget) => void
  widget: Widget | undefined
  canEdit: boolean
}

function EditWidgetDrawer({
  widget: initialWidget,
  canEdit,
  ...rest
}: Props): ReactElement {
  const [widget, setWidget] = useState<Widget | undefined>(undefined)

  useEffect(() => {
    setWidget(initialWidget)
    return () => {
      setWidget(undefined)
    }
  }, [initialWidget])

  const handleSettingsChange = (settings: WidgetSettings) => {
    setWidget((prevWidget: Widget | undefined) => {
      if (prevWidget != undefined) {
        const newWidget: Widget = { ...prevWidget }
        newWidget.settings = settings
        return newWidget
      }
    })
  }

  if (widget === undefined) {
    return <></>
  }

  const settingsComponent: ReactElement = (
    <>
      {widget.type === 'stocks' && (
        <StockSettings
          canEdit={canEdit}
          settings={widget.settings}
          onChange={handleSettingsChange}
        />
      )}
      {widget.type === 'news' && (
        <NewsSettings
          settings={widget.settings}
          onChange={handleSettingsChange}
          canEdit={canEdit}
        />
      )}
      {/* {widget.type === 'crypto' && (
        <CryptoSettings
          canEdit={canEdit}
          settings={widget.settings}
          onChange={handleSettingsChange}
        />
      )} */}
      {widget.type === 'reddit' && (
        <RedditSettings
          settings={widget.settings}
          onChange={handleSettingsChange}
          canEdit={canEdit}
        />
      )}
      {widget.type === 'twitter' && (
        <TwitterSettings
          settings={widget.settings}
          onChange={handleSettingsChange}
          canEdit={canEdit}
        />
      )}
    </>
  )

  return (
    <Drawer
      isOpen={widget != undefined}
      placement="right"
      onClose={rest.onClose}
      size="md"
    >
      <DrawerOverlay />

      <WidgetSettingsDrawerContext.Provider
        value={{
          ...rest,
          canEdit,
          widget,
          onChangeTitle: (title) => {
            setWidget((w) => ({ ...(w as Widget), title }))
          },
        }}
      >
        {settingsComponent}
      </WidgetSettingsDrawerContext.Provider>
    </Drawer>
  )
}

export default EditWidgetDrawer
