import React, { ReactNode, useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'

import {
  Box,
  Flex,
  Slide,
  useMediaQuery,
  IconButton,
  ChakraProps,
} from '@chakra-ui/react'

import { GrClose } from 'react-icons/gr'

import { useOutsideListener } from '../../utils/hooks/useOutsideListener'

interface Props extends ChakraProps {
  isOpen: boolean
  bgColor?: string
  onClose: () => void
  children: ReactNode | ReactNode[]
}

const VerticalDrawer = ({
  isOpen,
  onClose,
  children,
  bgColor = 'white',
  height = '90vh',
  width = '70vw',
  padding = 2,
  ...rest
}: Props): React.ReactElement => {
  const drawerRef = useRef(null)
  useOutsideListener(drawerRef, onClose)
  const [isMobile] = useMediaQuery('(max-width: 840px)')

  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const h = window?.innerHeight

  const drawer = isOpen && (
    <Flex w="100" h="100%" justifyContent="center" pos="absolute">
      <Box
        w="100%"
        h="100%"
        pos={'fixed'}
        top="0"
        right="0"
        bgColor="black"
        opacity="0.8"
        zIndex={100}
      ></Box>
      <Slide direction="bottom" in={isOpen} style={{ zIndex: 1000 }}>
        <Flex w="100" h="100%" justifyContent="center" top={0}>
          <RemoveScroll>
            <Box
              ref={drawerRef}
              h={isMobile ? h : height}
              w={isMobile ? '100vw' : width}
              pt={isMobile ? '10rem' : rest.paddingTop ?? padding}
              // pt={rest.paddingTop ?? padding}
              px={isMobile ? '2vw' : padding}
              bg={bgColor}
              borderTopLeftRadius="md"
              borderTopRightRadius="md"
              shadow="md"
              overflowY="scroll"
              top={0}
              zIndex={1100}
              {...rest}
            >
              {isMobile && (
                <IconButton
                  icon={<GrClose fontSize={10} />}
                  aria-label="Close"
                  pos="fixed"
                  left="calc(100% - 2rem)"
                  mt="5vh"
                  isRound
                  shadow="md"
                  size={'xs'}
                  onClick={onClose}
                />
              )}
              {children}
            </Box>
          </RemoveScroll>
        </Flex>
      </Slide>
    </Flex>
  )

  if (isBrowser) {
    const root = document.getElementById('drawer-root')
    if (root != null) return ReactDOM.createPortal(drawer, root)
  }
  return <></>
}

export default VerticalDrawer
