import React, { ReactElement } from 'react'

import { Center, Grid, GridItem } from '@chakra-ui/react'

import CryptoIcon from './../../../../public/genericCrypto.svg'
import NewsIcon from './../../../../public/news-emoji.png'
import RedditIcon from './../../../../public/roundedSocials/reddit.svg'
import StocksIcon from './../../../../public/stocks-emoji.png'
import TwitterIcon from './../../../../public/twitterIconBlue.svg'
import Modal from '../../../components/settings/Modal'
import { NewsWidget } from '../../widgets/news/types'
import { RedditWidget } from '../../widgets/reddit/types'
import { TwitterWidget } from '../../widgets/twitter/types'
import { Widget } from '../../widgets/types'
import AddWidgetSelector from './AddWidgetSelector'

export const defaultRedditWidget: RedditWidget = {
  title: '',
  id: '',
  type: 'reddit',
  settings: {
    subreddits: [],
    shouldUseRequiredWords: false,
    requiredWords: [],
    shouldUseExcludedWords: false,
    excludedWords: [],
    requiredWordsJoiner: 'any',
    excludedWordsJoiner: 'any',
  },
}

export interface AddWidgetOption {
  description: string
  imageSrc: StaticImageData
  isNew: boolean
  subtitle?: string
  title: string
  handleAddWidget: () => Promise<void>
  isDisabled: boolean
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onAddWidget: (widget: Widget) => void
  dashboardId: string
}

const AddWidgetModal = ({
  isOpen,
  onClose,
  onAddWidget,
}: Props): ReactElement => {
  const addWidget = (widget: Widget) => {
    widget.id = 'new'
    onAddWidget(widget)
  }

  const addStockWidget = async () => {
    const widget: Widget = {
      title: '',
      id: '',
      type: 'stocks',
      settings: { tickers: [] },
    }

    addWidget(widget)
  }

  const addNewsWidget = async () => {
    const widget: NewsWidget = {
      title: '',
      id: '',
      type: 'news',
      settings: {
        category: '',
        sources: [],
        requiredWords: [],
        excludedWords: [],
        requiredWordsJoiner: 'any',
        excludedWordsJoiner: 'any',
      },
    }

    addWidget(widget)
  }

  // const addCryptoWidget = async () => {
  //   const widget: CryptoWidget = {
  //     title: '',
  //     id: '',
  //     type: 'crypto',
  //     settings: { symbols: [] },
  //   }

  //   addWidget(widget)
  // }

  const addRedditWidget = async () => {
    addWidget(defaultRedditWidget)
  }

  const addTwitterWidget = async () => {
    const widget: TwitterWidget = {
      title: '',
      id: '',
      type: 'twitter',
      settings: {
        authorIds: [],
        allowedTweetTypes: ['quote', 'reply'],
        requiredWords: [],
        excludedWords: [],
        requiredWordsJoiner: 'any',
        excludedWordsJoiner: 'any',
      },
    }

    addWidget(widget)
  }

  const addWidgetTypes: AddWidgetOption[] = [
    {
      description: 'Filter by author, tweet text, and tweet type',
      imageSrc: TwitterIcon,
      isNew: false,
      subtitle: undefined,
      title: 'Tweets',
      handleAddWidget: addTwitterWidget,
      isDisabled: false,
    },
    {
      description: 'Filter by subreddit and key words (Flairs coming soon!)',
      imageSrc: RedditIcon,
      isNew: false,
      subtitle: undefined,
      title: 'Reddit Posts',
      handleAddWidget: addRedditWidget,
      isDisabled: false,
    },
    {
      description: 'Get real-time stock quotes and charts',
      imageSrc: StocksIcon,
      isNew: false,
      subtitle: undefined,
      title: 'Stock Prices',
      handleAddWidget: addStockWidget,
      isDisabled: false,
    },
    {
      description: 'Filter news by topic, sources, and key words',
      imageSrc: NewsIcon,
      isNew: false,
      subtitle: undefined,
      title: 'News',
      handleAddWidget: addNewsWidget,
      isDisabled: false,
    },
    {
      description: 'Get real-time cryptocurrency quotes and charts',
      imageSrc: CryptoIcon,
      isNew: false,
      subtitle: undefined,
      title: 'Crypto Prices',
      handleAddWidget: addNewsWidget,
      isDisabled: true,
    },
  ]

  return (
    <Modal
      hideDivider
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      title="Add Widget"
      shortDeviceMaxHeight="650px"
    >
      <Center h="100%" w="100%" overflowY="scroll" pb={'6'}>
        <Grid templateColumns="repeat(2, 3fr)" gap={6}>
          {addWidgetTypes.map((widgetOption, idx) => (
            <GridItem key={idx} colSpan={1}>
              <AddWidgetSelector option={widgetOption} />
            </GridItem>
          ))}
        </Grid>
      </Center>
    </Modal>
  )
}

export default AddWidgetModal
