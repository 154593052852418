import React, { ReactNode, ReactElement, useState } from 'react'
import { Button, InputGroup, InputRightElement } from '@chakra-ui/react'
import { HiEye, HiEyeOff } from 'react-icons/hi'

interface Props {
  children: (showPassword: boolean) => ReactNode
}

const PasswordInputGroup = ({ children }: Props): ReactElement => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword((prev) => !prev)

  return (
    <InputGroup>
      {children(showPassword)}
      <InputRightElement width="4rem">
        <Button
          h="1.75rem"
          size="lg"
          variant="link"
          onClick={toggleShowPassword}
        >
          {showPassword ? <HiEye /> : <HiEyeOff />}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

export default PasswordInputGroup
