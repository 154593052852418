import firebase from '../../utils/firebase/firebase'
import React, { ReactElement } from 'react'
import { useFormik, FormikProps } from 'formik'
import * as yup from 'yup'
import {
  Flex,
  FormControl,
  Input,
  FormErrorMessage,
  FormLabel,
  Button,
  VStack,
  FormHelperText,
  Text,
} from '@chakra-ui/react'
import PasswordInputGroup from '../forms/fields/PasswordInputGroup'
import { BODY_MARGIN } from './SettingsModal'
import { passwordRequirementText } from '../forms/utils'

const auth = firebase.auth()

interface FormikValues {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

function ChangePassword(): ReactElement {
  const validationSchema: yup.SchemaOf<FormikValues> = yup.object({
    currentPassword: yup
      .string()
      .required('Please enter your current password'),
    newPassword: yup
      .string()
      .min(8, 'Must be at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        `Must contain 8 characters, including at least one uppercase, number,
       and special character`
      )
      .required('Required'),
    newPasswordConfirmation: yup
      .string()
      .oneOf([yup.ref('newPassword')], `Passwords don't match`)
      .required(),
  })

  const formik: FormikProps<FormikValues> = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (
      values: FormikValues,
      { setStatus, setSubmitting, setFieldError, resetForm }
    ) => {
      try {
        const user = auth.currentUser
        if (user == null) {
          throw new Error()
        }
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email ?? '',
          values.currentPassword
        )
        try {
          await user.reauthenticateWithCredential(credential)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error(error)
          setStatus(1)
          if (error.code === 'auth/wrong-password') {
            setFieldError('currentPassword', 'Incorrect Password')
          }
          setSubmitting(false)
          return
        }
        setSubmitting(false)
        await user.updatePassword(values.newPassword)
        resetForm()
        setStatus(3)
        setTimeout(() => setStatus(undefined), 2000)
      } catch (error) {
        console.error(error)
        setStatus(2)
        setSubmitting(false)
      }
    },
  })

  return (
    <Flex
      direction="column"
      h="100%"
      py={BODY_MARGIN.t}
      px={BODY_MARGIN.x}
      overflowY="hidden"
    >
      <Text as="b" fontSize="2xl" mb={2}>
        Update Password
      </Text>
      <VStack align="stretch" spacing={4}>
        <FormControl
          isInvalid={
            Boolean(formik.errors.currentPassword) &&
            Boolean(formik.touched.currentPassword)
          }
        >
          <FormLabel>Current Password</FormLabel>
          <PasswordInputGroup>
            {(showPassword) => (
              <Input
                id="currentPassword"
                name="currentPassword"
                onChange={formik.handleChange}
                type={showPassword ? 'text' : 'password'}
                onBlur={formik.handleBlur}
                value={formik.values.currentPassword}
                maxLength={30}
                variant="outline"
                w="100%"
              />
            )}
          </PasswordInputGroup>
          <FormErrorMessage>{formik.errors.currentPassword}</FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel>New Password</FormLabel>
          <PasswordInputGroup>
            {(showPassword) => (
              <Input
                id="newPassword"
                name="newPassword"
                type={showPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                maxLength={30}
                variant="outline"
                w="100%"
              />
            )}
          </PasswordInputGroup>
          <FormHelperText
            color={
              Boolean(formik.errors.newPassword) &&
              Boolean(formik.touched.newPassword)
                ? 'red'
                : 'gray'
            }
          >
            {passwordRequirementText}
          </FormHelperText>
        </FormControl>
        <FormControl
          isInvalid={
            Boolean(formik.errors.newPasswordConfirmation) &&
            Boolean(formik.touched.newPasswordConfirmation)
          }
        >
          <FormLabel>Confirm New Password</FormLabel>
          <PasswordInputGroup>
            {(showPassword) => (
              <Input
                id="newPasswordConfirmation"
                name="newPasswordConfirmation"
                type={showPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPasswordConfirmation}
                maxLength={30}
                variant="outline"
                w="100%"
              />
            )}
          </PasswordInputGroup>
          <FormErrorMessage>{`Passwords don't match`}</FormErrorMessage>
        </FormControl>
        {formik.status === 2 && (
          <Text color="red">Server Error. Please try again later.</Text>
        )}
        {formik.status === 3 && (
          <Text color="green">Your password has been updated</Text>
        )}
        <Button
          width="100%"
          isLoading={formik.isSubmitting}
          isDisabled={!formik.isValid || !formik.dirty}
          onClick={() => formik.handleSubmit()}
        >
          Update Password
        </Button>
      </VStack>
    </Flex>
  )
}

export default ChangePassword
