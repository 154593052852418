import { useAuthUser } from 'next-firebase-auth'
import { RedditToken } from '../../components/widgets/reddit/types'
import { TwitterToken } from '../../components/widgets/twitter/types'
import { useCurrentUser } from '../../context/user/context'
import db from '../firebase/firestore'

type AuthProvider = 'reddit' | 'twitter'
type TokenType<T> = T extends 'reddit'
  ? RedditToken
  : T extends 'twitter'
  ? TwitterToken
  : never

export default function useAuthToken<T extends AuthProvider>(
  authProvider: T
): [TokenType<T> | null, (token: TokenType<T>) => void] {
  const authUser = useAuthUser()
  const userID = authUser?.firebaseUser?.uid

  type Token = TokenType<T>
  const { connectedAccounts } = useCurrentUser()
  const token = connectedAccounts.find(
    (connectedAccount) => connectedAccount.type === authProvider
  ) as Token | null

  const updateToken = async (newToken: Token) => {
    await db
      .doc(`users/${userID}/connectedAccounts/${authProvider}`)
      .set(newToken)
  }

  return [token, updateToken]
}
