import { FormikProps } from 'formik'
import React, { ReactElement } from 'react'

import {
  Center,
  Heading,
  ModalBody,
  ModalHeader,
  VStack,
} from '@chakra-ui/react'

import AvatarUpload from '../forms/fields/AvatarUpload'
import BioField from '../forms/fields/BioField'
import NameField from '../forms/fields/NameField'
import UsernameField from '../forms/fields/UsernameField'
import { UserInfoValues } from '../forms/types'
import Footer from './Footer'

interface PersonalInfoProps {
  formik: FormikProps<UserInfoValues>
  isLast?: boolean
  onNav: (increment: number, isLast?: boolean) => void
}

const PersonalInfo = ({ formik, onNav }: PersonalInfoProps): ReactElement => {
  return (
    <>
      <ModalHeader p={6}>
        <Heading size="lg">Your Profile</Heading>
      </ModalHeader>

      <ModalBody>
        <Center w="100%" h="max-content">
          <VStack alignItems="left" spacing={6} w="500px" mb={'2rem'}>
            <AvatarUpload formik={formik} />
            <NameField formik={formik} />
            <UsernameField formik={formik} />
            <BioField formik={formik} />
          </VStack>
        </Center>
      </ModalBody>

      <Footer
        isNextDisabled={
          Boolean(formik.touched.username) &&
          !Boolean(formik.errors.username) &&
          !Boolean(formik.errors.displayName) &&
          formik.status === undefined
            ? false
            : true
        }
        backLabel={undefined}
        nextLabel={'Next'}
        onNav={onNav}
        onSubmit={() => formik.handleSubmit()}
      />
    </>
  )
}

export default PersonalInfo
