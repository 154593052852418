import { ParsedUrlQuery } from 'querystring'

export interface SelectedDashboardViews {
  id: string | undefined
  thread: string | undefined
  widget: string | undefined
}

const extractNavigationQueryParams = (
  query: ParsedUrlQuery
): SelectedDashboardViews => {
  const extract = (
    value: string | string[] | undefined
  ): string | undefined => {
    if (Array.isArray(value) && value.length >= 1) return value[0]
    if (typeof value === 'string') return value
    return undefined
  }

  const selectedViews: SelectedDashboardViews = {
    id: extract(query.id),
    thread: extract(query.thread),
    widget: extract(query.widget),
  }

  return selectedViews
}

export default extractNavigationQueryParams
