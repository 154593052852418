import { useEffect, useState } from 'react'
import { Dashboard } from '../../components/dashboard/dashboardDisplay/DashboardDisplayV3'
import db from '../../utils/firebase/firestore'

export default function useDashboard(
  dashboardId: string | undefined,
  shouldListen: boolean
): Dashboard | undefined {
  const [dashboard, setDashboard] = useState<Dashboard | undefined>(undefined)

  useEffect(() => {
    if (dashboardId == undefined || !shouldListen) {
      setDashboard(undefined)
      return
    }
    const unsubscribe = db
      .doc(`dashboards/${dashboardId}`)
      .onSnapshot((snapshot) => {
        setDashboard(snapshot.data() as Dashboard)
      })
    return unsubscribe
  }, [dashboardId, shouldListen])

  return dashboard
}
